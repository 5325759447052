import React, { useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { injectIntl, FormattedMessage } from 'react-intl'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import classNames from 'classnames'
import find from 'lodash/find'
import get from 'lodash/get'

import CloseButton from '../CloseButton'
import { capitalizeAllWords } from '../../helpers/utils'
import Topology from '../SvgViews/Topology'
import { formatLocalizedDate } from '../../helpers/datetime'

const CommentSection = ({ comment }) => {
  const classes = classNames({ 'no-comments': !comment })
  const content = comment || (
    <FormattedMessage id="tracer.componentHistory.noComments" />
  )
  return <p className={classes}>{content}</p>
}

const FaultSourceDetail = ({ problemLog, component }) => {
  const { comment, faultType, relatedIncidentCount } = problemLog
  const { title, description } = component
  return (
    <div className="fault-type-container">
      <p className="subtitle">
        <FormattedMessage id="generic.component" />:
      </p>
      <p className="big">{title}</p>
      <p>{description}</p>

      <p className="subtitle">
        <FormattedMessage id="tracer.sameIssues.faultSource" />:
      </p>
      <p className="big fault-type-name">{capitalizeAllWords(faultType)}</p>

      <div className="related-incidents">
        <p className="subtitle">
          <FormattedMessage id="tracer.sameIssues.numberReported" />:
        </p>
        <span className="incident-count">{relatedIncidentCount}</span>
      </div>

      <hr />
      <p className="subtitle">
        <FormattedMessage id="generic.comments" />:
      </p>
      <CommentSection comment={comment} />
    </div>
  )
}

const PreviousCaseSelect = ({ value, onChange, history, formatString, tz }) => {
  const selectOptions = history.map((problemLog, index) => {
    const formattedTime = formatLocalizedDate(
      problemLog.timestamp,
      formatString,
      tz,
    )
    return {
      value: index,
      label: (
        <span>
          {formattedTime} <span className="vin">{problemLog.vin}</span>
        </span>
      ),
    }
  })

  return (
    <div className="select-previous-test-case">
      <Select
        className="select"
        clearable={false}
        searchable={false}
        value={selectOptions[value]}
        options={selectOptions}
        onChange={onChange}
      />
    </div>
  )
}

function SameIssuesHistory(props) {
  const { intl, isOpen, onClose, components, historiesForComponents, tz } =
    props

  const firstProblemLogIndex = 0
  const [selectedProblemLogIndex, onProblemLogSelect] =
    useState(firstProblemLogIndex)
  const selectedProblemLog = get(
    historiesForComponents,
    `[${selectedProblemLogIndex}]`,
  )
  const selectedComponentId = get(selectedProblemLog, 'componentId')
  const selectedComponent = find(components, { id: selectedComponentId })

  const formatString = intl.formatMessage({ id: 'format.timestamp' })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-same-issues"
      overlayClassName="modal-overlay"
      contentLabel="Modal"
    >
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">
            <FormattedMessage id="tracer.sameIssues.modalHeader" />
            <PreviousCaseSelect
              value={selectedProblemLogIndex}
              history={historiesForComponents}
              formatString={formatString}
              tz={tz}
              onChange={(newIndex) => onProblemLogSelect(newIndex.value)}
            />
            <CloseButton wrapperClassName="pull-right" onClose={onClose} />
          </h3>
        </div>
        <div className="panel-body">
          <div className="col-sm-3 col-lg-3 fault-source-detail">
            <FaultSourceDetail
              problemLog={selectedProblemLog}
              component={selectedComponent}
            />
          </div>
          <div className="col-sm-9 col-lg-9 vehicle-view">
            <Topology component={selectedComponent} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

SameIssuesHistory.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  historiesForComponents: arrayOf(
    shape({
      timestamp: string.isRequired,
      comment: string,
      componentId: string,
      faultType: string.isRequired,
      relatedIncidentCount: number.isRequired,
      vin: string.isRequired,
    }),
  ).isRequired,
}

export default injectIntl(SameIssuesHistory)
