import sumBy from 'lodash/sumBy'
import values from 'lodash/values'
import keys from 'lodash/keys'
import each from 'lodash/each'
import filter from 'lodash/filter'
import flattenDeep from 'lodash/flattenDeep'
import sortBy from 'lodash/sortBy'
import moment from '../i18n/moment'

const flattenHistory = (historiesForComponents) => {
  const expandedHistory = Object.entries(historiesForComponents).map(
    (component) => {
      const [componentId, faultTypes] = component
      return Object.entries(faultTypes).map((fault) => {
        const [faultType, problemLogs] = fault
        const relatedIncidentCount = problemLogs.length
        return problemLogs.map((problemLog) => {
          const { vin, created_at, annotation } = problemLog
          return {
            timestamp: created_at,
            comment: annotation,
            componentId,
            faultType: faultType,
            relatedIncidentCount,
            vin: vin,
          }
        })
      })
    },
  )
  return flattenDeep(expandedHistory)
}

const sortByMostRecent = (listOfEntries, sortingField = 'timestamp') => {
  const byDate = (entry) => moment.utc(entry[sortingField]).toDate()
  const sorted = sortBy(listOfEntries, [byDate])
  return sorted.reverse()
}

const getTotalIssuesCount = (historiesForComponents) => {
  const groups = values(historiesForComponents)
  return sumBy(groups, (group) => {
    const groupIssues = values(group)
    return sumBy(groupIssues, (issues) => issues.length)
  })
}

const isSameDtcsFactory =
  (currentDtcsSet) =>
  (givenDtcs = []) => {
    const givenDtcsSet = new Set(givenDtcs)
    if (
      givenDtcsSet.size === 0 ||
      currentDtcsSet.size === 0 ||
      currentDtcsSet.size !== givenDtcsSet.size
    ) {
      return false
    }
    const difference = new Set(
      givenDtcs.filter((dtc) => !currentDtcsSet.has(dtc)),
    )
    return difference.size === 0
  }

const calculateFaultsCountInVehicleTestResult = (historiesForComponents) => {
  const counter = {}
  each(historiesForComponents, (groupedFaults) => {
    each(groupedFaults, (faults) => {
      each(faults, (fault) => {
        const vehicleTestResultId = fault.vehicle_test_result_id
        if (!counter[vehicleTestResultId]) {
          counter[vehicleTestResultId] = 0
        }

        counter[vehicleTestResultId] += 1
      })
    })
  })

  return counter
}

const isItemInCollection = (item, collection) => collection.indexOf(item) !== -1

const filterMatchedByDtcsFaults = (
  historiesForComponents,
  currentDtcsSet,
  currentComponentsId,
) => {
  const filteredHistoriesForComponents = {}
  const isSameDtcChecker = isSameDtcsFactory(currentDtcsSet)
  const faultsCounterInVehicleTestResult =
    calculateFaultsCountInVehicleTestResult(historiesForComponents)

  each(historiesForComponents, (groupedFaults, componentId) => {
    const filteredComponentGroup = {}
    const doesComponentIdExistInCurrentConfiguration = isItemInCollection(
      componentId,
      currentComponentsId,
    )

    if (doesComponentIdExistInCurrentConfiguration) {
      each(groupedFaults, (faults, faultName) => {
        const filteredFaults = filter(faults, (fault) => {
          const dtcAreMatched = isSameDtcChecker(fault.vehicle_dtcs)
          const vehicleTestResultId = fault.vehicle_test_result_id
          const vehicleTestContainsOneFault =
            faultsCounterInVehicleTestResult[vehicleTestResultId] === 1
          return dtcAreMatched && vehicleTestContainsOneFault
        })
        if (filteredFaults.length > 0) {
          filteredComponentGroup[faultName] = filteredFaults
        }
      })
    }

    if (keys(filteredComponentGroup).length > 0) {
      filteredHistoriesForComponents[componentId] = filteredComponentGroup
    }
  })

  return filteredHistoriesForComponents
}

export default {
  filterMatchedByDtcsFaults,
  flattenHistory,
  getTotalIssuesCount,
  sortByMostRecent,
}
