import { push } from 'connected-react-router'
import {
  fetchUsers,
  createUser,
  updateUser,
  fetchUser,
  cancelFetchUsers,
} from '../api/users-api'
import {
  setUsersList,
  setUsersListLoading,
  setUserLoading,
  setUserSaving,
  setUserSaved,
  setUserForEditing,
  setMoreLoadedUsers,
  setMoreUsersLoading,
} from '../actions/users-actions'

export function loadUsersList(params) {
  return function (dispatch, getState) {
    const isLoading = getState()?.isLoading
    if (isLoading) {
      cancelFetchUsers()
    }

    dispatch(setUsersListLoading())
    return fetchUsers(params)
      .then((users) => dispatch(setUsersList(users)))
      .catch(() => dispatch(setUsersList({})))
  }
}

export function loadMoreUsers(params) {
  return function (dispatch, getState) {
    const isLoading = getState()?.isMoreUsersLoading
    if (isLoading) {
      return
    }

    dispatch(setMoreUsersLoading())
    return fetchUsers(params).then((users) =>
      dispatch(setMoreLoadedUsers(users)),
    )
  }
}

export function requestUserCreation(userData) {
  return function (dispatch) {
    dispatch(setUserSaving())
    return createUser(userData)
      .then(() => {
        dispatch(setUserSaved())
        dispatch(push('/users'))
      })
      .catch(() => dispatch(setUserSaved()))
  }
}

export function fetchEditingUser(userId) {
  return function (dispatch) {
    dispatch(setUserLoading())
    return fetchUser(userId).then((user) => dispatch(setUserForEditing(user)))
  }
}

export function requestUserUpdating(userId, userData) {
  return function (dispatch) {
    dispatch(setUserSaving())
    return updateUser(userId, userData)
      .then(() => {
        dispatch(setUserSaved())
        dispatch(push('/users'))
      })
      .catch(() => dispatch(setUserSaved()))
  }
}
