import * as types from '../actions/action-types'

const initialState = {}

const additionalComponentFaultMenuReducer = function (
  state = initialState,
  action,
) {
  switch (action.type) {
    case types.RESET_ADDITIONAL_COMP_FAULT_MENU:
      return {}
    case types.SET_ADDITIONAL_COMP_FAULT_MENU_VIS:
      return Object.assign({}, state, { [action.componentId]: action.visible })
    default:
      return state
  }
}

export default additionalComponentFaultMenuReducer
