import React from 'react'
import { Table, Spin } from 'antd'
import { useComponentNeighbors } from '../BlockDiagram'
import { useDiagram, useDiagramDispatch } from './DiagramContext'

import { FormattedMessage, injectIntl } from 'react-intl'

const columns = [
  {
    title: (
      <FormattedMessage id="functionalDiagrams.componentNeighbours.alias" />
    ),
    dataIndex: 'alias',
  },
  {
    title: (
      <FormattedMessage id="functionalDiagrams.componentNeighbours.description" />
    ),
    dataIndex: 'description',
  },
  {
    title: <FormattedMessage id="functionalDiagrams.componentNeighbours.pin" />,
    dataIndex: 'pin',
  },
]

const ComponentNeighbors = ({ componentId, vin, showSelection = false }) => {
  const dispatch = useDiagramDispatch()
  const diagram = useDiagram()

  const { isLoading, isSuccess, neighbors } = useComponentNeighbors({
    vin,
    componentId,
  })

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {isSuccess && (
        <Table
          rowSelection={
            showSelection && {
              type: 'radio',
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                dispatch.detailDiagram.handleComponentNeighborSelection({
                  componentId: record.componentId,
                  imageName: record.imageName,
                  locationImageName: record.locationImageName,
                  pin: record.pin,
                  componentDescription: record.description,
                  componentAlias: record.alias,
                  category: record.category,
                })
              },
              selectedRowKeys: [
                diagram.detailDiagram.componentNeighbor.data.componentId,
              ],
            }
          }
          size="small"
          rowKey={'componentId'}
          dataSource={neighbors}
          columns={columns}
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            current: diagram.detailDiagram.componentNeighbor.table.currentPage,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            dispatch.detailDiagram.handleComponentNeighborTablePageSelection({
              currentPage: pagination.current,
            })
          }}
          loading={isLoading}
          data-testid="component-neighbors-table"
        />
      )}
    </>
  )
}

export default injectIntl(ComponentNeighbors)
