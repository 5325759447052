import { SET_HTML_TITLE } from '../actions/action-types'

const defaultTitle = 'Tracer'

const titleReducer = function (currentState = defaultTitle, action) {
  switch (action.type) {
    case SET_HTML_TITLE:
      return action.title ? action.title : currentState
    default:
      return currentState
  }
}

export { defaultTitle }
export default titleReducer
