import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  createConnectorSetFromHiglightedDtcs,
  createConnectorSetFromToggledDtcs,
  createDtcList,
  getHighlightedDTCs,
  getCurrentDTC,
} from '../selectors'
import Cytoscape from '../components/Cytoscape'
import { selectElement } from '../thunks/component'
import { toggleOffDtc, highlightDtcs } from '../actions/dtc-actions'
import { MAIN_NAMESPACE } from '../constants'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
  const highlightedDtcs = getHighlightedDTCs(state)
  const toggledDtc = getCurrentDTC(state)
  let connectorSet = new Set()

  if (!isEmpty(toggledDtc)) {
    connectorSet = createConnectorSetFromToggledDtcs(state)
  }
  if (!isEmpty(highlightedDtcs)) {
    connectorSet = createConnectorSetFromHiglightedDtcs(state)
  }

  return {
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    centerOnSelectedElement: state.componentState.centerOnSelectedElement,
    componentStates: state.loggingState.componentStates,
    deviceStates: state.loggingState.deviceStates,
    connectorSet: connectorSet,
    toggledOnDtc: getCurrentDTC(state),
    currentTab: _.get(state.tabState.currentTab, MAIN_NAMESPACE),
    elements: state.componentState.graphData,
    makeModelId: state.componentState.makeModelId,
    selectedElement: state.componentState.selectedElement,
    sidebarOpen: state.sidebar.open,
    tooltipEnabled: true,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    historiesForComponents: state.loggingState.historiesForComponents,
    zoomInControl: ownProps.zoomInControl,
    zoomOutControl: ownProps.zoomOutControl,
    zoomResetControl: ownProps.zoomResetControl,
    panControls: ownProps.panControls,
    layout: ownProps.layout,
    userPanningEnabled: ownProps.userPanningEnabled,
    userZoomingEnabled: ownProps.userZoomingEnabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGraphElementSelected: (element, dtcs) => {
      const componentIds = (element.isEdge && [
        element.source,
        element.target,
      ]) || [element.id]
      dispatch(
        highlightDtcs(
          _.chain(dtcs)
            .filter('components')
            .filter((dtc) =>
              _.find(componentIds, (componentId) =>
                dtc.components.has(componentId),
              ),
            )
            .value(),
        ),
      )
      dispatch(selectElement(element, false))
      dispatch(toggleOffDtc())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cytoscape)
