import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetModels() {
  return api.get('/collection/make_models').then((resp) => resp.data)
}

export function apiGetMemos({ modelId, q }) {
  const query = encodeURIComponent(q)

  return api
    .get(`/dtc/memos?model=${modelId}&q=${query}`)
    .then((resp) => resp.data)
}
