import React, { Component } from 'react'
import Sidebar from 'react-sidebar'
import { FormattedMessage, injectIntl } from 'react-intl'
import DTCListContainer from '../containers/DTCListContainer'
import ListPanel from '../components/ListPanel'
import IconChevron from './Icons/IconChevron'
import { ORIENTATION_FORWARD, ORIENTATION_BACK } from '../constants'
import isEmpty from 'lodash/isEmpty'
import IconInfoBlue from './Icons/IconInfoBlue'
import { ButtonSimple } from './Controls'

class CollapsibleSidePanel extends Component {
  onSetSidebarOpen = (open) => {
    this.props.setOpen(open)
  }

  toggle = () => {
    this.onSetSidebarOpen(!this.props.open)
  }

  render() {
    const props = {
      title: this.props.intl.formatMessage({ id: 'tracer.dtcListPanel.title' }),
      scrolling: true,
      onHeaderClick: this.toggle,
      onScroll: this.props.onScroll,
      toggleShowHideDtcMemoEdit: this.props.toggleShowHideDtcMemoEdit,
      isDtcMemoEditShown: this.props.isDtcMemoEditShown,
      id: 'dtc-list-container',
      isEphemeralVehicleTest: this.props.isEphemeralVehicleTest,
    }
    const dtcDependencies = this.props.dtcDependencies
    const sidebarContent = (
      <ListPanel {...props}>
        {dtcDependencies && dtcDependencies.length > 0 && (
          <ButtonSimple
            onClick={this.props.onClickDtcDependencies}
            className="not-relevant-ctrl"
            data-cy="not-relevant-ctrl"
          >
            <FormattedMessage
              id="dtcDependencies.title"
              values={{ total: dtcDependencies.length }}
            />
          </ButtonSimple>
        )}
        <DTCListContainer />
      </ListPanel>
    )

    return (
      <Sidebar
        defaultSidebarWidth={220}
        sidebarClassName="sidebar"
        contentClassName="sidebar-content"
        touch={false}
        sidebar={sidebarContent}
        transitions={false}
        shadow={false}
        open={false}
        onSetOpen={this.onSetSidebarOpen}
        docked={this.props.open}
        pullRight={true}
        styles={{
          sidebar: {
            overflowY: 'initial',
          },
        }}
      >
        <a
          className="panel-heading btn-collapse clickable"
          onClick={this.toggle}
        >
          <IconChevron
            fill="#0089E9"
            orientation={
              this.props.open ? ORIENTATION_FORWARD : ORIENTATION_BACK
            }
          />
        </a>
        {this.props.children}
      </Sidebar>
    )
  }
}

export default injectIntl(CollapsibleSidePanel)
