import React, { Component } from 'react'
import { Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { EmailField, RoleField, PlantField, Buttons } from './Fields'

export default class CreateUser extends Component {
  requestUserCreation = (formValues) => {
    const { createUser } = this.props
    const userData = {
      email: formValues.email,
      role: formValues.role.value,
      plant: formValues.plant.map((p) => p.value),
    }
    createUser(userData)
  }

  validateForm = (values) => {
    const errors = {}
    if (!values.email || values.email.length === 0) {
      errors.email = 'Required'
    }
    if (!values.role || values.role.length === 0) {
      errors.role = 'Required'
    }

    if (!errors.role && !values.plant) {
      errors.plant = 'Required'
    }
    return errors
  }

  render() {
    const { roles, plants, isSaving } = this.props
    const roleOptions = roles.map((role) => ({ value: role, label: role }))
    const plantOptions = plants.map((plant) => ({ value: plant, label: plant }))
    const initialValues = { email: '', role: '', plant: [] }

    return (
      <div id="user-add-form">
        <h1>
          <FormattedMessage id="users.addNewUser" />
        </h1>
        <Formik
          initialValues={initialValues}
          onSubmit={this.requestUserCreation}
          validate={this.validateForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <EmailField
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
              />

              <RoleField
                options={roleOptions}
                value={values.role}
                onChange={(option) => setFieldValue('role', option)}
                error={touched.role && errors.role}
              />

              <PlantField
                role={values.role.value}
                options={plantOptions}
                onChange={(option) => setFieldValue('plant', option)}
                value={values.plant}
                error={touched.plant && errors.plant}
              />

              <Buttons isSaving={isSaving} />
            </form>
          )}
        </Formik>
      </div>
    )
  }
}
