import React from 'react'

import { SUPERNOVA_COLOR } from '../../constants'

const IconWarning = (props) => {
  const { fill, onClick } = props

  return (
    <div>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        className="icon-warning"
      >
        <path
          fill={fill}
          fillRule="nonzero"
          d="M11.802 6.186a2.013 2.013 0 0 0-3.605 0l-3.937 6.72c-.15.295-.229.622-.229.954 0 1.144.91 2.07 2.031 2.07h7.875a2 2 0 0 0 .937-.233c.995-.527 1.382-1.777.865-2.791l-3.937-6.72zm5.74 5.765c1.034 2.03.259 4.528-1.732 5.583a4.001 4.001 0 0 1-1.873.466H6.062C3.82 18 2 16.147 2 13.86c0-.664.157-1.319.458-1.909l3.937-6.72c1.035-2.028 3.487-2.818 5.478-1.764a4.102 4.102 0 0 1 1.731 1.764l3.937 6.72zM9 7h2v5H9V7zm0 6h2v2H9v-2z"
        />
      </svg>
    </div>
  )
}

IconWarning.defaultProps = {
  onClick: () => null,
  fill: SUPERNOVA_COLOR,
}

export default IconWarning
