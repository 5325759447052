import React from 'react'
import { Table, Switch } from 'antd'

import { ReactComponent as GroundIcon } from '../../assets/images/icons/svg/pinout/GND.svg'
import { ReactComponent as LINBusIcon } from '../../assets/images/icons/svg/pinout/LIN.svg'
import { ReactComponent as PowerIcon } from '../../assets/images/icons/svg/pinout/PWR.svg'
import { ReactComponent as CANBusIcon } from '../../assets/images/icons/svg/pinout/CAN.svg'
import CircuitColorIcon from '../CircuitColorIcon'
import { FormattedMessage, injectIntl } from 'react-intl'

const categoryIcons = new Map(
  Object.entries({
    ground: GroundIcon,
    power: PowerIcon,
    can_bus: CANBusIcon,
    lin_bus: LINBusIcon,
  }),
)

const columns = [
  {
    title: <FormattedMessage id="functionalDiagrams.pinoutTable.pin" />,
    dataIndex: 'pin',
    width: 120,
    ellipsis: true,
  },
  {
    title: <FormattedMessage id="functionalDiagrams.pinoutTable.color" />,
    dataIndex: ['colors', 'letter_code'],
    width: 80,
  },
  {
    title: '',
    dataIndex: ['colors', 'hex_triplets'],
    render: (text, record, index) => {
      if (text) {
        const [color_1, color_2] = text
        return <CircuitColorIcon firstColor={color_1} secondColor={color_2} />
      }
    },
    width: 50,
  },
  {
    title: '',
    dataIndex: 'category',
    render: (text, record, index) => {
      const CategoryIcon = categoryIcons.get(text)
      return (
        CategoryIcon && (
          <div className="block-diagram-circuit-category-icon">
            <CategoryIcon />
          </div>
        )
      )
    },
    width: 80,
  },
  {
    title: <FormattedMessage id="functionalDiagrams.pinoutTable.description" />,
    dataIndex: 'description',
    ellipsis: true,
  },
]

const PinoutTable = ({
  pinout,
  isLoading,
  isAllPins,
  setIsAllPins,
  showSummary = true,
}) => {
  return (
    <Table
      size="small"
      rowKey={'pin'}
      dataSource={pinout}
      columns={columns}
      pagination={{
        pageSize: 5,
        hideOnSinglePage: true,
      }}
      loading={isLoading}
      summary={() =>
        showSummary && (
          <Table.Summary fixed="top">
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5}>
                <Switch
                  checkedChildren="All Pins"
                  unCheckedChildren="All Pins"
                  checked={isAllPins}
                  onChange={() => setIsAllPins(!isAllPins)}
                  loading={isLoading}
                />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )
      }
      sticky
    />
  )
}

export default injectIntl(PinoutTable)
