import React from 'react'
import PropTypes from 'prop-types'

const IconDtcMemo = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          id="iconDtcMemo"
          d="M75.75 19C81.9632 19 87 23.9965 87 30.16V70.2946C87 76.4581 81.9632 81.4546 75.75 81.4546H23.25C17.0368 81.4546 12 76.4581 12 70.2946V30.16C12 23.9965 17.0368 19 23.25 19H75.75ZM75.75 22.72H23.25C19.1079 22.72 15.75 26.051 15.75 30.16V70.2946C15.75 74.4036 19.1079 77.7346 23.25 77.7346H75.75C79.8921 77.7346 83.25 74.4036 83.25 70.2946V30.16C83.25 26.051 79.8921 22.72 75.75 22.72ZM31.0021 39.2124H63.9979C64.5514 39.2124 65 38.7171 65 38.1062C65 37.4952 64.5514 37 63.9979 37H31.0021C30.4486 37 30 37.4952 30 38.1062C30 38.7171 30.4486 39.2124 31.0021 39.2124ZM55.9979 42.0205C56.5514 42.0205 57 42.5157 57 43.1266C57 43.7376 56.5514 44.2328 55.9979 44.2328H31.0021C30.4486 44.2328 30 43.7376 30 43.1266C30 42.5157 30.4486 42.0205 31.0021 42.0205H55.9979ZM44.1701 62C44.7236 62 45.1722 62.4952 45.1722 63.1062C45.1722 63.7171 44.7236 64 44.1701 64H31.0021C30.4486 64 30 63.7171 30 63.1062C30 62.4952 30.4486 62 31.0021 62H44.1701ZM72.3675 47.3849L70.2966 49.4558C70.0854 49.6669 69.744 49.6669 69.5329 49.4558L64.5466 44.4695C64.3354 44.2584 64.3354 43.9169 64.5466 43.7058L66.6175 41.6349C67.4575 40.7949 68.8231 40.7949 69.6677 41.6349L72.3675 44.3347C73.212 45.1748 73.212 46.5404 72.3675 47.3849ZM62.7677 45.4847L50.9712 57.2812L50.0189 62.7392C49.8886 63.4759 50.531 64.1138 51.2677 63.988L56.7257 63.0312L68.5222 51.2347C68.7333 51.0236 68.7333 50.6822 68.5222 50.471L63.5358 45.4847C63.3202 45.2736 62.9788 45.2736 62.7677 45.4847ZM55.5757 56.2705C55.3286 56.0234 55.3286 55.6281 55.5757 55.381L62.4937 48.463C62.7407 48.216 63.136 48.216 63.3831 48.463C63.6302 48.7101 63.6302 49.1054 63.3831 49.3525L56.4651 56.2705C56.2181 56.5175 55.8228 56.5175 55.5757 56.2705ZM53.954 60.0484H56.1103V61.6791L53.2128 62.1867L51.8157 60.7896L52.3233 57.8921H53.954V60.0484ZM23 27.5C21.6193 27.5 20.5 28.6193 20.5 30V70C20.5 71.3807 21.6193 72.5 23 72.5H76C77.3807 72.5 78.5 71.3807 78.5 70V30C78.5 28.6193 77.3807 27.5 76 27.5H23ZM23 30H25.5H73.5H76V32.5V67.5V70H73.5H25.5H23V67.5V32.5V30Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconDtcMemo" />
      </g>
    </svg>
  )
}

IconDtcMemo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
IconDtcMemo.defaultProps = {
  height: '100',
  width: '100',
}

export default IconDtcMemo
