import React from 'react'

const IconRelatedSubSystems = (props) => {
  const viewBox = '0 0 21 21'
  return (
    <svg
      style={{ verticalAlign: 'middle' }}
      width="21"
      height="16"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 2C11.067 2 9.5 3.567 9.5 5.5C9.5 7.433 11.067 9 13 9C14.933 9 16.5 7.433 16.5 5.5C16.5 3.567 14.933 2 13 2ZM7.5 5.5C7.5 2.46243 9.9624 0 13 0C16.0376 0 18.5 2.46243 18.5 5.5C18.5 6.6575 18.1424 7.7315 17.5317 8.6175L20.2071 11.2929L18.7929 12.7071L16.1175 10.0317C15.2315 10.6424 14.1575 11 13 11C9.9624 11 7.5 8.5376 7.5 5.5ZM0.5 1H5.5V3H0.5V1ZM0.5 8H5.5V10H0.5V8ZM18.5 15V17H0.5V15H18.5Z" />
    </svg>
  )
}
export default IconRelatedSubSystems
