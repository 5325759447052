import { useEffect, useMemo, useState } from 'react'

export default function useIsVisible(ref) {
  const [isVisible, setIsVisible] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsVisible(entry.isIntersecting),
        { threshold: 1.0 },
      ),
    [ref],
  )

  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  })

  return isVisible
}
