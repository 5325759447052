import * as types from '../actions/action-types'

export function setError(error) {
  return {
    type: types.SET_ERROR,
    error,
  }
}

export function clearError() {
  return {
    type: types.CLEAR_ERROR,
  }
}
