import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Measure from 'react-measure'
import IconChevron from './Icons/IconChevron'
import { ORIENTATION_UP, ORIENTATION_DOWN } from '../constants'
import MustHave from '../containers/MustHave'
import { injectIntl } from 'react-intl'
import { ButtonSimple } from './Controls'

class ListPanel extends Component {
  state = {
    expanded: true,
    headingDimensions: {
      width: 0,
      height: 0,
    },
    footerDimensions: {
      width: 0,
      height: 0,
    },
  }

  render() {
    const panelClassNames = classNames('panel-collapse', {
      collapse: this.props.collapsible && !this.state.expanded,
      'collapse.in': this.props.collapsible && this.state.expanded,
    })

    const collapseTarget = `${this.props.title}-${this.props.key}`
    const id = this.props.id || collapseTarget

    // Make the panel able to scroll. We need to know the header head in-order to set it properly
    let style = {}
    if (this.props.scrolling) {
      style = {
        position: 'absolute',
        top: this.state.headingDimensions.height + this.props.headerTop,
        left: 0,
        right: 0,
        bottom: this.props.footerDimensions
          ? this.props.footerDimensions.height
          : 0,
        overflow: 'auto',
      }
    }
    let wrapperStyle = {}
    if (this.props.panelHeight) {
      wrapperStyle.height = this.props.panelHeight
    }
    return (
      <div className="panel panel-default" style={wrapperStyle}>
        <Measure
          bounds
          offset
          client
          margin
          onResize={(contentRect) => {
            this.setState({ headingDimensions: contentRect.bounds })
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className="panel-heading">
              <div className="title-container">
                <div className="flex space-between w-100">
                  <div
                    className="clickable"
                    onClick={this.handlePanelHeaderClick}
                  >
                    {this.props.title}
                  </div>
                  {this.props.toggleShowHideDtcMemoEdit &&
                    !this.props.isEphemeralVehicleTest && (
                      <MustHave permission="dtc-memo:edit">
                        <ButtonSimple
                          id="show-hide-dtc-memo-edit"
                          onClick={() => this.props.toggleShowHideDtcMemoEdit()}
                        >
                          {this.props.isDtcMemoEditShown
                            ? this.props.intl.formatMessage({
                                id: 'dtcMemo.switchToPreview',
                              })
                            : this.props.intl.formatMessage({
                                id: 'dtcMemo.switchToEdit',
                              })}
                        </ButtonSimple>
                      </MustHave>
                    )}
                </div>
                {this.props.collapsible && (
                  <div className="icon-chevron">
                    <IconChevron
                      fill="#FFFFFF"
                      opacity="0.3"
                      orientation={
                        this.state.expanded ? ORIENTATION_DOWN : ORIENTATION_UP
                      }
                    />
                  </div>
                )}
                {this.props.infoIcon}
              </div>
            </div>
          )}
        </Measure>

        <div
          id={id}
          style={style}
          className={panelClassNames}
          onScroll={this.props.onScroll}
        >
          {this.props.children}
        </div>
      </div>
    )
  }

  handlePanelHeaderClick = () => {
    if (this.props.collapsible) {
      this.setState({
        expanded: !this.state.expanded,
      })
    }

    if (this.props.onHeaderClick) {
      this.props.onHeaderClick()
    }
  }
}

ListPanel.propTypes = {
  title: PropTypes.string.isRequired,
  collapsible: PropTypes.bool,
  scrolling: PropTypes.bool,
  headerTop: PropTypes.number,
  panelHeight: PropTypes.string,
}

ListPanel.defaultProps = {
  collapsible: false,
  scrolling: false,
  headerTop: 0,
}

export default injectIntl(ListPanel)
