import { useQuery } from 'react-query'
import { apiGetGlobalSearch } from '../api/global-search-api'

const GLOBAL_SEARCH_KEY = 'global-search'

export const useGlobalSearch = ({ vin, searchString }) => {
  const {
    status,
    data: results,
    error,
    isFetching,
    isLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    [GLOBAL_SEARCH_KEY, vin, searchString],
    async () => {
      const result = await apiGetGlobalSearch({ vin, searchString })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!searchString && !!vin,
    },
  )

  return {
    status,
    results,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}
