import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.oneOf(['top', 'bottom']),
  visible: PropTypes.bool,
}

const defaultProps = {
  visible: true,
  location: 'top',
}

class PinNumber extends Component {
  render() {
    const style = {
      visibility: this.props.visible ? 'visable' : 'hidden',
    }

    const number = <p style={style}>{this.props.number}</p>
    return (
      <div>
        {this.props.location === 'top' && number}
        {this.props.children}
        {this.props.location === 'bottom' && number}
      </div>
    )
  }
}

PinNumber.propTypes = propTypes
PinNumber.defaultProps = defaultProps

export default PinNumber
