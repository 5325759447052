import React from 'react'
import { connect, useSelector, shallowEqual, useDispatch } from 'react-redux'
import get from 'lodash/get'
import Measure from 'react-measure'
import { injectIntl } from 'react-intl'

import ListPanel from './ListPanel'
import ComponentListContainer from '../containers/ComponentListContainer'
import VinPanelContainer from '../containers/VinPanelContainer'
import BackButton from './BackButton'
import { useCantFix } from '../hooks/CantFix'
import { resetAdditionalComponentFaultMenu } from '../actions/action-menu'
import { hideComponentPopover } from '../actions/component-actions'

const LeftSideBar = ({ resetAdditionalComponentMenu, vehicleTestId, intl }) => {
  const dispatch = useDispatch()
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )
  const [headerTop, setHeaderTop] = React.useState(0)

  const handleScroll = (e) => {
    // fixme: this is too dirty, should be replaced with refs
    const element = e.target
    if (element.className !== 'popover-content') {
      dispatch(hideComponentPopover())
      resetAdditionalComponentMenu()
    }
  }

  const [error, cantFix] = useCantFix(vehicleTestId)

  return (
    <div
      className="col-sm-3 col-lg-2 repair-history-container"
      id="repair-history"
    >
      <Measure
        bounds
        offset
        client
        margin
        onResize={(contentRect) => {
          setHeaderTop(contentRect.bounds.height)
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className={`vin-panel-container ${cantFix ? 'cantfix' : ''}`}
          >
            <BackButton intl={intl} />
            {!isEphemeralVehicleTest && <VinPanelContainer />}
          </div>
        )}
      </Measure>
      <ListPanel
        panelHeight={'auto'}
        onScroll={handleScroll}
        scrolling={true}
        headerTop={headerTop}
        title={intl.formatMessage({ id: 'tracer.componentListPanel.title' })}
      >
        <ComponentListContainer />
      </ListPanel>
    </div>
  )
}

const mapStateToProps = (state) => ({
  vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
})

const mapDispatchToProps = (dispatch) => ({
  resetAdditionalComponentMenu: () =>
    dispatch(resetAdditionalComponentFaultMenu()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LeftSideBar))
