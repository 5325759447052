import { flattenNestedKeys, selectAppropriateLocale } from '../helpers/utils'
import momentWrapper from './moment'
import url from '../services/url'
import _ from 'lodash'

import en_US from './en_US.json'
import demo_en_US from './demo_en_US.json'
import localhost_en_US from './localhost_en_US.json'
import gm_en_US from './gm_en_US.json'
import fca_en_US from './fca_en_US.json'
import ford_en_US from './ford_en_US.json'
import zh_CN from './zh_CN.json'
import es_MX from './es_MX.json'
import es_ES from './es_ES.json'
import it_IT from './it_IT.json'

class I18n {
  constructor() {
    this.validLocales = ['en-US', 'zh-CN', 'es-MX', 'es-ES', 'it-IT']
    this.defaultLocale = 'en-US'
    this.messages = {
      'en-US': en_US,
      'zh-CN': zh_CN,
      'es-MX': es_MX,
      'es-ES': es_ES,
      'it-IT': it_IT,
    }

    this.customerMessages = {
      'demo-en-US': demo_en_US,
      'localhost-en-US': localhost_en_US,
      'gm-en-US': gm_en_US,
      'fca-en-US': fca_en_US,
      'ford-en-US': ford_en_US,
    }
    this.locale = this.defaultLocale

    this.languageSupport = {
      en: ['en-US'],
      es: ['es-ES', 'es-MX'],
      it: ['it-IT'],
      zh: ['zh-CN'],
    }
  }

  isLocaleSupported(locale) {
    return this.validLocales.indexOf(locale) > -1
  }

  validateLocale(locale) {
    return this.isLocaleSupported(locale) ? locale : this.defaultLocale
  }

  _setMomentLocale(validLocale) {
    // Assumes you already ran validate before this step
    momentWrapper.locale(validLocale.toLowerCase())
  }

  setLocale(locale) {
    locale = selectAppropriateLocale(locale, this.languageSupport, '-')
    const validLocale = this.validateLocale(locale)
    if (!this.isLocaleSupported(locale)) {
      // TODO make this a user-friendly message in the UI
      console.error(
        `Locale '${locale}' unsupported. Setting locale to ${validLocale} instead.`,
      )
    }
    this.locale = validLocale
    this._setMomentLocale(validLocale)
    return this.locale
  }

  translationsForLocale(locale) {
    locale = selectAppropriateLocale(locale, this.languageSupport, '-')
    const validLocale = this.validateLocale(locale)
    const baseTranslations = this.messages[validLocale]
    const customer = url.getCustomerSubdomainPart('localhost')
    const customerTranslations = this.getCustomerTranslations(
      customer,
      validLocale,
    )

    const translations = customerTranslations
      ? this.mergeTranslations(baseTranslations, customerTranslations)
      : baseTranslations

    return flattenNestedKeys(translations)
  }

  mergeTranslations(baseTranslations, customerTranslations) {
    return _.merge({}, baseTranslations, customerTranslations)
  }

  getCustomerTranslations(customer, locale) {
    const customerLocale = `${customer}-${locale}`

    return this.customerMessages.hasOwnProperty(customerLocale)
      ? this.customerMessages[customerLocale]
      : null
  }

  translationsForCurrentLocale() {
    return this.translationsForLocale(this.locale)
  }
}

export default new I18n()
