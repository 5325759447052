import React from 'react'
import classNames from 'classnames'
import { UpArrow, DownArrow } from '../components/Arrows'

export function Up(props) {
  const {
    onClick,
    disabled,
    className = '',
    disabledClassName = 'disabled-control',
  } = props
  const actionClass = classNames(className, {
    [disabledClassName]: disabled,
  })

  return (
    <div onClick={onClick} className={actionClass}>
      <UpArrow />
    </div>
  )
}

export function Down(props) {
  const {
    onClick,
    disabled = false,
    className = '',
    disabledClassName = 'disabled-control',
  } = props
  const actionClass = classNames(className, {
    [disabledClassName]: disabled,
  })
  return (
    <div onClick={onClick} className={actionClass}>
      <DownArrow />
    </div>
  )
}
