import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { ROUTING } from '../constants'
import history from '../history'
import DemoContainer from '../containers/DemoContainer'
import Forbidden from '../components/Forbidden'
import Login from '../components/Login'
import Logout from '../components/Logout'
import Auth0Callback from '../components/Auth0Callback'
import MainMenuContainer from '../containers/MainMenuContainer'
import VehicleTestsSearchContainer from '../containers/VehicleTestsSearchContainer'
import UserList from '../components/Users/List'
import UserAddContainer from '../containers/UserAddContainer'
import UserEditContainer from '../containers/UserEditContainer'
import ComponentDetailsPage from '../ComponentDetails/Page'
import DtcMemoSearchContainer from '../containers/DtcMemoSearchContainer'
import DTCMemoContainer from '../containers/DTCMemoContainer'
import ProductionLinePage from '../ProductionLineDashboard'
import VehicleTestResultEmbeddedMode from './VehicleTestResult'

const RoutingEmbeddedMode = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/tracer/:pathId">
        <VehicleTestResultEmbeddedMode />
      </Route>
      <Route exact path="/auth0callback" component={Auth0Callback} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/forbidden" component={Forbidden} />
      <Route exact path="/" component={MainMenuContainer} />
      <Route exact path="/demo" component={DemoContainer} />
      <Route
        exact
        path="/search/:vin?"
        component={VehicleTestsSearchContainer}
      />
      <Route exact path="/users/add" component={UserAddContainer} />
      <Route exact path="/users/:userId" component={UserEditContainer} />
      <Route exact path="/users" component={UserList} />
      <Route
        exact
        path={ROUTING.memoAuthoring}
        component={DtcMemoSearchContainer}
      />
      <Route
        exact
        path="/dtc-memo/:dtcMemoId/:add?"
        component={DTCMemoContainer}
      />
      <Route
        exact
        path="/vehicle-test/:vin/component/:componentId"
        component={ComponentDetailsPage}
      />
      <Route
        exact
        path="/production-line-dashboard"
        component={ProductionLinePage}
      />
      <Redirect exact strict to="/" push={false} />
    </Switch>
  </ConnectedRouter>
)

export default RoutingEmbeddedMode
