import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconDemo = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.height}
      viewBox={viewBox}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="iconDemo"
          d="M47.525 50c.27-1.058.524-2.058.76-3H51a6 6 0 0 0 6-6V9a6 6 0 0 0-6-6H9a6 6 0 0 0-6 6v32a6 6 0 0 0 6 6h12.584l1.551 3H9a9 9 0 0 1-9-9V9a9 9 0 0 1 9-9h42a9 9 0 0 1 9 9v32a9 9 0 0 1-9 9h-3.475zm1.747-7l.213-.885c.091-.382.162-.753.213-1.115H51V9H9v32h9.482l1.034 2H9a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2h-1.728zm-2.705-1.582c-.81 3.395-2.01 8.141-3.6 14.238a.358.358 0 0 1-.345.339H29.816a.362.362 0 0 1-.307-.2l-9.12-17.637c-1.156-1.91.473-3.698 2.138-3.698.736 0 1.476.35 1.988 1.195l1.158 1.792c.242.37.767.486 1.132.486.599 0 1.155-.45 1.155-1.179L28 23.95c-.04-1.196 1.253-2 2.461-2s2.501.804 2.539 2v8c-.038.655.164.805.347.805a.632.632 0 0 0 .146-.016c.337-.109.473-.177 1.032-.177.958 0 2.093.353 2.892 1.723a.368.368 0 0 0 .438.162c.32-.118.881-.282 1.524-.282.896 0 1.949.318 2.735 1.518.188.286.505.46.847.479 1.085.062 4.822.164 3.606 5.257zm-21.283-11.7a.937.937 0 0 1-1.32-.108A8.269 8.269 0 0 1 22 24.262C22 19.706 25.707 16 30.262 16c4.556 0 8.262 3.706 8.262 8.262 0 1.96-.699 3.861-1.968 5.352a.932.932 0 0 1-1.318.106.935.935 0 0 1-.106-1.318 6.391 6.391 0 0 0 1.522-4.14 6.398 6.398 0 0 0-6.392-6.391 6.398 6.398 0 0 0-6.391 6.39c0 1.516.54 2.985 1.52 4.137a.936.936 0 0 1-.107 1.32z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g transform="translate(10 15)">
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconDemo" />
        </g>
      </g>
    </svg>
  )
}

IconDemo.defaultProps = defaultProps

export default IconDemo
