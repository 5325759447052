import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { change, untouch } from 'redux-form'

import { setModal, unsetModal } from '../../actions/modal-actions'
import RepairHistory from './RepairHistory'
import {
  getRepairHistory,
  getCircuitCategories,
  deleteRepairHistoryItem,
} from '../../api/logging-api'
import {
  addRepairHistoryItem,
  deleteEmptyRepairHistoryItems,
} from '../../actions/logging-actions'
import { createDtcList } from '../../selectors'
import {
  getComponentsForModel,
  getHarnessesForModel,
} from '../../api/controller-api'
import { MODAL_ADD_FAULT } from '../../constants'

class RepairHistoryContainer extends Component {
  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    this.loadData()
  }

  shouldComponentUpdate(nextProps) {
    const imRepairHistory = fromJS(this.props.repairHistory)
    const imNextRepairHistory = fromJS(nextProps.repairHistory)
    return (
      !imRepairHistory.equals(imNextRepairHistory) ||
      isEmpty(this.props.componentsForModel) ||
      isEmpty(this.props.harnessesForModel) ||
      isEmpty(this.props.circuitCategories)
    )
  }

  loadData() {
    const { makeModelId, vin } = this.props
    const repairHistoryHasNewElements = _.filter(
      this.props.repairHistory,
      (item) => !item.id,
    ).length

    if (makeModelId && vin && !repairHistoryHasNewElements) {
      getRepairHistory(makeModelId, vin)
    }

    if (makeModelId) {
      getComponentsForModel(makeModelId)
      getHarnessesForModel(makeModelId)
      getCircuitCategories()
    }
  }

  render() {
    return <RepairHistory {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tz: state.user.businessTimezone,
    userRules: state.user.data.rules,
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    repairHistory: state.loggingState.repairHistory,
    componentsForModel: state.componentState.componentsForModel,
    harnessesForModel: state.harnessState.harnessesForModel,
    circuitCategories: state.loggingState.circuitCategories,
    faults: state.loggingState.faults,
    modToCompIdx: state.componentState.modToCompIdx,
    dtcs: createDtcList(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetFields: (fields) => {
      fields.forEach((field) => {
        dispatch(change('repairHistory', field, ''))
        dispatch(untouch('repairHistory', field))
      })
    },
    onClose: () => {
      dispatch(unsetModal())
    },
    addRepairHistoryItem: (newRepairHistoryItem = {}) => {
      dispatch(addRepairHistoryItem(newRepairHistoryItem))
    },
    onDeleteHistoryItem: (id, makeModelId, vin) => {
      if (!id) {
        dispatch(deleteEmptyRepairHistoryItems())
      } else {
        deleteRepairHistoryItem(id, makeModelId, vin)
      }
    },
    openAddFaultModal: () => dispatch(setModal(MODAL_ADD_FAULT)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepairHistoryContainer)
