import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requireAuthentication } from '../helpers/authentication'
import { getUserPlants, getUserManagableRoles } from '../selectors/user'
import { getUserIsSaving } from '../selectors/users'
import { requestUserCreation } from '../thunks/users'
import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import { CreateUser } from '../components/Users'

class UserAddContainer extends Component {
  render() {
    return (
      <MustHave
        permission="page-user-administration:get"
        fallback={<Forbidden />}
      >
        <CreateUser {...this.props} />
      </MustHave>
    )
  }
}

const mapState = (state) => ({
  plants: getUserPlants(state),
  roles: getUserManagableRoles(state),
  isSaving: getUserIsSaving(state),
})

const mapDispatch = (dispatch) => ({
  createUser: (values) => dispatch(requestUserCreation(values)),
})

export default connect(
  mapState,
  mapDispatch,
)(requireAuthentication(UserAddContainer))
