import React from 'react'
import { createSelector } from '@reduxjs/toolkit'

const getDemoPayloads = (state) => state.componentState.demoPayloads

export const createDemoPayloadList = createSelector(
  [getDemoPayloads],
  (demoPayloads) => {
    return Object.keys(demoPayloads).map((key) => {
      return (
        <option key={key} value={key}>
          {key}
        </option>
      )
    })
  },
)
