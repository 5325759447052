import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import { CancelToken } from 'axios'

export let cancelSearchingComponents

const createApi = () =>
  axiosWrapper.create(
    {
      baseURL: '/tracer/components',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    InterceptorOptions.defaultErrorInterceptor,
  )

export function search(query) {
  return createApi()
    .get('', {
      params: query,
      cancelToken: new CancelToken((c) => {
        cancelSearchingComponents = c
      }),
    })
    .then((res) => res.data)
}

export function getComponentDetails(vin, componentId) {
  return createApi()
    .get(`${vin}/${componentId}`)
    .then((res) => res.data)
}
