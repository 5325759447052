import React from 'react'
import { requireAuthentication } from '../helpers/authentication'
import DashboardResults from './Results'
import DashboardControl from './Control'
import { DEFAULT_TIME_AFTER_TEST } from './constants'
import { useLocalStorage } from 'react-use'

import './styles.scss'

function ProductionLinePage() {
  const [searchTerm, setSearchTerm] = useLocalStorage(
    'production-line-search-query',
    { plant: null, testZones: [], timeAfterTest: DEFAULT_TIME_AFTER_TEST },
  )

  return (
    <div id="production-line-page">
      <div className="page-name">Production Line Dashboard</div>
      <DashboardControl searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <DashboardResults searchTerm={searchTerm} />
    </div>
  )
}

export default requireAuthentication(ProductionLinePage)
