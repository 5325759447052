import { connect } from 'react-redux'
import get from 'lodash/get'
import Cytoscape from '../components/Cytoscape'
import { selectElement } from '../thunks/pin'
import { PINOUT_NAMESPACE } from '../constants'
import { selectPin } from '../actions/pin-actions'

const mapStateToProps = (state, ownProps) => {
  return {
    addPinsToComponents: true,
    centerOnSelectedElement: false,
    elements: state.pinState.pinDestination,
    selectedElement: state.pinState.selectedElement,
    showPinDestinationLabels: true,
    currentTab: get(state.tabState.currentTab, PINOUT_NAMESPACE),
    zoomInControl: ownProps.zoomInControl,
    zoomOutControl: ownProps.zoomOutControl,
    zoomResetControl: ownProps.zoomResetControl,
    panControls: ownProps.panControls,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGraphElementSelected: (element) => {
      let pin
      if (element.pins.incoming.length > 0) {
        pin = element.pins.incoming[0]
      } else if (element.pins.outgoing.length > 0) {
        pin = element.pins.outgoing[0]
      }
      if (pin) {
        dispatch(selectPin(pin.number, {}, element.id))
      }
      dispatch(selectElement(element))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cytoscape)
