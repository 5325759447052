import React from 'react'
import history from '../../history'
import { useCreateDTCMemo } from '../../hooks/DTCMemo'
import { useSelector, shallowEqual } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

const DTCMemoType = Object.freeze({
  CROWDSOURCED: 'crowdsourced',
  IMPORTED: 'imported',
})

const CreateMemo = ({
  makeModelId,
  acronym,
  dtc,
  shortDescription,
  memo = null,
}) => {
  const createMemo = useCreateDTCMemo()
  const vin = useSelector(
    (state) => state.vehicleTests.currentVehicleTest?.vin,
    shallowEqual,
  )

  const handleClick = (e) => {
    e.stopPropagation()
    const data = createMemo.mutate(
      { makeModelId, acronym, shortDescription, memo, dtc },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/dtc-mapping/${data.id}/${vin}`),
      },
    )
  }
  return <AddIcon className="memo-edit-icon" onClick={handleClick} />
}

const EditMemo = ({
  memoId,
  icon,
  initialMemo = null,
  initialShortDescription = null,
}) => {
  const Icon = icon
  const vin = useSelector(
    (state) => state.vehicleTests.currentVehicleTest?.vin,
    shallowEqual,
  )

  const handleClick = (e) => {
    e.stopPropagation()
    history.push(`/dtc-mapping/${memoId}/${vin}`, {
      initialMemo,
      initialShortDescription,
    })
  }
  return <Icon className="memo-edit-icon" onClick={handleClick} />
}

function MemoButton({ dtc, makeModelId, acronym, editIcon, addIcon }) {
  const EditIcon = editIcon
  const AddIcon = addIcon

  if (!dtc.memo) {
    // Create new Memo
    return (
      <CreateMemo
        makeModelId={makeModelId}
        acronym={acronym}
        shortDescription={dtc.description}
        dtc={dtc.dtc_code}
        icon={AddIcon}
      />
    )
  }

  if (dtc.memo) {
    if (dtc.memo.memo_type === DTCMemoType.CROWDSOURCED) {
      return (
        <EditMemo
          memoId={dtc.memo.id}
          icon={dtc.memo.memo ? EditIcon : AddIcon}
          initialShortDescription={dtc.description}
        />
      )
    }
    if (dtc.memo.memo_type === DTCMemoType.IMPORTED) {
      if (dtc.memo.alternative_memo_id) {
        // In case of imported memo its memo and short description is passed as initial values
        return (
          <EditMemo
            memoId={dtc.memo.alternative_memo_id}
            initialMemo={dtc.memo.memo}
            initialShortDescription={dtc.description}
            icon={dtc.memo.memo ? EditIcon : AddIcon}
          />
        )
      } else {
        return (
          <CreateMemo
            makeModelId={makeModelId}
            acronym={acronym}
            shortDescription={dtc.description}
            dtc={dtc.dtc_code}
            memo={dtc.memo.memo}
            icon={EditIcon}
          />
        )
      }
    }
  }
  return null
}

export function EditButton({ onClick, className }) {
  return (
    <button
      className={`btn table-btn uppercase action-button ${className}`}
      onClick={onClick}
    >
      <FormattedMessage id="tracer.dtcListPanel.memoButton.editMemo" />
    </button>
  )
}

export function AddButton({ onClick, className }) {
  return (
    <button
      className={`btn table-btn uppercase action-button ${className}`}
      onClick={onClick}
    >
      <FormattedMessage id="tracer.dtcListPanel.memoButton.addMemo" />
    </button>
  )
}

export function EditIcon({ onClick, className }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6H12V8H1V6ZM1 4H12V2H1V4ZM1 12H8V10H1V12ZM16.01 9.87L16.72 9.16C16.8125 9.0673 16.9224 8.99375 17.0434 8.94357C17.1643 8.89339 17.294 8.86756 17.425 8.86756C17.556 8.86756 17.6857 8.89339 17.8066 8.94357C17.9276 8.99375 18.0375 9.0673 18.13 9.16L18.84 9.87C19.23 10.26 19.23 10.89 18.84 11.28L18.13 11.99L16.01 9.87ZM15.3 10.58L10 15.88V18H12.12L17.42 12.7L15.3 10.58Z"
        fill="white"
      />
    </svg>
  )
}

export function AddIcon({ onClick, className }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6H1V8H12V6ZM12 4H1V2H12V4ZM8 12H1V10H8V12ZM13 10H15V13H18V15H15V18H13V15H10V13H13V10Z"
        fill="white"
      />
    </svg>
  )
}

export default injectIntl(MemoButton)
