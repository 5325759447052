import { createSelector } from '@reduxjs/toolkit'

const getUsersState = (state) => state.users

export const getUserIsSaving = createSelector(
  [getUsersState],
  (state) => state.isSaving,
)

export const getEditingUser = createSelector(
  [getUsersState],
  (state) => state.edit,
)

export const getUsersList = createSelector(
  [getUsersState],
  (state) => state.data,
)

export const getUserIsLoading = createSelector(
  [getUsersState],
  (state) => state.isLoading,
)
