import React from 'react'
import Modal from 'react-modal'
import { func, bool } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import CloseButton from './CloseButton'

const propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

function VerifyComponentConfirmationModal(props) {
  const { isOpen, onClose, onSubmit, title } = props

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-confirmation"
      overlayClassName="modal-overlay"
      contentLabel="Modal"
    >
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-8">
              <div className="panel-title">
                <FormattedMessage
                  id="verifyComponentConfirmation.title"
                  values={{ title }}
                />
              </div>
            </div>
            <div className="col-xs-4">
              <CloseButton
                wrapperClassName="close-button-container"
                onClose={onClose}
              />
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="row message">
            <FormattedMessage
              id="verifyComponentConfirmation.content"
              values={{ title }}
            />
          </div>
          <div className="row">
            <div className="modal-btn-group text-center">
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onSubmit}
                >
                  <FormattedMessage id="forms.confirm" />
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={onClose}
                >
                  <FormattedMessage id="forms.cancel" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

VerifyComponentConfirmationModal.propTypes = propTypes

export default injectIntl(VerifyComponentConfirmationModal)
