import { useQuery } from 'react-query'
import {
  getPrevNextNavigation,
  getGenerateNavigation,
} from '../api/vehicle-tests-api'

const GET_PREV_NEXT_NAVIGATION_KEY = 'get-prev-next-navigation'
const GENERATE_NAVIGATION_KEY = 'generate-navigation'

export const usePrevNextNavigation = ({ vehicleTestResultId }) => {
  const {
    status,
    data: navigation,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [GET_PREV_NEXT_NAVIGATION_KEY, vehicleTestResultId],
    async () => {
      const result = await getPrevNextNavigation(vehicleTestResultId)
      return result
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vehicleTestResultId,
    },
  )

  return {
    status,
    navigation,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  }
}

export const useGenerateNavigationResult = ({
  vehicleTestResultId,
  navType,
}) => {
  const {
    status,
    data = {},
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [GENERATE_NAVIGATION_KEY, vehicleTestResultId, navType],
    async () => {
      const result = await getGenerateNavigation(vehicleTestResultId, navType)
      return result
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vehicleTestResultId,
    },
  )

  return { status, data, error, isLoading, isFetching, isError, isSuccess }
}
