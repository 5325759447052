import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import ConnectorView from '../components/SvgViews/ConnectorView'
import {
  COMPONENT_CATEGORY_DEVICE,
  COMPONENT_CATEGORY_SPLICE,
} from '../constants/index'
import { getElementName } from '../helpers/element'
import SvgCircle from './SvgCircle'

const propTypes = {
  selectedElement: PropTypes.object,
  showConnectorModal: PropTypes.func,
  svgCircleDiameter: PropTypes.number,
}

const defaultProps = {
  selectedElement: null,
  svgCircleDiameter: 26,
}

class PinDetails extends Component {
  renderComponentDetails(component) {
    if (!component._addPinsToComponents) {
      // You need to hold your horses and wait for the graph to add the pin data.
      return null
    }

    const incomingConnectorDetails = []
    const outgoingConnectorDetails = []
    const pinNumbers = []
    if (component.pins) {
      for (const pin of component.pins.incoming) {
        if (pin) {
          let alias = getElementName(pin.source_component)
          if (alias !== pin.source_component.id) {
            alias += ` (${pin.source_component.id})`
          }
          if (component.category === COMPONENT_CATEGORY_SPLICE) {
            incomingConnectorDetails.push(this.renderSpliceDetails(pin))
          } else {
            incomingConnectorDetails.push(this.renderConnectorDetails(pin))
          }
          pinNumbers.push(pin.number)
        }
      }
      for (const pin of component.pins.outgoing) {
        let alias = getElementName(pin.target_component)
        if (alias !== pin.target_component.id) {
          alias += ` (${pin.target_component.id})`
        }
        if (component.category === COMPONENT_CATEGORY_SPLICE) {
          outgoingConnectorDetails.push(this.renderSpliceDetails(pin))
        } else {
          outgoingConnectorDetails.push(this.renderConnectorDetails(pin))
        }
        pinNumbers.push(pin.number)
      }
    }

    const renderComponentDescription = []
    if (component.description) {
      renderComponentDescription.push(
        <div className="dt" key="description-label">
          <FormattedMessage id="pinout.detail.component.description" />
        </div>,
      )
      renderComponentDescription.push(
        <div className="dd" key="description-text">
          {component.description}
        </div>,
      )
    }

    return (
      <div id="pin-details">
        <h5>
          <FormattedMessage id="pinout.detail.component.title" />{' '}
          {getElementName(component)}
        </h5>
        <div className="row dl">
          <div className="dt">
            <FormattedMessage id="pinout.detail.component.id" />
          </div>
          <div className="dd">{component.id}</div>
          {renderComponentDescription}
          {component.category !== COMPONENT_CATEGORY_DEVICE &&
            component.total_pins && [
              <div key="total_pins_header" className="dt">
                <FormattedMessage id="pinout.detail.component.totalPins" />
              </div>,
              <div key="total_pins_value" className="dd">
                {component.total_pins}
              </div>,
            ]}
        </div>
        {incomingConnectorDetails}
        {outgoingConnectorDetails}
        <hr />

        <div className="connector-container">
          <div className="connector-preview">
            <ConnectorView
              connectorName={getElementName(component)}
              pins={pinNumbers}
              imageUrl={component.images.connector_file_name}
              onClick={this.handleConnectorViewClick(
                pinNumbers,
                component.images.connector_file_name,
              )}
            />
          </div>
          {component.images.connector_file_name && (
            <div className="btn-connector-fullview-container">
              <button
                key={`view-connector-${component.images.connector_file_name}`}
                onClick={this.handleConnectorViewClick(
                  pinNumbers,
                  component.images.connector_file_name,
                )}
                className="btn btn-default btn-sm"
              >
                <FormattedMessage id="pinout.buttons.viewConnector" />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  handleConnectorViewClick = (pins, imageUrl) => {
    return () => {
      this.props.showConnectorModal(pins, imageUrl)
    }
  }

  renderConnectorDetails(pin) {
    const key = `${pin.source_component.label}->${pin.target_component.label}->${pin.number}`
    return (
      <div key={key}>
        <hr />
        <div className="row dl">
          <div className="dt">
            <FormattedMessage id="pinout.detail.component.pinNumber" />
          </div>
          <div className="dd">{pin.number}</div>
          <div className="dt">
            <FormattedMessage id="pinout.detail.component.pinColor" />
          </div>
          <div className="dd pin-color">
            <p>{pin.color_desc}</p>
            <SvgCircle
              colors={pin.colors}
              diameter={this.props.svgCircleDiameter}
            />
          </div>
        </div>
      </div>
    )
  }

  renderSpliceDetails(pin) {
    const key = `${pin.source_component.label}->${pin.target_component.label}->${pin.number}`
    return (
      <div key={key}>
        <hr />
        <div className="row dl">
          <div className="dt">
            <FormattedMessage id="pinout.detail.component.pinColor" />
          </div>
          <div className="dd pin-color">
            <p>{pin.color_desc}</p>
            <SvgCircle
              colors={pin.colors}
              diameter={this.props.svgCircleDiameter}
            />
          </div>
        </div>
      </div>
    )
  }

  renderHarnessDetails(harness) {
    let harnessDescription = harness.harness_id
    let pin
    if (harness.pins && harness.pins.length > 0) {
      pin = {
        circuit: harness.circuit,
        wiresize: harness.wiresize,
        signal_desc: harness.signal_desc,
        colors: harness.colors,
        color_desc: harness.color_desc,
      }
    }
    if (harness.harness_description) {
      harnessDescription += ' - ' + harness.harness_description
    }
    return (
      <div id="pin-details">
        <h5>
          <FormattedMessage
            id="pinout.detail.harness.title"
            values={{ harness: getElementName(harness) }}
          />
        </h5>
        <div className="row dl">
          <div className="dt">
            <FormattedMessage id="pinout.detail.harness.color" />
          </div>
          <div className="dd">
            <div className="pin-color">
              <p>{pin.color_desc}</p>
              <SvgCircle
                colors={pin.colors}
                diameter={this.props.svgCircleDiameter}
              />
            </div>
          </div>
          <div className="dt">
            <FormattedMessage id="pinout.detail.harness.circuitID" />
          </div>
          <div className="dd">{pin.circuit}</div>
          <div className="dt">
            <FormattedMessage id="pinout.detail.harness.wireSize" />
          </div>
          <div className="dd">{pin.wiresize}</div>
          <div className="dt">
            <FormattedMessage id="pinout.detail.harness.signalDescription" />
          </div>
          <div className="dd">{pin.signal_desc}</div>
          <div className="dt">
            <FormattedMessage id="pinout.detail.harness.harnessDescription" />
          </div>
          <div className="dd">{harnessDescription}</div>
        </div>
      </div>
    )
  }

  render() {
    if (!isEmpty(this.props.selectedElement)) {
      if (this.props.selectedElement.isNode) {
        return this.renderComponentDetails(this.props.selectedElement)
      } else if (
        this.props.selectedElement.isEdge &&
        !this.props.selectedElement.edge_type
      ) {
        return this.renderHarnessDetails(this.props.selectedElement)
      }
    }
    return null
  }
}

PinDetails.defaultProps = defaultProps
PinDetails.propTypes = propTypes

export default injectIntl(PinDetails)
