import { connect } from 'react-redux'

import { SameIssuesHistoryButton } from '../components/SameIssuesHistory'
import { setModal } from '../actions/modal-actions'
import { MODAL_SAME_ISSUES_HISTORY } from '../constants'
import { getHistoryForAllComponents, getDtcsSet } from '../selectors'
import FaultsService from '../services/faults'

const mapStateToProps = (state) => {
  const { elements: currentComponents } = state.componentState
  const currentDtcsSet = getDtcsSet(state)
  const allHistoriesForComponents = getHistoryForAllComponents(state)
  const currentComponentsId = currentComponents.map((component) => component.id)
  const matchedHistoriesForComponents = FaultsService.filterMatchedByDtcsFaults(
    allHistoriesForComponents,
    currentDtcsSet,
    currentComponentsId,
  )
  const totalIssuesCount = FaultsService.getTotalIssuesCount(
    matchedHistoriesForComponents,
  )
  const hasSameIssuesHistory = totalIssuesCount > 0

  return {
    hasSameIssuesHistory,
    totalIssuesCount,
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSameIssuesHistory: () => dispatch(setModal(MODAL_SAME_ISSUES_HISTORY)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SameIssuesHistoryButton)
