import { useQuery } from 'react-query'
import { getUserInfo } from '../api/auth-api'

export const USER_FEATURE_FLAGS = 'user-feature-flags'

export const useFeatureFlags = () => {
  const {
    status,
    data: usersFeatureFlags,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [USER_FEATURE_FLAGS],
    async () => {
      const result = await getUserInfo()
      return result?.feature_flags || []
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    status,
    usersFeatureFlags,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
