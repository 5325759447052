import { DEFAULT_BUSINESS_TIMEZONE } from '../constants'
import moment from '../i18n/moment'

export const formatLocalizedDate = (
  utcDate,
  formatString,
  businessTimezone = DEFAULT_BUSINESS_TIMEZONE,
) => {
  const date = moment.utc(utcDate) || moment()
  return date.tz(businessTimezone).format(formatString)
}
