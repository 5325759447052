import {
  SET_USERS_LIST,
  SET_USERS_LIST_LOADING,
  SET_USER_LOADING,
  SET_USER_SAVING,
  SET_USER_SAVED,
  SET_USER_FOR_EDITING,
  SET_MORE_LOADED_USERS,
  SET_MORE_USERS_LOADING,
} from '../actions/action-types'

const initialState = {
  data: {},
  edit: {},
  isLoading: false,
  isMoreUsersLoading: false,
  isSaving: false,
}

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USERS_LIST:
      return Object.assign({}, state, { data: action.users, isLoading: false })
    case SET_MORE_LOADED_USERS:
      const { pagination_token, query, users } = action.users
      return {
        ...state,
        data: {
          pagination_token,
          query,
          users: [...state.data.users, ...users],
        },
        isMoreUsersLoading: false,
      }
    case SET_MORE_USERS_LOADING:
      return { ...state, isMoreUsersLoading: true }
    case SET_USERS_LIST_LOADING:
      return Object.assign({}, state, { isLoading: true })
    case SET_USER_LOADING:
      return Object.assign({}, state, { edit: {}, isLoading: true })
    case SET_USER_SAVING:
      return Object.assign({}, state, { isSaving: true })
    case SET_USER_SAVED:
      return Object.assign({}, state, { isSaving: false })
    case SET_USER_FOR_EDITING:
      return Object.assign({}, state, {
        edit: { ...action.user },
        isLoading: false,
      })
    default:
      return state
  }
}

export default reducer
