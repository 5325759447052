import { Edge } from '../../reaflow'

export const BlockDiagramEdge = ({ onEdgeClick, ...props }) => {
  return (
    <Edge
      {...props}
      interpolation="linear"
      onClick={(event, edge) => {
        onEdgeClick({ event, edge })
      }}
    >
      {
        // Set default edge color.
        (event) => (
          <use
            href={`#${props.properties.id}`}
            strokeWidth="1pt"
            stroke="#485a74"
          />
        )
      }
    </Edge>
  )
}
