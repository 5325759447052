import * as types from '../actions/action-types'
import { LOADING_STATE } from '../constants'

const initialState = {
  originalMemo: null,
  memoObj: {},
  memoDownloadingState: LOADING_STATE.notStarted,
  memoUploadingState: LOADING_STATE.notStarted,
}

const dtcMemoReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.DTC_MEMO_DOWNLOAD_START:
      return Object.assign({}, state, {
        memoDownloadingState: LOADING_STATE.started,
      })
    case types.DTC_MEMO_DOWNLOAD_END: {
      const originalMemo = action.memoObj.memo // memo text from server
      return Object.assign({}, state, {
        originalMemo,
        memoObj: {
          ...action.memoObj,
          memo: originalMemo ? originalMemo : '',
        },
        memoDownloadingState: LOADING_STATE.endOk,
      })
    }
    case types.DTC_MEMO_DOWNLOAD_ERROR:
      return Object.assign({}, state, {
        memoDownloadingState: LOADING_STATE.endWithError,
      })
    case types.DTC_MEMO_UPLOAD_START:
      return Object.assign({}, state, {
        memoUploadingState: LOADING_STATE.started,
      })
    case types.DTC_MEMO_UPLOAD_END:
      return Object.assign({}, state, {
        memoUploadingState: LOADING_STATE.endOk,
        memoDownloadingState: LOADING_STATE.notStarted,
      })
    case types.DTC_MEMO_UPLOAD_ERROR:
      return Object.assign({}, state, {
        memoUploadingState: LOADING_STATE.endWithError,
      })
    case types.DTC_MEMO_EDIT:
      const uploadingState =
        action.memo !== state.originalMemo
          ? LOADING_STATE.notStarted
          : LOADING_STATE.endOk // current memo is effectively synced with server
      return Object.assign({}, state, {
        memoObj: {
          ...state.memoObj,
          memo: action.memo,
        },
        memoUploadingState: uploadingState,
      })
    default:
      return state
  }
}

export default dtcMemoReducer
