import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetDTCMappings({ makeModelId, dtcCode, acronym }) {
  return api
    .get(
      `/crowdsourced-dtc/mappings?make_model_id=${makeModelId}&dtc_code=${dtcCode}&acronym=${acronym}`,
    )
    .then((resp) => resp.data)
}

export function apiCreateDTCMapping({
  destinationComponentId,
  makeModelId,
  dtcCode,
  acronym,
  updatedBy,
  startComponentId,
}) {
  return api
    .post(`/crowdsourced-dtc/mappings`, {
      destination_component_id: destinationComponentId,
      make_model_id: makeModelId,
      dtc_code: dtcCode,
      acronym,
      start_component_id: startComponentId,
      updated_by: updatedBy,
    })
    .then((resp) => resp.data)
}

export function apiDeleteDTCMapping(id) {
  return api
    .delete(`/crowdsourced-dtc/mappings/${id}`)
    .then((resp) => resp.data)
}

export function apiActivateDTCMapping({ id, updatedBy }) {
  return api
    .post(`/crowdsourced-dtc/mappings/${id}/activate`, {
      updated_by: updatedBy,
    })
    .then((resp) => resp.data)
}
