import * as types from '../actions/action-types'

export function resetState() {
  return {
    type: types.ADD_FAULT_COMPONENT_RESET_STATE,
  }
}

export function setSearch(search) {
  return {
    type: types.ADD_FAULT_COMPONENT_SET_SEARCH,
    search,
  }
}

export function setComponents(components) {
  return {
    type: types.ADD_FAULT_COMPONENT_SET_COMPONENTS,
    components,
  }
}

export function setSelectedComponentId(selectedComponentId) {
  return {
    type: types.ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT_ID,
    selectedComponentId,
  }
}

export function setSelectedComponent(selectedComponent) {
  return {
    type: types.ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT,
    selectedComponent,
  }
}

export function resetComponent() {
  return {
    type: types.ADD_FAULT_COMPONENT_RESET_COMPONENT,
  }
}
