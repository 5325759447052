import React from 'react'
import { FormattedMessage } from 'react-intl'

import IconNoGraph from '../components/Icons/IconNoGraph'

function NoGraph() {
  return (
    <div className="error-message">
      <IconNoGraph />
      <h2>
        <FormattedMessage id="tracer.harnessView.noGraph.header" />
      </h2>
      <p>
        <FormattedMessage id="tracer.harnessView.noGraph.embeddedModeMessage" />
      </p>
    </div>
  )
}

export default NoGraph
