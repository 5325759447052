import * as types from '../actions/action-types'

const initialState = {
  // Array of IDs from problem_logs table
  appliedReasons: [],
  isDtcMemoEditShown: false,
  toggledOnDtc: null,
  highlightedDtcs: [],
  dtcMemoPopup: false,
  dtcMemoTooltip: false,
}

const dtcReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_ON_DTC:
      return Object.assign({}, state, {
        toggledOnDtc: action.dtc,
      })
    case types.TOGGLE_OFF_DTC:
      return Object.assign({}, state, {
        toggledOnDtc: initialState.toggledOnDtc,
      })
    case types.HIGHLIGHT_DTCS:
      return Object.assign({}, state, {
        highlightedDtcs: action.dtcs,
      })
    case types.UNHIGHLIGHT_DTCS:
      return Object.assign({}, state, {
        highlightedDtcs: initialState.highlightedDtcs,
      })
    case types.DTC_MEMO_SHOW_HIDE_EDIT:
      return Object.assign({}, state, {
        isDtcMemoEditShown: !state.isDtcMemoEditShown,
      })
    case types.DTC_MEMO_SHOW_TOOLTIP:
      return Object.assign({}, state, {
        dtcMemoTooltip: true,
      })
    case types.DTC_MEMO_HIDE_TOOLTIP:
      return Object.assign({}, state, {
        dtcMemoTooltip: false,
      })
    case types.DTC_MEMO_SHOW_POPUP:
      return Object.assign({}, state, {
        dtcMemoPopup: true,
      })
    case types.DTC_MEMO_HIDE_POPUP:
      return Object.assign({}, state, {
        dtcMemoPopup: false,
      })
    default:
      return state
  }
}

export default dtcReducer
