import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatTimestamp } from '../helpers/utils'
import { navigateToVehicleTest } from '../api/vehicle-tests-api'
import Table from '../components/Table'
import { VEHICLE_TEST_ORGANISATION_TYPE } from '../constants'

const useVehicleTests = (testZones, timeAfterTest) => {
  const testZoneParams = testZones.map((zone) => ['test_zone', zone.value])
  const params = new URLSearchParams([
    ...testZoneParams,
    ['age[lte]', timeAfterTest.value],
    ['organisation_type', VEHICLE_TEST_ORGANISATION_TYPE.tester],
  ])

  return useQuery(
    ['vehicle-tests', testZones, timeAfterTest.value],
    async () => {
      const result = await axios.get('/tracer/vehicles/tests/', { params })
      if (
        result.data &&
        result.data.data &&
        result.data.data instanceof Array &&
        result.data.data.length > 0 &&
        result.data.data[0].sequence_number
      ) {
        return result.data.data.sort((a, b) => {
          return a.sequence_number.localeCompare(b.sequence_number)
        })
      }
      return result.data.data
    },
    {
      refetchInterval: 5000,
      enabled: !!testZones,
    },
  )
}

const ViewVehicleTestButton = ({ vehicleTestId }) => {
  return (
    <button
      className="btn table-btn uppercase action-button"
      onClick={(event) =>
        navigateToVehicleTest({
          vehicleTestId,
          newTab: false,
          navigatedFrom: 'production-line-dashboard',
          online: true,
        })
      }
    >
      <FormattedMessage id="productionLineDashboard.dashboardResults.view" />
    </button>
  )
}

function DashboardResults({ intl, searchTerm }) {
  const { testZones, timeAfterTest } = searchTerm

  const { status, data, error, isFetching } = useVehicleTests(
    testZones,
    timeAfterTest,
  )

  const dateTimeFormat = intl.formatMessage({ id: 'format.dayAndTime' })

  const columns = React.useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.sequence',
        }),
        accessor: (row) => row.sequence_number,
      },
      {
        Header: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.vin',
        }),
        accessor: (row) => row.vin,
      },
      {
        Header: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.date',
        }),
        accessor: (row) => formatTimestamp(dateTimeFormat, row.test_timestamp),
      },
      {
        Header: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.testZone',
        }),
        accessor: 'test_zone.specifier',
      },
      {
        Header: () => null,
        accessor: 'id',
        Cell: ({ value }) => <ViewVehicleTestButton vehicleTestId={value} />,
      },
    ],
    [],
  )

  if (testZones.length === 0) {
    return (
      <div className="dashboard-results-help-message">
        <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessage" />
      </div>
    )
  }

  if (status === 'error') {
    return (
      <>
        <div className="dashboard-results-help-message">
          <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessageError" />
        </div>
        {isFetching ? (
          <div>
            <FormattedMessage id="productionLineDashboard.dashboardResults.refreshing" />
          </div>
        ) : null}
      </>
    )
  }

  if (data && data.length > 0) {
    return <Table columns={columns} data={data} id="production-line-results" />
  }

  return (
    <>
      <div className="dashboard-results-help-message">
        <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessageNoTestZone" />{' '}
        {testZones.map((zone) => `"${zone.label}"`).join(', ')}
      </div>
      {isFetching ? (
        <div>
          <FormattedMessage id="productionLineDashboard.dashboardResults.refreshing" />
        </div>
      ) : null}
    </>
  )
}

export default injectIntl(DashboardResults)
