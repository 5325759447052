import { connect } from 'react-redux'
import get from 'lodash/get'
import VinPanel from '../components/VinPanel'

const mapStateToProps = (state) => {
  return {
    currentVehicleTestTimestamp: state.vehicleTests.currentVehicleTest
      ? state.vehicleTests.currentVehicleTest.timestamp
      : undefined,
    testZoneDescription:
      get(state.vehicleTests, 'currentVehicleTest.test_zone_description') || '',
    sequenceNumber: state.componentState.sequenceNumber,
    vin: state.componentState.vin,
    model_tag: get(state.vehicleTests, 'currentVehicleTest.model_tag'),
    year: get(state.vehicleTests, 'currentVehicleTest.year'),
  }
}

export default connect(mapStateToProps)(VinPanel)
