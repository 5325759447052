import { connect } from 'react-redux'
import ConnectorViewModal from '../components/ConnectorViewModal'
import { unsetModal } from '../actions/modal-actions'

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    pins: state.modalState.pins,
    imageUrl: state.modalState.imageUrl,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(unsetModal())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorViewModal)
