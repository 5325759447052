import * as types from '../actions/action-types'

export function centerOnSelectedElement() {
  return {
    type: types.CENTER_ON_SELECTED_ELEMENT,
  }
}

export function selectElement(selectedElement, centerOnSelectedElement) {
  return {
    type: types.SELECT_ELEMENT,
    centerOnSelectedElement,
    selectedElement,
  }
}

export function selectElementById(
  selectedElementId,
  centerOnSelectedElement = true,
) {
  return {
    type: types.SELECT_ELEMENT_BY_ID,
    centerOnSelectedElement,
    selectedElementId,
  }
}

export function resetElement() {
  return {
    type: types.RESET_ELEMENT,
  }
}

export function setAllVehicleData(props) {
  return {
    type: types.SET_ALL_VEHICLE_DATA,
    ...props,
  }
}

export function setComponentsForModel(elements) {
  return {
    type: types.SET_COMPONENTS_FOR_MODEL,
    elements,
  }
}

export function setComponentStatus(componentId, status) {
  return {
    type: types.SET_COMPONENT_STATUS,
    componentId,
    status,
  }
}

export function setDemoPayloads(demoPayloads) {
  return {
    type: types.SET_DEMO_PAYLOADS,
    demoPayloads,
  }
}

export function setVerifiedComponents(componentIds) {
  return {
    type: types.SET_VERIFIED_COMPONENTS,
    componentIds,
  }
}

export function appendVerifiedComponent(componentId) {
  return {
    type: types.APPEND_VERIFIED_COMPONENT,
    componentId,
  }
}

export function removeVerifiedComponent(componentId) {
  return {
    type: types.REMOVE_VERIFIED_COMPONENT,
    componentId,
  }
}

export function resetState() {
  return {
    type: types.RESET_COMPONENTS,
  }
}

export function setDtcMemosForComponents(acronymMemos) {
  return {
    type: types.SET_DTC_MEMOS_FOR_COMPONENTS,
    acronymMemos,
  }
}

export function showComponentPopover() {
  return {
    type: types.SHOW_COMPONENT_POPOVER,
  }
}

export function hideComponentPopover() {
  return {
    type: types.HIDE_COMPONENT_POPOVER,
  }
}
