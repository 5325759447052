import {
  dtcMemoDownloadStart,
  dtcMemoDownloadEnd,
  dtcMemoDownloadError,
  dtcMemoUploadStart,
  dtcMemoUploadEnd,
  dtcMemoUploadError,
} from '../actions/dtc-memo'

import { apiFindMemosForDTCs, apiGetMemo, apiPatchMemo } from '../api/dtc-memo'

import { setDtcMemosForComponents } from '../actions/component-actions'
import { setMemoEdit } from '../actions/dtc-memo-search'

export function downloadDTCMemo(dtcMemoId) {
  return function (dispatch) {
    dispatch(dtcMemoDownloadStart())

    return apiGetMemo(dtcMemoId)
      .then((resp) => dispatch(dtcMemoDownloadEnd(resp)))
      .catch(() => dispatch(dtcMemoDownloadError()))
  }
}

export function uploadDTCMemo(dtcMemoId, afterSaveFn) {
  return function (dispatch, getState) {
    dispatch(dtcMemoUploadStart())

    const memoObj = getState().dtcMemo.memoObj
    return apiPatchMemo(dtcMemoId, { memo: memoObj.memo })
      .then(() => dispatch(dtcMemoUploadEnd()))
      .then(() => dispatch(setMemoEdit(parseInt(dtcMemoId))))
      .then(() => dispatch(afterSaveFn()))
      .catch(() => dispatch(dtcMemoUploadError()))
  }
}

export function setDTCMemosByModules() {
  return function (dispatch, getState) {
    const state = getState()
    const makeModelId = state.componentState.makeModelId
    const modules = state.componentState.modules

    if (!makeModelId || !modules) return

    apiFindMemosForDTCs(makeModelId, modules).then((data) => {
      // Group memos by acronym
      const acronymMemos = data.memos.reduce((acc, memo) => {
        if (!acc[memo.acronym]) {
          acc[memo.acronym] = []
        }
        acc[memo.acronym].push(memo)

        return acc
      }, {})
      dispatch(setDtcMemosForComponents(acronymMemos))
    })
  }
}
