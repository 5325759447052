import { connect } from 'react-redux'
import DTCList from '../components/DTCList'
import { selectModulesWithActiveDtcs } from '../selectors'

const mapStateToProps = (state, ownProps) => {
  return {
    modules: selectModulesWithActiveDtcs(state),
    selectedElement: state.componentState.selectedElement,
    sidebarOpen: state.sidebar.open,
  }
}

export default connect(mapStateToProps)(DTCList)
