import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiPostEphemeralVehicleTest({ payload }) {
  return api.post('/ephemeral-vehicle-test', payload).then((resp) => resp.data)
}
