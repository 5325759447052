import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { injectIntl } from 'react-intl'
import Comparator from '../helpers/comparator'
import { selectModulesWithActiveDtcs, getHighlightedDTCs } from '../selectors'
import { setDTCListScroll } from '../actions/embedded-mode-actions'
import { resetElement } from '../actions/component-actions'
import { unhighlightDtcs, toggleOnDtc } from '../actions/dtc-actions'

import classNames from 'classnames'
import { getShortDescription } from '../helpers/dtc'
import IconWarning from '../components/Icons/IconWarning'
import { VEHICLE_VIEW } from '../constants'

const DTC_HEIGHT = 80

const DTC = injectIntl(function ({ dtc, intl }) {
  const dispatch = useDispatch()

  const onClick = () => {
    if (isVehicleView) return

    dispatch(resetElement())
    dispatch(unhighlightDtcs())
    dispatch(toggleOnDtc(dtc))
  }

  const toggledOnDtc = useSelector(
    (state) => state.dtcState.toggledOnDtc,
    shallowEqual,
  )
  const highlightedDTCs = useSelector(getHighlightedDTCs, shallowEqual)
  const isVehicleView = useSelector(
    (state) => state.embeddedModeState.harnessVehicleSwitch === VEHICLE_VIEW,
    shallowEqual,
  )

  function isSelected(thisDtc, thatDtc) {
    const dtcCodeMatched = thisDtc?.dtc_code === thatDtc?.dtc_code
    const acronymMatched = thisDtc?.module?.acronym === thatDtc?.module?.acronym

    return dtcCodeMatched && acronymMatched
  }

  function isHighlighted(highlightedDTCs, currentDtc) {
    return highlightedDTCs.some((highlightedDtc) =>
      isSelected(highlightedDtc, currentDtc),
    )
  }

  const dtcClassNames = classNames('embedded-panel-item', {
    selected:
      isSelected(toggledOnDtc, dtc) || isHighlighted(highlightedDTCs, dtc),
    disabled: isVehicleView,
    unsupported:
      !dtc.is_supported && !dtc.is_misconfigured && dtc.memo && !dtc.memo.memo,
  })

  return (
    <div className={dtcClassNames} onClick={onClick}>
      <div className="embedded-dtc-acronym">
        {dtc.module.acronym} - {dtc.module.connector}
      </div>
      <div className="embedded-dtc-code">
        {dtc.dtc_code} {dtc.is_misconfigured && <IconWarning />}
      </div>
      <div className="embedded-dtc-description">
        {getShortDescription(dtc, intl)}
      </div>
    </div>
  )
})

const DTCList = React.forwardRef(function DTCList(props, ref) {
  const [listItems, setListItems] = React.useState([])

  const listRef = React.useRef()
  const modules = useSelector(
    (state) => selectModulesWithActiveDtcs(state),
    shallowEqual,
  )

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(
      setDTCListScroll({
        scrollTop: listRef.current.scrollTop,
        scrollHeight: listRef.current.scrollHeight,
        clientHeight: listRef.current.clientHeight,
      }),
    )
  }, [listRef, ref, modules])

  const handleScroll = (event) => {
    dispatch(
      setDTCListScroll({
        scrollTop: listRef.current.scrollTop,
        scrollHeight: listRef.current.scrollHeight,
        clientHeight: listRef.current.clientHeight,
      }),
    )
  }

  const comparator = Comparator.bind(null, 'asc', 'dtc_code')

  React.useEffect(() => {
    const dtcs = []

    for (const module of modules) {
      dtcs.push(
        module.dtcs
          .sort(comparator)
          .filter((dtc) => dtc.active)
          .map((dtc) => {
            return (
              <DTC
                key={`${dtc.module.acronym}-${dtc.module.connector}-${dtc.dtc_code}`}
                dtc={dtc}
              />
            )
          }),
      )
    }
    setListItems(dtcs)
  }, [modules])

  function scrollUp() {
    listRef.current.scrollTo({ top: listRef.current.scrollTop - DTC_HEIGHT })
  }

  function scrollDown() {
    listRef.current.scrollTo({ top: listRef.current.scrollTop + DTC_HEIGHT })
  }

  React.useImperativeHandle(ref, () => ({
    scrollUp,
    scrollDown,
  }))

  return (
    <div
      ref={listRef}
      className="embedded-mode-panel fix-select"
      onScroll={handleScroll}
    >
      {listItems}
    </div>
  )
})

export default DTCList
