import UrlService from './url'
import { INIT_CONTEXT_QUERY_PARAM } from '../constants'

export const ONLINE = 'ONLINE'
export const OFFLINE = 'OFFLINE'

const contextsAvailable = {
  online: ONLINE,
  offline: OFFLINE,
}

const defaultContext = OFFLINE

const normalize = (context) => (context ? context.toUpperCase() : context)

export function isContextValid(requested) {
  if (!requested) {
    return false
  }
  requested = normalize(requested)
  return Object.values(contextsAvailable).includes(requested)
}

function requestedContext() {
  const requested = UrlService.getQueryParam(INIT_CONTEXT_QUERY_PARAM)
  return isContextValid(requested) ? normalize(requested) : null
}

export function currentContext() {
  return requestedContext() || defaultContext
}

export function isContextActive(context) {
  if (!isContextValid(context)) {
    return false
  }

  const active = currentContext() === normalize(context)
  return active
}

export function isOnline() {
  return currentContext() === ONLINE
}

export function isOffline() {
  return currentContext() === OFFLINE
}

export default {
  currentContext,
  isContextActive,
  isOnline,
  isOffline,
  ONLINE,
  OFFLINE,
}
