import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  id: PropTypes.string,
  handleListItemClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  indicate: PropTypes.bool,
  height: PropTypes.number,
}

class ListItem extends Component {
  render() {
    const faulty = this.props.status !== undefined

    const listItemClassNames = classNames('list-group-item', {
      active: this.props.selected,
      verified: this.props.verified,
      indicate: this.props.indicate,
      misconfigured: this.props.misconfigured,
      faulty,
      disabled: this.props.disabled,
      unsupported: this.props.unsupported,
    })

    const onClickHandler = () => {
      if (this.props.disabled) {
        return false
      }
      this.props.handleListItemClick &&
        this.props.handleListItemClick(this.props.item)
    }

    return (
      <li
        id={this.props.id}
        className={listItemClassNames}
        onClick={onClickHandler.bind(this)}
        style={{ height: this.props.height || 'auto' }}
        data-testid="dtc-group-item"
      >
        {this.props.children}
      </li>
    )
  }
}

ListItem.propTypes = propTypes

export default ListItem
