import * as types from '../actions/action-types'

export function setComponent(component) {
  return {
    type: types.SET_COMPONENT,
    component,
  }
}

export function setConnectorImage(pins, imageUrl) {
  return {
    type: types.SET_CONNECTOR_IMAGE,
    pins,
    imageUrl,
  }
}

export function setModal(container) {
  return {
    type: types.SET_MODAL_CONTAINER,
    container,
  }
}

export function unsetModal() {
  return {
    type: types.UNSET_MODAL_CONTAINER,
  }
}
