import React from 'react'
import async from 'async'
import capitalize from 'lodash/capitalize'

import moment from '../i18n/moment'
import { getElementName } from './element'
import { ICON_FAULTY } from '../constants'
import guidesData from '../constants/guides.json'

export function objKeyDiff(one, two) {
  // Returns keys from object "one" that aren't in "two" (independent of values).
  // Example: one = {a: 1, b: 2, c:3}, two = {a: 42} => ["b", "c"]
  const res = []
  Object.keys(one).forEach((key) => {
    if (!two[key]) {
      res.push(key)
    }
  })
  return res
}

export function arrayDiff(one, two) {
  // Returns values from array "one" that aren't in "two".
  return one.filter((x) => !two.includes(x))
}

/*
 * Converts an alphanumeric pin into an Integer
 * E.g. "A17" -> 17
 */
export function getPinNumberAsInt(pin, totalPins, inlineSiblings) {
  const number = Number.parseInt(pin, 10)
  if (number) {
    return number
  } else {
    switch (pin) {
      case 'A':
        return 1
      case 'B':
        return 2
      case 'C':
        return 3
      case 'D':
        return 4
      case 'E':
        return 5
      case 'F':
        return 6
      case 'G':
        return 7
      case 'H':
        return 8
      case 'J':
        return 9
      case 'K':
        return 10
      case 'L':
        return 11
      case 'M':
        return 12
      case 'N':
        return 13
      case 'P':
        return 14
      case 'R':
        return 15
      case 'S':
        return 16
      default:
        const alpha = pin.match(/[a-z]+|\d+/gi)
        if (!inlineSiblings || inlineSiblings.length <= 1) {
          return Number(alpha[1])
        } else {
          if (alpha[0].toUpperCase() === 'A') {
            return Number(alpha[1])
          } else {
            return Number(alpha[1]) + totalPins / 2
          }
        }
    }
  }
}

export function getPinLetter(pin) {
  if ((pin > 8) & (pin <= 13)) {
    return String.fromCharCode(65 + pin)
  } else {
    if ((pin > 13) & (pin <= 14)) {
      return String.fromCharCode(66 + pin)
    } else {
      if (pin > 14) {
        return String.fromCharCode(67 + pin)
      } else {
        return String.fromCharCode(64 + pin)
      }
    }
  }
}

/*
 * Converts networkx graph data into elements that cytoscape (our graph library) can consume
 */
export async function createElementsAndNodeMapFromGraph(graph) {
  const nodes = graph.nodes
  const edges = graph.links

  const nodeMap = new Map()
  const edgeList = []

  const elements = {
    nodes: [],
    edges: [],
  }

  // Keep track of edges so can filter them
  const createdEdges = new Set()
  await async.forEach(edges, (edge, key, callback) => {
    const edges = [edge.source, edge.target]
    const edgeKey1 = edges.join('-')
    const edgeKey2 = edges.reverse().join('-')

    // Don't add duplicate edges to the graph
    if (!(createdEdges.has(edgeKey1) && createdEdges.has(edgeKey2))) {
      createdEdges.add(edgeKey1)
      createdEdges.add(edgeKey2)

      const edgeData = Object.assign(edge, {
        source: edge.source,
        target: edge.target,
      })
      elements.edges.push({
        data: edgeData,
      })
      edgeData.isEdge = true
      edgeList.push(edgeData)
    }
  })

  await async.forEach(nodes, (node, key, callback) => {
    elements.nodes.push({
      data: node,
    })
    node.isNode = true
    nodeMap.set(node.id, node)
  })

  return {
    elements,
    nodeMap,
    edgeList,
  }
}

/**
 * Returns node's label according its status(verified or faulty)
 * @param {object} node is a graph element passed into Cytoscape.
 * @param {string} componentState is the current state of the element.
 * @return {string} a string label to be used for the node in the Cytoscape graph
 */
export function getNodeLabel(node, componentState) {
  let prefix = ''
  if (componentState && componentState.status) {
    prefix = ICON_FAULTY
  }

  return createLabel(prefix, getElementName(node))
}

/**
 * Creates text for cytoscape node
 * @param {string} prefix Unicode prefix that will be added to the text
 * @param {string} text Text for label
 * @return {string}
 */
export function createLabel(prefix, text) {
  return `${prefix}${prefix ? ' ' : ''}${text}`
}

export function getComponentsForInlineEdge(graph, edge, componentStates) {
  if (graph && edge) {
    let sourceElement
    let targetElement
    const sourceElementId = edge.data('source')
    const targetElementId = edge.data('target')

    graph.elements().forEach((e) => {
      if (e.data('id') === sourceElementId) {
        sourceElement = e
      }
    })
    graph.elements().forEach((e) => {
      if (e.data('id') === targetElementId) {
        targetElement = e
      }
    })

    return {
      [sourceElementId]: {
        element: sourceElement && sourceElement.data(),
        isMarked: componentStates ? !!componentStates[sourceElementId] : null,
      },
      [targetElementId]: {
        element: targetElement && targetElement.data(),
        isMarked: componentStates ? !!componentStates[targetElementId] : null,
      },
    }
  } else {
    return {}
  }
}

/**
 * Checks if a square item in a list is on the screen.
 * @param {object} element is a DOM element, typically found using the method ReactDOM.findDOMNode().
 * @return {boolean} true if the list item is visible.
 */
export function isListItemVisible(element) {
  const rect = element.getBoundingClientRect()
  const vWidth = window.innerWidth || document.documentElement.clientWidth
  const vHeight = window.innerHeight || document.documentElement.clientHeight
  const efp = (x, y) => {
    return document.elementFromPoint(x, y)
  }

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  ) {
    return false
  }

  // Return true if any of its four corners are visible
  return (
    element.contains(efp(rect.left, rect.top)) ||
    element.contains(efp(rect.right, rect.top)) ||
    element.contains(efp(rect.right, rect.bottom)) ||
    element.contains(efp(rect.left, rect.bottom))
  )
}

export function dtcItemKey(module, dtc) {
  return `${module.acronym}-${dtc.dtc_code}`
}

export function scrollToElement(container, element) {
  if (!container || !element) return
  element.scrollIntoView({ behavior: 'smooth' })
}

export function flattenNestedKeys(nestedObject, prefix = '') {
  if (!nestedObject) return {}

  return Object.keys(nestedObject).reduce((obj, key) => {
    const value = nestedObject[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      obj[prefixedKey] = value
    } else {
      Object.assign(obj, flattenNestedKeys(value, prefixedKey))
    }

    return obj
  }, {})
}

export function formatTimestamp(format, timestamp) {
  const localDate = moment.utc(timestamp).toDate()
  return moment(localDate).format(format)
}

export function capitalizeAllWords(phrase) {
  return phrase.split(' ').map(capitalize).join(' ')
}

export function validateVin(vin) {
  if (!vin) return vin
  vin = vin.replace(/\s+/g, '').toUpperCase()
  if (vin.length > 17) {
    vin = vin.slice(-17)
  }
  return vin
}

const emailValidator =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const isValidEmail = (str) => {
  return emailValidator.test(str)
}

const MAX_TEST_ZONE_DESC_LEN = 10

export function formatTestZone(testZoneDescription) {
  if (testZoneDescription) {
    if (testZoneDescription.length <= MAX_TEST_ZONE_DESC_LEN) {
      return `[${testZoneDescription}] `
    } else {
      // 3 characters reserved for dots (...)
      return `[${testZoneDescription.substring(
        0,
        MAX_TEST_ZONE_DESC_LEN - 3,
      )}...] `
    }
  }
  return ''
}

export function getComponentListItemId(componentId) {
  return `component-${componentId}`
}

/**
 * Selects the most appropriate locale based on the input and a set of supported languages.
 *
 * @function
 * @param {string} locale - The desired locale to check for and potentially use.
 *                          Expected format: "<language_code>-<country_code>" (e.g., "en-US", "es-MX").
 * @param {Object} languageSupport - An object detailing the supported languages and their respective locales.
 *                                    Keys are language codes (e.g., "en", "es").
 *                                    Values are arrays of supported locales for the language (e.g., ["en-US"], ["es-ES", "es-MX"]).
 * @param {string} delimiter - The character used to separate the language code and the country code in the locale.
 *                             Typically, this is "-" or "_".
 * @returns {string} - The most appropriate locale based on the input and support:
 *                     - The exact input locale if it's supported.
 *                     - The primary (first listed) locale for the language if the exact locale isn't supported but the language is.
 *                     - The primary locale for English ("en") if neither the input locale nor its language is supported.
 *
 * @example
 * const languageSupport = {
 *   "en": ["en-US"],
 *   "es": ["es-ES", "es-MX"],
 *   "it": ["it-IT"],
 *   "zh": ["zh-CN"]
 * };
 *
 * selectAppropriateLocale("es-AR", languageSupport, '-');  // Returns: "es-ES"
 * selectAppropriateLocale("es-MX", languageSupport, '-');  // Returns: "es-MX"
 * selectAppropriateLocale("de-DE", languageSupport, '-');  // Returns: "en-US"
 **/
export function selectAppropriateLocale(locale, languageSupport, delimiter) {
  const localeParts = locale.split(delimiter) // [lang, locale]
  const localeList = languageSupport[localeParts[0]] // seeing if language support exists for a given language
  if (localeList) {
    //it does
    if (localeParts.length !== 2 || !localeList.includes(locale)) {
      return localeList[0] // exact locale is not found, so pick first locale in the list
    }
    return locale //exact locale is found and return
  }
  return languageSupport['en'][0] //unsupported locale/language, will switch to en_US
}
