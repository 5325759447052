const partitions = {
  power_distribution: 1,
  other: 7,
  ground: 8,
  noEdge: 10,
}

export default function calculatePartition(compound) {
  if (compound.children().connectedEdges().length === 0) {
    return partitions.noEdge
  } else if (partitions[compound.data('category')]) {
    return partitions[compound.data('category')]
  }

  return partitions.other
}

export function getDetailedPartitions(graph, first, last) {
  const root = graph.getElementById(first).children()
  const goal = graph.getElementById(last).children()

  const permutations = root.flatMap((r) =>
    goal.map((g) => ({ root: r, goal: g })),
  )

  const paths = permutations.reduce((acc, permutation) => {
    const path = graph.elements().aStar({
      root: permutation.root,
      goal: permutation.goal,
    })

    if (path.found) {
      acc.push(path.path)
    }

    return acc
  }, [])

  const partitions = paths.reduce((acc, path) => {
    for (const [index, element] of path.entries()) {
      if (element.isNode()) {
        acc[element.data('parent')] = index
      }
    }
    return acc
  }, {})

  return partitions
}
