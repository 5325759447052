import { SET_SIDEBAR_STATE } from '../actions/action-types'

const initialState = {
  open: true,
}

const sideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_STATE:
      return Object.assign({}, state, {
        open: action.open,
      })
    default:
      return state
  }
}

export default sideBarReducer
