import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiPostVirtualVehicleConfiguration({
  makeModelID: make_model_id,
  salesCodes: sales_codes,
}) {
  return api.post(`/vehicle-configuration/virtual`, {
    make_model_id,
    sales_codes,
  })
}

export function apiGetVINFromSalesCodes(salesCodesString, makeModelID) {
  const params = new URLSearchParams({
    sales_codes: salesCodesString,
    make_model_id: makeModelID,
  })

  return api.get(`/vehicle-configuration/get-vin?${params.toString()}`)
}
