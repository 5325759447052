import cytoscape from 'cytoscape'

export function addExtraInlinePorts(graph) {
  const inlines = graph
    .edges()
    .filter((ele) => ele.data('category') === 'inline')

  const elements = []

  for (const inline of inlines) {
    const sourcePrimeId = `${inline.source().data('id')}::prime`
    const targetPrimeId = `${inline.target().data('id')}::prime`
    elements.push(
      ...[
        {
          group: 'nodes',
          data: {
            ...inline.source().data(),
            ...{
              id: sourcePrimeId,
              label: inline.source().data('label') + "'",
              category: 'inline-prime',
            },
          },
        },
        {
          group: 'nodes',
          data: {
            ...inline.target().data(),
            ...{
              id: targetPrimeId,
              label: inline.target().data('label') + "'",
              category: 'inline-prime',
            },
          },
        },
        {
          group: 'edges',
          data: {
            ...inline.data(),
            id: `${inline.source().data('id')}::${sourcePrimeId}`,
            source: inline.source().data('id'),
            target: sourcePrimeId,
          },
        },
        {
          group: 'edges',
          data: {
            ...inline.data(),
            id: `${inline.target().data('id')}::${targetPrimeId}`,
            source: inline.target().data('id'),
            target: targetPrimeId,
          },
        },
        {
          group: 'edges',
          data: {
            ...inline.data(),
            id: `${inline.source().data('id')}::prime::${inline
              .target()
              .data('id')}::prime`,
            source: sourcePrimeId,
            target: targetPrimeId,
            category: 'inline-prime',
            colors: { hex_triplets: ['#475872'], letter_code: '' },
          },
        },
      ],
    )
  }

  graph.add(elements)
  graph.remove(inlines)

  return graph
}

export function addInlinePairParents(graph) {
  const parentSiblings = getInlineSiblings(graph)

  for (const [group, inlines] of Object.entries(parentSiblings)) {
    graph.add({
      group: 'nodes',
      data: {
        ...{
          id: group,
          label: '',
        },
      },
    })
    for (const inline of inlines) {
      graph.getElementById(inline).move({ parent: group })
    }
  }
}

export function getInlineSiblings(graph) {
  const inlines = graph.filter(
    (ele) => !ele.isParent() && ele.data('category') === 'inline',
  )
  const directSiblings = inlines.edges().reduce(
    (graph, edge) => {
      const sourceParent = edge.source().data('componentId')
      const targetParent = edge.target().data('componentId')

      if (graph.getElementById(sourceParent).length === 0) {
        graph.add({ group: 'nodes', data: { id: sourceParent } })
      }
      if (graph.getElementById(targetParent).length === 0) {
        graph.add({ group: 'nodes', data: { id: targetParent } })
      }

      if (
        graph.getElementById(`${sourceParent}-${targetParent}`).length === 0
      ) {
        graph.add({
          group: 'edges',
          data: {
            id: `${sourceParent}-${targetParent}`,
            source: sourceParent,
            target: targetParent,
          },
        })
      }
      return graph
    },
    cytoscape({
      headless: true,
    }),
  )

  const groupsBFS = directSiblings.nodes().reduce((acc, root) => {
    const bfs = directSiblings
      .elements()
      .breadthFirstSearch({ roots: root })
      .path.nodes()
      .map((node) => node.id())
      .sort()
    if (!(bfs.join('::') in acc)) {
      acc[bfs.join('::')] = bfs
    }

    return acc
  }, {})

  return groupsBFS
}
