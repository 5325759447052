import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { BLACK_COLOR } from '../constants'

const propTypes = {
  diameter: PropTypes.number,
  colors: PropTypes.array,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
}

const defaultProps = {
  stroke: BLACK_COLOR,
  strokeWidth: 1,
  colors: [],
  diameter: 26,
}

class SvgCircle extends Component {
  render() {
    const center = this.props.diameter / 2
    const radius = center - this.props.strokeWidth / 2

    let fill
    let linearGradientDefinition
    if (this.props.colors.length > 1) {
      const linearGradientDefId = `half-gradient-${this.props.colors}`
      const offset = 100 / this.props.colors.length
      linearGradientDefinition = (
        <defs>
          <linearGradient id={linearGradientDefId}>
            {this.props.colors.map((color) => {
              return (
                <stop key={color} offset={`${offset}%`} stopColor={color} />
              )
            })}
          </linearGradient>
        </defs>
      )
      fill = `url(#${linearGradientDefId})`
    } else {
      fill = this.props.colors[0]
    }

    return (
      <svg
        key={this.props.colors}
        width={this.props.diameter}
        height={this.props.diameter}
      >
        {linearGradientDefinition}
        <circle
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          stroke={this.props.stroke}
          fill={fill}
        />
      </svg>
    )
  }
}

SvgCircle.propTypes = propTypes
SvgCircle.defaultProps = defaultProps

export default SvgCircle
