import * as types from './action-types'

export function setEphemeralVehicleTest() {
  return {
    type: types.SET_EPHEMERAL_VEHICLE_TEST,
  }
}

export function unsetEphemeralVehicleTest() {
  return {
    type: types.UNSET_EPHEMERAL_VEHICLE_TEST,
  }
}
