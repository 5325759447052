import { useQuery } from 'react-query'
import { apiGetCompleteVIN } from '../api/vin-api'

export const COMPLETE_VIN = 'complete-vin'

export const useCompleteVIN = ({ searchString }) => {
  const {
    status,
    data: vin,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  } = useQuery(
    [COMPLETE_VIN, searchString],
    async () => {
      const result = await apiGetCompleteVIN({ searchString })
      return result
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!searchString,
    },
  )

  return {
    status,
    vin,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  }
}
