export const getReportDay = (fault) =>
  new Date(fault.created_at).toLocaleString('en-US', { dateStyle: 'long' })
export const getReportHour = (fault) =>
  new Date(fault.created_at).toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
  })

/**
 * Returns faults grouped by day and hour.
 */
export function groupFaults(faults) {
  const groupedFaults = faults.reduce((grouped, fault) => {
    const day = getReportDay(fault)
    const hour = getReportHour(fault)

    grouped[day] = grouped[day] || {}
    grouped[day][hour] = grouped[day][hour] || {
      day,
      hour,
      component: [],
      device: [],
      other: [],
      harness: [],
    }
    grouped[day][hour][fault.type].push(fault)
    return grouped
  }, {})

  return groupedFaults
}
