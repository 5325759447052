import React from 'react'
import Modal from 'react-modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Multiselect } from 'react-widgets'
import { unsetModal } from '../../actions/modal-actions'
import { FormattedMessage } from 'react-intl'
import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import {
  ADD_FAULT_COMPONENT_VIEW,
  ADD_FAULT_GENERIC_VIEW,
  ADD_FAULT_HARNESS_VIEW,
  ADD_FAULT_DEVICE_VIEW,
  ADD_FAULT_TABS_NAMESPACE,
} from '../../constants'
import NavigationTabs from '../Navigation/NavigationTabs'
import { useComponentSearch } from '../../hooks/ComponentSearch'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import { useHarnessSearch } from '../../hooks/HarnessSearch'
import {
  resetComponent,
  setSearch,
  setSelectedComponent,
  setSelectedComponentId,
} from '../../actions/add-fault-component'
import Topology from '../SvgViews/Topology'
import { getDtcOptions } from '../../selectors/repairHistory'
import {
  addComponentProblem,
  addHarnessProblem,
  addOtherProblem,
  logDeviceProblem,
} from '../../api/controller-api'
import {
  resetHarness,
  setSearchHarness,
  setSelectedHarness,
} from '../../actions/add-fault-harness'
import SvgViewer from '../SvgViews/SvgViewer'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import IconNoImage from '../Icons/IconNoImage'
import { selectNavigationTab } from '../../actions/tab-actions'
import {
  setSearchDevice,
  resetDevice,
  setSelectedDevice,
} from '../../actions/add-fault-device'
import { useDeviceSearch } from '../../hooks/DeviceSearch'
import { useCDN } from '../../hooks/CDN'

const ComponentsSearchTable = ({ componentSearchString }) => {
  const dispatch = useDispatch()
  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      selectedComponentId: state.addFaultComponent.selectedComponentId,
    }),
    shallowEqual,
  )
  const selectedComponentId = props.selectedComponentId

  const { components, isLoading, isSuccess } = useComponentSearch({
    searchString: componentSearchString,
    vin: props.vin,
  })
  const { componentDetails, isSuccess: componentDetailsIsSuccess } =
    useComponentDetails({ vin: props.vin, componentId: selectedComponentId })

  const doSelectComponent = (componentId) => () => {
    dispatch(resetComponent())
    dispatch(setSelectedComponentId(componentId))
  }

  React.useEffect(() => {
    if (componentDetailsIsSuccess)
      dispatch(setSelectedComponent(componentDetails.component))
  }, [componentDetails, selectedComponentId])

  return (
    <div className="add-fault-table-wrapper">
      <div className="add-fault-table-header">
        {isLoading ? (
          <FormattedMessage id="addFaultComponent.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultComponent.searchRes"
            values={{ num: components.length, search: componentSearchString }}
          />
        ) : (
          <FormattedMessage id="addFaultComponent.searchResLabel" />
        )}
      </div>
      <table className="table">
        <thead className="bs-table-header">
          <tr>
            <th />
            <th>
              <FormattedMessage id="addFaultComponent.columns.alias" />
            </th>
            <th>
              <FormattedMessage id="addFaultComponent.columns.id" />
            </th>
            <th>
              <FormattedMessage id="addFaultComponent.columns.description" />
            </th>
          </tr>
        </thead>
        <tbody className="bs-table-body">
          {components &&
            components.map((obj) => (
              <tr
                key={obj.component.id}
                onClick={doSelectComponent(obj.component.id)}
              >
                <td>
                  <input
                    type="radio"
                    value={obj.component.id}
                    checked={obj.component.id === selectedComponentId}
                    onChange={doSelectComponent(obj.component.id)}
                  />
                </td>
                <td>{obj.component.alias}</td>
                <td>{obj.component.id}</td>
                <td>{obj.component.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const FaultComponentForm = ({ goBack, componentId }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.component.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultComponent() {
    addComponentProblem({
      makeModelId: props.makeModelId,
      vin: props.vin,
      vehicleTestResultId: props.vehicleTestResultId,
      componentId: componentId,
      isProblem: true,
      reason: faultType,
      dtcs: dtcs,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="row">
          <label htmlFor="type-of-fault">
            <FormattedMessage id="addFaultComponent.typeOfFault" />
          </label>
          <Select
            id="type-of-fault"
            options={props.typesOfFault}
            onChange={(data) => setFaultType(data.value)}
            validate={faultType}
            placeholder="Select type of fault"
          />
        </div>
        <div className="row">
          <label htmlFor="dtc-codes">
            <FormattedMessage id="addFaultComponent.dtcCodes" />
          </label>
          <Multiselect
            id="dtc-codes"
            data={props.dtcOptions}
            onChange={(data) => setDtcs(data.map((obj) => obj.value))}
            textField="label"
            valueField="value"
            placeholder="Select DTC codes"
          />
        </div>
        <div className="row">
          <label htmlFor="notes">
            <FormattedMessage id="addFaultComponent.notes" />
          </label>
          <textarea
            id="notes"
            className="form-control annotation"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="row modal-btn-group text-center">
          <button className="btn btn-cancel" onClick={goBack}>
            <FormattedMessage id="forms.cancel" />
          </button>
          <button
            className="btn"
            onClick={submitFaultComponent}
            disabled={faultType === '' || !faultType}
          >
            <FormattedMessage id="forms.submit" />
          </button>
        </div>
      </div>
    </div>
  )
}

const AddFaultComponent = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultComponentReducer
  const props = useSelector((state) => state.addFaultComponent, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearch(searchString))
    dispatch(resetComponent())
  }

  const doSearchByPressEnter = (e) =>
    e.key === 'Enter' && searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <div className="add-fault-container" data-testid="add-fault-component">
      <div className="flex">
        <div className="add-fault-image-view-wrapper">
          <Topology component={props.selectedComponent} />
        </div>
        <div className="add-fault-search-and-submit-wrapper">
          <label htmlFor="search-input">
            <FormattedMessage id="addFaultComponent.searchLabel" />
          </label>
          <div className="row mb-2">
            <div className="col-sm-8">
              <input
                className="form-control"
                id="search-input"
                type="text"
                value={searchString}
                onKeyPress={doSearchByPressEnter}
                onChange={doChangeSearchQuery}
              />
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-default uppercase w-100"
                onClick={doSearch}
                disabled={!searchString}
              >
                <FormattedMessage id="addFaultComponent.search" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ComponentsSearchTable componentSearchString={props.search} />
            </div>
          </div>
          {props.selectedComponentId && (
            <div className="add-fault-details-wrapper row">
              <div className="col-sm-12">
                <FaultComponentForm
                  goBack={goBack}
                  componentId={props.selectedComponentId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function getIdForHarness(harnessObj) {
  const harnessId = `${harnessObj.harness}:${harnessObj.make_model_id}:${harnessObj.description}`
  const file_name = `${harnessObj.file_name}:${harnessObj.level}`
  return `${harnessId}:${file_name}:`
}

const HarnessSearchTable = ({ searchString }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      selectedHarness: state.addFaultHarness.selectedHarness,
    }),
    shallowEqual,
  )

  const { harnesses, isLoading, isSuccess } = useHarnessSearch({
    searchString,
    vehicleTestResultId: props.vehicleTestResultId,
    vin: props.vin,
  })

  const doSelectHarness = (harness) => {
    dispatch(resetHarness())
    dispatch(setSelectedHarness(harness))
  }

  return (
    <div className="add-fault-table-wrapper">
      <div className="add-fault-table-header">
        {isLoading ? (
          <FormattedMessage id="addFaultHarness.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultHarness.searchRes"
            values={{ num: harnesses.length, search: searchString }}
          />
        ) : (
          <FormattedMessage id="addFaultHarness.searchResLabel" />
        )}
      </div>
      <table className="table">
        <thead className="bs-table-header">
          <tr>
            <th />
            <th>
              <FormattedMessage id="addFaultHarness.columns.id" />
            </th>
            <th>
              <FormattedMessage id="addFaultHarness.columns.description" />
            </th>
          </tr>
        </thead>
        <tbody className="bs-table-body">
          {harnesses &&
            harnesses.map((obj) => (
              <tr
                key={getIdForHarness(obj)}
                onClick={() => doSelectHarness(obj)}
              >
                <td>
                  <input
                    type="radio"
                    value={getIdForHarness(obj)}
                    checked={
                      getIdForHarness(obj) ===
                      (props.selectedHarness &&
                        getIdForHarness(props.selectedHarness))
                    }
                    onChange={() => doSelectHarness(obj)}
                  />
                </td>
                <td>{obj.harness}</td>
                <td>{obj.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const FaultHarnessForm = ({ goBack, harness }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.harness.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      faults: state.loggingState.faults,
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultHarness() {
    addHarnessProblem({
      vehicleTestResultId: props.vehicleTestResultId,
      makeModelId: props.makeModelId,
      vin: props.vin,
      harness: harness.harness,
      harnessLevel: harness.level,
      reason: faultType,
      dtcs,
      isProblem: true,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="row">
          <label htmlFor="type-of-fault">
            <FormattedMessage id="addFaultHarness.typeOfFault" />
          </label>
          <Select
            id="type-of-fault"
            options={props.typesOfFault}
            onChange={(data) => setFaultType(data.value)}
            validate={faultType}
            placeholder="Select type of fault"
          />
        </div>
        <div className="row">
          <label htmlFor="dtc-codes">
            <FormattedMessage id="addFaultHarness.dtcCodes" />
          </label>
          <Multiselect
            id="dtc-codes"
            data={props.dtcOptions}
            onChange={(data) => setDtcs(data.map((obj) => obj.value))}
            textField="label"
            valueField="value"
            placeholder="Select DTC codes"
          />
        </div>
        <div className="row">
          <label htmlFor="notes">
            <FormattedMessage id="addFaultHarness.notes" />
          </label>
          <textarea
            id="notes"
            className="form-control annotation"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="row modal-btn-group text-center">
          <button className="btn btn-cancel" onClick={goBack}>
            <FormattedMessage id="forms.cancel" />
          </button>
          <button
            className="btn"
            onClick={submitFaultHarness}
            disabled={faultType === '' || !faultType}
          >
            <FormattedMessage id="forms.submit" />
          </button>
        </div>
      </div>
    </div>
  )
}

const ImageViewer = ({ fileName, noImageMsg = '' }) => {
  const { isSuccess, cdn } = useCDN()
  let zoomCtrl

  const zoomCenter = () => {
    zoomCtrl.resize()
    zoomCtrl.fit()
    zoomCtrl.center()
  }

  const onSvgReady = (svg, panZoom) => {
    zoomCtrl = panZoom

    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    zoomCenter()
  }

  return (
    <>
      {isSuccess && (
        <div className="image-viewer overflow-hidden">
          {fileName ? (
            <>
              <SvgViewer
                src={`${cdn.imagesURL}/${fileName}`}
                onReady={onSvgReady}
                panzoom={true}
                zoomEnabled={true}
              />
              <ZoomInControl zoomIn={() => zoomCtrl.zoomIn()} />
              <ZoomResetControl zoomReset={() => zoomCenter()} />
              <ZoomOutControl zoomOut={() => zoomCtrl.zoomOut()} />
            </>
          ) : (
            <div className="error-message">
              <IconNoImage />
              <h2>
                <FormattedMessage id="imageViewer.noImage" />
              </h2>
              {noImageMsg && <p>{noImageMsg}</p>}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const AddFaultHarness = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultHarnessReducer
  const props = useSelector((state) => state.addFaultHarness, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearchHarness(searchString))
    dispatch(resetHarness())
  }

  const doSearchByPressEnter = (e) =>
    e.key === 'Enter' && searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <div className="add-fault-container" data-testid="add-fault-harness">
      <div className="flex">
        <div className="add-fault-image-view-wrapper">
          <ImageViewer
            fileName={props.selectedHarness && props.selectedHarness.file_name}
            noImageMsg={<FormattedMessage id="addFaultHarness.noImage" />}
          />
        </div>
        <div className="add-fault-search-and-submit-wrapper">
          <label htmlFor="search-input">
            <FormattedMessage id="addFaultHarness.searchLabel" />
          </label>
          <div className="row mb-2">
            <div className="col-sm-8">
              <input
                className="form-control"
                id="search-input"
                type="text"
                value={searchString}
                onKeyPress={doSearchByPressEnter}
                onChange={doChangeSearchQuery}
              />
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-default uppercase w-100"
                onClick={doSearch}
                disabled={!searchString}
              >
                <FormattedMessage id="addFaultHarness.search" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <HarnessSearchTable searchString={props.search} />
            </div>
          </div>
          {props.selectedHarness && (
            <div className="add-fault-details-wrapper row">
              <div className="col-sm-12">
                <FaultHarnessForm
                  goBack={goBack}
                  harness={props.selectedHarness}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const FaultDeviceForm = ({ goBack, device }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      dtcOptions: getDtcOptions(state),
      typesOfFault: state.loggingState.faults.device.map((item) => ({
        value: item.key,
        label: item.key,
      })),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultDevice() {
    logDeviceProblem({
      annotation: notes,
      vehicleTestResultId: props.vehicleTestResultId,
      dtcs,
      makeModelId: props.makeModelId,
      reason: faultType,
      vin: props.vin,
      deviceName: device.id,
    }).then(() => goBack())
  }

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="row">
          <label htmlFor="type-of-fault">
            <FormattedMessage id="addFaultDevice.typeOfFault" />
          </label>
          <Select
            id="type-of-fault"
            options={props.typesOfFault}
            onChange={(fault) => setFaultType(fault.value)}
            validate={faultType}
            placeholder="Select type of fault"
          />
        </div>
        <div className="row">
          <label htmlFor="dtc-codes">
            <FormattedMessage id="addFaultDevice.dtcCodes" />
          </label>
          <Multiselect
            id="dtc-codes"
            data={props.dtcOptions}
            onChange={(data) => setDtcs(data.map((obj) => obj.value))}
            textField="label"
            valueField="value"
            placeholder="Select DTC codes"
          />
        </div>
        <div className="row">
          <label htmlFor="notes">
            <FormattedMessage id="addFaultDevice.notes" />
          </label>
          <textarea
            id="notes"
            className="form-control annotation"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="row modal-btn-group text-center">
          <button className="btn btn-cancel" onClick={goBack}>
            <FormattedMessage id="forms.cancel" />
          </button>
          <button
            className="btn"
            onClick={submitFaultDevice}
            disabled={faultType === '' || !faultType}
          >
            <FormattedMessage id="forms.submit" />
          </button>
        </div>
      </div>
    </div>
  )
}

const DevicesSearchTable = ({ searchString }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      makeModelId: state.componentState.makeModelId,
      selectedDevice: state.addFaultDevice.selectedDevice,
    }),
    shallowEqual,
  )

  const { devices, isLoading, isSuccess } = useDeviceSearch({
    searchString,
    makeModelId: props.makeModelId,
  })

  const doSelectDevice = (device) => {
    dispatch(resetDevice())
    dispatch(setSelectedDevice(device))
  }

  return (
    <div className="add-fault-table-wrapper">
      <div className="add-fault-table-header">
        {isLoading ? (
          <FormattedMessage id="addFaultDevice.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultDevice.searchRes"
            values={{ num: devices.length, search: searchString }}
          />
        ) : (
          <FormattedMessage id="addFaultDevice.searchResLabel" />
        )}
      </div>
      <table className="table">
        <thead className="bs-table-header">
          <tr>
            <th />
            <th>
              <FormattedMessage id="addFaultDevice.columns.id" />
            </th>
            <th>
              <FormattedMessage id="addFaultDevice.columns.description" />
            </th>
          </tr>
        </thead>
        <tbody className="bs-table-body">
          {devices &&
            devices.map((obj) => (
              <tr key={obj.id} onClick={() => doSelectDevice(obj)}>
                <td>
                  <input
                    type="radio"
                    value={obj.id}
                    checked={
                      obj.id ===
                      (props.selectedDevice && props.selectedDevice.id)
                    }
                    onChange={() => doSelectDevice(obj)}
                  />
                </td>
                <td>{obj.id}</td>
                <td>{obj.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const AddFaultDevice = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultDeviceReducer
  const props = useSelector((state) => state.addFaultDevice, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearchDevice(searchString))
    dispatch(resetDevice())
  }

  const doSearchByPressEnter = (e) =>
    e.key === 'Enter' && searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <div className="add-fault-container" data-testid="add-fault-device">
      <div className="flex justify-content-center">
        <div className="add-fault-search-and-submit-wrapper">
          <label htmlFor="search-input">
            <FormattedMessage id="addFaultDevice.searchLabel" />
          </label>
          <div className="row mb-2">
            <div className="col-sm-8">
              <input
                className="form-control"
                id="search-input"
                type="text"
                value={searchString}
                onKeyPress={doSearchByPressEnter}
                onChange={doChangeSearchQuery}
              />
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-default uppercase w-100"
                onClick={doSearch}
                disabled={!searchString}
              >
                <FormattedMessage id="addFaultDevice.search" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <DevicesSearchTable searchString={props.search} />
            </div>
          </div>
          {props.selectedDevice && (
            <div className="add-fault-details-wrapper row">
              <div className="col-sm-12">
                <FaultDeviceForm
                  goBack={goBack}
                  device={props.selectedDevice}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const FaultGenericForm = ({ goBack }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.other.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submit() {
    addOtherProblem({
      vehicleTestResultId: props.vehicleTestResultId,
      makeModelId: props.makeModelId,
      vin: props.vin,
      reason: faultType,
      dtcs,
      isProblem: true,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <div
      className="repair-history-form-wrapper"
      data-testid="add-fault-generic"
    >
      <div>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="row">
              <label htmlFor="type-of-fault">
                <FormattedMessage id="addFaultGeneric.typeOfFault" />
              </label>
              <Select
                id="type-of-fault"
                options={props.typesOfFault}
                onChange={(data) => setFaultType(data.value)}
                validate={faultType}
                placeholder="Select type of fault"
              />
            </div>
            <div className="row">
              <label htmlFor="dtc-codes">
                <FormattedMessage id="addFaultGeneric.dtcCodes" />
              </label>
              <Multiselect
                id="dtc-codes"
                data={props.dtcOptions}
                onChange={(data) => setDtcs(data.map((obj) => obj.value))}
                textField="label"
                valueField="value"
                placeholder="Select DTC codes"
              />
            </div>
            <div className="row">
              <label htmlFor="notes">
                <FormattedMessage id="addFaultGeneric.notes" />
              </label>
              <textarea
                id="notes"
                className="form-control annotation"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="row modal-btn-group text-center">
              <button className="btn btn-cancel" onClick={goBack}>
                <FormattedMessage id="forms.cancel" />
              </button>
              <button
                className="btn"
                onClick={submit}
                disabled={faultType === '' || !faultType}
              >
                <FormattedMessage id="forms.submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AddFault() {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      isOpen: state.modalState.isOpen,
    }),
    shallowEqual,
  )

  const goBack = () => {
    dispatch(unsetModal())
    dispatch(resetComponent())
    dispatch(setSearch(''))
    dispatch(resetHarness())
    dispatch(setSearchHarness(''))
    dispatch(
      selectNavigationTab(ADD_FAULT_GENERIC_VIEW, ADD_FAULT_TABS_NAMESPACE),
    )
  }

  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-repair-history-view"
      overlayClassName="modal-overlay"
      onRequestClose={goBack}
    >
      <div className="add-fault-wrapper" data-testid="add-fault-modal">
        <div className="repair-history-modal-header">
          <div
            className="repair-history-modal-back-button"
            onClick={goBack}
            data-testid="add-fault-modal-back-button"
          />
          <h2 className="repair-history-modal-title">
            <FormattedMessage id="repairHistory.titles.addFault" />
          </h2>
          <div className="add-fault-tabs-wrapper">
            <div className="add-fault-tabs" data-testid="add-fault-tabs">
              <NavigationTabs
                namespace={ADD_FAULT_TABS_NAMESPACE}
                changeSidebarState={false}
              />
            </div>
          </div>
        </div>
        <div className="add-fault-content">
          <TabContent>
            <TabPane
              id={ADD_FAULT_GENERIC_VIEW}
              namespace={ADD_FAULT_TABS_NAMESPACE}
              position={0}
              ignoreError={true}
              enabled
            >
              <FaultGenericForm goBack={goBack} />
            </TabPane>
            <TabPane
              id={ADD_FAULT_COMPONENT_VIEW}
              namespace={ADD_FAULT_TABS_NAMESPACE}
              position={1}
              ignoreError={true}
            >
              <AddFaultComponent goBack={goBack} />
            </TabPane>
            <TabPane
              id={ADD_FAULT_HARNESS_VIEW}
              namespace={ADD_FAULT_TABS_NAMESPACE}
              position={2}
              ignoreError={true}
            >
              <AddFaultHarness goBack={goBack} />
            </TabPane>
            <TabPane
              id={ADD_FAULT_DEVICE_VIEW}
              namespace={ADD_FAULT_TABS_NAMESPACE}
              position={3}
              ignoreError={true}
            >
              <AddFaultDevice goBack={goBack} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Modal>
  )
}

export default AddFault
