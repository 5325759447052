import BaseNode from './BaseNode'
import SpliceIcon from '../icons/splice.svg'
import NodeLabel from '../labels/Label'

const SpliceLabel = ({ ...props }) => {
  return <NodeLabel {...props} text="" />
}

export default function SpliceNode({
  node,
  onPortClick,
  onNodeClick,
  ...props
}) {
  const { width, height } = node
  return (
    <BaseNode
      {...props}
      node={node}
      label={<SpliceLabel />}
      className="splice"
      onPortClick={onPortClick}
      onNodeClick={onNodeClick}
    >
      {(event) => (
        <image
          pointerEvents={'none'}
          width={width}
          height={height}
          href={SpliceIcon}
        />
      )}
    </BaseNode>
  )
}
