import classNames from 'classnames'

export default function CircuitColorIcon({
  firstColor,
  secondColor,
  className,
}) {
  return (
    <div className={classNames('circuit-color-icon', className)}>
      <svg viewBox="0 0 100 100">
        <g strokeWidth="2px" stroke="black">
          <circle cx="50" cy="50" r="48" fill={firstColor} />
          {secondColor && (
            <path
              fill={secondColor}
              d="M50,2
                                a1,1 0 0,1 0,96"
            />
          )}
        </g>
      </svg>
    </div>
  )
}
