import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table } from 'antd'

import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import { requireAuthentication } from '../helpers/authentication'
import { downloadModels, downloadMemos } from '../thunks/dtcMemoSearch'
import { selectModel, editDTCField } from '../actions/dtc-memo-search'
import { LOADING_STATE } from '../constants'

const MemoEditLink = ({ memoId, add }) => (
  <Link
    className="btn table-btn uppercase"
    to={`/dtc-memo/${memoId}${(add && '/add') || ''}`}
  >
    {add ? 'add' : 'edit'}
  </Link>
)

class DtcMemoSearchContainer extends React.Component {
  componentDidMount() {
    this.props.doDownloadModels()
  }

  isSearchDisabled() {
    const { selectedModel, dtc } = this.props
    return !selectedModel || dtc.length === 0
  }

  handleSearchByEnterPress(e) {
    if (e.key === 'Enter') {
      this.doSearchOfMemos()
    }
  }

  doSearchOfMemos() {
    const { doDownloadMemos } = this.props
    if (!this.isSearchDisabled()) {
      doDownloadMemos()
    }
  }

  columns = [
    {
      title: <FormattedMessage id="dtcMemoSearch.dtcName" />,
      dataIndex: 'dtc',
    },
    {
      title: ' ',
      dataIndex: 'active',
      render: (record) => (record.active ? '' : 'Inactive'),
    },
    {
      title: <FormattedMessage id="dtcMemoSearch.module" />,
      dataIndex: 'acronym',
    },
    {
      title: <FormattedMessage id="dtcMemoSearch.description" />,
      dataIndex: 'short_desc',
    },
    {
      title: ' ',
      dataIndex: 'memo',
      render: (_, record) => {
        return <MemoEditLink memoId={record.id} add={record.add} />
      },
    },
  ]

  render() {
    const {
      // data
      models,
      selectedModel,
      dtc,
      memos,
      modelsAreDownloading,
      memosAreDownloading,
      // actions
      doSelectModel,
      doEditDTCField,
      intl,
    } = this.props

    const modelsOpts = models
      ? models.map((m) => ({ value: m, label: `${m.model} - ${m.year}` }))
      : null

    return (
      <div>
        <MustHave permission="dtc-memo:get" fallback={<Forbidden />}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 id="memo-authoring">
                  <FormattedMessage id="dtcMemoSearch.pageTitle" />
                </h2>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-5">
                <label htmlFor="select-model">
                  <FormattedMessage id="dtcMemoSearch.selectModelLabel" />
                </label>
                <Select
                  id="model-select"
                  className="gray-text-color"
                  disabled={modelsAreDownloading}
                  options={modelsOpts}
                  value={selectedModel}
                  placeholder="Model"
                  onChange={doSelectModel}
                />
              </div>
              <div className="col-sm-5">
                <label htmlFor="dtc-input">
                  <FormattedMessage id="dtcMemoSearch.dtcInputLabel" />
                </label>
                <input
                  id="dtc-input"
                  className="form-control gray-text-color"
                  disabled={!selectedModel}
                  type="input"
                  value={dtc}
                  placeholder="Example: D1572A"
                  onKeyPress={(e) => this.handleSearchByEnterPress(e)}
                  onChange={(e) => doEditDTCField(e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <input
                  id="search-btn"
                  className="btn form-btn-inline btn-default w-100"
                  disabled={this.isSearchDisabled()}
                  type="button"
                  value={intl.formatMessage({ id: 'dtcMemoSearch.search' })}
                  onClick={() => this.doSearchOfMemos()}
                />
              </div>
            </div>

            {memosAreDownloading === LOADING_STATE.endOk &&
              memos &&
              (memos.length > 0 ? (
                <div className="row mb-2">
                  <div className="col-lg-12 table-container">
                    <Table
                      columns={this.columns}
                      dataSource={memos}
                      className="bs-table-container"
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <FormattedMessage id="dtcMemoSearch.noResults" />
                  </div>
                </div>
              ))}
          </div>
        </MustHave>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  models: state.dtcMemoSearch.models,
  selectedModel: state.dtcMemoSearch.selectedModel,
  dtc: state.dtcMemoSearch.dtc,
  memos: state.dtcMemoSearch.memos,
  modelsAreDownloading: state.dtcMemoSearch.modelsAreDownloading,
  memosAreDownloading: state.dtcMemoSearch.memosAreDownloading,
})

const mapDispatch = (dispatch) => ({
  doDownloadModels: () => dispatch(downloadModels()),
  doSelectModel: (model) => dispatch(selectModel(model)),
  doEditDTCField: (dtc) => dispatch(editDTCField(dtc)),
  doDownloadMemos: () => dispatch(downloadMemos()),
})

const ProtectedDtcMemoSearchContainer = requireAuthentication(
  DtcMemoSearchContainer,
)

export default connect(
  mapStateToProps,
  mapDispatch,
)(injectIntl(ProtectedDtcMemoSearchContainer))
