import React from 'react'
import Loading from './Loading'
import { login } from '../api/auth-api'
import { FormattedMessage } from 'react-intl'

class Login extends React.Component {
  componentDidMount() {
    login()
  }
  render() {
    return (
      <Loading
        backgroundColor=""
        text={<FormattedMessage id="processes.authenticating" />}
      />
    )
  }
}

export default Login
