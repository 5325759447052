import React, { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import {
  getVehicleTestsByVin,
  navigateToVehicleTest,
} from '../api/vehicle-tests-api'
import { ReactComponent as IconBack } from '../assets/images/icons/embedded/svg/back.svg'
import { Down, Up } from './Controls'
import { CantFixMessage } from '../components/CantFixReason'
import { formatTimestamp } from '../helpers/utils'
import {
  setActionBarSelection,
  setSwitchTestTableScroll,
} from '../actions/embedded-mode-actions'
import { SELECTION_VALUES } from './const'
import { centerOnSelectedElement } from '../actions/component-actions'
import { ReactComponent as SwitchTestIcon } from '../assets/images/icons/embedded/svg/switch-test.svg'

// NOTE: the same value defined in embedded-mode/_switch-test-view.scss,
// if you change this value you should make corresponding change in CSS code.
const ROW_HEIGHT = 70 // px
const N = 4 // scroll multiple
const SCROLL_UP_PARAMS = { top: N * ROW_HEIGHT, left: 0, behavior: 'smooth' }
const SCROLL_DOWN_PARAMS = { top: -N * ROW_HEIGHT, left: 0, behavior: 'smooth' }

const currentOrChangeLabel = (rowObj, currentVehicleTest, intl) => {
  if (currentVehicleTest && currentVehicleTest.id === rowObj.id) {
    return (
      <p className="embedded-mode-current-vehicle-test uppercase">
        {intl.formatMessage({ id: 'generic.current' })}
      </p>
    )
  }
  return (
    <div>
      <div className="embedded-mode-switch-test-icon">
        <SwitchTestIcon />
      </div>
    </div>
  )
}

const Row = ({ rowObj, currentTest, intl }) => {
  const dispatch = useDispatch()

  const timestampFormat = intl.formatMessage({ id: 'format.dayAndTime' })
  const isClickable = !(currentTest && currentTest.id === rowObj.id)

  const navigateToTest = () => {
    if (isClickable) {
      navigateToVehicleTest({
        vehicleTestId: rowObj.id,
        embeddedMode: true,
      }).then(() => {
        dispatch(setActionBarSelection(SELECTION_VALUES.components))
        dispatch(centerOnSelectedElement())
      })
    }
  }

  const clazz = classNames({
    'embedded-mode-clickable-animation': isClickable,
  })

  return (
    <tr className={clazz} onClick={navigateToTest}>
      <td>{formatTimestamp(timestampFormat, rowObj.timestamp)}</td>
      <td>{rowObj.meta ? rowObj.meta.test_type : null}</td>
      <td>{rowObj.meta ? rowObj.meta.hardware_location_id : null}</td>
      <td>
        <span>
          <CantFixMessage vehicleTestId={rowObj.id} />
        </span>
      </td>
      <td>{rowObj.meta ? rowObj.meta.user_id : null}</td>
      <td>{currentOrChangeLabel(rowObj, currentTest, intl)}</td>
    </tr>
  )
}

const SwitchTestEmbeddedMode = ({ intl }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vehicleTests: state.vehicleTests.data
        ? state.vehicleTests.data.sort((a, b) =>
            a.timestamp > b.timestamp ? -1 : 1,
          )
        : state.vehicleTests.data,
      currentVehicleTest: state.vehicleTests.currentVehicleTest,
      vin: state.componentState.vin,
      switchTestTableScrollParams:
        state.embeddedModeState.switchTestTableScrollParams,
    }),
    shallowEqual,
  )

  useEffect(() => {
    const vin = props.vin
    if (vin) {
      getVehicleTestsByVin(vin).then(handleScroll) // enable scroll controls if need to
    }
  }, [props.vin])

  const goBack = () => {
    dispatch(setActionBarSelection(SELECTION_VALUES.components))
    dispatch(centerOnSelectedElement())
  }

  const { scrollTop, scrollHeight, clientHeight } =
    props.switchTestTableScrollParams
  const isUpScrollDisabled = scrollTop === 0
  const isScrollDownDisabled = scrollTop === scrollHeight - clientHeight

  const testsTableRef = useRef(null)

  const handleScroll = () => {
    dispatch(
      setSwitchTestTableScroll({
        scrollTop: testsTableRef.current.scrollTop,
        scrollHeight: testsTableRef.current.scrollHeight,
        clientHeight: testsTableRef.current.clientHeight,
      }),
    )
  }

  return (
    <div className="embedded-mode-table-wrapper">
      <div className="embedded-mode-table-scroll-header embedded-mode-switch-test-header">
        <div className="controls" onClick={goBack}>
          <IconBack />
        </div>
        <Up
          onClick={() => testsTableRef.current.scrollBy(SCROLL_DOWN_PARAMS)}
          className="controls"
          disabledClassName="disabled"
          disabled={isUpScrollDisabled}
        />
        <div className="controls-stub" />
      </div>
      <div
        ref={testsTableRef}
        className="embedded-mode-table-scroll-content fix-select"
        onScroll={handleScroll}
      >
        <table className="embedded-mode-switch-test-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="vinSearch.columns.date" />
              </th>
              <th>
                <FormattedMessage id="vinSearch.columns.testType" />
              </th>
              <th>
                <FormattedMessage id="vinSearch.columns.location" />
              </th>
              <th>
                <FormattedMessage id="vinSearch.columns.cantFix" />
              </th>
              <th>
                <FormattedMessage id="vinSearch.columns.user" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.vehicleTests.map((rowObj, idx) => (
              <Row
                key={`${rowObj.id}-${idx}`}
                rowObj={rowObj}
                currentTest={props.currentVehicleTest}
                intl={intl}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="embedded-mode-table-scroll-footer embedded-mode-switch-test-footer">
        <Down
          onClick={() => testsTableRef.current.scrollBy(SCROLL_UP_PARAMS)}
          className="controls"
          disabledClassName="disabled"
          disabled={isScrollDownDisabled}
        />
      </div>
    </div>
  )
}

export default injectIntl(SwitchTestEmbeddedMode)
