import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import DescriptiveSelect from '../Fields/DescriptiveSelect'
import { Multiselect } from 'react-widgets'
import Select from '../Fields/Select'
import { required } from '../../validators'
import { createDtcList } from '../../selectors'
import {
  getSelectedDtcs,
  getDtcOptions,
  getComponentsList,
} from '../../selectors/repairHistory'
import _ from 'lodash'
import { FieldEntry, FormButtons } from './index'
import {
  addComponentProblem,
  updateComponentProblem,
} from '../../api/controller-api'

class ComponentDetails extends Component {
  constructor(props) {
    super(props)
    const isEdit = this.props.item && this.props.item.id
    const isNew = !isEdit
    this.state = {
      isNew,
      isEdit,
      connectorType: 'connector',
      selectedDtcs: this.props.selectedDtcs,
    }

    const component = this.state.isEdit && {
      label: this.props.item.alias,
      description: this.props.item.description,
      value: this.props.item.component_id,
      id: this.props.item.component_id,
    }
    this.props.initialize({ item: this.props.item, component: component })
  }

  saveItem = (values) => {
    const { id, annotation } = values.item

    const reason = values.item.reason.value || values.item.reason
    const componentId = values.component.id
    const dtcs = _.map(this.state.selectedDtcs, 'value')
    if (this.state.isNew) {
      this.props.add({
        annotation,
        componentId,
        dtcs,
        reason,
      })
    } else {
      this.props.update({ id, annotation, componentId, dtcs, reason })
    }
  }
  onDtcChange = (selectedDtcs) => {
    this.setState({ selectedDtcs })
  }
  isConnectorChecked = () => this.state.connectorType === 'connector'
  isInlineChecked = () => this.state.connectorType === 'inline'
  render = () => (
    <form
      id="repair-history-component-details"
      onSubmit={this.props.handleSubmit(this.saveItem)}
    >
      <div className="panel panel-default">
        <div className="panel-body">
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.componentLabel',
            })}
          >
            <DescriptiveSelect
              isDisabled
              name="component"
              options={this.props.components}
              clearable={false}
              validate={[required]}
              placeholder={this.props.intl.formatMessage({
                id: 'repairHistory.add.form.componentPlaceholder',
              })}
            />
          </FieldEntry>
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.faultTypeLabel',
            })}
          >
            <Select
              name="item.reason"
              options={this.props.faults}
              clearable={false}
              placeholder={this.props.intl.formatMessage({
                id: 'repairHistory.add.form.faultTypePlaceholder',
              })}
              validate={[required]}
            />
          </FieldEntry>
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.dtcCodesLabel',
            })}
          >
            <Multiselect
              name="item.dtcs"
              defaultValue={this.state.selectedDtcs}
              data={this.props.dtcOptions}
              onChange={this.onDtcChange}
              textField="label"
              valueField="value"
            />
          </FieldEntry>
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.notesLabel',
            })}
          >
            <Field
              className="form-control annotation"
              name="item.annotation"
              type="text"
              component="textarea"
              placeholder={this.props.intl.formatMessage({
                id: 'repairHistory.add.form.notesPlaceholder',
              })}
              ref={(elem) => (this.elemAnnotation = elem)}
            />
          </FieldEntry>
          <FormButtons onCancel={() => this.props.onClose()} />
        </div>
      </div>
    </form>
  )
}
ComponentDetails = reduxForm({
  form: 'repair-history-component-details',
  destroyOnUnmount: true,
})(ComponentDetails)

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: _.get(
      ownProps,
      'item.vehicle_test_result_id',
      state.componentState.vehicleTestResultId,
    ),
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    dtcOptions: getDtcOptions(state),
    selectedDtcs: getSelectedDtcs(_.get(ownProps, 'item.dtcs', []))(state),
    faults: state.loggingState.faults.component.map((item) => ({
      value: item.key,
      label: item.key,
    })),
    components: getComponentsList(state),
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _add: ({
    annotation,
    vehicleTestResultId,
    componentId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    addComponentProblem({
      annotation,
      vehicleTestResultId,
      componentId,
      dtcs,
      isProblem: true,
      makeModelId,
      reason,
      vin,
    })
  },
  _update: ({
    id,
    annotation,
    vehicleTestResultId,
    componentId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    updateComponentProblem({
      id,
      annotation,
      vehicleTestResultId,
      componentId,
      makeModelId,
      vin,
      isProblem: true,
      reason,
      dtcs,
    })
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    add: (args) => {
      dispatchProps._add({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
    update: (args) => {
      dispatchProps._update({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
  }
}

export const ComponentDetailsContainer = injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ComponentDetails),
)
