import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DTCItemContainer from '../containers/DTCItemContainer'
import { scrollToElement } from '../helpers/utils'

const propTypes = {
  modules: PropTypes.array.isRequired,
}

class DTCList extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sidebarOpen) {
      const dtcList = document.getElementById('dtc-list')
      const firstIndicatedDtc = dtcList.querySelector('.indicate')
      if (firstIndicatedDtc) {
        scrollToElement(dtcList.parentNode, firstIndicatedDtc)
      }
    }
  }

  render() {
    return (
      <ul id="dtc-list" className="list-group">
        {this.props.modules.map((module) => {
          return <DTCItemContainer key={module.acronym} module={module} />
        })}
      </ul>
    )
  }
}

DTCList.propTypes = propTypes

export default DTCList
