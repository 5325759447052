import React from 'react'
import classNames from 'classnames'
import { Up, Down } from './Controls'
import { FormattedMessage, injectIntl } from 'react-intl'

const COMPONENT_HEIGHT = 80

const EmbeddedSelect = ({ options, selected, onChange }) => {
  const [isTop, setIsTop] = React.useState(true)
  const [isBottom, setIsBottom] = React.useState(false)

  const listRef = React.useRef()

  const scrollUp = () => {
    listRef.current.scrollTo({
      top: listRef.current.scrollTop - COMPONENT_HEIGHT,
    })
    setIsTop(getIsTop())
    setIsBottom(getIsBottom())
  }

  const scrollDown = () => {
    listRef.current.scrollTo({
      top: listRef.current.scrollTop + COMPONENT_HEIGHT,
    })
    setIsTop(getIsTop())
    setIsBottom(getIsBottom())
  }

  const getIsTop = () => {
    return listRef.current.scrollTop == 0
  }

  const getIsBottom = () => {
    return (
      listRef.current.scrollHeight - Math.abs(listRef.current.scrollTop) ===
      listRef.current.clientHeight
    )
  }

  React.useEffect(() => {
    if (!listRef.current) return
    setIsTop(getIsTop())
    setIsBottom(getIsBottom())
  }, [listRef])

  return (
    <div className="embedded-select">
      <Up
        className="embedded-control"
        onClick={() => scrollUp()}
        disabled={isTop}
      />
      <div ref={listRef} className="embedded-select-list">
        {options.map((option) => (
          <EmbeddedSelectOption
            option={option}
            key={option.value}
            onClick={onChange}
            selected={selected}
          />
        ))}
      </div>
      <Down
        className="embedded-control"
        onClick={() => scrollDown()}
        disabled={isBottom}
      />
    </div>
  )
}

const EmbeddedSelectOption = ({ option, onClick, selected }) => {
  const classes = classNames('embedded-select-item', {
    selected: option.value == selected?.value,
  })

  return (
    <div onClick={() => onClick(option)} className={classes}>
      {option.label}
      {option.isSuggested && (
        <div className="embedded-select-item-suggested">
          <FormattedMessage id="embeddedSelect.recentlyReported" />
        </div>
      )}
    </div>
  )
}

export default injectIntl(EmbeddedSelect)
