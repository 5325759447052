import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import DTCItem from '../components/DTCItem'
import {
  unhighlightDtcs,
  toggleOnDtc,
  showDtcMemoTooltip,
} from '../actions/dtc-actions'
import { resetElement } from '../actions/component-actions'
import { MAIN_NAMESPACE } from '../constants'

class DTCItemContainer extends Component {
  shouldComponentUpdate(nextProps) {
    // TODO: consider to remove this method such thing is incompatible with a happy life
    const isChangedToggledOnDtc = !isEqual(
      this.props.toggledOnDtc,
      nextProps.toggledOnDtc,
    )
    const isChangedHighlightedDtcs = !isEqual(
      this.props.highlightedDtcs,
      nextProps.highlightedDtcs,
    )
    const isChangedSelectedComponent = !isEqual(
      this.props.selectedComponent,
      nextProps.selectedComponent,
    )
    const isTabChanged = !isEqual(this.props.currentTab, nextProps.currentTab)
    const isDtcListChanged = !isEqual(this.props.module, nextProps.module)
    const isMemosOpenStateChanged = !isEqual(
      this.props.memosOpenState,
      nextProps.memosOpenState,
    )
    const isDtcMemoEditSwitchChanged = !isEqual(
      this.props.isDtcMemoEditShown,
      nextProps.isDtcMemoEditShown,
    )
    const isMemoTooltipOpenStateChanged = !isEqual(
      this.props.memosTooltipOpenState,
      nextProps.memosTooltipOpenState,
    )

    return (
      isChangedToggledOnDtc ||
      isChangedSelectedComponent ||
      isChangedHighlightedDtcs ||
      isTabChanged ||
      isDtcListChanged ||
      isMemosOpenStateChanged ||
      isDtcMemoEditSwitchChanged ||
      isMemoTooltipOpenStateChanged
    )
  }

  render() {
    return <DTCItem {...this.props} />
  }
}

const mapStateToProps = (state) => {
  const currentTab = get(state.tabState.currentTab, MAIN_NAMESPACE)

  return {
    makeModelId: state.componentState.makeModelId,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    selectedComponent: state.componentState.selectedElement,
    currentTab,
    toggledOnDtc: state.dtcState.toggledOnDtc,
    highlightedDtcs: state.dtcState.highlightedDtcs,
    memosOpenState: state.dtcState.memosOpenState,
    isDtcMemoEditShown: state.dtcState.isDtcMemoEditShown,
    memosTooltipOpenState: state.dtcState.memosTooltipOpenState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDtcClick: (dtc) => {
      dispatch(resetElement())
      dispatch(unhighlightDtcs())
      dispatch(toggleOnDtc(dtc))
      dispatch(showDtcMemoTooltip())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DTCItemContainer)
