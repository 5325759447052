import DeviceNode from '../DeviceNode'
import GroundNode from '../GroundNode'

export default function nodeRouter({ node, onNodeClick, onPortClick }) {
  const { category } = node.properties.data
  switch (category) {
    case 'ground':
      return (
        <GroundNode
          node={node}
          onNodeClick={onNodeClick}
          onPortClick={onPortClick}
        />
      )
    default:
      return (
        <DeviceNode
          node={node}
          onNodeClick={onNodeClick}
          onPortClick={onPortClick}
        />
      )
  }
}
