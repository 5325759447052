import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetActiveDTCMappings({ makeModelId, dtcCode, acronym }) {
  return api
    .get(
      `/dtc-mappings/active?make_model_id=${makeModelId}&dtc_code=${dtcCode}&acronym=${acronym}`,
    )
    .then((resp) => resp.data)
}
