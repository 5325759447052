import * as types from '../actions/action-types'

export function resetState() {
  return {
    type: types.ADD_FAULT_DEVICE_RESET_STATE,
  }
}

export function setSearchDevice(search) {
  return {
    type: types.ADD_FAULT_DEVICE_SET_SEARCH,
    search,
  }
}

export function setDevices(devices) {
  return {
    type: types.ADD_FAULT_DEVICE_SET_DEVICES,
    devices,
  }
}

export function setSelectedDevice(selectedDevice) {
  return {
    type: types.ADD_FAULT_DEVICE_SET_SELECTED_DEVICE,
    selectedDevice,
  }
}

export function resetDevice() {
  return {
    type: types.ADD_FAULT_DEVICE_RESET_DEVICE,
  }
}
