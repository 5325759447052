import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from '../history'
import { ROUTING } from '../constants'
import { useFeatureFlags } from '../hooks/FeatureFlags'
import FunctionalDiagrams from './index'
import SalesCodesPage from './SalesCodesPage'
import { VirtualVehicleConfigurationProvider } from '../VirtualVehicleConfiguration/VirtualVehicleConfigurationContext'
import { DiagramProvider } from './DiagramContext'
import { FUNCTIONAL_DIAGRAMS_FLAG } from '../constants'
import Loading from './Loading'
import VehicleConfigurationSearch from '../components/VehicleConfiguration/VehicleConfigurationSearch'
import { VINSearchInputProvider } from '../components/VINSearchInput/VINSearchInputContext'

const FunctionalDiagramsRouter = () => {
  const { isLoading, isSuccess, usersFeatureFlags } = useFeatureFlags()

  if (isLoading) return <Loading />
  if (isSuccess && !usersFeatureFlags.includes(FUNCTIONAL_DIAGRAMS_FLAG))
    return <Redirect exact strict to="/" push={false} />

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <DiagramProvider>
          <Route exact path="/functional-diagrams/">
            <VirtualVehicleConfigurationProvider>
              <VINSearchInputProvider>
                <VehicleConfigurationSearch path={'functional-diagrams'} />
              </VINSearchInputProvider>
            </VirtualVehicleConfigurationProvider>
          </Route>
          <Route
            exact
            path="/functional-diagrams/vin/:vin"
            component={FunctionalDiagrams}
          />
          <VirtualVehicleConfigurationProvider>
            <Route
              exact
              path="/functional-diagrams/sales-codes"
              component={SalesCodesPage}
            />
          </VirtualVehicleConfigurationProvider>
        </DiagramProvider>
        <Redirect exact strict to="/" push={false} />
      </Switch>
    </ConnectedRouter>
  )
}

export default FunctionalDiagramsRouter
