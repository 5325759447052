import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import { get, isEmpty } from 'lodash'

import { clearPinDestination } from '../actions/pin-actions'
import {
  unsetModal,
  setModal,
  setConnectorImage,
} from '../actions/modal-actions'
import { HARNESS_VIEW, MODAL_EMBEDDED_MODE_CONNECTOR_VIEW } from '../constants'
import { Loadable } from '../components/Loading'
import PinDestinationGraphContainer from '../containers/PinDestinationGraphContainer'
import Topology from '../components/SvgViews/Topology'
import {
  ZoomInControlEmbedded,
  ZoomOutControlEmbedded,
  ZoomResetControlEmbedded,
} from './ZoomControls'
import PanControls from './PanControls'
import { setHarnessVehicleSwitchSelectionOnPinout } from '../actions/embedded-mode-actions'
import { HarnessViewVehicleViewSwitch } from './HarnessViewVehicleViewSwitch'
import { ReactComponent as IconBack } from '../assets/images/icons/embedded/svg/back.svg'
import PinDetailsEmbeddedMode from './PinDetails'

function ModalContainer(ownProps) {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      harnessVehicleSwitchOnPinout:
        state.embeddedModeState.harnessVehicleSwitchOnPinout,
    }),
    shallowEqual,
  )

  const setSelected = (selected) =>
    dispatch(setHarnessVehicleSwitchSelectionOnPinout(selected))

  const { isLoading, onCloseModal, selectedElement, onShowConnectorModal } =
    ownProps

  return (
    <div className="embedded-mode-container">
      <div className="embedded-mode-component-details-main">
        <div
          className="controls embedded-mode-back-button"
          onClick={onCloseModal}
        >
          <IconBack />
        </div>
        <HarnessViewVehicleViewSwitch
          viewName={props.harnessVehicleSwitchOnPinout}
          setView={setSelected}
        />
        {props.harnessVehicleSwitchOnPinout === HARNESS_VIEW ? (
          <div className="embedded-mode-harness-view">
            <Loadable isLoading={isLoading}>
              <PinDestinationGraphContainer
                zoomInControl={ZoomInControlEmbedded}
                zoomOutControl={ZoomOutControlEmbedded}
                zoomResetControl={ZoomResetControlEmbedded}
                panControls={PanControls}
              />
            </Loadable>
          </div>
        ) : (
          <div className="embedded-mode-vehicle-view">
            <Loadable isLoading={isLoading}>
              <Topology
                component={selectedElement}
                zoomInControl={ZoomInControlEmbedded}
                zoomOutControl={ZoomOutControlEmbedded}
                zoomResetControl={ZoomResetControlEmbedded}
                panControls={PanControls}
              />
            </Loadable>
          </div>
        )}
      </div>
      <div className="embedded-mode-component-details-panel">
        <Loadable isLoading={isLoading}>
          <PinDetailsEmbeddedMode
            selectedElement={selectedElement}
            showConnectorModal={onShowConnectorModal}
          />
        </Loadable>
      </div>
    </div>
  )
}

const PinDestinationEmbeddedMode = () => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      isOpen: state.modalState.isOpen,
      pinDestination: state.pinState.pinDestination,
      selectedElement: state.pinState.selectedElement,
      pin: state.pinState.pinNumber,
      vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
    }),
    shallowEqual,
  )

  const onClose = () => {
    dispatch(clearPinDestination())
    dispatch(unsetModal())
    dispatch(setHarnessVehicleSwitchSelectionOnPinout(HARNESS_VIEW))
  }

  const showConnectorModal = (pins, imageUrl) => {
    dispatch(setConnectorImage(pins, imageUrl))
    dispatch(setModal(MODAL_EMBEDDED_MODE_CONNECTOR_VIEW))
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => onClose()}
      className="modal-pin-destination"
      overlayClassName="embedded-mode-modal-overlay"
      contentLabel="Modal"
    >
      <ModalContainer
        isLoading={isEmpty(props.pinDestination)}
        selectedElement={props.selectedElement}
        onCloseModal={() => onClose()}
        onShowConnectorModal={showConnectorModal}
        pin={props.pin}
        vehicleTestId={props.vehicleTestId}
      />
    </Modal>
  )
}

export default PinDestinationEmbeddedMode
