import { apiGetModels, apiGetMemos } from '../api/dtc-memo-search'

import {
  modelsDownloadStart,
  setModels,
  memosDownloadStart,
  setMemos,
  memosDownloadError,
} from '../actions/dtc-memo-search'

export function downloadModels() {
  return function (dispatch) {
    dispatch(modelsDownloadStart())

    return apiGetModels()
      .then((resp) => dispatch(setModels(resp.models)))
      .catch(() => dispatch(setModels([])))
  }
}

export function downloadMemos() {
  return function (dispatch, getState) {
    dispatch(memosDownloadStart())

    return apiGetMemos({
      modelId: getState().dtcMemoSearch.selectedModel.value.id,
      q: getState().dtcMemoSearch.dtc,
    })
      .then((resp) => dispatch(setMemos(resp.memos)))
      .catch(() => dispatch(memosDownloadError()))
  }
}
