import { padFailureModeIndicator, padSuspectParameterName } from './padZero'

export default function generatePayload({ machine, userId, alerts }) {
  const payload = {
    make_model_id: machine.model.name,
    meta: {
      user_id: userId,
    },
    modelyear: machine.modelYear,
    sales_codes: [
      '501', // hardcoded for POC
    ],
    vehicle_phase: 'V1', // hardcoded for POC
    vin: machine.vin,
  }

  const modules = alerts.reduce((modules, alert) => {
    const { threeLetterAcronym, suspectParameterName, failureModeIndicator } =
      alert.definition
    const error = `${padSuspectParameterName(suspectParameterName)}.${padFailureModeIndicator(failureModeIndicator)}`

    if (modules.get(threeLetterAcronym)) {
      modules.get(threeLetterAcronym).push({ error })
    } else {
      modules.set(threeLetterAcronym, [{ error }])
    }

    return modules
  }, new Map())

  return { ...payload, modules: Object.fromEntries(modules) }
}

export function generateManualPayload({ vin, userId, dtcs }) {
  const payload = {
    meta: {
      user_id: userId,
    },
    make_model_id: 'x9', // hardcoded for POC
    sales_codes: [
      '501', // hardcoded for POC
    ],
    vehicle_phase: 'V1', // hardcoded for POC
    vin,
  }

  const modules = dtcs.reduce((modules, dtc) => {
    if (modules.get(dtc.acronym)) {
      modules.get(dtc.acronym).push({ error: dtc.dtc })
    } else {
      modules.set(dtc.acronym, [{ error: dtc.dtc }])
    }

    return modules
  }, new Map())

  return { ...payload, modules: Object.fromEntries(modules) }
}
