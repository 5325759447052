import React from 'react'
import { useJohnDeereAuthorize } from './hooks'
import Loading from '../../components/Loading'
import { Redirect } from 'react-router'

function JohnDeereAuthorize() {
  const { isSuccess, isLoading, isAuthorized } = useJohnDeereAuthorize()

  if (isLoading) return <Loading useBackgroundColor={false} />
  if (isAuthorized) return <Redirect push to={'/alerts'} />
}

export default JohnDeereAuthorize
