import { useQuery } from 'react-query'
import { apiSearchDTCs } from '../api/dtcs-api'

export const DTC_KEY = 'dtc'

export const useSearchDTCs = ({ searchString, makeModelID }) => {
  const {
    status,
    data: dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [DTC_KEY, makeModelID, searchString],
    async () => {
      const result = await apiSearchDTCs({ searchString, makeModelID })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!makeModelID && !!searchString,
    },
  )
  return {
    status,
    dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
