import {
  SET_USERS_LIST,
  SET_USERS_LIST_LOADING,
  SET_USER_LOADING,
  SET_USER_SAVING,
  SET_USER_SAVED,
  SET_USER_FOR_EDITING,
  SET_MORE_LOADED_USERS,
  SET_MORE_USERS_LOADING,
} from './action-types'

export function setUsersList(users) {
  return {
    users,
    type: SET_USERS_LIST,
  }
}

export function setMoreLoadedUsers(users) {
  return {
    users,
    type: SET_MORE_LOADED_USERS,
  }
}

export function setUsersListLoading() {
  return {
    type: SET_USERS_LIST_LOADING,
  }
}

export function setMoreUsersLoading() {
  return {
    type: SET_MORE_USERS_LOADING,
  }
}

export function setUserLoading() {
  return {
    type: SET_USER_LOADING,
  }
}

export function setUserSaving() {
  return {
    type: SET_USER_SAVING,
  }
}

export function setUserSaved() {
  return {
    type: SET_USER_SAVED,
  }
}

export function setUserForEditing(user) {
  return {
    type: SET_USER_FOR_EDITING,
    user,
  }
}
