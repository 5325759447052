import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Popover } from 'antd'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import MemoButton, { AddButton, EditButton } from './DTCMemoButton'
import MustHave from '../../containers/MustHave'

const PopoverTitle = ({ title, onClick }) => (
  <>
    <span>{title}</span>
  </>
)

function DTCMemoPopup({
  dtc,
  makeModelId,
  acronym,
  intl,
  isSelected,
  children,
}) {
  const isDtcMemoPopupOpen = useSelector(
    (state) => state.dtcState.dtcMemoPopup,
    shallowEqual,
  )
  const title = (
    <PopoverTitle title={intl.formatMessage({ id: 'dtcMemo.label' })} />
  )
  const hasDtcMemo = !!dtc.memo && !!dtc.memo.memo

  const Content = () => {
    return (
      <div className="dtc-memo-view-body-content">
        <div className="flex space-between">
          <div>
            <div>
              <strong>{`${dtc.memo.dtc} - ${dtc.memo.acronym}`}</strong>
            </div>
            <div>
              <strong>
                <FormattedMessage id="dtcMemo.author" />:{' '}
              </strong>
              {dtc.memo.updated_by ? dtc.memo.updated_by : 'Tracer'}
            </div>
          </div>
          <div>
            <MustHave permission="dtc-memo:edit">
              <MemoButton
                dtc={dtc}
                makeModelId={makeModelId}
                acronym={acronym}
                editIcon={EditButton}
                addIcon={AddButton}
              />
            </MustHave>
          </div>
        </div>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: dtc.memo.memo }} />
      </div>
    )
  }

  return (
    <Popover
      open={isDtcMemoPopupOpen && isSelected && hasDtcMemo}
      title={hasDtcMemo ? title : ''}
      trigger="click"
      id={`dtc-memo-${dtc.memo?.id}`}
      zIndex={9000}
      placement="left"
      overlayStyle={{
        width: '40vw',
      }}
      content={() => <Content />}
    >
      {children}
    </Popover>
  )
}

export default injectIntl(DTCMemoPopup)
