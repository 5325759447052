import * as types from '../actions/action-types'
export function setNotification(container, message) {
  return {
    type: types.SET_NOTIFICATION_CONTAINER,
    container,
    message,
  }
}

export function unsetNotification() {
  return {
    type: types.UNSET_NOTIFICATION_CONTAINER,
  }
}
