import {
  USER_LOADING,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
} from '../actions/action-types'
import { DEFAULT_BUSINESS_TIMEZONE } from '../constants'

const initialState = {
  businessTimezone: DEFAULT_BUSINESS_TIMEZONE,
  data: null,
  isLoading: true,
}

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return { ...state, isLoading: true }
    case USER_LOGGED_IN:
      const newTimezone = action.user
        ? action.user.businessTimezone
        : DEFAULT_BUSINESS_TIMEZONE
      const newUserData = {
        ...action.user,
        businessTimezone: newTimezone,
      }
      return {
        businessTimezone: newTimezone,
        data: newUserData,
        isLoading: false,
      }
    case USER_LOGGED_OUT:
      return { data: null, isLoading: false }
    default:
      return state
  }
}

export default userReducer
