import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetGlobalSearch({ vin, searchString }) {
  const params = new URLSearchParams({ search_string: searchString })

  return api
    .get(`/vin/${vin}/search?${params.toString()}`)
    .then((resp) => resp.data)
}
