import React from 'react'

import IconSameIssuesHistory from '../Icons/IconSameIssuesHistory'

function formatTotalIssuesCount(totalIssuesCount) {
  return totalIssuesCount >= 100 ? '99+' : totalIssuesCount
}

function Button(props) {
  const { totalIssuesCount, hasSameIssuesHistory, openSameIssuesHistory } =
    props
  if (!hasSameIssuesHistory) {
    return null
  }

  return (
    <button
      className="same-issues-history-button-container"
      onClick={openSameIssuesHistory}
    >
      <div className="counter">{formatTotalIssuesCount(totalIssuesCount)}</div>
      <div className="same-issues-history-button">
        <IconSameIssuesHistory />
      </div>
    </button>
  )
}

export default Button
