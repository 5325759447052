import * as types from '../actions/action-types'
import { DTC_MAPPING_ACTIVE_UI } from '../constants'

const initialState = {
  // Active UI component.
  activeUIComponent: DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS,

  // Search and select component.
  search: '',
  components: null,
  selectedComponentId: '',
  selectedComponent: {},
  pins: null,
  jumperPins: null,
}

const dtcMappingReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.DTC_MAPPING_SET_ACTIVE_UI:
      return Object.assign({}, state, {
        activeUIComponent: action.activeUIComponent,
      })
    case types.DTC_MAPPING_RESET_STATE:
      return Object.assign({}, initialState)
    case types.DTC_MAPPING_SET_SEARCH:
      return Object.assign({}, state, { search: action.search })
    case types.DTC_MAPPING_SET_COMPONENTS:
      return Object.assign({}, state, { components: action.components })
    case types.DTC_MAPPING_SET_SELECTED_COMPONENT_ID:
      return Object.assign({}, state, {
        selectedComponentId: action.selectedComponentId,
      })
    case types.DTC_MAPPING_SET_SELECTED_COMPONENT:
      return Object.assign({}, state, {
        selectedComponent: action.selectedComponent,
      })
    case types.DTC_MAPPING_RESET_COMPONENT:
      return Object.assign({}, state, {
        selectedComponent: initialState.selectedComponent,
        selectedComponentId: initialState.selectedComponentId,
      })
    default:
      return state
  }
}

export default dtcMappingReducer
