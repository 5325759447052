import _ from 'lodash'
import isObject from 'lodash/isObject'
import isUndefined from 'lodash/isUndefined'

export function getFirstFoundPropertyOrDefault(
  element,
  properties,
  defaultName,
) {
  return (
    _.chain(properties)
      .filter((p) => element[p])
      .map((k) => element[k])
      .first()
      .value() || defaultName
  )
}

/*
 * Gets the name to be displayed for the component.
 * Displays the component's Alias if defined, otherwise unknown.
 */
export function getElementName(element, defaultName = 'UNKNOWN') {
  if (isObject(element)) {
    if (isNode(element))
      return getFirstFoundPropertyOrDefault(
        element,
        ['device_name', 'alias', 'component_id', 'id'],
        defaultName,
      )
    if (isEdge(element))
      return getFirstFoundPropertyOrDefault(
        element,
        ['harness', 'circuit_category'],
        defaultName,
      )
  }

  return defaultName
}

export function guessComponentOrHarnessName(element, defaultName = 'UNKNOWN') {
  return getFirstFoundPropertyOrDefault(
    element,
    [
      'device_name',
      'alias',
      'component_id',
      'harness',
      'id',
      'circuit_category',
    ],
    defaultName,
  )
}

/**
 * Returns name of the field which is used as ID
 * on the backend
 *
 * @param {Node} element
 * @return {string}
 */
export function getElementIdField(element) {
  return isEdge(element) ? 'harness' : 'id'
}

/**
 * Element which is got from Graph has specific property 'isNode'
 * Element which is got directly from API has property 'component_id'
 * If one of those fields is true then the element is node
 *
 * @param {Node} element
 * @return {boolean}
 */
export function isNode(element) {
  if (!isUndefined(element.isNode)) {
    return element.isNode
  }
  if (!isUndefined(element.device_id)) {
    return !!element.device_id
  }
  if (!isUndefined(element.component_id)) {
    return !!element.component_id
  }
  return false
}

/**
 * Element which is got from Graph has specific property 'isEdge'
 * Element which is got directly from API has property 'harness'
 * If one of those fields is true then the element is edge
 *
 * @param {Node} element
 * @return {boolean}
 */
export function isEdge(element) {
  if (!isUndefined(element.isEdge)) {
    return element.isEdge
  }
  if (!isUndefined(element.harness)) {
    return !!element.harness
  }
  return false
}
