import Loading from '../components/Loading'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import VehicleTestsTable from './VehicleTestsTable'
import { validateVin } from '../helpers/utils'

const propTypes = {
  messageEnterVin: PropTypes.string,
  messageInvalidVin: PropTypes.string,
  messageLoading: PropTypes.string,
  messageNoTestsFound: PropTypes.string,
  vehicleTests: PropTypes.array,
  vehicleTestsLoading: PropTypes.bool,
  vin: PropTypes.string,
}

const defaultProps = {
  messageEnterVin: 'Please enter a VIN number and click submit.',
  messageInvalidVin: 'You must enter a valid VIN number.',
  messageLoading: 'Loading...',
  messageNoTestsFound:
    'No tests found.  Please enter a VIN number and click submit.',
  vehicleTests: [],
  vehicleTestsLoading: false,
  vin: undefined,
}

const vehicleTestsTableStyle = {
  maxHeight: '65%',
  position: 'absolute',
  width: '100%',
}

class VehicleTestsSearch extends Component {
  constructor(props) {
    super(props)
    const vinInput =
      this.props.vin ||
      (this.props.match.params.vin ? String(this.props.match.params.vin) : '')
    this.state = {
      errorMessage: undefined,
      vin: undefined,
      vinInput: vinInput,
    }
    this.handleChangeEvent = this.handleChangeEvent.bind(this)
    this.handleSubmitEvent = this.handleSubmitEvent.bind(this)
  }

  componentDidMount() {
    this.unlistenToHistory = this.props.history.listen((location, action) => {
      if (this.props.match.params.vin !== this.state.vinInput) {
        this.search(this.props.match.params.vin)
      }
    })
    if (this.state.vinInput) {
      this.search(this.state.vinInput)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.vin && prevState.vin !== this.state.vin) {
      this.props.searchForVin(encodeURIComponent(this.state.vin))
    }
  }

  componentWillUnmount() {
    this.unlistenToHistory()
  }

  handleChangeEvent(event) {
    this.setState({
      vinInput: validateVin(event.target.value),
    })
  }

  handleSubmitEvent(event) {
    event.preventDefault()
    this.search(this.state.vinInput)
  }

  renderSearchResults() {
    if (this.props.vehicleTestsLoading) {
      return <Loading backgroundColor="" text={this.state.messageLoading} />
    }
    if (this.state && this.state.errorMessage) {
      return <p id="vehicle-test-help-message">{this.state.errorMessage}</p>
    }
    if (this.props.vehicleTests.length === 0) {
      if (!this.state || !this.state.vin) {
        return (
          <p id="vehicle-test-help-message">{this.props.messageEnterVin}</p>
        )
      }
      return (
        <div
          id="vehicle-test-help-message"
          dangerouslySetInnerHTML={{ __html: this.props.messageNoTestsFound }}
        />
      )
    }
    return (
      <VehicleTestsTable
        data={this.props.vehicleTests}
        style={vehicleTestsTableStyle}
      />
    )
  }

  render() {
    return (
      <div id="vehicle-tests-search" className="container">
        <div className="row">
          <h2>
            <FormattedMessage id="generic.vin" />
          </h2>
        </div>
        <div className="row">
          <form
            id="vehicleTestsSearchForm"
            className="form"
            onSubmit={this.handleSubmitEvent}
          >
            <div className="form-group col-sm-10">
              <label htmlFor="vin">
                <FormattedMessage id="vinSearch.inputLabel" />
              </label>
              <input
                className="form-control"
                id="vin"
                name="vin"
                onChange={this.handleChangeEvent}
                placeholder={this.props.intl.formatMessage({
                  id: 'vinSearch.inputPlaceholder',
                })}
                type="vin"
                value={this.state.vinInput}
              />
            </div>
            <input
              className="btn col-sm-2 form-btn-inline"
              onClick={this.handleSubmitEvent}
              value={this.props.intl.formatMessage({ id: 'forms.submit' })}
              type="submit"
            />
          </form>
        </div>
        <div className="row">{this.renderSearchResults()}</div>
      </div>
    )
  }

  search(vin) {
    if (this.props.match.params.vin !== vin) {
      this.props.history.push(`/search/${encodeURIComponent(vin)}`)
    }

    if (validateVin(vin)) {
      this.setState({
        errorMessage: undefined,
        vin: vin,
        vinInput: vin,
      })
    } else {
      this.setState({
        errorMessage: this.props.messageInvalidVin,
        vinInput: vin || '',
      })
    }
  }
}

class LocalizedVehicleTestsSearch extends Component {
  render() {
    const newProps = {
      ...this.props,
      messageEnterVin: this.props.intl.formatMessage({
        id: 'vinSearch.messages.enterVin',
      }),
      messageInvalidVin: this.props.intl.formatMessage({
        id: 'vinSearch.messages.invalidVin',
      }),
      messageLoading: this.props.intl.formatMessage({
        id: 'processes.loading',
      }),
      messageNoTestsFound: this.props.intl.formatMessage({
        id: 'vinSearch.messages.noTestsFound',
      }),
    }
    return <VehicleTestsSearch {...newProps} />
  }
}

VehicleTestsSearch.propTypes = propTypes
VehicleTestsSearch.defaultProps = defaultProps

export default injectIntl(LocalizedVehicleTestsSearch)
