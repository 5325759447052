import { useSelector, shallowEqual } from 'react-redux'
import { useMakeModelByVin } from '../../hooks/MakeModels'
import VehicleDetails from '../VehicleDetails'
import { useSalesCodeConstraintsByVin } from '../../hooks/SalesCodes'
import { Popover } from 'antd'
import iconCar from '../../assets/images/ic-car.svg'
import IconChevron from '../Icons/IconChevron'
import { ORIENTATION_UP } from '../../constants'
import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'

function Collapsible(props) {
  const { vehicleName, onClick } = props

  return (
    <div
      data-testid="collapsible_vehicle_make_model"
      onClick={onClick}
      className="collapsible"
    >
      <div className="vehicle-name">
        <div>{vehicleName}</div>
        <img src={iconCar} alt="car" />
      </div>
      <span data-testid="toggle-icon" className="toggle-icon">
        <IconChevron orientation={ORIENTATION_UP} />
      </span>
    </div>
  )
}

const VehicleMakeModelName = (props) => {
  const { className, showVin } = props
  const vin =
    useSelector((state) => state.componentState.vin, shallowEqual) || props.vin
  const { isSuccess, isLoading, makeModel } = useMakeModelByVin({ vin })
  const {
    isSuccess: salesCodeConstraintsIsSuccess,
    isLoading: salesCodeConstraintsisLoading,
    salesCodeConstraints,
  } = useSalesCodeConstraintsByVin({ vin })

  if (isLoading)
    return (
      <div className={className}>
        <p>
          <FormattedMessage id="tracer.loading" />
        </p>
      </div>
    )

  let vehicleName = (
    <>
      <p>
        <FormattedMessage id="tracer.unknownVehicle" />
      </p>
    </>
  )
  if (makeModel) {
    if (showVin) {
      vehicleName = (
        <>
          <p>
            {makeModel.make_description || makeModel.make}{' '}
            {makeModel.model_description || makeModel.model}
          </p>
          {makeModel.is_virtual === false && (
            <p>
              <span className="make-model-label">VIN:</span> {vin}
            </p>
          )}
        </>
      )
    } else {
      vehicleName = (
        <>
          <p>{makeModel.make_description || makeModel.make}</p>
          <p>{makeModel.model_description || makeModel.model}</p>
        </>
      )
    }
  }

  return (
    <div className={className}>
      <Popover
        trigger="click"
        placement="bottom"
        content={() => (
          <VehicleDetails salesCodeConstraints={salesCodeConstraints} />
        )}
        overlayStyle={{
          width: '40vw',
        }}
      >
        <Collapsible vehicleName={vehicleName} />
      </Popover>
    </div>
  )
}

export default injectIntl(VehicleMakeModelName)
