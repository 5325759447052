import { shallowEqual, useSelector } from 'react-redux'
import get from 'lodash/get'

import { userHasPermission } from '../services/permissions'

export function useCheckPermission() {
  const rules = useSelector(
    (state) => get(state, 'user.data.rules', []),
    shallowEqual,
  )
  return (permission) => userHasPermission(rules, permission)
}
