import React from 'react'
import { Input, Form } from 'antd'
import { injectIntl } from 'react-intl'
import { useDebounce } from 'react-use'
import { useCompleteVIN } from '../../hooks/VIN'
import {
  useVINSearchInputContext,
  useVINSearchInputDispatch,
} from './VINSearchInputContext'

const VINSearchInput = ({ intl, disabled }) => {
  const context = useVINSearchInputContext()
  const dispatch = useVINSearchInputDispatch()

  const {
    vin: vinSearchResult,
    isLoading,
    isSuccess,
  } = useCompleteVIN({
    searchString: context.debouncedVIN,
  })

  React.useEffect(() => {
    const validateVINSearchResult = ({ vin }) => {
      if (vin === null) {
        return {
          validateStatus: 'error',
          errorMessage: intl.formatMessage({
            id: 'vinSearchInput.validation.vinDoesNotExist',
          }),
        }
      }
      return {
        validateStatus: 'success',
        errorMessage: null,
      }
    }

    if (isSuccess) {
      dispatch.handleVINValidated(
        validateVINSearchResult({ vin: vinSearchResult }),
      )
      dispatch.handleCompleteVINSearchResult({ completeVIN: vinSearchResult })
    }
  }, [vinSearchResult, dispatch, isSuccess, intl])

  React.useEffect(() => {
    if (isLoading) {
      dispatch.handleVINValidated({
        validateStatus: 'validating',
        errorMessage: null,
      })
    }
  }, [isLoading, dispatch])

  // Do not make a lot of unnecessary API calls:
  //      1) while the user provides the VIN;
  //      2) or user provided the VIN of unsupported length.
  useDebounce(
    () => {
      if (context.inputVIN === '') {
        // User deleted all characters
        dispatch.reset()
        return
      } else if (context.inputVIN === null) {
        // Initial state
        return
      }
      const vinLengthvalidation = validateVINLength(context.inputVIN)
      dispatch.handleVINValidated(vinLengthvalidation)
      if (vinLengthvalidation.validateStatus === 'error') return
      dispatch.handleVINDebounced({ debouncedVIN: context.inputVIN })
    },
    800,
    [context.inputVIN],
  )

  const validateVINLength = (vin) => {
    if (vin.length < 8) {
      return {
        validateStatus: 'error',
        errorMessage: intl.formatMessage({
          id: 'vinSearchInput.validation.unsupportedVINLength',
        }),
      }
    }
    return {
      validateStatus: '',
      errorMessage: null,
    }
  }

  return (
    <Form.Item
      label="VIN"
      labelCol={{ span: 24 }}
      hasFeedback
      validateStatus={context.validationResult.validateStatus}
      help={context.validationResult.errorMessage}
    >
      <Input
        data-testid="vin-search-input"
        placeholder={intl.formatMessage({
          id: 'vinSearchInput.inputVINPlaceholder',
        })}
        onChange={(event) => {
          dispatch.handleVINProvided({ inputVIN: event.target.value })
        }}
        defaultValue={context.inputVIN}
        disabled={disabled}
        value={context.inputVIN}
        maxLength={17}
      />
    </Form.Item>
  )
}

export default injectIntl(VINSearchInput)
