import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setComponentListScroll } from '../actions/embedded-mode-actions'
import classNames from 'classnames'
import { highlightDtcs, toggleOffDtc } from '../actions/dtc-actions'
import { getElementName } from '../helpers/element'
import { createDtcList, createConnectorSetFromToggledDtcs } from '../selectors'
import { getSelectedComponentId } from '../selectors/component'
import { selectElement } from '../thunks/component'
import { ReactComponent as FaultyIcon } from '../assets/images/icons/embedded/svg/faulty.svg'

const COMPONENT_HEIGHT = 80

const FaultyComponentIcon = () => {
  return <FaultyIcon />
}

const Component = React.forwardRef(function Component(
  { component, status },
  ref,
) {
  const dispatch = useDispatch()

  const dtcs = useSelector((state) => createDtcList(state), shallowEqual)
  const highlightedComponents = useSelector(
    createConnectorSetFromToggledDtcs,
    shallowEqual,
  )
  const toggledOnDtc = useSelector(
    (state) => state.dtcState.toggledOnDtc,
    shallowEqual,
  )
  const selectedComponentId = useSelector(getSelectedComponentId, shallowEqual)

  const onClick = () => {
    dispatch(selectElement(component, true))
    dispatch(toggleOffDtc())
    dispatch(
      highlightDtcs(
        dtcs.filter(
          (dtc) => dtc.components && dtc.components.has(component.id),
        ),
      ),
    )
  }

  function isSelected(thisComponentId, thatComponentId) {
    return thisComponentId === thatComponentId
  }

  function isHighlighted(highlighted, currentComponent) {
    return toggledOnDtc && highlighted.has(currentComponent)
  }

  const componentClassNames = classNames('embedded-panel-item', {
    selected:
      isSelected(selectedComponentId, component.id) ||
      isHighlighted(highlightedComponents, component.id),
    faulty: status !== undefined,
  })

  return (
    <div ref={ref} className={componentClassNames} onClick={onClick}>
      <div className="embedded-component-name">
        {getElementName(component)} {status ? <FaultyComponentIcon /> : null}
      </div>
      <div className="embedded-component-description">
        {component.description}
      </div>
    </div>
  )
})

const ComponentList = React.forwardRef(function ComponentList(props, ref) {
  const statuses = useSelector(
    (state) => state.loggingState.componentStates,
    shallowEqual,
  )

  const listRef = React.useRef()
  const selectedComponentId = useSelector(getSelectedComponentId, shallowEqual)
  const componentRef = React.useRef()

  const dispatch = useDispatch()
  const components = useSelector(
    (state) => state.componentState.elements,
    shallowEqual,
  )

  React.useEffect(() => {
    dispatch(
      setComponentListScroll({
        scrollTop: listRef.current.scrollTop,
        scrollHeight: listRef.current.scrollHeight,
        clientHeight: listRef.current.clientHeight,
      }),
    )
  }, [listRef, ref, components])

  const isVisibleInList = (parent, child) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    const parentRect = parent.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    return (
      childRect.top >= parentRect.top && parentRect.bottom >= childRect.bottom
    )
  }

  React.useEffect(() => {
    if (!componentRef.current) return

    const visibleInList = isVisibleInList(listRef.current, componentRef.current)
    if (!visibleInList) {
      componentRef.current.scrollIntoView()
    }
  }, [listRef, componentRef, ref, components, selectedComponentId])

  const handleScroll = (event) => {
    dispatch(
      setComponentListScroll({
        scrollTop: listRef.current.scrollTop,
        scrollHeight: listRef.current.scrollHeight,
        clientHeight: listRef.current.clientHeight,
      }),
    )
  }

  const listItems = []

  for (const component of components.filter((c) => c.isNode)) {
    listItems.push(
      <Component
        ref={component.id === selectedComponentId ? componentRef : undefined}
        key={component.id}
        component={component}
        status={statuses[component.id]?.status}
      />,
    )
  }

  function scrollUp() {
    listRef.current.scrollTo({
      top: listRef.current.scrollTop - COMPONENT_HEIGHT,
    })
  }

  function scrollDown() {
    listRef.current.scrollTo({
      top: listRef.current.scrollTop + COMPONENT_HEIGHT,
    })
  }

  React.useImperativeHandle(ref, () => ({
    scrollUp,
    scrollDown,
  }))

  return (
    <div
      ref={listRef}
      className="embedded-mode-panel fix-select"
      onScroll={handleScroll}
    >
      {listItems}
    </div>
  )
})

export default ComponentList
