import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Table } from 'antd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'

import Search from './Search'
import { loadMoreUsers, loadUsersList } from '../../thunks/users'
import { requireAuthentication } from '../../helpers/authentication'

function TableBS(props) {
  const { users = [], pagination = null } = props.usersData
  // usersData has the following shape:
  // {
  //     query: 'john.doe',   // Value from Search component.
  //     pagination: 'TOKEN_VALUE',
  //     users: [
  //         {
  //             email: 'user.email@example.com',
  //             name: 'USER NAME',
  //             id: 'auth0|3ae334c00fa4f22a3qwe333',
  //             plant: 'PLANT NAME',
  //             role: 'ROLE NAME',
  //             disabled: false/true,
  //             created_at: 1588801973350,
  //         },
  //         // ...
  //     ],
  // }
  const columns = [
    {
      title: <FormattedMessage id="userList.email" />,
      width: '35%',
      render: (record) => {
        return (
          <Link className="edit-user-link" to={`/users/${record.id}`}>
            {record.email}
          </Link>
        )
      },
    },
    {
      title: <FormattedMessage id="userList.fullName" />,
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: <FormattedMessage id="userList.role" />,
      dataIndex: 'role',
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="userList.plants" />,
      render: (record) => {
        if (record.plants && record.plants.length > 0) {
          return record.plants.join(', ')
        }
        return ''
      },
    },
    {
      title: <FormattedMessage id="userList.disabled" />,
      render: (record) =>
        record.disabled ? (
          <FormattedMessage id="generic.yes" />
        ) : (
          <FormattedMessage id="generic.no" />
        ),
    },
  ]
  return (
    <div className="table-container">
      <Table
        dataSource={users}
        columns={columns}
        className="bs-table-container"
        rowKey="id"
        pagination={false}
      />
    </div>
  )
}

function UserList() {
  const users = useSelector((state) => state.users?.data, shallowEqual)
  const isLoading = useSelector((state) => state.users?.isLoading, shallowEqual)
  const isMoreUsersLoading = useSelector(
    (state) => state.users?.isMoreUsersLoading,
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUsersList())
  }, [])

  const query = users?.query
  const paginationToken = users?.pagination_token

  return (
    <div id="users-list">
      <div className="users-list-header">
        <Link className="btn btn-default btn-sm" to={'/users/add'}>
          <FormattedMessage id="users.addNewUser" />
        </Link>
        <div>
          <p className="white">Search</p>
          <Search
            makeSearchRequest={debounce(
              (params) => dispatch(loadUsersList(params)),
              250,
            )}
            isLoading={isLoading}
            opts={{ query }}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="loading">
          <FormattedMessage id="processes.loading" />
        </div>
      ) : (
        <div>
          <TableBS usersData={users} />
          {paginationToken ? (
            <div className="load-more-users">
              <button
                className="btn btn-default"
                onClick={() =>
                  dispatch(
                    loadMoreUsers({ query, pagination: paginationToken }),
                  )
                }
                disabled={isMoreUsersLoading}
              >
                <FormattedMessage id="processes.loadMoreData" />
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default requireAuthentication(injectIntl(UserList))
