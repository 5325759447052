import React from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { requireAuthentication } from '../../helpers/authentication'
import { Form, Input, Row, Col, Button, Table, Tooltip } from 'antd'

import { formatTimestamp } from '../../helpers/utils'
import {
  useMachineAlerts,
  useMachine,
  useJohnDeereAuth,
  useJohnDeereLogin,
} from './hooks'
import { useAddVehicleTest } from '../../hooks/VehicleTests'
import generatePayload from './payload'
import { padFailureModeIndicator, padSuspectParameterName } from './padZero'
import Loading from '../../components/Loading'

const GenerateVehicleTestButton = ({ vin, alerts }) => {
  const user = useSelector((state) => state.user.data.username, shallowEqual)
  const authToken = useSelector((state) => state.user.data.token, shallowEqual)
  const { isSuccess, machine } = useMachine({ vin })
  const addVehicleTest = useAddVehicleTest()
  const history = useHistory()

  const onClick = () => {
    const payload = generatePayload({ machine, userId: user, alerts })
    addVehicleTest.mutate(
      { payload, authToken },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/tracer/${data.cache_id}`),
      },
    )
  }

  return (
    <Button
      block
      disabled={alerts.length === 0 || !isSuccess}
      onClick={onClick}
      size="large"
      type="primary"
      loading={addVehicleTest.isLoading}
    >
      <FormattedMessage id="johnDeere.alerts.generateTest" />
    </Button>
  )
}

const MachineAlertsTable = ({ intl, vin, setSelectedAlerts }) => {
  const { isLoading, machineAlerts } = useMachineAlerts({ vin })

  const isDisabled = (record) => {
    return (
      !record.definition?.threeLetterAcronym ||
      !record.definition?.suspectParameterName ||
      !record.definition?.failureModeIndicator
    )
  }

  const alertsTableColumns = [
    {
      title: intl.formatMessage({
        id: 'johnDeere.alerts.alertsTableColumns.date',
      }),
      dataIndex: 'time',
      key: 'time',
      render: (time) =>
        formatTimestamp(intl.formatMessage({ id: 'format.dayAndTime' }), time),
    },
    {
      title: intl.formatMessage({
        id: 'johnDeere.alerts.alertsTableColumns.threeLetterAcronym',
      }),
      dataIndex: ['definition', 'threeLetterAcronym'],
      key: 'threeLetterAcronym',
    },
    {
      title: intl.formatMessage({
        id: 'johnDeere.alerts.alertsTableColumns.suspectParameterName',
      }),
      dataIndex: ['definition', 'suspectParameterName'],
      key: 'suspectParameterName',
      render: (suspectParameterName) =>
        padSuspectParameterName(suspectParameterName),
    },
    {
      title: intl.formatMessage({
        id: 'johnDeere.alerts.alertsTableColumns.failureModeIndicator',
      }),
      dataIndex: ['definition', 'failureModeIndicator'],
      key: 'failureModeIndicator',
      render: (failureModeIndicator) =>
        padFailureModeIndicator(failureModeIndicator),
    },
    {
      title: intl.formatMessage({
        id: 'johnDeere.alerts.alertsTableColumns.description',
      }),
      dataIndex: ['definition', 'description'],
      key: 'description',
    },
  ]
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedKeys, selectedRows) =>
          setSelectedAlerts(selectedRows),
        getCheckboxProps: (record) => ({ disabled: isDisabled(record) }),
        renderCell: (checked, record, index, node) => {
          if (isDisabled(record)) {
            return (
              <Tooltip title={`Event type: "${record['@type']}"`}>
                {node}
              </Tooltip>
            )
          }
          return node
        },
      }}
      size="small"
      dataSource={machineAlerts?.values.map((alert) => {
        return {
          ...alert,
          key: alert.id,
        }
      })}
      columns={alertsTableColumns}
      loading={isLoading}
      pagination={{ size: 'default' }}
    />
  )
}

const MachineAlertsSearch = ({ intl, setVin }) => {
  return (
    <Form layout="vertical">
      <Form.Item label="Enter VIN">
        <Input.Search
          placeholder={intl.formatMessage({ id: 'alerts.vin' })}
          allowClear
          enterButton="Search"
          size="medium"
          onSearch={setVin}
        />
      </Form.Item>
    </Form>
  )
}

const Login = () => {
  const authorizeURIPath = '/jd/authorize'
  const redirectURI = `${window.location.origin}${authorizeURIPath}`
  const { isLoading, isSuccess, url } = useJohnDeereLogin({ redirectURI })

  React.useEffect(() => {
    if (isLoading) return
    window.location = url.authorization_url
  }, [isSuccess])

  return <Loading useBackgroundColor={false} />
}

function JohnDeereAlertsPage({ intl }) {
  const { isLoading, isAuthenticated } = useJohnDeereAuth()

  if (isLoading) return <Loading useBackgroundColor={false} />

  return isAuthenticated ? <JohnDeereAlerts intl={intl} /> : <Login />
}

function JohnDeereAlerts({ intl }) {
  const [vin, setVin] = React.useState(null)
  const [selectedAlerts, setSelectedAlerts] = React.useState([])

  return (
    <div id="john-deere-alerts">
      <Row justify="center" align="middle">
        <Col span={22}>
          <h2>
            <FormattedMessage id="alerts.title" />
          </h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <MachineAlertsSearch intl={intl} setVin={setVin} />
          <MachineAlertsTable
            intl={intl}
            vin={vin}
            setSelectedAlerts={setSelectedAlerts}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}></Col>
      </Row>
      <Row justify="start">
        <Col span={4} offset={1}>
          <GenerateVehicleTestButton vin={vin} alerts={selectedAlerts} />
        </Col>
      </Row>
    </div>
  )
}

export default requireAuthentication(injectIntl(JohnDeereAlertsPage))
