import React from 'react'
import classNames from 'classnames'
import './style.scss'

const COLOR_CODES = [
  'BK',
  'WH',
  'BG',
  'RD',
  'GN',
  'YE',
  'DG',
  'GY',
  'BN',
  'DB',
  'VT',
  'BU',
  'PK',
  'NA',
  'NAx',
  'OG',
  'CU',
  'BO',
  'PU',
]

export const ConnectorColorTag = ({ colorCode }) => {
  if (!colorCode || !COLOR_CODES.includes(colorCode)) {
    return null
  }

  const wrapperClass = classNames(
    'component-connector-tag',
    `component-connector-color-code-${colorCode.toLowerCase()}`,
  )

  return <div className={wrapperClass}>{colorCode}</div>
}
