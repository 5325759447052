import React, { useState } from 'react'
import { Form, Select, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMakeModels } from '../hooks/MakeModels'
import { useSalesCodeConstraints } from '../hooks/SalesCodes'

import {
  useVirtualVehicleConfiguration,
  useVirtualVehicleConfigurationDispatch,
} from './VirtualVehicleConfigurationContext'
import Loading from '../FunctionalDiagrams/Loading'
import { useSalesCodesToFetchVIN } from '../hooks/VirtualVehicleConfiguration'
import { Loader } from '../components/Loader/Loader'
import './style.scss'

export const MakeModelSelect = injectIntl(
  ({ intl, required = true, disabled = false, preselectSalesCodes = true }) => {
    const { isSuccess, makeModels } = useMakeModels()
    const dispatch = useVirtualVehicleConfigurationDispatch()
    const virtualVehicleConfiguration = useVirtualVehicleConfiguration()

    React.useEffect(() => {
      if (!preselectSalesCodes) return
      if (isSuccess) {
        dispatch.handleMakeModelIDSelection({ makeModelID: makeModels[0].id })
      }
    }, [makeModels])

    return (
      isSuccess && (
        <Form.Item
          label="Make Model"
          required={required}
          labelCol={{ span: 24 }}
        >
          <Select
            showSearch
            placeholder={intl.formatMessage({
              id: 'virtualVehicleConfiguration.selectMakeModel',
            })}
            defaultValue={makeModels[0].id}
            value={virtualVehicleConfiguration.makeModelID}
            optionFilterProp="label"
            options={makeModels.map((makeModel) => ({
              label: `${makeModel.model_description || makeModel.model} - ${makeModel.phase} - ${makeModel.year}`,
              value: makeModel.id,
            }))}
            onSelect={(makeModelID) =>
              dispatch.handleMakeModelIDSelection({ makeModelID })
            }
            className="virtual-vehicle-configuration-make-model-selection"
            disabled={disabled || virtualVehicleConfiguration.isVinLoading}
            data-testid="virtual-vehicle-configuration-make-model-selection"
          />
        </Form.Item>
      )
    )
  },
)

function groupBySalesCodeGroup(salesCodes) {
  return salesCodes.reduce((acc, obj) => {
    const key = obj.group.name
    const curGroup = acc[key] ?? []

    return { ...acc, [key]: [...curGroup, obj] }
  }, {})
}

const SalesCodeSelectForm = ({ salesCodeConstraints }) => {
  const dispatch = useVirtualVehicleConfigurationDispatch()
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const salesCodeGroups = groupBySalesCodeGroup(salesCodeConstraints)

  React.useEffect(() => {
    if (salesCodeConstraints) {
      generateInitialSalesCodesData()
    }
  }, [salesCodeConstraints])

  const generateInitialSalesCodesData = () => {
    const salesCodesData = {}
    Object.entries(salesCodeGroups).forEach(([group, constraints], index) => {
      salesCodesData[group] = null
    })

    dispatch.handleInitialSalesCodes({ salesCodesSelection: salesCodesData })
  }

  return (
    <>
      {Object.entries(salesCodeGroups).map(([group, constraints], index) => {
        const groupLabel = constraints[0].group.description || group

        return (
          <div
            className="virtual-vehicle-configuration-sales-codes-group"
            key={`${virtualVehicleConfiguration.makeModelID}-${group}`}
          >
            <div className="virtual-vehicle-configuration-sales-codes-group-label">
              {groupLabel}
            </div>
            <div className="virtual-vehicle-configuration-sales-codes-group-options">
              {constraints.map((constraint) => {
                const key = `${virtualVehicleConfiguration.makeModelID}-${group}-${constraint.sales_code.name}`
                const salesCodeLabel =
                  constraint.sales_code.description ||
                  constraint.sales_code.name
                return (
                  <div
                    className="virtual-vehicle-configuration-sales-codes-group-button"
                    key={key}
                  >
                    <input
                      checked={
                        virtualVehicleConfiguration.salesCodesSelection[
                          group
                        ] == constraint.sales_code.name
                      }
                      type="radio"
                      value={constraint.sales_code.name}
                      id={constraint.sales_code.name}
                      name={group}
                      onClick={(event) => {
                        if (
                          virtualVehicleConfiguration.salesCodesSelection[
                            group
                          ] == event.target.value
                        ) {
                          dispatch.handleSalesCodeDelete({ group })
                          const salesCodesSelection = {
                            ...virtualVehicleConfiguration.salesCodesSelection,
                          }
                          delete salesCodesSelection[group]
                          const result = Object.values(
                            salesCodesSelection,
                          ).filter((element) => element)
                          if (result.length == 0) {
                            dispatch.updateSalesCodesPreSelectedVIN({
                              salesCodesPreSelectedVIN: null,
                            })
                          }
                        } else {
                          dispatch.handleSalesCodeAdded({
                            group,
                            salesCode: event.target.value,
                          })
                        }
                      }}
                    />
                    <label htmlFor={constraint.sales_code.name}>
                      {salesCodeLabel}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export const SalesCodeSelect = ({ makeModelID }) => {
  const [salesCodesString, updateSalesCodesString] = useState('')
  const { isSuccess, isLoading, salesCodeConstraints } =
    useSalesCodeConstraints({ makeModelID })
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const dispatch = useVirtualVehicleConfigurationDispatch()
  const { isVinLoading, results } = useSalesCodesToFetchVIN({
    salesCodesString: salesCodesString,
    makeModelID: makeModelID,
  })

  React.useEffect(() => {
    if (
      Object.keys(virtualVehicleConfiguration.salesCodesSelection).length != 0
    ) {
      prepareSalesCodesString()
    } else {
      updateSalesCodesString('')
    }
  }, [virtualVehicleConfiguration.salesCodesSelection])

  const prepareSalesCodesString = () => {
    const salesCodes = virtualVehicleConfiguration.salesCodesSelection
    updateSalesCodesString(
      Object.values(salesCodes).filter((value) => value != null),
    )
  }

  React.useEffect(() => {
    dispatch.toggleVINLoading(isLoading)
    if (results) {
      updateSalesCodesSelection()
    }
  }, [results, isLoading])

  const updateSalesCodesSelection = () => {
    const currentlySelectedSalesCodes = {
      ...virtualVehicleConfiguration.salesCodesSelection,
    }

    if (!results.selectAllSalesCodes) {
      if (results.vin.length > 0) {
        let lastSelectedSalesCode = ''

        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          if (value != null) {
            lastSelectedSalesCode = key
          }
        })

        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          if (lastSelectedSalesCode != key) {
            currentlySelectedSalesCodes[key] = null
          }
        })
      } else {
        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          currentlySelectedSalesCodes[key] = null
        })
      }
    }

    dispatch.handleInitialSalesCodes({
      salesCodesSelection: currentlySelectedSalesCodes,
    })
    dispatch.updateSalesCodesPreSelectedVIN({
      salesCodesPreSelectedVIN: results.vin,
    })
  }

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div className="virtual-vehicle-configuration-sales-code-select-form-container">
          {isVinLoading && <Loader />}
          <div className="virtual-vehicle-configuration-sales-codes-subtitle">
            <FormattedMessage id="virtualVehicleConfiguration.selectSalesCodes" />
          </div>
          <SalesCodeSelectForm salesCodeConstraints={salesCodeConstraints} />
        </div>
      )}
    </>
  )
}
