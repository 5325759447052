import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconDashboard = (props) => {
  const viewBox = `0 0 100 100`
  return (
    <svg
      height={props.width}
      viewBox={viewBox}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          clip-rule="evenodd"
          fill-rule="evenodd"
          id="iconDashboard"
          d="M76.2364 18.8071C82.4496 18.8071 87.4864 23.7672 87.4864 29.8858V70.1328C87.4864 76.2515 82.4496 81.2116 76.2364 81.2116L23.7721 81.2116C17.5589 81.2116 12.5221 76.2515 12.5221 70.1328V29.8858C12.5221 23.7672 17.5589 18.807 23.7721 18.807L76.2364 18.8071ZM76.2364 22.5L23.7721 22.5C19.63 22.5 16.2721 25.8067 16.2721 29.8858V70.1328C16.2721 74.2119 19.63 77.5187 23.7721 77.5187L76.2364 77.5187C80.3785 77.5187 83.7364 74.2119 83.7364 70.1328V29.8858C83.7364 25.8067 80.3785 22.5 76.2364 22.5ZM29 26.5C27.6193 26.5 26.5 27.6193 26.5 29V30.75H23.5C22.2574 30.75 21.25 31.7574 21.25 33V70.5C21.25 71.7426 22.2574 72.75 23.5 72.75H76.5C77.7426 72.75 78.75 71.7426 78.75 70.5V33C78.75 31.7574 77.7426 30.75 76.5 30.75H73.5V29C73.5 27.6193 72.3807 26.5 71 26.5H29ZM73.5 33.25V64C73.5 65.3807 72.3807 66.5 71 66.5H29C27.6193 66.5 26.5 65.3807 26.5 64V33.25H23.75V70.25H76.25V33.25H73.5ZM29 29H31.5H49V64H31.5H29V61.5V31.5V29ZM68.5 29H51V64H68.5H71V61.5V31.5V29H68.5ZM33.5 32.4894L37.325 34.88L41.325 37.38L44.717 39.5L41.325 41.62L37.325 44.12L33.5 46.5106V42V37V32.4894ZM36 42L37.6415 40.9741L38.5 40.4375L40 39.5L38.5 38.5625L37.6415 38.0259L36 37V39.0519V39.9481V42ZM55 33C54.4477 33 54 33.4477 54 34C54 34.5523 54.4477 35 55 35H67C67.5523 35 68 34.5523 68 34C68 33.4477 67.5523 33 67 33H55ZM54 39C54 38.4477 54.4477 38 55 38H62C62.5523 38 63 38.4477 63 39C63 39.5523 62.5523 40 62 40H55C54.4477 40 54 39.5523 54 39ZM55 43C54.4477 43 54 43.4477 54 44C54 44.5523 54.4477 45 55 45H59C59.5523 45 60 44.5523 60 44C60 43.4477 59.5523 43 59 43H55ZM54 59C54 58.4477 54.4477 58 55 58H67C67.5523 58 68 58.4477 68 59C68 59.5523 67.5523 60 67 60H55C54.4477 60 54 59.5523 54 59ZM32 59C32 58.4477 32.4477 58 33 58H35.2676C35.6134 57.4022 36.2597 57 37 57C37.7403 57 38.3866 57.4022 38.7324 58H45C45.5523 58 46 58.4477 46 59C46 59.5523 45.5523 60 45 60H38.7324C38.3866 60.5978 37.7403 61 37 61C36.2597 61 35.6134 60.5978 35.2676 60H33C32.4477 60 32 59.5523 32 59Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g>
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconDashboard" />
        </g>
      </g>
    </svg>
  )
}

IconDashboard.defaultProps = defaultProps

export default IconDashboard
