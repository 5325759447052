import React from 'react'

const defaultProps = {
  height: '100',
  width: '100',
}

const IconReport = (props) => {
  const viewBox = `0 14 ${props.height} ${props.width}`
  return (
    <svg
      height={props.height}
      viewBox={viewBox}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="iconUsers"
          d="M64.25 0.75C70.4632 0.75 75.5 5.7868 75.5 12V52C75.5 58.2132 70.4632 63.25 64.25 63.25H11.75C5.5368 63.25 0.5 58.2132 0.5 52V12C0.5 5.7868 5.5368 0.75 11.75 0.75H64.25ZM64.25 4.5H11.75C7.60786 4.5 4.25 7.85786 4.25 12V52C4.25 56.1421 7.60786 59.5 11.75 59.5H64.25C68.3921 59.5 71.75 56.1421 71.75 52V12C71.75 7.85786 68.3921 4.5 64.25 4.5ZM58 42C60.7231 43.4681 62.9375 47.721 62.375 48.875C61.8125 50.029 58.7169 50.75 54.25 50.75C49.7831 50.75 46.6875 50.029 46.125 48.875C45.5625 47.721 47.6304 43.4129 50.5 42C51.6765 42.8333 52.9265 43.25 54.25 43.25C55.5735 43.25 56.8235 42.8333 58 42ZM23.7554 39.8269C26.5939 44.981 32.009 48.25 38 48.25C38.2311 48.25 38.4616 48.2452 38.6914 48.2356C39.3812 48.2067 39.9637 48.7425 39.9925 49.4323C40.0214 50.122 39.4856 50.7046 38.7958 50.7334C38.5312 50.7445 38.2659 50.75 38 50.75C31.0886 50.75 24.8393 46.9774 21.5656 41.0329C21.2325 40.4282 21.4528 39.668 22.0575 39.335C22.6622 39.002 23.4224 39.2222 23.7554 39.8269ZM54.0159 29.5C57.0915 29.5 59.0785 32.0184 59.0785 35.125C59.0785 38.2316 57.0915 40.75 54.0159 40.75C50.9404 40.75 48.9534 38.2316 48.9534 35.125C48.9534 32.0184 50.9404 29.5 54.0159 29.5ZM25.5 25.75C28.2231 27.2181 30.4375 31.471 29.875 32.625C29.3125 33.779 26.2169 34.5 21.75 34.5C17.2831 34.5 14.1875 33.779 13.625 32.625C13.0625 31.471 15.1304 27.1629 18 25.75C19.1765 26.5833 20.4265 27 21.75 27C23.0735 27 24.3235 26.5833 25.5 25.75ZM21.516 13.25C24.5915 13.25 26.5785 15.7684 26.5785 18.875C26.5785 21.9816 24.5915 24.5 21.516 24.5C18.4404 24.5 16.4535 21.9816 16.4535 18.875C16.4535 15.7684 18.4404 13.25 21.516 13.25ZM38 13.25C44.59 13.25 50.5909 16.6784 53.9804 22.1877C54.3422 22.7757 54.1588 23.5456 53.5708 23.9074C52.9828 24.2691 52.2129 24.0857 51.8511 23.4977C48.912 18.7205 43.7126 15.75 38 15.75C36.2194 15.75 34.4794 16.036 32.8275 16.5902C32.173 16.8098 31.4644 16.4572 31.2448 15.8027C31.0252 15.1482 31.3777 14.4397 32.0322 14.2201C33.9396 13.5801 35.9482 13.25 38 13.25Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h100v100H0z" />
        <g transform="translate(10 33)">
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconUsers" />
        </g>
      </g>
    </svg>
  )
}

IconReport.defaultProps = defaultProps

export default IconReport
