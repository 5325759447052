import React, { Component } from 'react'
import { connect } from 'react-redux'
import CollapsibleSidePanel from '../components/CollapsibleSidePanel'
import { setSidebarState } from '../actions/sidebar-actions'
import { setModal } from '../actions/modal-actions'
import { MODAL_DTC_DEPENDENCY_INFO } from '../constants'
import {
  showHideDtcMemoEdit,
  hideDtcMemoTooltip,
  hideDtcMemoPopup,
} from '../actions/dtc-actions'

// Right container with list of DTCs
class SidebarContainer extends Component {
  render() {
    return <CollapsibleSidePanel {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.sidebar.open,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    isDtcMemoEditShown: state.dtcState.isDtcMemoEditShown,
    dtcDependencies: state.componentState.dtcDependencies,
    isEphemeralVehicleTest: state.ephemeralVehicleTest.isEphemeralVehicleTest,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setOpen: (open) => {
      dispatch(setSidebarState(open))
    },
    onScroll: () => {
      dispatch(hideDtcMemoPopup())
      dispatch(hideDtcMemoTooltip())
    },
    toggleShowHideDtcMemoEdit: () => {
      dispatch(showHideDtcMemoEdit())
    },
    onClickDtcDependencies: () => {
      dispatch(setModal(MODAL_DTC_DEPENDENCY_INFO))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
