const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export function addPortIndex({ ports }) {
  // get north ports, sort and add index 0...n
  const northPorts = ports
    .filter((port) => port.side === 'NORTH')
    .sort((a, b) => collator.compare(a.label, b.label))
    .map((port, index) => ({ ...port, index }))

  // get south ports, sort and add index n...0
  const southPorts = ports
    .filter((port) => port.side === 'SOUTH')
    .sort((a, b) => collator.compare(a.label, b.label))
    .map((port, index, array) => ({ ...port, index: array.length - 1 - index }))

  return [...northPorts, ...southPorts]
}
