import React, { useState } from 'react'
import { withCookies } from 'react-cookie'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import isEmpty from 'lodash/isEmpty'

import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR, GRAY_COLOR } from '../constants'
import {
  availableMainViewsToFavorite,
  mainViewCookieKey,
} from '../reducers/tab-reducer'

import CloseButton from './CloseButton'

const setTabFavorite = (cookies, selectedTab) => {
  const expirationDate = new Date()
  const ageInYears = 1
  const ageInDays = ageInYears * 365
  const ageInSeconds = ageInDays * 24 * 60 * 60
  const ageInMillis = ageInSeconds * 1000
  expirationDate.setTime(expirationDate.getTime() + ageInMillis)

  // I.E. doesn't use maxAge, so use expires as well
  const options = {
    expires: expirationDate,
    maxAge: ageInSeconds,
    path: '/',
  }
  cookies.remove(mainViewCookieKey)
  cookies.set(mainViewCookieKey, selectedTab, options)
}

const ChangeStartView = (props) => {
  const { tabs, favoritedStartTab, intl, isOpen, closeModal, cookies } = props
  const [selectedTab, selectTab] = useState(favoritedStartTab)
  const onSubmitClick = () => setTabFavorite(cookies, selectedTab)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Change Start View"
    >
      <form onSubmit={onSubmitClick} id="change-start-view-form">
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage id="changeStartView.title" />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row" style={{ color: GRAY_COLOR }}>
              <FormattedMessage id="changeStartView.leadingText" />
            </div>
            <div className="row">
              <div className="btn-group-vertial" role="group">
                {tabs.map((tab) => {
                  const intlId = `tracer.${tab}.tabName`
                  const text = intl.formatMessage({ id: intlId })
                  const checked = tab === selectedTab
                  return (
                    <div className="radio" key={tab} id={tab}>
                      <label style={{ color: BLACK_COLOR }}>
                        <input
                          type="radio"
                          name="changeStartView"
                          id={`changeStartView-${tab}`}
                          value={tab}
                          checked={checked}
                          onChange={() => selectTab(tab)}
                        />
                        {text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="row" style={{ color: GRAY_COLOR }}>
              <FormattedMessage id="changeStartView.footerText" />
            </div>
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={isEmpty(selectedTab)}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { cookies } = ownProps

  return {
    isOpen: state.modalState.isOpen,
    favoritedStartTab: cookies.get(mainViewCookieKey),
    tabs: availableMainViewsToFavorite,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default withCookies(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeStartView)),
)
