import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import {
  NOTIFICATION_ERROR,
  VEHICLE_VIEW,
  INIT_MAIN_VIEW_QUERY_PARAM,
  INIT_SELECTED_COMPONENT_QUERY_PARAM,
} from '../constants'
import history from '../history'
import {
  setVehicleTests,
  setVehicleTestsLoading,
  setCurrentVehicleTest,
} from '../actions/vehicle-tests-actions'
import store from '../store'
import {
  unsetNotification,
  setNotification,
} from '../actions/notification-actions'
import UrlService from '../services/url'
import { EMBEDDED_MODE, MODE } from '../services/navigation'

const baseUrlPath = '/tracer/vehicle_tests'
const api = axiosWrapper.create(
  { baseURL: baseUrlPath },
  InterceptorOptions.defaultErrorInterceptor,
)

export function getVehicleTestsByVin(vin) {
  store.dispatch(unsetNotification())
  store.dispatch(setVehicleTestsLoading())
  return api
    .get(`/with_vin/${vin}`)
    .then((response) => {
      const obj = response.data
      if (obj.data) {
        store.dispatch(setVehicleTests(obj.data))
      }
    })
    .catch((error) => {
      store.dispatch(setVehicleTests([]))
      console.error(error)
    })
}

export const vehicleTestPayloadUrl = (vehicleTestId) =>
  `${baseUrlPath}/${vehicleTestId}/request_payload`

export function navigateToVehicleTest({
  vehicleTestId,
  newTab = false,
  online = false,
  navigatedFrom = '',
  embeddedMode = false,
}) {
  store.dispatch(setVehicleTestsLoading())

  let url = `/${vehicleTestId}/tracer_url?format=html&context=${online ? 'online' : 'offline'}`
  if (embeddedMode) {
    url = `${url}&${MODE}=${EMBEDDED_MODE}`
  }
  if (navigatedFrom) {
    url = `${url}&navigatedFrom=${navigatedFrom}`
  }

  return api
    .get(url)
    .then((response) => {
      const obj = response.data
      if (obj) {
        if (obj.url) {
          if (newTab) {
            window.open(obj.url, 'vehicle-test')
            window.focus()
          } else {
            history.push(obj.url)
          }
        } else {
          console.error(obj.errors)
        }
      }
      store.dispatch(setVehicleTestsLoading(false))
    })
    .catch((error) => {
      console.error(error)
      store.dispatch(setVehicleTestsLoading(false))
    })
}

export function openTabWithVehicleTestByResultId({
  vehicleTestResultId,
  componentId,
}) {
  store.dispatch(setVehicleTestsLoading())
  return api
    .get(`/with_result_id/${vehicleTestResultId}/tracer_url`)
    .then((response) => {
      const obj = response.data
      if (obj) {
        if (obj.url) {
          const queryStringOrEmpty = UrlService.buildQueryString({
            [INIT_MAIN_VIEW_QUERY_PARAM]: VEHICLE_VIEW,
            [INIT_SELECTED_COMPONENT_QUERY_PARAM]: componentId,
          })
          const fullUrl = obj.url + queryStringOrEmpty
          window.open(fullUrl, '_blank')
          window.focus()
        } else {
          if (obj.errors) {
            let message
            if (Array.isArray(obj.errors) && obj.errors.length > 0) {
              message = obj.errors.join('. ')
            } else {
              message = obj.errors
            }
            if (message) {
              store.dispatch(setNotification(NOTIFICATION_ERROR, message))
            }
          }
        }
      }
      store.dispatch(setVehicleTestsLoading(false))
    })
    .catch((error) => {
      console.error(error)
      store.dispatch(setVehicleTestsLoading(false))
    })
}

export function getVehicleTestIdByResultId(vehicleTestResultId) {
  if (vehicleTestResultId) {
    return api
      .get(`/with_vehicle_test_result_id/${vehicleTestResultId}`)
      .then((response) => {
        const obj = response.data
        if (obj.data) {
          store.dispatch(setCurrentVehicleTest(obj.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getTestsForVin(vin) {
  return api
    .get(`/with_vin/${vin}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export function getPrevNextNavigation(vehicleTestResultId) {
  let url = `/with_result_id/${vehicleTestResultId}/navigation`
  return api
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export function getGenerateNavigation(vehicleTestResultId, navType) {
  let url = `/with_result_id/${vehicleTestResultId}/${navType}`
  return api
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}
