import * as types from '../actions/action-types'
import { LOADING_STATE } from '../constants'

const initialState = {
  models: [],
  selectedModel: null,
  dtc: '',
  memos: [],
  modelsAreDownloading: false,
  memosAreDownloading: LOADING_STATE.notStarted,
}

const dtcMemoSearch = function (state = initialState, action) {
  switch (action.type) {
    case types.DMS_MODELS_DOWNLOAD_START:
      return Object.assign({}, state, { modelsAreDownloading: true })
    case types.DMS_SET_MODELS:
      return Object.assign({}, state, {
        models: action.models,
        modelsAreDownloading: false,
      })
    case types.DMS_SELECT_MODEL:
      return Object.assign({}, state, { selectedModel: action.model })
    case types.DMS_EDIT_DTC_FIELD:
      return Object.assign({}, state, { dtc: action.dtc })
    case types.DMS_MEMOS_DOWNLOAD_START:
      return Object.assign({}, state, {
        memosAreDownloading: LOADING_STATE.started,
      })
    case types.DMS_SET_MEMOS:
      return Object.assign({}, state, {
        memosAreDownloading: LOADING_STATE.endOk,
        memos: action.memos,
      })
    case types.DMS_MEMOS_DOWNLOAD_ERROR:
      return Object.assign({}, state, {
        memosAreDownloading: LOADING_STATE.endWithError,
        memos: [],
      })
    case types.DMS_SET_MEMO_EDIT: {
      const memos = state.memos.map((memo) => ({
        ...memo,
        add: memo.id === action.memoId ? false : memo.add,
      }))
      return Object.assign({}, state, { memos })
    }
    default:
      return state
  }
}

export default dtcMemoSearch
