import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import './style.scss'
import {
  INIT_MAIN_VIEW_QUERY_PARAM,
  MODAL_ADD_FAULT,
  MODAL_HARNESS_STATE,
  VEHICLE_VIEW,
} from '../../constants'
import Topology from '../SvgViews/Topology'
import VehicleTestResultNavigation from '../VehicleTestResultNavigation'
import { setComponent, setModal } from '../../actions/modal-actions'
import Button from '../Buttons/Button'

const VehicleView = () => {
  const dispatch = useDispatch()

  const vehicleTestResultId = useSelector(
    (state) => state.componentState.vehicleTestResultId,
  )
  const component = useSelector((state) => state.componentState.selectedElement)

  return (
    <>
      {component && component.isEdge ? (
        <Button
          onClick={() => {
            dispatch(setComponent(component))
            dispatch(setModal(MODAL_HARNESS_STATE))
          }}
          dataTestId="vehicle-view-add-faulty-harness-button"
          className="vehicle-view-faulty-harness"
        >
          <FormattedMessage id="generic.faultyHarness" />
        </Button>
      ) : null}
      <Topology />
      <VehicleTestResultNavigation
        vehicleTestResultId={vehicleTestResultId}
        hrefParams={`${INIT_MAIN_VIEW_QUERY_PARAM}=${VEHICLE_VIEW}`}
      />
      <Button
        onClick={() => dispatch(setModal(MODAL_ADD_FAULT))}
        dataTestId="vehicle-view-add-fault-button"
        className="vehicle-view-add-fault"
      >
        <FormattedMessage id="repairHistory.titles.addFault" />
      </Button>
    </>
  )
}

export default VehicleView
