import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import { COMPONENT_CATEGORY_SPLICE } from '../constants/index'
import { getElementName } from '../helpers/element'
import SvgCircle from '../components/SvgCircle'
import { Down, Up } from './Controls'
import classNames from 'classnames'

const SCROLL_UP_PARAMS = { top: 100, left: 0, behavior: 'smooth' }
const SCROLL_DOWN_PARAMS = { top: -100, left: 0, behavior: 'smooth' }

function PinDetailsEmbeddedMode(props) {
  const [showScroll, setShowScroll] = React.useState(false)
  const pinDetailsWrapperRef = useRef(null)
  const pinDetailsRef = useRef(null)
  useEffect(() => {
    if (pinDetailsRef.current) {
      if (
        pinDetailsRef.current.scrollHeight >
        pinDetailsWrapperRef.current.clientHeight
      ) {
        setShowScroll(true)
      } else {
        setShowScroll(false)
      }
    }
  }, [props.selectedElement, pinDetailsWrapperRef, pinDetailsRef])

  const wrapperClass = classNames('embedded-mode-pin-details-wrapper', {
    'show-scroll': showScroll,
  })

  const detailsClass = classNames('embedded-mode-pin-details', 'fix-select', {
    'show-scroll': showScroll,
  })

  function renderComponentDetails(component) {
    if (!component._addPinsToComponents) {
      // You need to hold your horses and wait for the graph to add the pin data.
      return null
    }

    const incomingConnectorDetails = []
    const outgoingConnectorDetails = []
    const pinNumbers = []
    if (component.pins) {
      for (const pin of component.pins.incoming) {
        if (pin) {
          let alias = getElementName(pin.source_component)
          if (alias !== pin.source_component.id) {
            alias += ` (${pin.source_component.id})`
          }
          if (component.category === COMPONENT_CATEGORY_SPLICE) {
            incomingConnectorDetails.push(renderSpliceDetails(pin))
          } else {
            incomingConnectorDetails.push(renderConnectorDetails(pin))
          }
          pinNumbers.push(pin.number)
        }
      }
      for (const pin of component.pins.outgoing) {
        let alias = getElementName(pin.target_component)
        if (alias !== pin.target_component.id) {
          alias += ` (${pin.target_component.id})`
        }
        if (component.category === COMPONENT_CATEGORY_SPLICE) {
          outgoingConnectorDetails.push(renderSpliceDetails(pin))
        } else {
          outgoingConnectorDetails.push(renderConnectorDetails(pin))
        }
        pinNumbers.push(pin.number)
      }
    }

    const renderComponentDescription = []
    if (component.description) {
      renderComponentDescription.push(
        <div key="description-label" className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.component.description" />
        </div>,
      )
      renderComponentDescription.push(
        <div key="description-text" className="embedded-mode-field">
          {component.description}
        </div>,
      )
    }

    return (
      <div ref={pinDetailsWrapperRef} className={wrapperClass}>
        {showScroll && (
          <Up
            className="embedded-mode-wide-control embedded-mode-pin-details-scroll-up"
            onClick={() => pinDetailsRef.current.scrollBy(SCROLL_DOWN_PARAMS)}
          />
        )}
        <div ref={pinDetailsRef} className={detailsClass}>
          <div className="embedded-mode-label">
            <FormattedMessage id="pinout.detail.component.title" />
          </div>
          <div className="embedded-mode-field">{getElementName(component)}</div>
          <div className="embedded-mode-label">
            <FormattedMessage id="pinout.detail.component.id" />
          </div>
          <div className="embedded-mode-field">{component.id}</div>
          {renderComponentDescription}
          {component.total_pins && [
            <div key="total_pins_header" className="embedded-mode-label">
              <FormattedMessage id="pinout.detail.component.totalPins" />
            </div>,
            <div key="total_pins_value" className="embedded-mode-field">
              {component.total_pins}
            </div>,
          ]}
          {incomingConnectorDetails}
          {outgoingConnectorDetails}

          <div>
            {component.images.connector_file_name && (
              <div className="btn-connector-fullview-container">
                <button
                  key={`view-connector-${component.images.connector_file_name}`}
                  onClick={() =>
                    props.showConnectorModal(
                      component.images.connector,
                      pinNumbers,
                      component.images.connector_file_name,
                    )
                  }
                  className="btn btn-default"
                >
                  <FormattedMessage id="pinout.buttons.viewConnector" />
                </button>
              </div>
            )}
          </div>
        </div>
        {showScroll && (
          <Down
            className="embedded-mode-wide-control embedded-mode-pin-details-scroll-down"
            onClick={() => pinDetailsRef.current.scrollBy(SCROLL_UP_PARAMS)}
          />
        )}
      </div>
    )
  }

  function renderConnectorDetails(pin) {
    const key = `${pin.source_component.label}->${pin.target_component.label}->${pin.number}`
    return (
      <div key={key}>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.component.pinNumber" />
        </div>
        <div className="embedded-mode-field">{pin.number}</div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.component.pinColor" />
        </div>
        <div className="embedded-mode-field">
          <span className="embedded-mode-pin-color">
            <SvgCircle colors={pin.colors} diameter={props.svgCircleDiameter} />
          </span>
          {pin.color_desc}
        </div>
      </div>
    )
  }

  function renderSpliceDetails(pin) {
    const key = `${pin.source_component.label}->${pin.target_component.label}->${pin.number}`
    return (
      <div key={key}>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.component.pinColor" />
        </div>
        <div className="embedded-mode-field">
          <SvgCircle
            colors={pin.colors}
            diameter={props.svgCircleDiameter}
            className="embedded-mode-pin-color"
          />
          {pin.color_desc}
        </div>
      </div>
    )
  }

  function renderHarnessDetails(harness) {
    let harnessDescription = harness.harness_id
    let pin
    if (harness.pins && harness.pins.length > 0) {
      pin = {
        circuit: harness.circuit,
        wiresize: harness.wiresize,
        signal_desc: harness.signal_desc,
        colors: harness.colors,
        color_desc: harness.color_desc,
      }
    }
    if (harness.harness_description) {
      harnessDescription += ' - ' + harness.harness_description
    }

    return (
      <div className="embedded-mode-pin-details">
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.title" />
        </div>
        <div className="embedded-mode-field">{getElementName(harness)}</div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.color" />
        </div>
        <div className="embedded-mode-field">
          <SvgCircle
            colors={pin.colors}
            diameter={props.svgCircleDiameter}
            className="embedded-mode-pin-color"
          />
          {pin.color_desc}
        </div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.circuitID" />
        </div>
        <div className="embedded-mode-field">{pin.circuit}</div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.wireSize" />
        </div>
        <div className="embedded-mode-field">{pin.wiresize}</div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.signalDescription" />
        </div>
        <div className="embedded-mode-field">{pin.signal_desc}</div>
        <div className="embedded-mode-label">
          <FormattedMessage id="pinout.detail.harness.harnessDescription" />
        </div>
        <div className="embedded-mode-field">{harnessDescription}</div>
      </div>
    )
  }

  if (!isEmpty(props.selectedElement)) {
    if (props.selectedElement.isNode) {
      return renderComponentDetails(props.selectedElement)
    } else if (
      props.selectedElement.isEdge &&
      !props.selectedElement.edge_type
    ) {
      return renderHarnessDetails(props.selectedElement)
    }
  }
  return null
}

PinDetailsEmbeddedMode.propTypes = {
  selectedElement: PropTypes.object,
  showConnectorModal: PropTypes.func,
  svgCircleDiameter: PropTypes.number,
}

PinDetailsEmbeddedMode.defaultProps = {
  selectedElement: null,
  svgCircleDiameter: 26,
}

export default injectIntl(PinDetailsEmbeddedMode)
