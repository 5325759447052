import React from 'react'

import { requireAuthentication } from '../helpers/authentication'
import MainMenu from '../components/MainMenu'
import Forbidden from '../components/Forbidden'
import MustHave from './MustHave'

class MainMenuContainer extends React.Component {
  render() {
    return (
      <MustHave permission="page-main:get" fallback={<Forbidden />}>
        <MainMenu />
      </MustHave>
    )
  }
}

export default requireAuthentication(MainMenuContainer)
