import React from 'react'

import { WHITE_COLOR } from '../../constants'

const defaultProps = {
  color: WHITE_COLOR,
  height: '24',
  width: '24',
}

const IconRepairHistory = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.height}
      viewBox={viewBox}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="IconRepairHistoryPath"
          d="M13 6a1 1 0 0 1 1 1v5h3a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm7.07-1.07A9.937 9.937 0 0 1 23 12.003a9.938 9.938 0 0 1-2.929 7.072A9.967 9.967 0 0 1 13.001 22c-2.561 0-5.121-.974-7.071-2.925a1 1 0 1 1 1.414-1.414c3.119 3.12 8.193 3.12 11.312 0A7.95 7.95 0 0 0 21 12.003a7.95 7.95 0 0 0-2.343-5.659A7.946 7.946 0 0 0 13 4.001a7.946 7.946 0 0 0-5.656 2.343A7.95 7.95 0 0 0 5 12.003h2l-3 4-3-4h2A9.938 9.938 0 0 1 5.929 4.93 9.932 9.932 0 0 1 12.999 2c2.671 0 5.182 1.04 7.071 2.93z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="IconRepairHistoryMask" fill={WHITE_COLOR}>
          <use xlinkHref="#IconRepairHistoryPath" />
        </mask>
        <use
          fill={WHITE_COLOR}
          fillRule="nonzero"
          xlinkHref="#IconRepairHistoryPath"
        />
        <g fill={props.color} mask="url(#IconRepairHistoryMask)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

IconRepairHistory.defaultProps = defaultProps

export default IconRepairHistory
