import React from 'react'

import { WHITE_COLOR } from '../../constants'

const defaultProps = {
  color: WHITE_COLOR,
  height: '24',
  width: '24',
}

const IconSwitchTest = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.height}
      viewBox={viewBox}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="IconSwitchTestPath"
          d="M19 8.997V9h-.003l-3.01 3V9H4V7h11.988V4l3.009 3H19v.003L20 8l-1 .997zM5 15.003V15h.003l3.01-3v3H20v2H8.012v3l-3.009-3H5v-.003L4 16l1-.997z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="IconSwitchTestMask" fill={WHITE_COLOR}>
          <use xlinkHref="#IconSwitchTestPath" />
        </mask>
        <use fill={WHITE_COLOR} xlinkHref="#IconSwitchTestPath" />
        <g fill={props.color} mask="url(#IconSwitchTestMask)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

IconSwitchTest.defaultProps = defaultProps

export default IconSwitchTest
