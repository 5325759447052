import React, { Component } from 'react'
import Modal from 'react-modal'
import ConnectorView from './SvgViews/ConnectorView'

class ConnectorViewModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="modal-connector-view"
        overlayClassName="modal-overlay"
        contentLabel="Connector View Modal"
        onRequestClose={this.props.onClose}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div className="row">
            <div className="back-rnd-button" onClick={this.props.onClose} />
          </div>
          <div
            className="row"
            style={{ height: 'calc(100% - 60px)', width: '100%' }}
          >
            <ConnectorView
              pins={this.props.pins}
              imageUrl={this.props.imageUrl}
              panzoom={false}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default ConnectorViewModal
