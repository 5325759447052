import * as types from '../actions/action-types'

const initialState = {
  search: '',
  devices: null,
  selectedDevice: null,
}

const addFaultDeviceReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_FAULT_DEVICE_RESET_STATE:
      return Object.assign({}, initialState)
    case types.ADD_FAULT_DEVICE_SET_SEARCH:
      return Object.assign({}, state, { search: action.search })
    case types.ADD_FAULT_DEVICE_SET_DEVICES:
      return Object.assign({}, state, { devices: action.devices })
    case types.ADD_FAULT_DEVICE_SET_SELECTED_DEVICE:
      return Object.assign({}, state, { selectedDevice: action.selectedDevice })
    case types.ADD_FAULT_DEVICE_RESET_DEVICE:
      return Object.assign({}, state, {
        selectedDevice: initialState.selectedDevice,
      })
    default:
      return state
  }
}

export default addFaultDeviceReducer
