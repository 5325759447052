import {
  CLASS_NAME_ACTIVE,
  CLASS_NAME_FAULTY,
  EDGE_TYPE_DEVICE,
  EDGE_TYPE_INLINE,
  EDGE_TYPE_INTERNAL_DEVICE,
  EDGE_TYPE_INTERNAL_FUSE,
  WHITE_COLOR,
  RED_FILL_COLOR,
  COMET_COLOR,
  AZURE_RADIANCE_COLOR,
  PIPER_COLOR,
  PICKLED_BLUEWOOD_COLOR,
  LIGHT_GRAY_COLOR,
  EBONY_CLAY_COLOR,
  DODGER_BLUE_COLOR,
  BRAND_TRANSPARENT_DARK_BLUE_COLOR,
  TURBO_COLOR,
  VALENCIA_COLOR,
  HIGHLIGHT_PIN_COLOR,
  GRAY_COLOR,
  COMPONENT_CATEGORY_SPLICE,
  COMPONENT_CATEGORY_FUSE,
  COMPONENT_CATEGORY_HIGH_VOLTAGE,
  COMPONENT_CATEGORY_DEVICE,
  COMPONENT_CATEGORY_INLINE,
} from '../constants'

import ConnectorIcon from '../assets/images/icons/svg/ic-connector.svg'
import ConnectorPng from '../assets/images/icons/ic-connector.png'
import JointIcon from '../assets/images/icons/svg/ic-joint.svg'
import JointPng from '../assets/images/icons/ic-joint.png'
import DeviceIcon from '../assets/images/icons/svg/ic-device.svg'
import DevicePng from '../assets/images/icons/ic-device.png'
import GroundIcon from '../assets/images/icons/svg/ic-ground.svg'
import GroundPng from '../assets/images/icons/ic-ground.png'
import InlineConnectionIcon from '../assets/images/icons/svg/ic-inline-connection.svg'
import InlineConnectionPng from '../assets/images/icons/ic-inline-connection.png'
import ModuleIcon from '../assets/images/icons/svg/ic-module.svg'
import ModulePng from '../assets/images/icons/ic-module.png'
import PowerIcon from '../assets/images/icons/svg/ic-power-distribution.svg'
import PowerPng from '../assets/images/icons/ic-power-distribution.png'
import FuseIcon from '../assets/images/icons/svg/ic-fuse.svg'
import FusePng from '../assets/images/icons/ic-fuse.png'
import ObdiiIcon from '../assets/images/icons/svg/ic-gwm.svg'
import ObdiiPng from '../assets/images/icons/ic-gwm.png'
import HEVIcon from '../assets/images/icons/svg/ic-ISO-7010.svg'
import HEVpng from '../assets/images/icons/ic-ISO-7010.png'
import CheckIcon from '../assets/images/icons/svg/ic-not-electrical.svg'
import CheckPng from '../assets/images/icons/ic-not-electrical.png'
import SplicePng from '../assets/images/icons/splice.png'
import DeviceSwitchIcon from '../assets/images/icons/svg/device-switch.svg'
import DeviceSpeakerIcon from '../assets/images/icons/svg/device-speaker.svg'
import DeviceSolenoidIcon from '../assets/images/icons/svg/device-solenoid.svg'
import DeviceSensorIcon from '../assets/images/icons/svg/device-sensor.svg'
import DeviceMotorIcon from '../assets/images/icons/svg/device-motor.svg'
import DeviceMicrophoneIcon from '../assets/images/icons/svg/device-microphone.svg'
import DeviceLedIcon from '../assets/images/icons/svg/device-led.svg'
import DeviceLampIcon from '../assets/images/icons/svg/device-lamp.svg'
import DeviceCameraIcon from '../assets/images/icons/svg/device-camera.svg'
import DeviceAntennaIcon from '../assets/images/icons/svg/device-antenna.svg'
import DeviceActuatorIcon from '../assets/images/icons/svg/device-actuator.svg'
import DeviceUltrasonicIcon from '../assets/images/icons/svg/device-ultrasonic.svg'
import DeviceThermistorIcon from '../assets/images/icons/svg/device-thermistor.svg'
import DeviceProximityIcon from '../assets/images/icons/svg/device-proximity.svg'
import DeviceHallIcon from '../assets/images/icons/svg/device-hall.svg'
import DeviceGeneratorIcon from '../assets/images/icons/svg/device-generator.svg'
import DeviceBatteryIcon from '../assets/images/icons/svg/device-battery.svg'
import DeviceHeaterIcon from '../assets/images/icons/svg/device-heater.svg'
import DeviceExternalMirrorIcon from '../assets/images/icons/svg/device-external-mirror.svg'
import DeviceInternalMirrorIcon from '../assets/images/icons/svg/device-internal-mirror.svg'
import DeviceBeltIcon from '../assets/images/icons/svg/device-belt.svg'
import DeviceSquibIcon from '../assets/images/icons/svg/device-squib.svg'
import DeviceInjectorIcon from '../assets/images/icons/svg/device-injector.svg'
import DeviceGeneric from '../assets/images/icons/svg/device-generic.svg'

import { detect } from 'detect-browser'
const browser = detect()
const isIE11orLower =
  browser &&
  browser.name === 'ie' &&
  parseInt(browser.version.split('.')[0], 10) < 12

/*
 * Gets the background image url for a graph node based on its category.
 */
const svgMaps = {
  connector: ConnectorIcon,
  device: DeviceIcon,
  ground: GroundIcon,
  inline: InlineConnectionIcon,
  joint: JointIcon,
  module: ModuleIcon,
  power_distribution: PowerIcon,
  fuse: FuseIcon,
  gateway_odbii: ObdiiIcon,
  high_voltage: HEVIcon,
  not_electrical: CheckIcon,
  splice: SplicePng, // Used in both svgMaps and pngMaps
}

const svgDeviceMaps = {
  ground: GroundIcon,
  joint: JointIcon,
  module: ModuleIcon,
  power_distribution: PowerIcon,
  high_voltage: HEVIcon,
  not_electrical: CheckIcon,
  switch: DeviceSwitchIcon,
  speaker: DeviceSpeakerIcon,
  solenoid: DeviceSolenoidIcon,
  sensor: DeviceSensorIcon,
  motor: DeviceMotorIcon,
  microphone: DeviceMicrophoneIcon,
  led: DeviceLedIcon,
  lamp: DeviceLampIcon,
  camera: DeviceCameraIcon,
  antenna: DeviceAntennaIcon,
  actuator: DeviceActuatorIcon,
  ultrasonic: DeviceUltrasonicIcon,
  thermistor: DeviceThermistorIcon,
  proximity: DeviceProximityIcon,
  hall: DeviceHallIcon,
  generator: DeviceGeneratorIcon,
  battery: DeviceBatteryIcon,
  heater: DeviceHeaterIcon,
  external_mirror: DeviceExternalMirrorIcon,
  internal_mirror: DeviceInternalMirrorIcon,
  belt: DeviceBeltIcon,
  squib: DeviceSquibIcon,
  injector: DeviceInjectorIcon,
  generic: DeviceGeneric,
}

const pngMaps = {
  connector: ConnectorPng,
  device: DevicePng,
  ground: GroundPng,
  inline: InlineConnectionPng,
  joint: JointPng,
  module: ModulePng,
  power_distribution: PowerPng,
  fuse: FusePng,
  gateway_odbii: ObdiiPng,
  high_voltage: HEVpng,
  not_electrical: CheckPng,
  splice: SplicePng,
}

export function getNodeBackgroundImage(node) {
  if (node.data('icon')) {
    return `/tracer/icon/${node.data('icon')}`
  }
  if (node.data('category') === 'device') {
    const category = node.data('device_category')
    return svgDeviceMaps[category]
  } else {
    const category = node.data('category')
    return isIE11orLower ? pngMaps[category] : svgMaps[category]
  }
}

/*
 * Creates the styling for the cytoscape graph
 * More info: http://js.cytoscape.org/#style
 */
export function getCyStyles(options = {}) {
  const styles = [
    {
      selector: 'node',
      style: {
        'background-color': COMET_COLOR,
        'background-fit': 'contain',
        'background-image': (el) => getNodeBackgroundImage(el),
        'background-image-crossorigin': 'use-credentials',
        'background-opacity': 1,
        'border-width': 2,
        'border-color': GRAY_COLOR,
        'background-width-relative-to': 'inner',
        color: WHITE_COLOR,
        content: (el) => {
          if (el.data('category') === COMPONENT_CATEGORY_SPLICE) {
            return ''
          } else {
            // We return UNKNOWN because it helps cytoscape position the text in the graph correctly
            // If you just return blank there will be weird rendering errors as the text gets updated.
            return el.data('label') ? el.data('label') : 'UNKNOWN'
          }
        },
        'font-family': 'TracerIcons,Montserrat,sans-serif',
        'font-size': '20px',
        'font-weight': '500',
        height: 40,
        padding: 5,
        shape: 'roundrectangle',
        'text-halign': 'right',
        'text-margin-x': '10px',
        'text-valign': 'center',
        width: 40,
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_FUSE}"]`,
      style: {
        'background-color': EBONY_CLAY_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el),
        'background-fit': 'contain',
        'border-width': 2,
        'border-color': GRAY_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_DEVICE}"]`,
      style: {
        'background-color': EBONY_CLAY_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el),
        'background-fit': 'contain',
        'border-width': 2,
        'border-color': GRAY_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_INLINE}"]`,
      style: {
        'border-width': 0,
        width: 40,
        height: 30,
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_HIGH_VOLTAGE}"]`,
      style: {
        'background-color': COMET_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el),
        'background-fit': 'contain',
        'border-width': 0,
        'border-color': COMET_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_SPLICE}"]`,
      style: {
        content: '',
        'background-color': PICKLED_BLUEWOOD_COLOR,
        'background-fit': 'none',
        'background-height': '50%',
        'background-width': '50%',
        width: 20,
        height: 20,
        'border-width': 0,
        'border-color': COMET_COLOR,
        padding: 0,
        shape: 'ellipse',
      },
    },
    {
      selector: 'node[?virtual]',
      style: {
        events: 'no',
      },
    },
    {
      selector: 'node[category="device"]',
      style: {
        events: 'yes',
      },
    },
    {
      selector: 'node[?unfocus]',
      style: {
        'background-image-opacity': 0.35,
        height: 35,
        opacity: 0.35,
        width: 35,
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INLINE}"]`,
      style: {
        'curve-style': 'bezier',
        'line-color': COMET_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_DEVICE}"]`,
      style: {
        'curve-style': 'bezier',
        'line-color': COMET_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INTERNAL_DEVICE}"]`,
      style: {
        'line-color': PIPER_COLOR,
        'line-style': 'dotted',
        'mid-source-arrow-shape': 'triangle-backcurve',
        display: 'none',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INTERNAL_FUSE}"]`,
      style: {
        'line-color': COMET_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: 'edge[!edge_type]',
      style: {
        color: 'white',
        'curve-style': 'bezier',
        'line-color': COMET_COLOR,
        opacity: 1,
        'font-size': '14px',
        'source-label': !options.showPinDestinationLabels
          ? ''
          : (el) => {
              const isInline =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_INLINE
              const isDevice =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_DEVICE
              const isSplice =
                el.data('from_category') ===
                  el.data('source') + '-' + COMPONENT_CATEGORY_SPLICE ||
                el.data('to_category') ===
                  el.data('source') + '-' + COMPONENT_CATEGORY_SPLICE
              const pins = el.data('pins')
              if (
                isInline ||
                isSplice ||
                isDevice ||
                !pins ||
                pins.length === 0 ||
                !pins[0][0]
              ) {
                return ''
              } else {
                return pins[0][0]
              }
            },
        'source-text-offset': '14px',
        'target-label': !options.showPinDestinationLabels
          ? ''
          : (el) => {
              const isInline =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_INLINE
              const isDevice =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_DEVICE
              const isSplice =
                el.data('from_category') ===
                  el.data('target') + '-' + COMPONENT_CATEGORY_SPLICE ||
                el.data('to_category') ===
                  el.data('target') + '-' + COMPONENT_CATEGORY_SPLICE
              const pins = el.data('pins')
              if (
                isInline ||
                isSplice ||
                isDevice ||
                !pins ||
                pins.length === 0 ||
                !pins[0][1]
              ) {
                return ''
              } else {
                return pins[0][1]
              }
            },
        'target-text-offset': '14px',
        'text-background-color': COMET_COLOR,
        'text-background-opacity': 1,
        'text-background-shape': 'roundrectangle',
        width: 3,
      },
    },
    {
      selector: 'edge[?unfocus]',
      style: {
        opacity: 0.35,
      },
    },
    {
      selector: `.${CLASS_NAME_ACTIVE}`,
      style: {
        'background-color': AZURE_RADIANCE_COLOR,
        'line-color': AZURE_RADIANCE_COLOR,
      },
    },
    {
      selector: `.${CLASS_NAME_FAULTY}`,
      style: {
        'background-color': RED_FILL_COLOR,
        'line-color': RED_FILL_COLOR,
      },
    },
    {
      selector: `[category!="${COMPONENT_CATEGORY_SPLICE}"][?${CLASS_NAME_ACTIVE}]`,
      style: {
        'background-color': AZURE_RADIANCE_COLOR,
        'line-color': AZURE_RADIANCE_COLOR,
      },
    },
    {
      selector: `[category!="${COMPONENT_CATEGORY_SPLICE}"][?${CLASS_NAME_FAULTY}]`,
      style: {
        'background-color': RED_FILL_COLOR,
        'line-color': RED_FILL_COLOR,
      },
    },
    {
      selector: ':active',
      style: {
        'overlay-opacity': '0',
      },
    },
  ]

  return styles
}
