import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGenerateNavigationResult } from '../hooks/VehicleTestNavigation'
import Spinner from '../components/Spinner'

const NavigationRedirect = () => {
  const history = useHistory()
  const search = history.location.search
  const { vehicleTestResultId, navType } = useParams()
  const {
    data: { url },
    isSuccess,
  } = useGenerateNavigationResult({
    vehicleTestResultId,
    navType,
  })

  useEffect(() => {
    history.push({ pathname: url, search })
  }, [isSuccess, url, navType])

  return (
    <div className="main-content">
      <Spinner />
    </div>
  )
}

export default NavigationRedirect
