import React, { useCallback, useEffect, useRef } from 'react'
import { Edge, Label, useCanvas, Port, Remove } from 'reaflow'
import { motion, useAnimation } from 'framer-motion'
import { CloneElement } from 'rdk'
import classNames from 'classnames'

import './CustomNode.scss'

/*
  Node is based on reaflow Node component.
 */
export const Node = ({
  id,
  x,
  y,
  ports,
  labels,
  height,
  width,
  properties,
  animated,
  className,
  rx = 2,
  ry = 2,
  offsetX = 0,
  offsetY = 0,
  icon,
  disabled,
  style,
  children,
  nodes,
  edges,
  draggable = true,
  linkable = true,
  selectable = true,
  removable = true,
  dragType = 'multiportOnly',
  dragCursor = 'crosshair',
  childEdge = <Edge />,
  childNode = <Node />,
  remove = <Remove />,
  port = <Port />,
  label = <Label />,
  onRemove,
  onDrag,
  onDragStart,
  onDragEnd,
  onClick,
  onKeyDown,
  onEnter,
  onLeave,
}) => {
  const nodeRef = useRef(null)
  const controls = useAnimation()
  const { canLinkNode, enteredNode, selections, readonly, ...canvas } =
    useCanvas()

  const isActive = selections?.length
    ? selections.includes(properties.id)
    : null
  const newX = x + offsetX
  const newY = y + offsetY
  const isDisabled = disabled || properties?.disabled
  const canSelect = selectable && !properties?.selectionDisabled
  const dataTestId = properties?.dataTestId

  useEffect(() => {
    controls.set({
      opacity: 1,
      translateX: x,
      translateY: y,
    })
  }, [controls, x, y])

  const nodeChildProps = {
    height,
    width,
    x,
    y,
    node: properties,
    nodes,
    edges,
  }

  const onClickCallback = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (!isDisabled && canSelect) {
        onClick?.(event, properties)
      }
    },
    [canSelect, isDisabled, onClick, properties],
  )

  const onKeyDownCallback = useCallback(
    (event) => {
      event.preventDefault()
      if (!isDisabled) {
        onKeyDown?.(event, properties)
      }
    },
    [isDisabled, onKeyDown, properties],
  )

  const onTouchStartCallback = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const onMouseEnterCallback = useCallback(
    (event) => {
      event.stopPropagation()
      canvas.onEnter(event, properties)
      if (!isDisabled) {
        onEnter?.(event, properties)
      }
    },
    [canvas, isDisabled, onEnter, properties],
  )

  const onMouseLeaveCallback = useCallback(
    (event) => {
      event.stopPropagation()
      canvas.onLeave(event, properties)
      if (!isDisabled) {
        onLeave?.(event, properties)
      }
    },
    [canvas, isDisabled, onLeave, properties],
  )

  return (
    <motion.g
      id={id}
      initial={{
        cursor: 'initial',
        opacity: 0,
        translateX: x,
        translateY: y,
      }}
      animate={controls}
      data-testid={dataTestId}
    >
      <motion.rect
        ref={nodeRef}
        tabIndex={-1}
        onKeyDown={onKeyDownCallback}
        onClick={onClickCallback}
        onTouchStart={onTouchStartCallback}
        onMouseEnter={onMouseEnterCallback}
        onMouseLeave={onMouseLeaveCallback}
        className={classNames('rect', className, properties?.className, {
          ['active']: isActive,
          ['disabled']: isDisabled,
          ['children']: nodes?.length > 0,
          ['selectionDisabled']: !canSelect,
        })}
        style={style}
        height={height}
        width={width}
        rx={rx}
        ry={ry}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: !animated ? { type: false, duration: 0 } : {},
        }}
      />
      {children && (
        <>
          {typeof children === 'function' ? children(nodeChildProps) : children}
        </>
      )}
      {icon && properties.icon && (
        <CloneElement element={icon} {...properties.icon} />
      )}
      {label &&
        labels?.length > 0 &&
        labels.map((l, index) => (
          <CloneElement
            element={label}
            key={index}
            {...l}
            tooltipText={properties?.data?.description}
          />
        ))}
      {port &&
        ports?.length > 0 &&
        ports.map((p) => {
          const element = typeof port === 'function' ? port(p) : port
          return (
            <CloneElement
              element={element}
              key={p.id}
              disabled={isDisabled || !linkable}
              offsetX={newX}
              offsetY={newY}
              {...p}
              id={`${id}-port-${p.id}`}
            />
          )
        })}
      <g>
        {edges?.length > 0 &&
          edges.map((e) => {
            const element =
              typeof childEdge === 'function' ? childEdge(e) : childEdge
            return (
              <CloneElement
                key={e.id}
                element={element}
                id={`${id}-edge-${e.id}`}
                disabled={isDisabled}
                {...e}
                properties={{
                  ...e.properties,
                  ...(e.data ? { data: e.data } : {}),
                }}
              />
            )
          })}
        {nodes?.length > 0 &&
          nodes.map(({ children, ...n }) => {
            const element =
              typeof childNode === 'function' ? childNode(n) : childNode
            const elementDisabled =
              element.props?.disabled != null
                ? element.props.disabled
                : disabled
            const elementAnimated =
              element.props?.animated != null
                ? element.props.animated
                : animated
            const elementSelectable =
              element.props?.selectable != null
                ? element.props.selectable
                : selectable
            return (
              <CloneElement
                key={n.id}
                element={element}
                id={`${id}-node-${n.id}`}
                disabled={elementDisabled}
                nodes={children}
                offsetX={newX}
                offsetY={newY}
                animated={elementAnimated}
                children={element.props.children}
                childNode={childNode}
                childEdge={childEdge}
                selectable={elementSelectable}
                onClick={onClick}
                onEnter={onEnter}
                onLeave={onLeave}
                onKeyDown={onKeyDown}
                onRemove={onRemove}
                {...n}
              />
            )
          })}
      </g>
    </motion.g>
  )
}
