import React from 'react'
/*
Usage renders a component description in two different spans
 <ComponentDescription name="Name" description="Description" />
*/
export default (props) => {
  return (
    <div className="repair-history-component-description">
      <span className="name">
        {props.name} {props.level}
      </span>
      <span className="description">{props.description}</span>
    </div>
  )
}
