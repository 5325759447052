import get from 'lodash/get'
import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { MODAL_SUBMIT_FEEDBACK } from '../constants'
import {
  SET_MODAL_CONTAINER,
  CANT_FIX_SUBMIT_START,
} from '../actions/action-types'
import { INCORRECT_RECOMMENDATION } from './FeedbackForm'

import { unsetModal } from '../actions/modal-actions'

import CloseButton from './CloseButton'

import {
  useCantFixReasons,
  useCantFix,
  useAddCantFix,
  useDeleteCantFix,
  useUpdateCantFix,
} from '../hooks/CantFix'

const ReasonInput = ({ reason, onClick, isChecked }) => {
  return (
    <>
      <div className="radio">
        <label className="radio">
          <span className="radio__input">
            <input
              key={reason.value}
              id={reason.value}
              type="radio"
              value={reason.value}
              checked={isChecked}
              onChange={(event) => {
                onClick(parseInt(event.target.value))
              }}
            />
            <span className="radio__control" />
          </span>
          <span className="radio__label">{reason.label}</span>
        </label>
      </div>
    </>
  )
}

const ReasonInputs = ({ selectedReason, selectReason }) => {
  const [status, cantFixReasons] = useCantFixReasons()

  if (status === 'loading') {
    return (
      <div>
        <FormattedMessage id="cantFix.loading" />
      </div>
    )
  }
  const reasons = cantFixReasons.map((reason) => ({
    value: reason.id,
    label: reason.name,
  }))

  return reasons.map((reason) => {
    return (
      <ReasonInput
        reason={reason}
        key={reason.value}
        isChecked={reason.value === selectedReason}
        onClick={selectReason}
      />
    )
  })
}

const CantFixForm = ({
  vehicleTestId,
  closeModal,
  openFeedbackModal,
  submitCantFix,
}) => {
  const [status, cantFix, error, isFetching, isLoading] =
    useCantFix(vehicleTestId)
  const [selectedReason, selectReason] = useState(
    cantFix ? cantFix.reason_id : null,
  )
  const addCantFix = useAddCantFix()
  const deleteCantFix = useDeleteCantFix()
  const updateCantFix = useUpdateCantFix()
  const [, cantFixReasons] = useCantFixReasons()

  const isFeedbackNeeded = () => {
    return cantFixReasons.some((reason) => {
      return reason.id == selectedReason && reason.needs_feedback
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const selectedReasonObj = cantFixReasons.find(
      (reason) => reason.id === selectedReason,
    )
    submitCantFix(selectedReasonObj && selectedReasonObj.name)

    const addingCantFix = !cantFix && selectedReason
    const updatingCantFix = cantFix && selectedReason

    if (updatingCantFix) {
      updateCantFix.mutate({ vehicleTestId, reasonId: selectedReason })
    } else if (addingCantFix) {
      addCantFix.mutate({ vehicleTestId, reasonId: selectedReason })
    } else if (cantFix && !selectedReason) {
      deleteCantFix.mutate({ vehicleTestId })
    }
    closeModal()

    if ((addingCantFix || updatingCantFix) && isFeedbackNeeded()) {
      openFeedbackModal()
    }
  }

  return (
    <>
      <form onSubmit={onSubmit} id="submit-cant-fix">
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage id="cantFix.reason.title" />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <ReasonInputs
                selectedReason={selectedReason}
                selectReason={selectReason}
              />
            </div>
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={selectedReason === null}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const CantFixPopup = ({
  intl,
  vehicleTestId,
  isOpen,
  closeModal,
  openFeedbackModal,
  submitCantFix,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel={intl.formatMessage({ id: 'cantFix.reason.title' })}
    >
      <CantFixForm
        vehicleTestId={vehicleTestId}
        closeModal={closeModal}
        openFeedbackModal={openFeedbackModal}
        submitCantFix={submitCantFix}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(unsetModal()),
    openFeedbackModal: () =>
      dispatch({
        type: SET_MODAL_CONTAINER,
        container: MODAL_SUBMIT_FEEDBACK,
        initialOption: INCORRECT_RECOMMENDATION,
      }),
    submitCantFix: (selectedReason) =>
      dispatch({
        type: CANT_FIX_SUBMIT_START,
        selectedReason,
      }),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CantFixPopup),
)
