import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PinNumber from './PinNumber'
import SvgCircle from './SvgCircle'

const propTypes = {
  pin: PropTypes.object,
  number: PropTypes.string,
  displayNumber: PropTypes.number,
  selectedElement: PropTypes.object,
  hideNumber: PropTypes.bool,
  pinClassName: PropTypes.string,
}

const defaultProps = {
  hideNumber: true,
  pin: {
    circuit: 'w/e',
  },
  pinClassName: 'pin text-center',
}

class Pin extends Component {
  render() {
    const tooltipId = `${this.props.number}-${this.props.pin.circuit}`
    return (
      <div className={this.props.pinClassName}>
        <PinNumber
          number={this.props.displayNumber}
          visible={this.props.hideNumber}
          location={this.props.incoming ? 'top' : 'bottom'}
        >
          <div data-for={tooltipId} data-tip={this.props.number}>
            <SvgCircle colors={this.props.colors || this.props.pin.colors} />
          </div>
        </PinNumber>
      </div>
    )
  }
}

Pin.propTypes = propTypes
Pin.defaultProps = defaultProps

export default Pin
