import React from 'react'
import IconInfo from './Icons/IconInfo'
import { Popover } from 'antd'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { useCantFixReason } from '../hooks/CantFix'
import { injectIntl, FormattedMessage } from 'react-intl'

export function CantFixMessage({ vehicleTestId }) {
  const [status, cantFixReason, error] = useCantFixReason(vehicleTestId)
  let message = <FormattedMessage id="cantFix.reason.notAvailable" />

  if (status === 'success' && cantFixReason) {
    message = cantFixReason.name
  } else if (status === 'loading') {
    message = <FormattedMessage id="cantFix.loading" />
  }

  return <>{message}</>
}

export function CantFixReason({ vehicleTestId }) {
  return (
    <Popover
      trigger="click"
      placement="right"
      content={() => (
        <CantFixMessage
          id="popover-cant-fix-reason"
          vehicleTestId={vehicleTestId}
        />
      )}
    >
      <div className="cant-fix-reason-icon">
        <IconInfo />
      </div>
    </Popover>
  )
}

const mapStateToProps = (state) => {
  return {
    vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
  }
}

export default injectIntl(connect(mapStateToProps, null)(CantFixReason))
