import React from 'react'
import { useQuery } from 'react-query'
import { useLocalStorage } from 'react-use'
import { getUserInfo } from '../api/auth-api'

const KEY = 'user-info'

export const useUserInfo = () => {
  const {
    status,
    data: userInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [KEY],
    async () => {
      return await getUserInfo()
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    userInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useUserPlant = () => {
  const { isSuccess, isLoading, userInfo } = useUserInfo()
  const [userPlant, setUserPlant] = useLocalStorage('user-plant', null)

  React.useEffect(() => {
    if (userPlant == null) {
      setUserPlant(userInfo?.plants[0]) // default plant
    }
  }, [userInfo, isSuccess])

  return [userPlant, setUserPlant]
}
