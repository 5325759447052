import { connect } from 'react-redux'
import { unsetModal } from '../actions/modal-actions'
import VerifyComponentConfirmationModal from '../components/VerifyComponentConfirmationModal'
import { getElementName } from '../helpers/element'
import { onMarkComponentAsVerified } from '../thunks/component'

const mapStateToProps = (state) => {
  return {
    isOpen: state.modalState.isOpen,
    title: getElementName(state.componentState.selectedElement),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(unsetModal())
    },
    onSubmit: () => dispatch(onMarkComponentAsVerified()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyComponentConfirmationModal)
