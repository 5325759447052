import get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Select from 'react-select'
import Modal from 'react-modal'

import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR } from '../constants'
import CloseButton from './CloseButton'

import { submitFeedback } from '../api/feedback-api'
import { isValidEmail } from '../helpers/utils'

const UNSUPPORTED_ERROR_CODE = 'unsupported_error_code'
const INCORRECT_IMAGE = 'incorrect_image'
const MISSING_IMAGE = 'missing_image'
const MISSING_COMPONENT = 'missing_component'
export const INCORRECT_RECOMMENDATION = 'incorrect_recommendation'
const FEATURE_REQUEST = 'feature_request'
const OTHER_PROBLEM = 'other_problem'

const FeedbackForm = ({
  intl,
  isOpen,
  closeModal,
  submitForm,
  username,
  initialOption = null,
}) => {
  const selectTypeToOption = {
    [UNSUPPORTED_ERROR_CODE]: 'Unsupported Error Code',
    [INCORRECT_IMAGE]: 'Incorrect Image',
    [MISSING_IMAGE]: 'Missing Image',
    [MISSING_COMPONENT]: 'Missing Component',
    [INCORRECT_RECOMMENDATION]: 'Incorrect Recommendation',
    [FEATURE_REQUEST]: 'Feature Request',
    [OTHER_PROBLEM]: 'Other',
  }
  const selectTypeOptions = Object.keys(selectTypeToOption).map((key) => ({
    value: key,
    label: selectTypeToOption[key],
  }))

  const [selection, selectOption] = useState(initialOption)
  const [description, updateDescription] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailEnabled, setEmailEnabled] = useState(true)

  let submittable = !!selection
  if (selection && [FEATURE_REQUEST, OTHER_PROBLEM].includes(selection))
    submittable = !!description.length

  useEffect(() => {
    if (isValidEmail(username) && !email) {
      setEmail(username)
      setEmailEnabled(false)
    }
  })

  const onSubmitClick = (e) => {
    e.preventDefault()
    if (!selection) return

    submitForm({
      selectionValue: selection,
      selectionLabel: selectTypeToOption[selection],
      description,
      name,
      email,
    })
    closeModal()
  }

  const renderSelectProblemType = () => {
    const placeholder = '--'
    return (
      <div className="row">
        <label>
          <FormattedMessage id="feedbackForm.selectProblem" /> (
          <FormattedMessage id="forms.required" />
          ):
        </label>
        <Select
          name="feedback-form-problem-type"
          options={selectTypeOptions}
          onChange={(e) => selectOption(e.value)}
          value={
            selection
              ? { value: selection, label: selectTypeToOption[selection] }
              : null
          }
          placeholder={placeholder}
        />
      </div>
    )
  }

  const renderEnterDescription = () => {
    return (
      <div className="row">
        <label>
          <FormattedMessage id="feedbackForm.description" /> (
          {submittable ? (
            <FormattedMessage id="forms.optional" />
          ) : (
            <FormattedMessage id="forms.required" />
          )}
          )
        </label>
        <textarea
          className="form-control"
          name="description"
          component="textarea"
          placeholder={intl.formatMessage({
            id: 'tracer.logFailure.commentPlaceholder',
          })}
          onChange={(e) => updateDescription(e.target.value)}
          value={description}
        />
      </div>
    )
  }

  const renderEnterName = () => {
    return (
      <div className="row">
        <label>
          <FormattedMessage id="feedbackForm.name" /> (
          <FormattedMessage id="forms.optional" />
          ):
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </div>
    )
  }

  const renderEnterEmail = () => {
    return (
      <div className="row">
        <label>
          <FormattedMessage id="feedbackForm.email" /> (
          <FormattedMessage id="forms.optional" />
          ):
        </label>
        <input
          type="email"
          className="form-control"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={emailEnabled ? '' : 'disabled'}
        />
      </div>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Submit Feedback"
    >
      <form onSubmit={onSubmitClick} id="submit-feedback-form">
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage id="feedbackForm.title" />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row" style={{ color: BLACK_COLOR }}>
              <FormattedMessage id="feedbackForm.leadingText" />
            </div>

            {renderSelectProblemType()}
            {renderEnterDescription()}
            {renderEnterEmail()}
            {renderEnterName()}

            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button type="submit" className="btn" disabled={!submittable}>
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const path = get(state, 'router.location.pathname')
  const vin = get(state.componentState, 'vin')
  const username = get(state, 'user.data.username')

  return {
    isOpen: state.modalState.isOpen,
    initialOption: state.modalState.initialOption,
    username: username,
    submitForm: (form) => {
      const payload = {
        form,
        path: get(state, 'router.location.pathname'),
        html_title: get(state, 'title'),
        vin: vin,
        redux_state: state,
      }
      submitFeedback(payload)
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackForm),
)
