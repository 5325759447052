import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { ROUTING } from './constants'
import history from './history'
import VehicleTestResult, {
  EphemeralVehicleTestResult,
} from './components/VehicleTestResult'
import NavigationRedirect from './containers/NavigationRedirect'
import DemoContainer from './containers/DemoContainer'
import Forbidden from './components/Forbidden'
import Login from './components/Login'
import Logout from './components/Logout'
import Auth0Callback from './components/Auth0Callback'
import MainMenuContainer from './containers/MainMenuContainer'
import VehicleTestsSearchContainer from './containers/VehicleTestsSearchContainer'
import UserList from './components/Users/List'
import UserAddContainer from './containers/UserAddContainer'
import UserEditContainer from './containers/UserEditContainer'
import DtcMemoSearchContainer from './containers/DtcMemoSearchContainer'
import DTCMemoContainer from './containers/DTCMemoContainer'
import ProductionLinePage from './ProductionLineDashboard'
import DTCMemoAndMappingEdit from './components/DTCMapping/DTCMemoAndMappingEdit'
import QrCodeScanContainer from './containers/QrCodeScanContainer'
import GettingStartedContainer from './containers/GettingStartedContainer'
import ReportsPage from './Reports'
import {
  JohnDeereAlertsPage,
  JohnDeereAuthorize,
} from './integrations/JohnDeere'
import { JohnDeereManualDTCPage } from './integrations/JohnDeere/ManualDTC'
import GlobalSearchPage, { GlobalSearchProvider } from './GlobalSearch'
import VehicleConfigurationSearch from './components/VehicleConfiguration/VehicleConfigurationSearch'
import { VINSearchInputProvider } from './components/VINSearchInput/VINSearchInputContext'
import { VirtualVehicleConfigurationProvider } from './VirtualVehicleConfiguration/VirtualVehicleConfigurationContext'

const Routing = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/tracer/:pathId">
        <VehicleTestResult />
      </Route>
      <Route exact path="/vehicle-test/preview">
        <EphemeralVehicleTestResult />
      </Route>
      <Route
        exact
        path="/navigation/vehicle_test_result/:vehicleTestResultId/:navType"
      >
        <NavigationRedirect />
      </Route>
      <Route exact path="/auth0callback" component={Auth0Callback} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/forbidden" component={Forbidden} />
      <Route exact path="/" component={MainMenuContainer} />
      <Route exact path="/demo" component={DemoContainer} />
      <Route
        exact
        path="/search/:vin?"
        component={VehicleTestsSearchContainer}
      />
      <Route exact path="/users/add" component={UserAddContainer} />
      <Route exact path="/users/:userId" component={UserEditContainer} />
      <Route exact path="/users" component={UserList} />
      <Route exact path="/global-search/configuration">
        <VirtualVehicleConfigurationProvider>
          <VINSearchInputProvider>
            <VehicleConfigurationSearch path={'global-search'} />
          </VINSearchInputProvider>
        </VirtualVehicleConfigurationProvider>
      </Route>
      <Route exact path="/global-search/vin/:vin">
        <GlobalSearchProvider>
          <GlobalSearchPage />
        </GlobalSearchProvider>
      </Route>
      <Route
        exact
        path={ROUTING.memoAuthoring}
        component={DtcMemoSearchContainer}
      />
      <Route
        exact
        path="/dtc-memo/:dtcMemoId/:add?"
        component={DTCMemoContainer}
      />
      <Route
        exact
        path="/dtc-mapping/:dtcMemoId/:vin"
        component={DTCMemoAndMappingEdit}
      />
      <Route
        exact
        path="/production-line-dashboard"
        component={ProductionLinePage}
      />
      <Route exact path="/qrcode-scan" component={QrCodeScanContainer} />
      <Route
        exact
        path="/getting-started"
        component={GettingStartedContainer}
      />
      <Route exact path="/reports" component={ReportsPage} />

      {/* Start of John Deere routes */}
      <Route exact path="/alerts" component={JohnDeereAlertsPage} />
      <Route exact path="/manual-alerts" component={JohnDeereManualDTCPage} />
      <Route exact path="/jd/authorize" component={JohnDeereAuthorize} />
      {/* END of John Deere routes */}

      <Redirect exact strict to="/" push={false} />
    </Switch>
  </ConnectedRouter>
)

export default Routing
