import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetCDNInfo() {
  return api
    .get(`/cdn/info`)
    .then((resp) => ({ imagesURL: resp.data.data.images_url }))
}
