import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import i18n from '../i18n'

const translations = i18n.translationsForCurrentLocale()

export const required = (value) => {
  const message = translations['forms.required']
  switch (true) {
    case isObject(value) && isEmpty(value):
    case isString(value) && !value.trim():
    case !value:
      return message
    default:
      return null
  }
}

export const isJson = (value) => {
  const message = translations['forms.invalidJSON']
  try {
    const obj = JSON.parse(value)
    if (obj && isObject(obj)) {
      return false
    }

    return message
  } catch (error) {
    return error.message
  }
}
