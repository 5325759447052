import * as types from '../actions/action-types'

const initialState = {
  component: null,
  containers: [],
  imageUrl: null,
  isOpen: true,
  pins: [],
}

const modalReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CONNECTOR_IMAGE:
      return Object.assign({}, state, {
        imageUrl: action.imageUrl,
        pins: action.pins,
      })
    case types.SET_COMPONENT:
      return Object.assign({}, state, {
        component: action.component,
      })
    case types.SET_MODAL_CONTAINER:
      return Object.assign({}, state, {
        containers: [...state.containers, action.container],
        isOpen: true,
        initialOption: action.initialOption,
      })
    case types.UNSET_MODAL_CONTAINER:
      return Object.assign({}, state, {
        ...initialState,
        containers: state.containers.slice(0, -1),
      })
    default:
      return state
  }
}

export default modalReducer
