import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'

const getCurrentComponent = (state) => state.componentState.selectedElement
export const getVerifiedComponents = (state) =>
  state.componentState.verifiedComponents || []

export const getComponentIsVerified = createSelector(
  [getCurrentComponent, getVerifiedComponents],
  (currentComponent, verifiedComponents) =>
    verifiedComponents.findIndex(
      (cid) => cid === get(currentComponent, 'id'),
    ) !== -1,
)

export const getSelectedComponentId = createSelector(
  [getCurrentComponent],
  (currentComponent) => get(currentComponent, 'id'),
)
