import React from 'react'

import {
  ANSI_HIGH_VOLTAGE_COLOR,
  PICKLED_BLUEWOOD_COLOR,
  WHITE_COLOR,
} from '../../constants'

const defaultProps = {
  fill: ANSI_HIGH_VOLTAGE_COLOR,
  sparkleFill: PICKLED_BLUEWOOD_COLOR,
  iconFill: WHITE_COLOR,
  stroke: PICKLED_BLUEWOOD_COLOR,
}

const IconHev = (props) => {
  let { fill, sparkleFill, iconFill } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="20px"
      viewBox="0 0 20 20"
    >
      <path
        id="sign_border"
        fill={fill}
        stroke={iconFill}
        strokeWidth="1"
        strokeMiterlimit="10"
        d="M17.9,15.4L10.3,2.2C10.2,2.1,10,2,9.9,2S9.6,2.1,9.5,2.2L1.9,15.4c-0.1,0.1-0.1,0.3,0,0.4 C2,16,2.1,16.1,2.3,16.1h15.2c0.1,0,0.3-0.1,0.4-0.2C17.9,15.7,17.9,15.6,17.9,15.4L17.9,15.4z"
      />
      <polygon id="polygon7" fill={fill} points="16.8,15.2 3,15.2 9.9,3.3 " />
      <path
        id="path1933"
        fill={sparkleFill}
        stroke={sparkleFill}
        strokeWidth="0.5"
        d="M9.6,6.5l-1.3,4.6l2-1l-0.4,3.1l-0.4-0.6l0.3,1.9l1.1-1.6l-0.6,0.4l1.2-4.4L9.4,10l1.6-3.5 L9.6,6.5L9.6,6.5z"
      />
    </svg>
  )
}

IconHev.defaultProps = defaultProps

export default IconHev
