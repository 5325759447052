import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import HarnessViewGraphContainer from '../containers/HarnessViewGraphContainer'
import {
  ZoomInControlEmbedded,
  ZoomOutControlEmbedded,
  ZoomResetControlEmbedded,
} from './ZoomControls'
import PanControls from './PanControls'
import { HARNESS_VIEW, VEHICLE_VIEW } from '../constants'
import Topology from '../components/SvgViews/Topology'
import {
  setHarnessVehicleSwitchSelection,
  setActionBarSelection,
} from '../actions/embedded-mode-actions'
import { HarnessViewVehicleViewSwitch } from './HarnessViewVehicleViewSwitch'
import { defaultLayout } from '../components/Cytoscape'

const HarnessGraph = () => {
  const props = useSelector(
    (state) => ({
      graphNodes: state.componentState.graphData.nodes,
    }),
    shallowEqual,
  )

  const cytoscapeLayout = { ...defaultLayout, fit: false }

  if (isEmpty(props.graphNodes)) {
    return null
  }

  return (
    <HarnessViewGraphContainer
      zoomInControl={ZoomInControlEmbedded}
      zoomOutControl={ZoomOutControlEmbedded}
      zoomResetControl={ZoomResetControlEmbedded}
      panControls={PanControls}
      layout={cytoscapeLayout}
      userPanningEnabled={false}
      userZoomingEnabled={false}
    />
  )
}

const VehicleViewEmbeddedMode = () => {
  return (
    <Topology
      zoomInControl={ZoomInControlEmbedded}
      zoomOutControl={ZoomOutControlEmbedded}
      zoomResetControl={ZoomResetControlEmbedded}
      panControls={PanControls}
      zoomEnabled={false}
      panEnabled={false}
    />
  )
}

const HarnessViewEmbeddedMode = () => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      harnessVehicleSwitch: state.embeddedModeState.harnessVehicleSwitch,
    }),
    shallowEqual,
  )

  const setSelected = (selected) => {
    dispatch(setHarnessVehicleSwitchSelection(selected))
  }

  return (
    <>
      <HarnessViewVehicleViewSwitch
        viewName={props.harnessVehicleSwitch}
        setView={setSelected}
      />
      {props.harnessVehicleSwitch === HARNESS_VIEW ? (
        <div className="embedded-mode-harness-view">
          <HarnessGraph />
        </div>
      ) : (
        <div className="embedded-mode-vehicle-view">
          <VehicleViewEmbeddedMode />
        </div>
      )}
    </>
  )
}

export default HarnessViewEmbeddedMode
