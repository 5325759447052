import React, { Component } from 'react'
import CloseButton from './CloseButton'
import Modal from 'react-modal'
import { FormattedMessage } from 'react-intl'
import VehicleTestsTable from './VehicleTestsTable'

class SwitchTest extends Component {
  constructor(props, context) {
    super(props, context)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    if (this.props.vin) {
      this.props.searchForVin(this.props.vin)
    }
  }

  componentDidUpdate(prevProps) {
    const prevVin = prevProps.vin
    const thisVin = this.props.vin
    if (prevVin !== thisVin && thisVin) {
      this.props.searchForVin(thisVin)
    }
  }

  closeModal() {
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeModal}
        className="modal-switch-test"
        overlayClassName="modal-overlay"
        contentLabel="Modal"
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              <FormattedMessage id="tracer.switchTest.modalHeader" />
              <CloseButton
                wrapperClassName="pull-right"
                onClose={this.props.onClose}
              />
            </h3>
          </div>
          <div className="panel-body">
            <VehicleTestsTable
              currentVehicleTest={this.props.currentVehicleTest}
              data={this.props.vehicleTests}
              onViewButtonClick={this.closeModal}
              showVin={false}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default SwitchTest
