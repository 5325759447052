import React from 'react'
import classNames from 'classnames'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { HARNESS_VIEW, VEHICLE_VIEW } from '../constants'
import { ReactComponent as HarnessViewIcon } from '../assets/images/icons/embedded/svg/harness-view-icon.svg'
import { ReactComponent as VehicleViewIcon } from '../assets/images/icons/embedded/svg/vehicle-view-icon.svg'

export const HarnessViewVehicleViewSwitch = ({ viewName, setView }) => {
  const [disabled, setDisabled] = React.useState()

  const toggledOnDtc = useSelector(
    (state) => state.dtcState.toggledOnDtc,
    shallowEqual,
  )

  React.useEffect(() => {
    setDisabled(viewName === HARNESS_VIEW && toggledOnDtc)
  }, [viewName, setView])

  const state = useSelector(
    (state) => ({
      actionBarSelection: state.embeddedModeState.actionBarSelection,
    }),
    shallowEqual,
  )

  const toggle = () => {
    if (disabled) return
    setView(
      { [HARNESS_VIEW]: VEHICLE_VIEW, [VEHICLE_VIEW]: HARNESS_VIEW }[viewName],
    )
  }

  const classes = classNames('controls harness-view-vehicle-view-switch', {
    'disabled-control': disabled,
  })

  return (
    <div className={classes} onClick={toggle}>
      {viewName === HARNESS_VIEW ? <VehicleViewIcon /> : <HarnessViewIcon />}
    </div>
  )
}
