import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider as StoreProvider } from 'react-redux'
import store from '../store'
import { QueryClientProvider, QueryClient } from 'react-query'

import { locale, translations } from '../Locale'

const queryClient = new QueryClient()

const TooltipRoot = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} messages={translations}>
        <StoreProvider store={store}>{children}</StoreProvider>
      </IntlProvider>
    </QueryClientProvider>
  )
}

export default TooltipRoot
