import React from 'react'

const IconNoImage = (props) => {
  return (
    <svg
      width="160px"
      height="160px"
      viewBox="0 0 160 160"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="path-1" cx="76" cy="76" r="76" />
      </defs>
      <g
        id="Vechile-View-Empty-State-1440"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-641.000000, -157.000000)"
      >
        <g id="Group-4" transform="translate(473.000000, 157.000000)">
          <g id="Group-3" transform="translate(168.000000, 0.000000)">
            <g id="Group-2" transform="translate(4.000000, 3.000000)">
              <g id="Shape">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Mask" />
                <path
                  d="M122,100 L122,54 C122,42.1426601 111.449115,32.1907361 98,32 C85.5131118,32.1907361 74.9634574,42.1426601 75,54 L75,101 C74.9634574,111.972129 65.7934299,120.621585 55,121 C43.2492663,120.621585 34.0792389,111.972129 34,101 L34,55 L34,10 L31,10 L31,55 L31,101 C31,113.572935 41.5508852,123.523698 55,124 C67.4881189,123.523698 78.0377734,113.572935 78,101 L78,54 C78.0377734,43.7423045 87.2078008,35.0928486 98,35 C109.750734,35.0928486 118.920761,43.7423045 119,54 L119,100 L119,119 L119,135 C118.627852,135.349927 119.31705,136 120,136 C121.015431,136 121.704629,135.349927 122,135 L122,119 L122,100 Z"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  mask="url(#mask-2)"
                />
              </g>
              <circle
                id="Oval"
                stroke="#FFFFFF"
                strokeWidth="3"
                cx="76"
                cy="76"
                r="74.5"
              />
              <g
                id="noun_1206611_cc"
                transform="translate(27.000000, 51.000000)"
                fill="#FFFFFF"
              >
                <rect
                  id="Rectangle-4"
                  x="44"
                  y="14"
                  width="11"
                  height="27"
                  rx="1"
                />
                <rect
                  id="Rectangle-4"
                  x="0"
                  y="0"
                  width="11"
                  height="14"
                  rx="1"
                />
                <rect
                  id="Rectangle-4"
                  x="88"
                  y="41"
                  width="11"
                  height="14"
                  rx="1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconNoImage
