import React from 'react'

const RightArrow = () => {
  return (
    <svg
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.22671 33.4768C1.49632 33.617 1.79903 33.6812 2.10234 33.6624C2.40566 33.6436 2.69814 33.5425 2.94837 33.3701L24.615 18.3701C24.8364 18.2167 25.0174 18.0119 25.1423 17.7733C25.2673 17.5348 25.3326 17.2694 25.3326 17.0001C25.3326 16.7307 25.2673 16.4654 25.1423 16.2268C25.0174 15.9882 24.8364 15.7835 24.615 15.6301L2.94837 0.630089C2.69833 0.457077 2.40572 0.355764 2.10223 0.337121C1.79874 0.318477 1.49593 0.383214 1.22659 0.524321C0.957251 0.665428 0.731646 0.877528 0.574202 1.13766C0.416759 1.39778 0.333477 1.69603 0.333374 2.00009V32.0001C0.333324 32.3043 0.416546 32.6028 0.574019 32.8631C0.731493 33.1234 0.957211 33.3356 1.22671 33.4768Z"
        fill="white"
      />
    </svg>
  )
}

const LeftArrow = () => {
  return (
    <svg
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38501 18.3698L23.0517 33.3698C23.3017 33.5428 23.5943 33.6441 23.8978 33.6628C24.2013 33.6814 24.5041 33.6167 24.7735 33.4756C25.0428 33.3345 25.2684 33.1224 25.4258 32.8622C25.5833 32.6021 25.6666 32.3039 25.6667 31.9998V1.9998C25.6669 1.6956 25.5838 1.39714 25.4265 1.13677C25.2692 0.876405 25.0436 0.664062 24.7742 0.522755C24.5048 0.381447 24.2019 0.316566 23.8982 0.335142C23.5946 0.353718 23.3018 0.455043 23.0517 0.628136L1.38501 15.6281C1.16427 15.7822 0.984 15.9873 0.859523 16.2259C0.735046 16.4646 0.670044 16.7298 0.670044 16.999C0.670044 17.2681 0.735046 17.5333 0.859523 17.772C0.984 18.0107 1.16427 18.2158 1.38501 18.3698Z"
        fill="white"
      />
    </svg>
  )
}

const UpArrow = () => {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9999 25.6668H31.9999C32.3036 25.6659 32.6014 25.5821 32.861 25.4245C33.1207 25.267 33.3325 25.0416 33.4736 24.7726C33.6146 24.5036 33.6797 24.2012 33.6617 23.898C33.6438 23.5948 33.5434 23.3023 33.3716 23.0518L18.3716 1.38518C17.7499 0.486842 16.2532 0.486842 15.6299 1.38518L0.629898 23.0518C0.456273 23.3017 0.354455 23.5945 0.335507 23.8982C0.316559 24.2019 0.381205 24.505 0.522422 24.7745C0.663638 25.0441 0.876025 25.2698 1.13651 25.4271C1.39699 25.5844 1.6956 25.6673 1.9999 25.6668Z"
        fill="white"
      />
    </svg>
  )
}

const DownArrow = () => {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.63 24.6152C15.7829 24.8372 15.9875 25.0188 16.2262 25.1442C16.4648 25.2697 16.7304 25.3352 17 25.3352C17.2696 25.3352 17.5352 25.2697 17.7739 25.1442C18.0125 25.0188 18.2171 24.8372 18.37 24.6152L33.37 2.9485C33.5436 2.69859 33.6455 2.40588 33.6644 2.10217C33.6834 1.79846 33.6187 1.49537 33.4775 1.22582C33.3363 0.956271 33.1239 0.730577 32.8634 0.57326C32.6029 0.415943 32.3043 0.333019 32 0.333498H2.00002C1.69642 0.334752 1.39891 0.418743 1.13948 0.576436C0.880055 0.73413 0.668521 0.959561 0.527631 1.22849C0.386741 1.49741 0.321825 1.79966 0.339865 2.10272C0.357905 2.40578 0.458218 2.69819 0.630016 2.9485L15.63 24.6152Z"
        fill="white"
      />
    </svg>
  )
}

export { RightArrow, LeftArrow, UpArrow, DownArrow }
