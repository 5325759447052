import * as types from '../actions/action-types'

const initialState = {
  isEphemeralVehicleTest: false,
}

const ephemeralVehicleTestReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_EPHEMERAL_VEHICLE_TEST:
      return Object.assign({}, state, {
        isEphemeralVehicleTest: true,
      })
    case types.UNSET_EPHEMERAL_VEHICLE_TEST:
      return Object.assign({}, state, {
        isEphemeralVehicleTest: false,
      })
    default:
      return state
  }
}

export default ephemeralVehicleTestReducer
