import * as types from '../actions/action-types'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  /*
    [
      {
        "id": "harness",
        "label": "Harness"
      },
      ...
    ]
   */
  circuitCategories: [],
  /*
    componentStates structure:
    {
      "C1CF02-A": {
        "id": "C1CF02-A",
        "category": "module",
        "count": 3,
        "status": "CONNECTOR_PUSH_BACK"
        "annotation": "should be checked one more time",
      },
      ...
    }
  */
  componentStates: {},
  /*
    harnessStates structure:
    {
      "14406-D": {
        "id": "14406-D",
        "count": 1,
        "status": "CONNECTOR_DAMAGE",
        "annotation": "everything is okay",
      },
      ...
    }
   */
  faults: [],
  harnessStates: {},
  deviceStates: {},
  repairHistory: [],
  historiesForComponents: {},
  appliedComponentIds: [],
}

const loggingReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CIRCUIT_CATEGORIES:
      return Object.assign({}, state, {
        circuitCategories: action.categories,
      })
    case types.UPDATE_COMPONENT_STATE:
      return Object.assign({}, state, {
        componentStates: action.states,
      })
    case types.UPDATE_HARNESS_STATE:
      return Object.assign({}, state, {
        harnessStates: action.states,
      })
    case types.UPDATE_DEVICE_STATE:
      return Object.assign({}, state, {
        deviceStates: action.states,
      })
    case types.SET_COMPONENT_STATUS:
      let componentStates = Object.assign({}, state.componentStates)
      if (!componentStates[action.componentId]) {
        componentStates[action.componentId] = {}
      }
      componentStates[action.componentId].status = action.status
      return Object.assign({}, state, {
        componentStates: componentStates,
      })
    case types.SET_REPAIR_HISTORY:
      return Object.assign({}, state, {
        repairHistory: action.repairHistory,
      })
    case types.DELETE_REPAIR_HISTORY_ITEM:
      const repairHistory = state.repairHistory.filter(
        (item) => item.id !== action.id,
      )
      return Object.assign({}, state, {
        repairHistory,
      })
    case types.DELETE_EMPTY_REPAIR_HISTORY_ITEMS:
      return Object.assign({}, state, {
        repairHistory: state.repairHistory.filter((o) => o.id),
      })
    case types.ADD_REPAIR_HISTORY_ITEM:
      const newRepairHistory = cloneDeep(state.repairHistory)
      newRepairHistory.unshift(action.newRepairHistoryItem)
      return Object.assign({}, state, {
        repairHistory: newRepairHistory,
      })
    case types.SET_FAULT_CATEGORIES:
      return Object.assign({}, state, {
        faults: action.faults,
      })
    case types.SET_HISTORY_FOR_COMPONENT:
      const historiesForComponents = Object.assign(
        {},
        state.historiesForComponents,
      )
      historiesForComponents[action.componentId] = action.history
      return Object.assign({}, state, {
        historiesForComponents,
      })
    case types.SET_BATCH_HISTORY_FOR_COMPONENTS:
      const newHistory = Object.assign(
        {},
        state.historiesForComponents,
        action.componentsWithHistory,
      )
      return Object.assign({}, state, {
        historiesForComponents: newHistory,
      })
    case types.RESET_HISTORY_FOR_COMPONENT:
      return Object.assign({}, state, {
        historiesForComponents: initialState.historiesForComponents,
      })
    case types.ADD_APPLIED_COMPONENT:
      return Object.assign({}, state, {
        appliedComponentIds: state.appliedComponentIds.concat(
          action.componentId,
        ),
      })
    case types.RESET_APPLIED_COMPONENTS:
      return Object.assign({}, state, {
        appliedComponentIds: initialState.appliedComponentIds,
      })
    default:
      return state
  }
}

export default loggingReducer
