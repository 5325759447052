import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/api',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
  InterceptorOptions.noCacheInterceptor,
)

export function apiPostVehicleTest({ payload, authToken }) {
  return api
    .post('/v1/vehicletests', payload, {
      headers: {
        Authorization: `bearer ${authToken}`,
      },
    })
    .then((resp) => resp.data)
}
