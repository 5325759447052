import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Popover } from 'antd'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import ComponentFaultsHistoryList from '../ComponentFaultsHistoryList'
import { getElementName } from '../../helpers/element'
import { getComponentListItemId } from '../../helpers/utils'
import { ConnectorColorTag } from '../ConnectorColorTag'

import IconInfo from '../Icons/IconInfo'
import IconHev from '../Icons/IconHev'
import StateButtons from '../StateButtons'
import IconFail from '../Icons/IconFail'
import IconOk from '../Icons/IconOk'

import { createDtcList } from '../../selectors'
import { selectElement } from '../../thunks/component'
import { highlightDtcs, toggleOffDtc } from '../../actions/dtc-actions'
import { COMPONENT_CATEGORY_HIGH_VOLTAGE } from '../../constants'
import useIsVisible from '../../hooks/IsVisible'
import {
  hideComponentPopover,
  showComponentPopover,
} from '../../actions/component-actions'

import './style.scss'

const propTypes = {
  component: PropTypes.object.isRequired,
  status: PropTypes.string,
  selected: PropTypes.bool,
  indicate: PropTypes.bool,
}

const ComponentListItem = (props) => {
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )
  const isComponentPopoverOpen = useSelector(
    (state) => state.componentState.popover,
    shallowEqual,
  )
  const dispatch = useDispatch()
  const liRef = useRef()
  const isCurrentListItemVisible = useIsVisible(liRef)

  const { status, selected, indicate, component } = props

  useEffect(() => {
    if (selected && !isCurrentListItemVisible) {
      liRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [selected])

  const faulty = status !== undefined
  const componentId = component?.id
  const dtcs = useSelector((state) => createDtcList(state))
  const componentFaultsHistory = useSelector(
    (state) => state.loggingState.historiesForComponents[componentId],
  )
  const showComponentHistoryIcon = !isEmpty(componentFaultsHistory)
  const verifiedComponents = useSelector(
    (state) => state.componentState.verifiedComponents || [],
  )
  const isComponentVerified =
    verifiedComponents.findIndex((cid) => cid === componentId) !== -1

  const onComponentClick = () => {
    dispatch(selectElement(component, true))
    dispatch(toggleOffDtc())
    dispatch(
      highlightDtcs(
        dtcs.filter((dtc) => dtc.components && dtc.components.has(componentId)),
      ),
    )
  }

  const highVoltageIcon =
    component?.category === COMPONENT_CATEGORY_HIGH_VOLTAGE ? <IconHev /> : null

  let okFailedIcon = null

  if (faulty) {
    okFailedIcon = <IconFail enableOutline={selected || indicate} />
  } else if (isComponentVerified) {
    okFailedIcon = <IconOk enableOutline={selected || indicate} />
  }

  // TODO: refactor list-group-item styling to include it in this components' directory
  return (
    <li
      ref={liRef}
      onClick={() => onComponentClick()}
      id={getComponentListItemId(componentId)}
      className={classNames('list-group-item', {
        active: selected,
        verified: isComponentVerified,
        indicate,
        faulty,
      })}
      data-testid="dtc-group-item"
    >
      <div className="component-list-item-title-wrap">
        {highVoltageIcon}
        <span className="component-list-item-title">
          {getElementName(component)}
        </span>
        {okFailedIcon}
        {showComponentHistoryIcon && (
          <Popover
            open={selected && isComponentPopoverOpen}
            id={`component-history-popover-${componentId}`}
            trigger="click"
            placement="right"
            content={() => (
              <ComponentFaultsHistoryList
                componentFaultsHistory={componentFaultsHistory}
              />
            )}
            overlayStyle={{
              width: '40vw',
            }}
            onOpenChange={() =>
              isComponentPopoverOpen
                ? dispatch(hideComponentPopover())
                : dispatch(showComponentPopover())
            }
          >
            <IconInfo />
          </Popover>
        )}
      </div>
      <div className="subtitle">
        <ConnectorColorTag colorCode={component?.color} />
        {component.description}
      </div>
      {selected && !isEphemeralVehicleTest && <StateButtons />}
    </li>
  )
}

ComponentListItem.propTypes = propTypes

export default ComponentListItem
