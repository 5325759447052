import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { resetState } from '../actions/component-actions'
import { getHarnessDataFromPath } from '../api/harness-api'
import { getUser } from '../api/auth-api'
import { loadVerifiedComponents, selectElement } from '../thunks/component'
import { updateCircuitState } from '../api/logging-api'
import { setHtmlTitle, setHtmlTitleToDefault } from '../actions/title-actions'
import {
  ActionBar,
  SELECTION_VALUES,
  DTCListScrollControls,
  ComponentListScrollControls,
} from './ActionBar'
import {
  HARNESS_VIEW,
  MODAL_EMBEDDED_MODE_PIN_DESTINATION,
  VEHICLE_VIEW,
} from '../constants'
import HarnessViewEmbeddedMode from './HarnessView'
import PinoutViewEmbeddedMode from './PinoutView'
import RepairHistoryEmbeddedMode from './RepairHistory'
import SwitchTestEmbeddedMode from './SwitchTestView'
import DTCList from './DTC'
import ComponentList from './Component'
import { createDtcList } from '../selectors'
import { highlightDtcs, toggleOffDtc } from '../actions/dtc-actions'
import { ERROR_NO_GRAPH } from '../constants'
import NoGraph from './NoGraph'
import Loading from '../components/Loading'

const VehicleTestResultEmbeddedMode = ({ match: { params } }) => {
  const [isLodaing, setIsLoading] = React.useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    document.body.classList.add('fix-qtip-scrolling')

    return () => document.body.classList.remove('fix-qtip-scrolling')
  }, [])

  useEffect(() => {
    dispatch(resetState())
  }, [dispatch])

  useEffect(() => {
    // Retrieved the cached JSON data from the path if it exists
    if (params.pathId) {
      getHarnessDataFromPath(params.pathId)
        .then(() => getUser())
        .then(() => dispatch(loadVerifiedComponents()))
        .then(() => setIsLoading(false))
    }
  }, [])

  const props = useSelector(
    (state) => ({
      elements: state.componentState.elements,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      sequenceNumber: state.componentState.sequenceNumber,
      svgEnabled: state.componentState.svgEnabled,
      graphNodes: state.componentState.graphData.nodes,
      actionBarSelection: state.embeddedModeState.actionBarSelection,
      harnessVehicleSwitch: state.embeddedModeState.harnessVehicleSwitch,
      component: state.componentState.selectedElement,
      showEmptyPins: state.pinState.showEmptyPins,
      modalContainers: state.modalState.containers,
    }),
    shallowEqual,
  )

  const toggledOnDtc = useSelector(
    (state) => state.dtcState.toggledOnDtc,
    shallowEqual,
  )
  const dtcs = useSelector((state) => createDtcList(state), shallowEqual)
  const noGraph = useSelector(
    (state) => state.error.error === ERROR_NO_GRAPH,
    shallowEqual,
  )

  useEffect(() => {
    if (props.elements && props.elements.length > 0) {
      dispatch(selectElement(props.elements[0], true))
      dispatch(toggleOffDtc(toggledOnDtc))
      dispatch(
        highlightDtcs(
          dtcs.filter(
            (dtc) => dtc.components && dtc.components.has(props.elements[0].id),
          ),
        ),
      )
    }
  }, [props.elements])

  useEffect(() => {
    if (props.makeModelId) {
      updateCircuitState(props.makeModelId, props.vin)
    }
  }, [props.makeModelId])

  useEffect(() => {
    getHarnessDataFromPath(params.pathId)
  }, [params.pathId])

  useEffect(() => {
    dispatch(setHtmlTitle(props.sequenceNumber || props.vin))
    // on component unmount set default title
    return () => dispatch(setHtmlTitleToDefault())
  }, [])

  const dtcPanelRef = React.useRef()
  const componentPanelRef = React.useRef()

  const [panelRef, setPanelRef] = React.useState()

  React.useEffect(() => {
    setPanelRef(determinePanelRef(props.actionBarSelection))
  }, [dtcPanelRef, componentPanelRef, props.actionBarSelection])

  function getMainView(selection) {
    if (
      selection === SELECTION_VALUES.dtc ||
      selection === SELECTION_VALUES.components
    ) {
      return <HarnessViewEmbeddedMode />
    } else if (selection === SELECTION_VALUES.pinout) {
      return <PinoutViewEmbeddedMode component={props.component} />
    } else if (selection === SELECTION_VALUES.repairHistory) {
      return <RepairHistoryEmbeddedMode />
    } else if (selection === SELECTION_VALUES.switchTest) {
      return <SwitchTestEmbeddedMode />
    }
    return null
  }

  function getPanel(selection) {
    if (selection === SELECTION_VALUES.components) {
      return <ComponentList ref={componentPanelRef} />
    } else if (selection === SELECTION_VALUES.dtc) {
      return <DTCList ref={dtcPanelRef} />
    }
  }

  function getControls(selection) {
    return {
      [SELECTION_VALUES.components]: ComponentListScrollControls,
      [SELECTION_VALUES.dtc]: DTCListScrollControls,
    }[selection]
  }

  function determinePanelRef(selection) {
    return {
      [SELECTION_VALUES.components]: componentPanelRef,
      [SELECTION_VALUES.dtc]: dtcPanelRef,
    }[selection]
  }

  function getTitle(
    actionBarSelection,
    harnessVehicleSwitch,
    showEmptyPins,
    modalContainers,
    component,
  ) {
    if (
      actionBarSelection === SELECTION_VALUES.dtc ||
      actionBarSelection === SELECTION_VALUES.components
    ) {
      if (harnessVehicleSwitch === HARNESS_VIEW) {
        return 'Harness View'
      } else if (harnessVehicleSwitch === VEHICLE_VIEW) {
        return 'Vehicle View'
      }
    } else if (actionBarSelection === SELECTION_VALUES.pinout) {
      const isPinDestinationOpen =
        modalContainers &&
        modalContainers[0] === MODAL_EMBEDDED_MODE_PIN_DESTINATION
      // Modal open over table with pins
      if (isPinDestinationOpen) {
        return `Component Details - ${component.label}`
      }

      // Table with pins without filtering of session pins.
      if (showEmptyPins) {
        return `Pinout View - All Pins - ${component.label}`
      }

      // Table with pins with only session pins.
      return `Pinout View - Session Pins - ${component.label}`
    } else if (actionBarSelection === SELECTION_VALUES.repairHistory) {
      return 'Repair History'
    } else if (actionBarSelection === SELECTION_VALUES.switchTest) {
      return 'Switch Test'
    }
  }

  const panel = getPanel(props.actionBarSelection)
  const ScrollControls = getControls(props.actionBarSelection)
  return isLodaing ? (
    <Loading useBackgroundColor={false} />
  ) : noGraph ? (
    <NoGraph />
  ) : (
    <div className="embedded-mode-body">
      <div className="embedded-mode-header">
        <div className="embedded-mode-header-item">
          {getTitle(
            props.actionBarSelection,
            props.harnessVehicleSwitch,
            props.showEmptyPins,
            props.modalContainers,
            props.component,
          )}
        </div>
        <div className="embedded-mode-header-item">VIN: {props.vin}</div>
      </div>
      <div className="embedded-mode-container">
        <div className="embedded-mode-main">
          {getMainView(props.actionBarSelection)}
        </div>
        {panel && <>{panel}</>}
        <div className="embedded-mode-action-bar">
          <ActionBar>
            {panelRef && ScrollControls && (
              <ScrollControls scrollable={panelRef.current} />
            )}
          </ActionBar>
        </div>
      </div>
    </div>
  )
}

export default withRouter(VehicleTestResultEmbeddedMode)
