import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/api/',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.noCacheInterceptor,
)

export async function getConfigGuidesInfo() {
  const url = `/getting-started/guides-url`
  const { data } = await api.get(url)
  if (data) {
    return data
  }
  return null
}

export async function getGuidesRecord(username) {
  const url = `/getting-started/guides-records?username=` + username
  try {
    const { data } = await api.get(url)
    if (data) {
      return data
    }
  } catch (err) {
    //console.log(err);
  }

  return null
}

export async function saveGuidesRecord(username, fullname, data) {
  const url = `/getting-started/guides-records`
  const payload = { username: username, full_name: fullname, data: data }
  try {
    return await api.post(url, payload)
  } catch (err) {
    //console.log(err);
  }
  return null
}
