import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'

const VINSearchInputContext = createContext(null)
const VINSearchInputDispatchContext = createContext(null)

export function VINSearchInputProvider({ children }) {
  const [state, dispatch] = useImmerReducer(reducer, initial)

  return (
    <VINSearchInputContext.Provider value={state}>
      <VINSearchInputDispatchContext.Provider value={dispatch}>
        {children}
      </VINSearchInputDispatchContext.Provider>
    </VINSearchInputContext.Provider>
  )
}

export function useVINSearchInputContext() {
  return useContext(VINSearchInputContext)
}

export function useVINSearchInputDispatch() {
  const dispatch = useContext(VINSearchInputDispatchContext)

  const reset = () => dispatch({ type: 'reset' })

  const handleVINProvided = ({ inputVIN }) => {
    dispatch({
      type: 'input_vin_provided',
      inputVIN,
    })
  }

  const handleVINValidated = ({ validateStatus, errorMessage }) => {
    dispatch({
      type: 'vin_validated',
      validateStatus,
      errorMessage,
    })
  }

  const handleVINDebounced = ({ debouncedVIN }) => {
    dispatch({
      type: 'vin_debounced',
      debouncedVIN,
    })
  }

  const handleCompleteVINSearchResult = ({ completeVIN }) => {
    dispatch({
      type: 'complete_vin_search_executed',
      completeVIN,
    })
  }

  return {
    handleVINProvided,
    handleVINValidated,
    handleVINDebounced,
    handleCompleteVINSearchResult,
    reset,
  }
}

function reducer(draft, action) {
  switch (action.type) {
    case 'reset': {
      draft.debouncedVIN = initial.debouncedVIN
      draft.inputVIN = initial.inputVIN
      draft.validationResult = initial.validationResult
      draft.completeVIN = initial.completeVIN
      break
    }
    case 'input_vin_provided': {
      draft.completeVIN = initial.completeVIN
      draft.debouncedVIN = initial.debouncedVIN
      draft.inputVIN = action.inputVIN
      break
    }
    case 'vin_validated': {
      draft.validationResult.validateStatus = action.validateStatus
      draft.validationResult.errorMessage = action.errorMessage
      break
    }
    case 'vin_debounced': {
      draft.debouncedVIN = action.debouncedVIN
      break
    }
    case 'complete_vin_search_executed': {
      draft.completeVIN = action.completeVIN
      break
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initial = {
  completeVIN: null,
  inputVIN: null,
  debouncedVIN: null,
  validationResult: {
    validateStatus: null,
    errorMessage: null,
  },
}
