import {
  SET_CURRENT_VEHICLE_TEST_ID,
  SET_VEHICLE_TESTS,
  SET_VEHICLE_TESTS_LOADING,
} from '../actions/action-types'

export function setVehicleTestsLoading(isLoading = true) {
  return {
    type: SET_VEHICLE_TESTS_LOADING,
    isLoading,
  }
}

export function setVehicleTests(vehicleTests) {
  return {
    type: SET_VEHICLE_TESTS,
    vehicleTests,
  }
}

export function setCurrentVehicleTest(currentVehicleTest) {
  return {
    type: SET_CURRENT_VEHICLE_TEST_ID,
    currentVehicleTest,
  }
}
