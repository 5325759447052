import React from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Modal from 'react-modal'
import isEmpty from 'lodash/isEmpty'

import CloseButton from '../CloseButton'
import SelectWithRadioButtons from '../Fields/SelectWithRadioButtons'
import EmbeddedSelectList from '../../EmbeddedMode/Select'
import { MAX_NUMBER_OF_SUGGESTED_FAULTS } from '../../constants'
import { isEmbeddedMode } from '../../services/navigation'

const ComponentStateModal = (props) => {
  const {
    historyForComponent,
    intl,
    isOpen,
    faults,
    onClose,
    onSubmit,
    placeHolder,
    title,
  } = props

  const [annotation, setAnnotation] = React.useState('')
  const [reason, setReason] = React.useState(null)

  const location = useLocation()

  const getSuggestedFaultList = () => {
    if (isEmpty(historyForComponent)) return []
    const suggestedItems = Object.keys(historyForComponent).slice(
      0,
      MAX_NUMBER_OF_SUGGESTED_FAULTS,
    )
    const formattedItems = suggestedItems.map((reason) => ({
      value: reason,
      label: reason,
    }))
    return formattedItems
  }

  const renderFaultSelection = () => {
    const allOptions = faults.map((fault) => ({
      value: fault.key,
      label: fault.key,
    }))
    const suggestedOptions = getSuggestedFaultList()
    const options = { allOptions, suggestedOptions }

    return (
      <div className="row">
        <div>
          <label>
            <FormattedMessage id="tracer.logFailure.typeLabel" />
          </label>
          <SelectWithRadioButtons
            name="reason"
            options={options}
            placeholder={placeHolder}
            titleText={
              <FormattedMessage id="tracer.logFailure.applyRecentChanges" />
            }
            onChange={(reason) => setReason(reason)}
            selected={reason}
            classNamePrefix="fault-control"
          />
        </div>
      </div>
    )
  }

  const renderEmbedded = () => {
    const suggestedOptions = getSuggestedFaultList().map((option) => ({
      ...option,
      isSuggested: true,
    }))

    // Exclude suggested from all faults
    const otherOptions = faults
      .filter((fault) => {
        if (
          !suggestedOptions.some((suggested) => suggested.value === fault.key)
        ) {
          return fault
        }
      })
      .map((fault) => ({ value: fault.key, label: fault.key }))

    // Suggested should be on top
    const options = [...suggestedOptions, ...otherOptions]

    return (
      <div className="row">
        <EmbeddedSelectList
          options={options}
          selected={reason}
          onChange={setReason}
        />
      </div>
    )
  }

  const renderAnnotation = () => {
    return (
      <div className="row">
        <div>
          <label>
            <FormattedMessage id="tracer.logFailure.commentLabel" />
          </label>
          <textarea
            className="form-control annotation"
            name="annotation"
            component="textarea"
            placeholder={intl.formatMessage({
              id: 'tracer.logFailure.commentPlaceholder',
            })}
            onChange={(e) => setAnnotation(e.target.value)}
            value={annotation}
          />
        </div>
      </div>
    )
  }

  const onSubmitClick = (e) => {
    e.preventDefault()

    onSubmit({
      annotation: annotation ? annotation : null,
      reason: reason.value,
    })

    onClose()

    return false
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Modal"
    >
      <form onSubmit={onSubmitClick}>
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title-component">{title}</div>
                <div className="panel-title">
                  <FormattedMessage id="tracer.logFailure.title" />
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={onClose}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            {isEmbeddedMode(location.search) ? (
              renderEmbedded()
            ) : (
              <>
                {renderFaultSelection()}
                {renderAnnotation()}
              </>
            )}
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={onClose}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={isEmpty(reason)}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default injectIntl(ComponentStateModal)
