import React from 'react'

const IconRecenter = ({ onClick, cssClass }) => {
  return (
    <svg
      onClick={onClick}
      className={cssClass}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0H7V7L0 7V12H7H12V7V0ZM24 0H29V7L36 7V12H29H24V7V0ZM29 36H24V29V24H29H36V29H29V36ZM12 36H7V29H0V24H7H12V29V36ZM18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23Z"
        fill="white"
      />
    </svg>
  )
}

export default IconRecenter
