import React from 'react'
import PropTypes from 'prop-types'
import 'trix'
import 'trix/dist/trix.css'

// See: https://github.com/basecamp/trix
// for information about Trix editor.

// Usage:
//      const [editorData, setEditorData] = React.useState('Example')
//      <Editor id="editor" value={editorData} onChange={setEditorData} />
export const Editor = ({ value, onChange, id }) => {
  const trixEditor = React.useRef(null)

  React.useEffect(() => {
    if (!trixEditor.current) return
    if (trixEditor.current.inputElement.value === value) return
    trixEditor.current.value = value
  }, [value])

  React.useEffect(() => {
    const handleChange = (event) => onChange(trixEditor.current.value)
    const preventFileUpload = (event) => event.preventDefault()

    if (!trixEditor.current) return
    const trixEditorCurrent = trixEditor.current
    trixEditor.current.addEventListener('trix-file-accept', preventFileUpload)
    trixEditor.current.addEventListener('trix-change', handleChange)

    return () => {
      trixEditorCurrent.removeEventListener('trix-change', handleChange)
      trixEditorCurrent.removeEventListener(
        'trix-file-accept',
        preventFileUpload,
      )
    }
  }, [trixEditor, onChange])

  return React.createElement('trix-editor', { ref: trixEditor, id })
}

Editor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
