import React from 'react'
import Modal from 'react-modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ConnectorView from '../components/SvgViews/ConnectorView'
import { unsetModal } from '../actions/modal-actions'
import { ReactComponent as IconBack } from '../assets/images/icons/embedded/svg/back.svg'

function ConnectorViewEmbeddedMode() {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      isOpen: state.modalState.isOpen,
      pins: state.modalState.pins,
      imageUrl: state.modalState.imageUrl,
    }),
    shallowEqual,
  )

  const goBack = () => {
    dispatch(unsetModal())
  }

  return (
    <Modal
      isOpen={props.isOpen}
      className="embedded-mode-modal-connector-view"
      overlayClassName="embedded-mode-modal-overlay"
      contentLabel="Connector View Modal"
      onRequestClose={goBack}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <div className="controls" onClick={goBack}>
          <IconBack />
        </div>
        <div
          className="row"
          style={{ height: 'calc(100% - 60px)', width: '100%' }}
        >
          <ConnectorView
            pins={props.pins}
            imageUrl={props.imageUrl}
            panzoom={false}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConnectorViewEmbeddedMode
