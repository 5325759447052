import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetTerminology({ makeModelId, vin, language, codes }) {
  let params
  if (!!makeModelId) {
    params = new URLSearchParams({
      codes,
      language,
      make_model_id: makeModelId,
    })
  } else if (!!vin) {
    params = new URLSearchParams({ codes, language, vin })
  }
  return api
    .get(`/terminology?${params.toString()}`)
    .then((resp) => {
      const terms = resp.data['terminology']
      const result = {}
      terms.forEach((term) => {
        result[term['code']] = term
      })
      return result
    })
    .catch((e) => {
      return {}
    })
}
