import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetMemo(memoId) {
  return api.get(`/dtc/memo/${memoId}`).then((resp) => resp.data)
}

export function apiPatchMemo(memoId, data) {
  return api.patch(`/dtc/memo/${memoId}`, data).then((res) => res.data)
}

export function apiCreateMemo({
  dtc,
  acronym,
  memo,
  shortDescription: short_description,
  makeModelId: make_model_id,
}) {
  return api
    .post(`/dtc/memos`, {
      make_model_id,
      dtc,
      acronym,
      memo,
      short_description,
    })
    .then((res) => res.data)
}

// "query" has a shape:
//
//  {
//      "dtc_data": [
//          { make_model_id, dtc_name, dtc_acronym },
//          ...
//      ]
//  }
//
export function apiFindMemosForDTCs(makeModelId, modules) {
  const dtc_data = []

  for (const module of modules) {
    dtc_data.push(
      ...module.dtcs.map((dtc) => ({
        make_model_id: makeModelId,
        dtc_name: dtc.dtc_code,
        dtc_acronym: module.acronym,
      })),
    )
  }

  const url = '/dtc/memos/search'
  return api
    .post(url, { dtc_data })
    .then((resp) => resp.data)
    .catch((error) => console.error(`Error during POST to ${url}`))
}
