import React, { useRef, useState } from 'react'
import { useMeasure } from 'react-use'
import { Spin, Button, Space } from 'antd'
import { CarOutlined, ClusterOutlined } from '@ant-design/icons'
import SVG from 'react-inlinesvg'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useCDN } from '../hooks/CDN'
import { Controls } from '../BlockDiagram'
import { FormattedMessage } from 'react-intl'

const COMPONENT_IMAGE = 'COMPONENT_IMAGE'
const LOCATION_IMAGE = 'LOCATION_IMAGE'

export const ComponentOrLocationImage = ({
  componentImageName,
  locationImageName,
  componentId,
  pins,
  description,
  alias,
  children,
  isComponentImagePDBox = false,
  onFuseClick,
  onImageSwitch = null,
}) => {
  const [view, setView] = React.useState(LOCATION_IMAGE)

  return (
    <div className="functional-diagrams-component-or-location-view">
      <div className="functional-diagrams-component-or-location-view-image">
        {componentId ? (
          view === COMPONENT_IMAGE ? (
            <ComponentImage
              fileName={componentImageName}
              pins={pins}
              isComponentImagePDBox={isComponentImagePDBox}
              onFuseClick={onFuseClick}
            />
          ) : (
            <LocationImage
              fileName={locationImageName}
              componentId={componentId}
            />
          )
        ) : null}
      </div>
      <div className="functional-diagrams-component-or-location-view-ctrls">
        <div className="functional-diagrams-image-description">
          <Space direction="horizontal" size="large">
            <div>
              <h5>
                <FormattedMessage id="componentOrLocation.alias" />
              </h5>
              {alias}
            </div>
            <div>
              <h5>
                <FormattedMessage id="componentOrLocation.description" />
              </h5>
              {description}
            </div>
          </Space>
        </div>
        <div className="functional-diagrams-button-group">
          <div>{children}</div>
          <Button
            shape="round"
            ghost
            icon={
              view === COMPONENT_IMAGE ? <CarOutlined /> : <ClusterOutlined />
            }
            onClick={() => {
              if (view === COMPONENT_IMAGE) {
                setView(LOCATION_IMAGE)
              }
              if (view === LOCATION_IMAGE) {
                setView(COMPONENT_IMAGE)
              }
              if (onImageSwitch) {
                onImageSwitch()
              }
            }}
            data-testid="location-or-component-toggle"
          />
        </div>
      </div>
    </div>
  )
}

export const LocationImage = ({
  fileName,
  componentId,
  showControls = true,
  onSVGLoad = null,
  clickHandler = null,
  removeSelectionHandler = null,
}) => {
  const [selectedElementId, updateSelectedElementId] = useState(null)

  const onClick = (event) => {
    if (event.target.id && event.currentTarget.children.circles) {
      event.target.setAttribute('fill-opacity', '0.30')
      const allChildren = [...event.currentTarget.children.circles.children]
      const overlap = allChildren.filter((child) => {
        if (
          selectedElementId &&
          selectedElementId === child.id &&
          selectedElementId !== event.target.id
        ) {
          child.setAttribute('fill-opacity', '0')
        }
        const targetBBox = event.target.getBBox()
        const childBBox = child.getBBox()
        return (
          Math.abs(targetBBox.y - childBBox.y) <= targetBBox.height &&
          Math.abs(targetBBox.x - childBBox.x) <= targetBBox.width
        )
      })
      updateSelectedElementId(event.target.id)
      clickHandler(overlap, event.target.id)
    } else if (!event.target.id && event.currentTarget.children.circles) {
      const circle = event.currentTarget.getElementById(`${selectedElementId}`)
      if (circle) {
        circle.setAttribute('fill-opacity', '0')
      }
      updateSelectedElementId(null)
      removeSelectionHandler()
    }
  }

  const svgPostProcess = ({ svgRef }) => {
    svgRef.current.setAttribute('data-testid', 'location-image')
    const circle = svgRef.current.getElementById(`_${componentId}`)

    if (!circle) return
    //circle.setAttribute('r', 50)
    //circle.setAttribute('stroke-width', 5)
    circle.setAttribute('stroke-opacity', '1')
    circle.setAttribute('fill-opacity', '0.20')

    if (onSVGLoad) {
      const allChildren = [...svgRef.current.children.circles.children]
      const ids = allChildren.map((child) => child.id)
      onSVGLoad(ids)
    }
  }

  return (
    <Image
      key={`${fileName}-${componentId}`}
      fileName={fileName}
      svgPostProcess={svgPostProcess}
      showControls={showControls}
      clickHandler={clickHandler ? onClick : null}
    />
  )
}

export const ComponentImage = ({
  fileName,
  pins = [],
  showControls = true,
  isComponentImagePDBox = false,
  onFuseClick = null,
}) => {
  const selectedElement = useRef(null)

  const highlightFuses = (pins, svgRef) => {
    const fuseGroup = svgRef.current.getElementById(`FUSE`)

    if (fuseGroup && fuseGroup.childNodes) {
      fuseGroup.childNodes.forEach((element) => {
        element.style.pointerEvents = 'none'
      })
    }

    pins.forEach((pinNumber) => {
      const pinElement = svgRef.current.getElementById(`pin_${pinNumber}`)
      if (pinElement && pinElement.childNodes) {
        const pattern = /^fuse_.*/
        pinElement.childNodes.forEach((node) => {
          if (node.id && pattern.test(node.id)) {
            node.childNodes[0].setAttribute('fill', '#E04F4D')
            node.childNodes[0].setAttribute('stroke', 'white')
            node.childNodes[0].style.cursor = 'pointer'
          }
        })
      }
    })
  }

  const svgPostProcess = ({ svgRef }) => {
    svgRef.current.setAttribute('data-testid', 'component-image')
    pins.forEach((pinNumber) => {
      const fillArea = svgRef.current.getElementById(`pin_${pinNumber}_path`)

      if (!fillArea) return
      fillArea.setAttribute('fill', '#E04F4D')
      fillArea.setAttribute('stroke', 'white')

      const text = svgRef.current.getElementById(`pin_${pinNumber}_text`)
      if (!text) return
      text.setAttribute('fill', 'white')
      text.setAttribute('font-family', 'Montserrat')
    })

    if (isComponentImagePDBox) {
      highlightFuses(pins, svgRef)
    }
  }

  const onClick = (event) => {
    if (isComponentImagePDBox) {
      const pattern = /^fuse_.*/
      const parentID = event.target.parentElement.id
      const pinID = event.target.parentElement.parentElement.id.substring('4')
      if (selectedElement.current) {
        selectedElement.current.setAttribute('stroke', 'white')
      }
      if (pattern.test(parentID) && pins.includes(pinID)) {
        selectedElement.current = event.target
        event.target.setAttribute('stroke', '#40a9ff')
        onFuseClick(event)
      } else {
        onFuseClick(undefined)
      }
    }
  }

  return (
    <Image
      key={`${fileName}-${pins.join('-')}`}
      fileName={fileName}
      svgPostProcess={svgPostProcess}
      showControls={showControls}
      clickHandler={onClick}
    />
  )
}

const Image = ({
  fileName,
  svgPostProcess,
  clickHandler = () => {},
  showControls,
  ...props
}) => {
  const { isSuccess, cdn } = useCDN()
  const [loaded, setIsLoaded] = React.useState(false)
  const svgRef = React.useRef()
  const transformComponentRef = React.useRef()
  const [containerRef, { width, height }] = useMeasure()

  React.useEffect(() => {
    if (!svgRef.current || !loaded) return
    svgPostProcess({ svgRef })

    // const { zoomToElement } = transformComponentRef.current
    // zoomToElement("imgExample")
  }, [loaded])

  return (
    <div
      className="tracer-image-viewer"
      ref={containerRef}
      style={{ height: '100%', width: '100%' }}
    >
      {isSuccess && (
        <TransformWrapper
          wheel={{ step: 0.1 }}
          initialScale={1}
          limitToBounds={false}
          minScale={-2}
          panning={{ disabled: false }}
          ref={transformComponentRef}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              {showControls && (
                <Controls
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  resetTransform={resetTransform}
                />
              )}
              <TransformComponent
                wrapperStyle={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SVG
                  innerRef={svgRef}
                  onLoad={(src, hasCache) => setIsLoaded(src)}
                  onClick={clickHandler}
                  loader={
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  }
                  style={{
                    height: height,
                    width: width,
                    maxHeight: height,
                    maxWidth: width,
                  }}
                  src={`${cdn.imagesURL}/${fileName}`}
                  width={height}
                  height={width}
                  fetchOptions={{ credentials: 'include' }}
                  //viewBox={`0 0 ${width} ${height}`}
                  //preserveAspectRatio="xMidYMid meet"
                ></SVG>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </div>
  )
}
