import React from 'react'
import classnames from 'classnames'

export const ButtonSimple = ({ children, className, ...props }) => {
  const clazz = classnames('button-simple', className)

  return (
    <button className={clazz} type="button" {...props}>
      {children}
    </button>
  )
}
