import { apiGetMemo, apiPatchMemo, apiCreateMemo } from '../api/dtc-memo'
import { useQuery, useMutation, useQueryClient } from 'react-query'

const MEMO_QUERY_KEY = 'dtc-memo'

export const useDTCMemo = (memoId) => {
  const {
    status,
    data: dtcMemo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [MEMO_QUERY_KEY, memoId],
    async () => {
      const result = await apiGetMemo(memoId)

      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return { status, dtcMemo, error, isLoading, isFetching, isError, isSuccess }
}

export const useUpdateDTCMemo = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ memoId, memo, shortDescription, updatedBy }) =>
      apiPatchMemo(memoId, {
        memo,
        short_desc: shortDescription,
        updated_by: updatedBy,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([MEMO_QUERY_KEY, variables.memoId])
      },
    },
  )
}

export const useCreateDTCMemo = () => {
  const queryClient = useQueryClient()

  return useMutation(({ makeModelId, dtc, acronym, memo, shortDescription }) =>
    apiCreateMemo({ makeModelId, dtc, acronym, memo, shortDescription }),
  )
}
