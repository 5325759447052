// App reducer
export const SET_MAIN_DASHBOARD_PAGE_NUM = 'SET_MAIN_DASHBOARD_PAGE_NUM'
export const SET_VIN = 'SET_VIN'
export const RESET_VIN = 'RESET_VIN'

export const SET_EPHEMERAL_VEHICLE_TEST = 'SET_EPHEMERAL_VEHICLE_TEST'
export const UNSET_EPHEMERAL_VEHICLE_TEST = 'UNSET_EPHEMERAL_VEHICLE_TEST'

// Title reducer
export const SET_HTML_TITLE = 'SET_HTML_TITLE'

// Tab reducer
export const SELECT_NAVIGATION_TAB = 'SELECT_NAVIGATION_TAB'
export const ADD_NAVIGATION_TAB = 'ADD_NAVIGATION_TAB'
export const REMOVE_NAVIGATION_TAB = 'REMOVE_NAVIGATION_TAB'

// Component reducer
export const UPDATE_DTC_LIST = 'UPDATE_DTC_LIST'
export const SELECT_ELEMENT = 'SELECT_ELEMENT'
export const CENTER_ON_SELECTED_ELEMENT = 'CENTER_ON_SELECTED_ELEMENT'
export const SELECT_ELEMENT_BY_ID = 'SELECT_ELEMENT_BY_ID'
export const RESET_ELEMENT = 'RESET_ELEMENT'
export const SET_ALL_VEHICLE_DATA = 'SET_ALL_VEHICLE_DATA'
export const SET_COMPONENT_STATUS = 'SET_COMPONENT_STATUS'
export const SET_VERIFIED_COMPONENTS = 'SET_VERIFIED_COMPONENTS'
export const APPEND_VERIFIED_COMPONENT = 'APPEND_VERIFIED_COMPONENT'
export const REMOVE_VERIFIED_COMPONENT = 'REMOVE_VERIFIED_COMPONENT'
export const RESET_COMPONENTS = 'RESET_COMPONENTS'
export const SET_DTC_MEMOS_FOR_COMPONENTS = 'SET_DTC_MEMOS_FOR_COMPONENTS'

// DTC reducer
export const TOGGLE_ON_DTC = 'TOGGLE_ON_DTC'
export const TOGGLE_OFF_DTC = 'TOGGLE_OFF_DTC'
export const HIGHLIGHT_DTCS = 'HIGHLIGHT_DTCS'
export const UNHIGHLIGHT_DTCS = 'UNHIGHLIGHT_DTCS'
export const DTC_MEMO_SHOW_HIDE_EDIT = 'DTC_MEMO_SHOW_HIDE_EDIT'
export const DTC_MEMO_SHOW_TOOLTIP = 'DTC_MEMO_SHOW_TOOLTIP'
export const DTC_MEMO_HIDE_TOOLTIP = 'DTC_MEMO_HIDE_TOOLTIP'
export const DTC_MEMO_SHOW_POPUP = 'DTC_MEMO_SHOW_POPUP'
export const DTC_MEMO_HIDE_POPUP = 'DTC_MEMO_HIDE_POPUP'

// DTC mapping
export const DTC_MAPPING_RESET_STATE = 'DTC_MAPPING_RESET_STATE'
export const DTC_MAPPING_SET_SEARCH = 'DTC_MAPPING_SET_SEARCH'
export const DTC_MAPPING_SET_COMPONENTS = 'DTC_MAPPING_SET_COMPONENTS'
export const DTC_MAPPING_SET_SELECTED_COMPONENT_ID =
  'DTC_MAPPING_SET_SELECTED_COMPONENT_ID'
export const DTC_MAPPING_SET_SELECTED_COMPONENT =
  'DTC_MAPPING_SET_SELECTED_COMPONENT'
export const DTC_MAPPING_SET_ACTIVE_UI = 'DTC_MAPPING_SET_ACTIVE_UI'
export const DTC_MAPPING_RESET_COMPONENT = 'DTC_MAPPING_RESET_COMPONENT'

// Pin reducer
export const SELECT_PIN = 'SELECT_PIN'
export const CLEAR_SELECTED_PIN = 'CLEAR_SELECTED_PIN'
export const TOGGLE_SHOW_EMPTY_PINS = 'TOGGLE_SHOW_EMPTY_PINS'
export const SET_SHOW_EMPTY_PINS = 'SET_SHOW_EMPTY_PINS'
export const SET_ELEMENT_PINS = 'SET_ELEMENT_PINS'

// Pin Destination
export const CLEAR_PIN_DESTINATION = 'CLEAR_PIN_DESTINATION'
export const SELECT_ELEMENT_IN_PIN_DESTINATION =
  'SELECT_ELEMENT_IN_PIN_DESTINATION'
export const SET_PIN_DESTINATION = 'SET_PIN_DESTINATION'

// Modal reducer
export const SET_MODAL_CONTAINER = 'SET_MODAL_CONTAINER'
export const UNSET_MODAL_CONTAINER = 'UNSET_MODAL_CONTAINER'
export const SET_COMPONENT = 'SET_COMPONENT'
export const SET_CONNECTOR_IMAGE = 'SET_CONNECTOR_IMAGE'

export const SET_DEMO_PAYLOADS = 'SET_DEMO_PAYLOADS'

export const SET_COMPONENTS_FOR_MODEL = 'SET_COMPONENTS_FOR_MODEL'
export const SET_INLINE_COMPONENTS_FOR_MODEL = 'SET_INLINE_COMPONENTS_FOR_MODEL'

// Harnesses
export const SET_HARNESSES_FOR_MODEL = 'SET_HARNESSES_FOR_MODEL'
export const SET_INLINE_COMPONENTS = 'SET_INLINE_COMPONENTS'
export const CLEAR_INLINE_COMPONENTS = 'CLEAR_INLINE_COMPONENTS'

// Logging reducer
export const SET_CIRCUIT_CATEGORIES = 'SET_CIRCUIT_CATEGORIES'
export const SET_REPAIR_HISTORY = 'SET_REPAIR_HISTORY'
export const UPDATE_COMPONENT_STATE = 'UPDATE_COMPONENT_STATE'
export const UPDATE_HARNESS_STATE = 'UPDATE_HARNESS_STATE'
export const UPDATE_DEVICE_STATE = 'UPDATE_DEVICE_STATE'
export const DELETE_REPAIR_HISTORY_ITEM = 'DELETE_REPAIR_HISTORY_ITEM'
export const DELETE_EMPTY_REPAIR_HISTORY_ITEMS =
  'DELETE_EMPTY_REPAIR_HISTORY_ITEMS'
export const ADD_REPAIR_HISTORY_ITEM = 'ADD_REPAIR_HISTORY_ITEM'
export const SET_FAULT_CATEGORIES = 'SET_FAULT_CATEGORIES'
export const SET_HARNESS_LEVELS = 'SET_HARNESS_LEVELS'
export const SET_HISTORY_FOR_COMPONENT = 'SET_HISTORY_FOR_COMPONENT'
export const SET_BATCH_HISTORY_FOR_COMPONENTS =
  'SET_BATCH_HISTORY_FOR_COMPONENTS'
export const RESET_HISTORY_FOR_COMPONENT = 'RESET_HISTORY_FOR_COMPONENT'
export const ADD_APPLIED_COMPONENT = 'ADD_APPLIED_COMPONENT'
export const RESET_APPLIED_COMPONENTS = 'RESET_APPLIED_COMPONENTS'

// Notification reducer
export const SET_NOTIFICATION_CONTAINER = 'SET_NOTIFICATION_CONTAINER'
export const UNSET_NOTIFICATION_CONTAINER = 'UNSET_NOTIFICATION_CONTAINER'
export const SET_RETRY_TIME = 'SET_RETRY_TIME'
export const SET_RETRY_NOW = 'SET_RETRY_NOW'

// Right panel with list of DTCs
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE'

// Additional component fault menu show / hide
export const SET_ADDITIONAL_COMP_FAULT_MENU_VIS =
  'SET_ADDITIONAL_COMP_FAULT_MENU_VIS'
export const RESET_ADDITIONAL_COMP_FAULT_MENU =
  'RESET_ADDITIONAL_COMP_FAULT_MENU'

// Search for Vehicle Tests
export const SET_VEHICLE_TESTS = 'SET_VEHICLE_TESTS'
export const SET_VEHICLE_TESTS_LOADING = 'SET_VEHICLE_TESTS_LOADING'
export const SET_CURRENT_VEHICLE_TEST_ID = 'SET_CURRENT_VEHICLE_TEST_ID'

// Errors
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Auth Token
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'

// User Reducer
export const USER_LOADING = 'USER_LOADING'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

// Loading indicators
export const SET_LOADING = 'LOADING'
export const CLEAR_LOADING = 'CLEAR_LOADING'

// Popover
export const SHOW_COMPONENT_POPOVER = 'SHOW_COMPONENT_POPOVER'
export const HIDE_COMPONENT_POPOVER = 'HIDE_COMPONENT_POPOVER'

// Can't Fix Event
export const CLEAR_CANT_FIX_EVENT = 'CLEAR_CANT_FIX_EVENT'
export const SET_CANT_FIX_LOADING = 'SET_CANT_FIX_LOADING'
export const SET_CANT_FIX_NOT_LOADING = 'SET_CANT_FIX_NOT_LOADING'
export const SET_CANT_FIX_EVENT = 'SET_CANT_FIX_EVENT'
export const CANT_FIX_SUBMIT_START = 'CANT_FIX_SUBMIT_START'

// User management
export const SET_USERS_LIST = 'SET_USERS_LIST'
export const SET_MORE_LOADED_USERS = 'SET_MORE_LOADED_USERS'
export const SET_USERS_LIST_LOADING = 'SET_USERS_LIST_LOADING'
export const SET_MORE_USERS_LOADING = 'SET_MORE_USERS_LOADING'
export const SET_USER_LOADING = 'SET_USER_LOADING'
export const SET_USER_SAVING = 'SET_USER_SAVING'
export const SET_USER_SAVED = 'SET_USER_SAVED'
export const SET_USER_FOR_EDITING = 'SET_USER_FOR_EDITING'

// Search amongst diagnostic trouble code (DTC) memos
// DMS - DTC memos search
export const DMS_MODELS_DOWNLOAD_START = 'DMS_MODELS_DOWNLOAD_START'
export const DMS_SET_MODELS = 'DMS_SET_MODELS'
export const DMS_SELECT_MODEL = 'DMS_SELECT_MODEL'
export const DMS_EDIT_DTC_FIELD = 'DMS_EDIT_DTC_FIELD'
export const DMS_MEMOS_DOWNLOAD_START = 'DMS_MEMOS_DOWNLOAD_START'
export const DMS_SET_MEMOS = 'DMS_SET_MEMOS'
export const DMS_MEMOS_DOWNLOAD_ERROR = 'DMS_MEMOS_DOWNLOAD_ERROR'
export const DMS_SET_MEMO_EDIT = 'DMS_SET_MEMO_EDIT'

// DTC Memo add / edit
export const DTC_MEMO_DOWNLOAD_START = 'DTC_MEMO_DOWNLOAD_START'
export const DTC_MEMO_DOWNLOAD_END = 'DTC_MEMO_DOWNLOAD_END'
export const DTC_MEMO_DOWNLOAD_ERROR = 'DTC_MEMO_DOWNLOAD_ERROR'
export const DTC_MEMO_UPLOAD_START = 'DTC_MEMO_UPLOAD_START'
export const DTC_MEMO_UPLOAD_END = 'DTC_MEMO_UPLOAD_END'
export const DTC_MEMO_UPLOAD_ERROR = 'DTC_MEMO_UPLOAD_ERROR'
export const DTC_MEMO_EDIT = 'DTC_MEMO_EDIT'

// Embedded Mode UI
export const EMBEDDED_SET_ACTION_BAR_SELECTION =
  'EMBEDDED_SET_ACTION_BAR_SELECTION'
export const EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION =
  'EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION'
export const EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION_ON_PINOUT =
  'EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION_ON_PINOUT'
export const EMBEDDED_SET_DTC_LIST_SCROLL = 'EMBEDDED_SET_DTC_LIST_SCROLL'
export const EMBEDDED_SET_COMPONENT_LIST_SCROLL =
  'EMBEDDED_SET_COMPONENT_LIST_SCROLL'
export const EMBEDDED_SET_PINOUT_TABLE_SCROLL =
  'EMBEDDED_SET_PINOUT_TABLE_SCROLL'
export const EMBEDDED_SET_SWITCH_TEST_TABLE_SCROLL =
  'EMBEDDED_SET_SWITCH_TEST_TABLE_SCROLL'

export const ADD_FAULT_COMPONENT_RESET_STATE = 'ADD_FAULT_COMPONENT_RESET_STATE'
export const ADD_FAULT_COMPONENT_SET_SEARCH = 'ADD_FAULT_COMPONENT_SET_SEARCH'
export const ADD_FAULT_COMPONENT_SET_COMPONENTS =
  'ADD_FAULT_COMPONENT_SET_COMPONENTS'
export const ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT_ID =
  'ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT_ID'
export const ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT =
  'ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT'
export const ADD_FAULT_COMPONENT_RESET_COMPONENT =
  'ADD_FAULT_COMPONENT_RESET_COMPONENT'

export const ADD_FAULT_HARNESS_RESET_STATE = 'ADD_FAULT_HARNESS_RESET_STATE'
export const ADD_FAULT_HARNESS_SET_SEARCH = 'ADD_FAULT_HARNESS_SET_SEARCH'
export const ADD_FAULT_HARNESS_SET_HARNESSS = 'ADD_FAULT_HARNESS_SET_HARNESSS'
export const ADD_FAULT_HARNESS_SET_SELECTED_HARNESS =
  'ADD_FAULT_HARNESS_SET_SELECTED_HARNESS'
export const ADD_FAULT_HARNESS_RESET_HARNESS = 'ADD_FAULT_HARNESS_RESET_HARNESS'

export const ADD_FAULT_DEVICE_RESET_STATE = 'ADD_FAULT_DEVICE_RESET_STATE'
export const ADD_FAULT_DEVICE_SET_SEARCH = 'ADD_FAULT_DEVICE_SET_SEARCH'
export const ADD_FAULT_DEVICE_SET_DEVICES = 'ADD_FAULT_DEVICE_SET_DEVICES'
export const ADD_FAULT_DEVICE_SET_SELECTED_DEVICE =
  'ADD_FAULT_DEVICE_SET_SELECTED_DEVICE'
export const ADD_FAULT_DEVICE_RESET_DEVICE = 'ADD_FAULT_DEVICE_RESET_DEVICE'
