import * as types from '../actions/action-types'

export function toggleOnDtc(dtc) {
  return {
    type: types.TOGGLE_ON_DTC,
    dtc,
  }
}

export function toggleOffDtc() {
  return {
    type: types.TOGGLE_OFF_DTC,
  }
}

export function highlightDtcs(dtc) {
  return {
    type: types.HIGHLIGHT_DTCS,
    dtcs: Array.isArray(dtc) ? dtc : [dtc],
  }
}

export function unhighlightDtcs() {
  return {
    type: types.UNHIGHLIGHT_DTCS,
  }
}

export function showHideDtcMemoEdit() {
  return {
    type: types.DTC_MEMO_SHOW_HIDE_EDIT,
  }
}

export function showDtcMemoTooltip() {
  return {
    type: types.DTC_MEMO_SHOW_TOOLTIP,
  }
}

export function hideDtcMemoTooltip() {
  return {
    type: types.DTC_MEMO_HIDE_TOOLTIP,
  }
}

export function showDtcMemoPopup() {
  return {
    type: types.DTC_MEMO_SHOW_POPUP,
  }
}

export function hideDtcMemoPopup() {
  return {
    type: types.DTC_MEMO_HIDE_POPUP,
  }
}
