import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import history from '../history'
import IconHamburgerMenu from './Icons/IconHamburgerMenu'

const MAX_HEIGHT = 120 // px
const UPWARDS_PADDING = 5 // px

const propTypes = {
  menuOptions: PropTypes.array,
}

const defaultProps = {
  menuOptions: [],
}

function MoreMenu({ isOpen, setIsOpen, menuOptions, isDisabled = false }) {
  const moreMenuListNode = useRef()

  const getCoord = () => {
    if (moreMenuListNode && moreMenuListNode.current) {
      return moreMenuListNode.current.getBoundingClientRect()
    }
    return 0
  }

  useEffect(() => {
    const handleClickOutsideComponent = (event) => {
      if (
        moreMenuListNode &&
        isOpen &&
        !moreMenuListNode.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('touchstart', handleClickOutsideComponent, false)
    document.addEventListener('mousedown', handleClickOutsideComponent, false)

    const unlistenToHistory = history.listen((location, action) => {
      if (isOpen) {
        setIsOpen(false)
      }
    })

    return () => {
      document.removeEventListener(
        'touchstart',
        handleClickOutsideComponent,
        false,
      )
      document.removeEventListener(
        'mousedown',
        handleClickOutsideComponent,
        false,
      )
      unlistenToHistory()
    }
  }, [isOpen, setIsOpen])

  const menuCoord = getCoord()
  const menuCoordTopOffset = menuCoord.top + menuCoord.height
  let menuListStyle = { top: `${menuCoordTopOffset}px` }
  // Show menu upwards when it is too low on screen.
  if (
    menuCoordTopOffset + menuOptions.length * MAX_HEIGHT >
    window.innerHeight
  ) {
    menuListStyle = {
      bottom: `${window.innerHeight - menuCoord.top + UPWARDS_PADDING}px`,
    }
  }
  const clazz = classNames('more-menu', {
    active: isOpen,
    disabled: isDisabled,
  })

  return (
    <div className={clazz} ref={moreMenuListNode}>
      <div
        className="more-menu-icon"
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
      >
        <IconHamburgerMenu />
      </div>
      {isOpen ? (
        <ol
          className="more-menu-list"
          style={menuListStyle}
          data-testid="more-menu-list"
        >
          {menuOptions.map((option, index) => (
            <li key={index} className="more-menu-option">
              {option}
            </li>
          ))}
        </ol>
      ) : null}
    </div>
  )
}

MoreMenu.propTypes = propTypes
MoreMenu.defaultProps = defaultProps

export default MoreMenu
