import React from 'react'
import './style.scss'

const Divider = ({ text }) => {
  return (
    <div className="divider">
      {text && <span className="divider-inner-text">{text}</span>}
    </div>
  )
}

export default Divider
