import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SvgEmptyCircle from './SvgEmptyCircle'
import PinNumber from './PinNumber'
import classNames from 'classnames'

const propTypes = {
  incoming: PropTypes.bool,
  number: PropTypes.number,
  displayNumber: PropTypes.bool,
  pinEmptyClassName: PropTypes.string,
}

const defaultProps = {
  displayNumber: true,
  pinEmptyClassName: 'pin text-center',
}

class PinEmpty extends Component {
  render() {
    const pinEmptyClassNames = classNames(this.props.pinEmptyClassName, {
      invisible: !this.props.displayNumber,
    })

    return (
      <div className={pinEmptyClassNames}>
        <PinNumber
          number={this.props.number}
          visible={this.props.displayNumber}
          location={this.props.incoming ? 'top' : 'bottom'}
        >
          <SvgEmptyCircle />
        </PinNumber>
      </div>
    )
  }
}

PinEmpty.propTypes = propTypes
PinEmpty.defaultProps = defaultProps

export default PinEmpty
