import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMeasure } from 'react-use'
import { connect } from 'react-redux'

import { getElementName } from '../../helpers/element'
import Header from '../Header'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import SvgViewer from './SvgViewer'
import IconNoImage from '../../components/Icons/IconNoImage'
import { useCDN } from '../../hooks/CDN'
import './style.scss'
import { ConnectorPreview } from '../ConnectorPreview'

export function Topology({
  component,
  zoomInControl,
  zoomOutControl,
  zoomResetControl,
  panControls,
  zoomScaleSensitivity = 0.2,
  minZoomLevel = 0.3,
  panEnabled = true,
  zoomEnabled = true,
}) {
  const { isSuccess, cdn } = useCDN()
  const [circle, setCircle] = React.useState(null)
  const [circleRadius, setCircleRadius] = React.useState(null)
  const [circleStroke, setCircleStroke] = React.useState(null)
  const [svg, setSvg] = React.useState(null)
  const [panZoom, setPanZoom] = React.useState(null)
  const [measureRef, { width, height }] = useMeasure()
  const [zoom, setZoom] = React.useState(1)

  React.useEffect(() => {
    if (!svg) return
    if (!component) return
    const circle = findCircleElement({ svg, component })
    if (circle) {
      setCircleRadius(Number(circle.getAttribute('r')) * 3)
      setCircleStroke(Number(circle.getAttribute('stroke-width')) * 2)
      setCircle(circle)
    }
  }, [svg, component])

  React.useEffect(() => {
    if (!circle) return
    circle.setAttribute('stroke-opacity', '1')
    circle.setAttribute('fill-opacity', '0.20')
  }, [circle])

  React.useEffect(() => {
    if (circle) {
      circle.setAttribute('r', circleRadius / zoom)
      circle.setAttribute('stroke-width', circleStroke / zoom)
    }
  }, [circle, circleRadius, circleStroke, zoom])

  const ZoomIn = zoomInControl || ZoomInControl
  const ZoomOut = zoomOutControl || ZoomOutControl
  const ZoomReset = zoomResetControl || ZoomResetControl
  const PanControls = panControls

  const getFileName = () => {
    if (component.isEdge) {
      return component.image_file_name
    } else if (component.isNode) {
      return component.images.vehicle_file_name
    }
    return null
  }

  const getTitle = () => {
    if (component.isNode) {
      return getElementName(component)
    } else if (component.isEdge) {
      return component.harness_id
    }
    return null
  }

  const getSubTitle = () => {
    if (component.isNode) {
      return null
    } else if (component.isEdge) {
      return component.level
    }
    return null
  }

  const getErrorComponent = () => {
    return (
      <div className="error-message">
        <IconNoImage />
        <h2>
          <FormattedMessage id="tracer.vehicleView.errorComponent.header" />
        </h2>
        <p>
          <FormattedMessage
            id="tracer.vehicleView.errorComponent.message"
            values={{ component: getElementName(component) }}
          />
        </p>
      </div>
    )
  }

  /**
   * Center on the selected component in the rendered SVG
   */
  const center = () => {
    const sizes = panZoom.getSizes()
    if (circle) {
      panZoom.pan({
        x:
          -(parseFloat(circle.getAttribute('cx')) * sizes.realZoom) + width / 2,
        y:
          -(parseFloat(circle.getAttribute('cy')) * sizes.realZoom) +
          height / 2,
      })
    } else {
      panZoom.resize()
      panZoom.fit()
      panZoom.center()
    }
  }

  const findCircleElement = ({ svg, component }) => {
    const ids = [component.id, component.sibling]
    for (const id of ids) {
      const circleElementId = `_${id}`
      const circleElement = svg.getElementById(circleElementId)
      if (circleElement) {
        return circleElement
      }
    }
    return null
  }

  const onBeforeZoom = (prevZoom, newZoom) => {
    setZoom(newZoom)
  }

  const onSvgReady = (svg, panZoom) => {
    // Max out the size of the svg container
    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    // Register panzoom events
    panZoom.setBeforeZoom(onBeforeZoom)
    panZoom.setZoomScaleSensitivity(zoomScaleSensitivity)
    panZoom.setMinZoom(minZoomLevel)

    // Center the SVG and have it fit its container
    panZoom.resize()
    panZoom.fit()
    panZoom.center()

    setSvg(svg)
    setPanZoom(panZoom)
  }

  /**
   * Zoom in using the on-screen control.
   */
  const zoomIn = () => {
    panZoom.zoomIn()
  }

  /**
   * Zoom out using the on-screen control.
   */
  const zoomOut = () => {
    panZoom.zoomOut()
  }

  const panRight = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: -step,
      y: 0,
    })
  }

  const panLeft = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: step,
      y: 0,
    })
  }

  const panDown = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: 0,
      y: -step,
    })
  }

  const panUp = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: 0,
      y: step,
    })
  }

  return (
    <>
      {isSuccess && component && (
        <div id="topology">
          <Header
            title={getTitle()}
            extendedtitle={getSubTitle()}
            subtitle={component.description}
          />
          <div
            ref={measureRef}
            id="topology-svg-container"
            className="overflow-hidden"
          >
            <ConnectorPreview
              title={component?.alias}
              colorCode={component?.color}
              fileName={component?.images?.connector_file_name}
            />
            <SvgViewer
              errorComponent={getErrorComponent()}
              key={component.id}
              src={`${cdn.imagesURL}/${getFileName()}`}
              onReady={onSvgReady}
              panzoom={true}
              panEnabled={panEnabled}
              zoomEnabled={zoomEnabled}
            />
          </div>
          <>
            {ZoomIn && <ZoomIn zoomIn={zoomIn} />}
            {ZoomReset && <ZoomReset zoomReset={center} />}
            {ZoomOut && <ZoomOut zoomOut={zoomOut} />}
            {PanControls && (
              <PanControls
                left={panLeft}
                right={panRight}
                up={panUp}
                down={panDown}
              />
            )}
          </>
        </div>
      )}
      {!component && (
        <div id="topology">
          <Header title="" subtitle="" />
          <div id="topology-svg-container" className="overflow-hidden">
            <div style={{ width: '100%', height: '100%' }}>
              <div className="error-message">
                <IconNoImage />
                <h2>
                  <FormattedMessage id="tracer.vehicleView.noComponent.header" />
                </h2>
                <p>
                  <FormattedMessage id="tracer.vehicleView.noComponent.message" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    component: ownProps.component || state.componentState.selectedElement,
  }
}

export default connect(mapStateToProps)(Topology)
