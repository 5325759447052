import React from 'react'
import Select from 'react-select'
import { Field } from 'redux-form'
import isArray from 'lodash/isArray'
import ErrorMessage from './ErrorMessage'
import PropTypes from 'prop-types'
import _ from 'lodash'
class SelectOption extends React.Component {
  handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onSelect(this.props.option, event)
  }
  handleMouseEnter = (event) => this.props.onFocus(this.props.option, event)
  handleMouseMove = (event) => {
    if (this.props.isFocused) return
    this.props.onFocus(this.props.option, event)
  }

  render() {
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        key={this.props.option.id}
      >
        <div id={this.props.option.id}>
          <div className="select-option-label">{this.props.option.label}</div>
          <div className="select-option-description">
            {this.props.option.description}
          </div>
        </div>
      </div>
    )
  }
}

SelectOption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired,
}

function filterOption(option, filter) {
  const contains = (filterLowered, value, description) =>
    _.includes((value || '').toLowerCase(), filterLowered) ||
    _.includes((description || '').toLowerCase(), filterLowered)

  if (!filter) return true
  return contains(filter.toLowerCase(), option.value, option.description)
}

function component(props, field) {
  const { input, meta } = field

  return (
    <div>
      <Select
        {...props}
        value={input.value}
        onChange={input.onChange}
        optionComponent={SelectOption}
        filterOption={filterOption}
      />
      <ErrorMessage meta={meta} />
    </div>
  )
}

export default function (props) {
  let validators = null
  const componentWithProps = component.bind(null, props)

  if (!props.disabled && props.validate) {
    validators = isArray(props.validate) ? props.validate : [props.validate]
  }

  return (
    <Field
      name={props.name}
      validate={validators}
      component={componentWithProps}
      onChange={props.onChange}
    />
  )
}
