import * as types from '../actions/action-types'

const initialState = {
  search: '',
  components: null,
  selectedComponentId: '',
  selectedComponent: {},
}

const addFaultComponentReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_FAULT_COMPONENT_RESET_STATE:
      return Object.assign({}, initialState)
    case types.ADD_FAULT_COMPONENT_SET_SEARCH:
      return Object.assign({}, state, { search: action.search })
    case types.ADD_FAULT_COMPONENT_SET_COMPONENTS:
      return Object.assign({}, state, { components: action.components })
    case types.ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT_ID:
      return Object.assign({}, state, {
        selectedComponentId: action.selectedComponentId,
      })
    case types.ADD_FAULT_COMPONENT_SET_SELECTED_COMPONENT:
      return Object.assign({}, state, {
        selectedComponent: action.selectedComponent,
      })
    case types.ADD_FAULT_COMPONENT_RESET_COMPONENT:
      return Object.assign({}, state, {
        selectedComponent: initialState.selectedComponent,
        selectedComponentId: initialState.selectedComponentId,
      })
    default:
      return state
  }
}

export default addFaultComponentReducer
