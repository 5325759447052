import React from 'react'

const defaultToggles = {
  dtc: true,
  components: true,
  pinout: true,
  repairHistory: false,
  switchTest: true,
}

const FeatureTogglesContext = React.createContext(defaultToggles)

function FeatureTogglesProvider({ children }) {
  return (
    <FeatureTogglesContext.Provider value={defaultToggles}>
      {children}
    </FeatureTogglesContext.Provider>
  )
}

function useFeatureToggles() {
  const context = React.useContext(FeatureTogglesContext)
  if (context === undefined) {
    throw new Error(
      'useFeatureToggles must be used within a FeatureTogglesProvider',
    )
  }
  return context
}

export { FeatureTogglesProvider, useFeatureToggles }
