import * as types from '../actions/action-types'

export const modelsDownloadStart = () => ({
  type: types.DMS_MODELS_DOWNLOAD_START,
})

export const setModels = (models) => ({ type: types.DMS_SET_MODELS, models })

export const selectModel = (model) => ({ type: types.DMS_SELECT_MODEL, model })

export const editDTCField = (dtc) => ({ type: types.DMS_EDIT_DTC_FIELD, dtc })

export const memosDownloadStart = () => ({
  type: types.DMS_MEMOS_DOWNLOAD_START,
})

export const setMemos = (memos) => ({ type: types.DMS_SET_MEMOS, memos })

export const memosDownloadError = () => ({
  type: types.DMS_MEMOS_DOWNLOAD_ERROR,
})

export const setMemoEdit = (memoId) => ({
  type: types.DMS_SET_MEMO_EDIT,
  memoId,
})
