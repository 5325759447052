import { SET_HTML_TITLE } from './action-types'
import { defaultTitle } from '../reducers/title-reducer'

export function setHtmlTitle(title) {
  return {
    title,
    type: SET_HTML_TITLE,
  }
}

export function setHtmlTitleToDefault() {
  return {
    title: defaultTitle,
    type: SET_HTML_TITLE,
  }
}
