import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { push } from 'connected-react-router'
import React from 'react'
import get from 'lodash/get'
import { getUser } from '../api/auth-api'
import Loading from '../components/Loading'
import store from '../store'

const isLoading = (user) => get(user, 'isLoading', false)
export const isAuthenticated = (user) => {
  const rules = get(user, 'data.rules', [])
  if (!rules) {
    return false
  }

  return rules.length > 0
}

const directToLogin = () =>
  store.dispatch(
    push(`/login?redirect_url=${encodeURIComponent(window.location.pathname)}`),
  )

export function requireAuthentication(WrappedComponent) {
  const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user,
    }
  }
  const RequireAuthWrapper = class extends React.Component {
    componentDidMount() {
      const userData = get(this.props.user, 'data')
      if (!userData) {
        getUser()
      }
      this.handleAuth()
    }

    componentDidUpdate() {
      this.handleAuth()
    }

    handleAuth() {
      if (!isLoading(this.props.user) && !isAuthenticated(this.props.user)) {
        directToLogin()
      }
    }

    render() {
      if (isLoading(this.props.user)) {
        return (
          <Loading
            backgroundColor=""
            text={<FormattedMessage id="processes.authenticating" />}
          />
        )
      } else if (!isAuthenticated(this.props.user)) {
        return (
          <Loading
            backgroundColor=""
            text={<FormattedMessage id="processes.authenticating" />}
          />
        )
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  }
  return connect(mapStateToProps)(RequireAuthWrapper)
}
