import React from 'react'
import { Spin } from 'antd'

import './style.scss'

export default function Loading() {
  return (
    <div className="functional-diagrams-loading">
      <Spin size="large" />
    </div>
  )
}
