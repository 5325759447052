import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Tab from './Tab'

const propTypes = {
  tabs: PropTypes.array.isRequired,
  changeSidebarState: PropTypes.bool,
}

class NavigationTabs extends Component {
  render() {
    // TODO: should we add something to the tab to show that it has been favorited?
    const { tabs, namespace, intl, changeSidebarState } = this.props
    return (
      <form className="navbar-form form-inline">
        <div className="btn-group btn-group-justified" role="group">
          {tabs.map((tab) => {
            const intlId = `tracer.${tab}.tabName`
            const text = intl.formatMessage({ id: intlId })
            return (
              <Tab
                key={tab}
                namespace={namespace}
                id={tab}
                text={text}
                changeSidebarState={changeSidebarState}
              />
            )
          })}
        </div>
      </form>
    )
  }
}

NavigationTabs.propTypes = propTypes

const mapStateToProps = (state, { namespace }) => {
  const defaultTabs = []
  const tabs = get(state.tabState.tabs, namespace, defaultTabs)

  return {
    tabs,
  }
}

export default injectIntl(connect(mapStateToProps)(NavigationTabs))
