import React, { Component } from 'react'

class SvgEmptyCircle extends Component {
  render() {
    return (
      <svg
        width="25px"
        height="25px"
        viewBox="33 153 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect id="path-1" x="34" y="154" width="24" height="24" rx="12" />
          <mask
            id="mask-2"
            maskContentUnits="userSpaceOnUse"
            maskUnits="objectBoundingBox"
            x="0"
            y="0"
            width="24"
            height="24"
            fill="#7c8d98"
          >
            <use xlinkHref="#path-1" />
          </mask>
        </defs>
        <use
          id="Input-Copy-7"
          stroke="#7c8d98"
          mask="url(#mask-2)"
          strokeWidth="4"
          fill="none"
          xlinkHref="#path-1"
        />
        <path
          d="M35,174.986091 L54.9860906,155"
          id="Path-2-Copy"
          stroke="#7c8d98"
          strokeWidth="3"
          fill="none"
        />
      </svg>
    )
  }
}

export default SvgEmptyCircle
