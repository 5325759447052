import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import './style.scss'
import HarnessViewGraphContainer from '../../containers/HarnessViewGraphContainer'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import VehicleTestResultNavigation from '../VehicleTestResultNavigation'
import Button from '../Buttons/Button'
import { setModal } from '../../actions/modal-actions'
import { MODAL_ADD_FAULT, ROOT_CAUSE_QUERY_PARAM } from '../../constants'

const HarnessView = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    if (params.has(ROOT_CAUSE_QUERY_PARAM)) {
      dispatch(setModal(MODAL_ADD_FAULT))
    }
  }, [location.search])

  const graphNodes = useSelector(
    (state) => state.componentState.graphData.nodes,
    shallowEqual,
  )
  const vehicleTestResultId = useSelector(
    (state) => state.componentState.vehicleTestResultId,
  )
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )

  if (isEmpty(graphNodes)) return null

  return (
    <>
      <HarnessViewGraphContainer
        zoomInControl={ZoomInControl}
        zoomOutControl={ZoomOutControl}
        zoomResetControl={ZoomResetControl}
      />
      {!isEphemeralVehicleTest && (
        <>
          <VehicleTestResultNavigation
            vehicleTestResultId={vehicleTestResultId}
          />
          <Button
            onClick={() => dispatch(setModal(MODAL_ADD_FAULT))}
            dataTestId="harness-view-add-fault-button"
            className="harness-view-add-fault"
          >
            <FormattedMessage id="repairHistory.titles.addFault" />
          </Button>
        </>
      )}
    </>
  )
}

export default HarnessView
