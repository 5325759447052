import { connect } from 'react-redux'
import { unsetModal } from '../actions/modal-actions'
import SameIssuesHistory from '../components/SameIssuesHistory'
import { getElementName } from '../helpers/element'
import { getHistoryForAllComponents, getDtcsSet } from '../selectors'
import FaultsService from '../services/faults'

const prepareComponentForVehicleView = (component) => ({
  id: component.id,
  title: getElementName(component),
  description: component.description,
  isNode: component.isNode,
  images: component.images,
  sibling: component.sibling,
})

const hasHistoryFactory = (historiesForComponents) => (component) => {
  return !!historiesForComponents[component.id]
}

const mapStateToProps = (state) => {
  const { elements: currentComponents } = state.componentState
  const allHistoriesForComponents = getHistoryForAllComponents(state)
  const currentDtcsSet = getDtcsSet(state)
  const currentComponentsId = currentComponents.map((component) => component.id)
  const matchedHistoriesForComponents = FaultsService.filterMatchedByDtcsFaults(
    allHistoriesForComponents,
    currentDtcsSet,
    currentComponentsId,
  )
  const flattenedHistory = FaultsService.flattenHistory(
    matchedHistoriesForComponents,
  )
  const sortedByMostRecent = FaultsService.sortByMostRecent(flattenedHistory)
  const hasHistory = hasHistoryFactory(matchedHistoriesForComponents)
  const componentsWithHistory = currentComponents
    .filter(hasHistory)
    .map(prepareComponentForVehicleView)

  return {
    tz: state.user.businessTimezone,
    isOpen: state.modalState.isOpen,
    components: componentsWithHistory,
    historiesForComponents: sortedByMostRecent,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(unsetModal())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SameIssuesHistory)
