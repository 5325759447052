import React from 'react'
import PropTypes from 'prop-types'
import IconPlus from '../components/Icons/IconPlus'
import IconMinus from '../components/Icons/IconMinus'
import IconRecenter from '../components/Icons/IconRecenter'
import classNames from 'classnames'

export const ZoomResetControlEmbedded = ({ zoomReset, disabled }) => {
  const onClick = () => {
    if (disabled) return
    zoomReset()
  }

  const className = classNames('zoom-ctrl zoom-reset-ctrl-embedded', {
    disabled: disabled,
  })

  return (
    <div className={className}>
      <a className="btn" onClick={onClick} disabled={disabled}>
        <IconRecenter />
      </a>
    </div>
  )
}

ZoomResetControlEmbedded.propTypes = {
  zoomReset: PropTypes.func,
}

export const ZoomInControlEmbedded = ({ zoomIn, disabled }) => {
  const onClick = () => {
    if (disabled) return
    zoomIn()
  }

  const className = classNames('zoom-ctrl zoom-in-ctrl-embedded', {
    disabled: disabled,
  })

  return (
    <div className={className}>
      <a className="btn" onClick={onClick}>
        <IconPlus />
      </a>
    </div>
  )
}

ZoomInControlEmbedded.propTypes = {
  zoomIn: PropTypes.func,
}

export const ZoomOutControlEmbedded = ({ zoomOut, disabled }) => {
  const onClick = () => {
    if (disabled) return
    zoomOut()
  }

  const className = classNames('zoom-ctrl zoom-out-ctrl-embedded', {
    disabled: disabled,
  })

  return (
    <div className={className}>
      <a className="btn" onClick={onClick}>
        <IconMinus />
      </a>
    </div>
  )
}

ZoomOutControlEmbedded.propTypes = {
  zoomOut: PropTypes.func,
}
