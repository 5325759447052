import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import get from 'lodash/get'

import { useCantFix, useAddCantFix, useDeleteCantFix } from '../hooks/CantFix'
import { setModal } from '../actions/modal-actions'
import { MODAL_CANT_FIX } from '../constants'

import Button from './Button'

function CantFixWidget({ vehicleTestId, openCantFixPopup }) {
  const [status, cantFix, error, isFetching, isLoading, isIdle] =
    useCantFix(vehicleTestId)
  const addCantFix = useAddCantFix()
  const deleteCantFix = useDeleteCantFix()

  const wrapperClasses = classnames('button-container')

  const handleClick = () => {
    if (!!cantFix) {
      return () => deleteCantFix.mutate({ vehicleTestId })
    } else {
      return openCantFixPopup
    }
  }

  return (
    <div className={wrapperClasses} style={{ marginBottom: '20px' }}>
      <Button
        handleClick={handleClick()}
        uppercase={true}
        small={true}
        wrapperClass="btn-cantfix"
        loading={isLoading || isFetching || deleteCantFix.isLoading}
      >
        <FormattedMessage id="cantFix.button" />
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
  }
}

const mapDispatchToProps = (dispatch) => ({
  openCantFixPopup: () => dispatch(setModal(MODAL_CANT_FIX)),
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CantFixWidget),
)
