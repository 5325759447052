import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Modal from 'react-modal'
import history from '../history'
import { useUserPlant, useUserInfo } from '../hooks/User'
import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR, GRAY_COLOR } from '../constants'

import CloseButton from './CloseButton'

const SelectPlant = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [userPlant, setUserPlant] = useUserPlant()
  const [, , remove] = useLocalStorage('reports-query')
  const { isLoading, userInfo } = useUserInfo()
  const [selectedPlant, selectPlant] = useState(userPlant)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Select Plant"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          remove()
          setUserPlant(selectedPlant)
          closeModal()
          if (location.pathname == '/reports') history.go(0)
        }}
        id="select-plant-form"
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage id="selectPlant.title" />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row" style={{ color: GRAY_COLOR }}>
              <FormattedMessage id="selectPlant.leadingText" />
            </div>
            <div className="row">
              <div className="btn-group-vertial" role="group">
                {isLoading && (
                  <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                )}
                {userInfo?.plants.map((availablePlant) => {
                  return (
                    <div
                      className="radio"
                      key={availablePlant}
                      id={availablePlant}
                    >
                      <label style={{ color: BLACK_COLOR }}>
                        <input
                          type="radio"
                          name="selectPlant"
                          id={availablePlant}
                          value={availablePlant}
                          checked={availablePlant == selectedPlant}
                          onChange={() => selectPlant(availablePlant)}
                        />
                        {availablePlant}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={selectedPlant === null}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectPlant),
)
