import React from 'react'
import BaseNode from './BaseNode'
import Icon from '../icons/DeviceIcon'

export default function DeviceNode({
  node,
  onPortClick,
  onNodeClick,
  ...props
}) {
  return (
    <BaseNode {...props} onPortClick={onPortClick} onNodeClick={onNodeClick}>
      {(event) => {
        return <Icon node={node} />
      }}
    </BaseNode>
  )
}
