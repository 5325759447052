import React, { Component } from 'react'
import { Formik } from 'formik'
import { injectIntl, FormattedMessage } from 'react-intl'
import { DisabledField, RoleField, PlantField, Buttons } from './Fields'

const getUserPlant = (user) =>
  user?.plants?.length > 0 ? user.plants[0] : 'none'

class EditUser extends Component {
  requestUserEdition = (formValues) => {
    const { updateUser } = this.props
    const userData = {
      role: formValues.role.value,
      disabled: formValues.disabled.value,
      plant: formValues.plant.map((p) => p.value),
    }
    updateUser(userData)
  }

  validateForm = (values) => {
    const errors = {}
    if (!values.role || values.role.length === 0) {
      errors.role = 'Required'
    }

    if (!errors.role && !values.plant) {
      errors.plant = 'Required'
    }
    return errors
  }

  render() {
    const { roles, plants, isSaving, isLoading, editingUser } = this.props
    const roleOptions = roles.map((role) => ({ value: role, label: role }))
    const plantOptions = plants.map((plant) => ({ value: plant, label: plant }))
    const disabledOptions = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ]
    const initialValues = {
      role: { value: editingUser.role, label: editingUser.role },
      plant: [],
      disabled: {
        value: editingUser.disabled,
        label: editingUser.disabled ? 'Yes' : 'No',
      },
    }
    if (isLoading) {
      return <FormattedMessage id="processes.loading" />
    }

    return (
      <div id="user-edit-form">
        <h1>Edit user {editingUser.email}</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={this.requestUserEdition}
          validate={this.validateForm}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            let plantVal
            if (Array.isArray(values.plant.value)) {
              plantVal = values.plant.value.map((p) => {
                return { label: p, value: p }
              })
            } else {
              plantVal = values.plant
            }
            return (
              <form onSubmit={handleSubmit}>
                <RoleField
                  options={roleOptions}
                  value={values.role}
                  onChange={(option) => setFieldValue('role', option)}
                  error={touched.role && errors.role}
                />

                <PlantField
                  role={values.role.value}
                  options={plantOptions}
                  onChange={(option) => setFieldValue('plant', option)}
                  value={plantVal}
                  error={touched.plant && errors.plant}
                />

                <DisabledField
                  options={disabledOptions}
                  onChange={(option) => setFieldValue('disabled', option)}
                  value={values.disabled}
                />

                <Buttons isSaving={isSaving} />
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default injectIntl(EditUser)
