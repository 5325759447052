import React from 'react'

import { WHITE_COLOR } from '../../constants'

const defaultProps = {
  color: WHITE_COLOR,
}

const IconHamburgerMenu = (props) => {
  const viewBox = `2 2 16 16`
  return (
    <svg
      height="100%"
      width="100%"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={props.color} fillRule="evenodd" transform="translate(8 2)">
        <circle cx="2" cy="2" r="2" />
        <circle cx="2" cy="8" r="2" />
        <circle cx="2" cy="14" r="2" />
      </g>
    </svg>
  )
}

IconHamburgerMenu.defaultProps = defaultProps

export default IconHamburgerMenu
