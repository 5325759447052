import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import filter from 'lodash/filter'
import { isEdge } from '../helpers/element'
import _ from 'lodash'

export const getHighlightedDTCs = (state) => state.dtcState.highlightedDtcs
export const getCurrentDTC = (state) => state.dtcState.toggledOnDtc
export const getSelectedElement = (state) =>
  state.componentState.selectedElement
export const getModToCompIdx = (state) => state.componentState.modToCompIdx
export const getModules = (state) => state.componentState.modules
export const getEdges = (state) => state.componentState.graphData.edges
export const getModalStateComponent = (state) => state.modalState.component
export const getHistoryForAllComponents = (state) =>
  state.loggingState.historiesForComponents
export const createConnectorSetFromHiglightedDtcs = createSelector(
  [getHighlightedDTCs, getModToCompIdx],
  (highlightedDTCs, modToCompIdx) => {
    if (!isEmpty(highlightedDTCs)) {
      return reduce(
        highlightedDTCs,
        (acc, highlightedDTC) => {
          const uberConnectorSet = reduce(
            highlightedDTC.connectors,
            (_acc, c) => {
              const connectorSet = modToCompIdx[c]
              return connectorSet ? new Set([..._acc, ...connectorSet]) : _acc
            },
            acc,
          )
          return new Set([...acc, ...uberConnectorSet])
        },
        new Set(),
      )
    }

    return new Set()
  },
)

export const createConnectorSetFromToggledDtcs = createSelector(
  [getCurrentDTC, getModToCompIdx],
  (currentDTC, modToCompIdx) => {
    if (!isEmpty(currentDTC)) {
      return reduce(
        currentDTC.connectors,
        (_acc, c) => {
          const connectorSet = modToCompIdx[c]
          return connectorSet ? new Set([..._acc, ...connectorSet]) : _acc
        },
        new Set(),
      )
    }

    return new Set()
  },
)

// Transform modules into an array of DTCs
export const createDtcList = createSelector(
  [getModules, getModToCompIdx],
  (modules, modToCompIdx) => {
    return modules.reduce((dtcs, m) => {
      if (!isEmpty(m.dtcs)) {
        const dtcsWithModules = m.dtcs.map((dtc) => {
          dtc.module = {
            supported: m.is_supported,
            acronym: m.acronym,
            connector: m.connector_alias || 'UNKNOWN',
          }
          // this property could be used to look for related components
          dtc.components = reduce(
            dtc.connectors,
            (acc, c) => {
              const connectorSet = modToCompIdx[c]
              return connectorSet ? new Set([...acc, ...connectorSet]) : acc
            },
            new Set(),
          )
          return dtc
        })
        return dtcs.concat(dtcsWithModules)
      } else {
        const dtcFormattedModule = {
          module: m,
          supported: m.is_supported,
          description: m.description,
          acronym: m.acronym,
          connector: m.connector_alias || 'UNKNOWN',
        }
        dtcs.push(dtcFormattedModule)
        return dtcs
      }
    }, [])
  },
)

export const isSelectedElementAHarness = createSelector(
  [getSelectedElement],
  (element) => {
    return element && isEdge(element)
  },
)

export const isSelectedElementADevice = createSelector(
  [getSelectedElement],
  (element) => {
    return element && element.category === 'device'
  },
)

export const isSelectedElementASplice = createSelector(
  [getSelectedElement],
  (element) => {
    return element && element.category === 'splice'
  },
)

export const selectModulesWithActiveDtcs = createSelector(
  [getModules],
  (modules) =>
    filter(modules, (module) =>
      find(module.dtcs, (dtc) => dtc.active !== false),
    ),
)

export const getDtcCodesFromHighlightedDtcs = createSelector(
  [getHighlightedDTCs],
  (highlightedDtcs) => _.map(highlightedDtcs, 'dtc_code'),
)

export const getHistoryForSelectedModalComponent = createSelector(
  [getHistoryForAllComponents, getModalStateComponent],
  (allHistory, component) => component && allHistory[component.id],
)

export const getDtcsSet = createSelector([getModules], (modules) => {
  const activeDtcsSet = new Set()
  _.each(modules, (module) => {
    _.each(module.dtcs, (dtc) => activeDtcsSet.add(dtc.dtc_code))
  })
  return activeDtcsSet
})
