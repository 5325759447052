import { useQuery } from 'react-query'
import { apiGetCDNInfo } from '../api/cdn-api'

export const CDN = 'cdn'

export const useCDN = () => {
  const {
    status,
    data: cdn,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [CDN],
    async () => {
      const result = await apiGetCDNInfo()
      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    status,
    cdn,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
