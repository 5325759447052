import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconFunctionalDiagrams = (props) => {
  const viewBox = `0 0 100 100`
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        id="iconFunctionDiagrams"
        d="M76.7143 18.8067C82.9275 18.8067 87.9643 23.7668 87.9643 29.8854V70.1324C87.9643 76.2511 82.9275 81.2112 76.7143 81.2112L24.25 81.2112C18.0368 81.2112 13 76.2511 13 70.1324V29.8854C13 23.7668 18.0368 18.8066 24.25 18.8066L76.7143 18.8067ZM76.7143 22.4996L24.25 22.4996C20.1079 22.4996 16.75 25.8063 16.75 29.8854V70.1324C16.75 74.2115 20.1079 77.5183 24.25 77.5183L76.7143 77.5183C80.8564 77.5183 84.2143 74.2115 84.2143 70.1324V29.8854C84.2143 25.8063 80.8564 22.4996 76.7143 22.4996ZM54.6314 33.9996H46.4939C45.7449 33.9996 45.1376 34.603 45.1376 35.3474V43.4342C45.1376 44.1786 45.7449 44.782 46.4939 44.782H54.6314C55.3805 44.782 55.9877 44.1786 55.9877 43.4342V35.3474C55.9877 34.603 55.3805 33.9996 54.6314 33.9996ZM46.4937 54.2171H38.3561C37.6071 54.2171 36.9999 54.8205 36.9999 55.5649V63.6518C36.9999 64.3961 37.6071 64.9996 38.3561 64.9996H46.4937C47.2427 64.9996 47.8499 64.3961 47.8499 63.6518V55.5649C47.8499 54.8205 47.2427 54.2171 46.4937 54.2171ZM62.7686 54.2171H54.6311C53.882 54.2171 53.2748 54.8205 53.2748 55.5649V63.6518C53.2748 64.3961 53.882 64.9996 54.6311 64.9996H62.7686C63.5176 64.9996 64.1249 64.3961 64.1249 63.6518V55.5649C64.1249 54.8205 63.5176 54.2171 62.7686 54.2171ZM54.3718 46.1301H52.8555L56.9242 52.8691H58.4406L54.3718 46.1301ZM44.2002 52.8691L48.2689 46.1301H46.7526L42.6838 52.8691H44.2002Z"
      />
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g>
          <use
            fill="#FFFFFF"
            fillRule="nonzero"
            xlinkHref="#iconFunctionDiagrams"
          />
        </g>
      </g>
    </svg>
  )
}

IconFunctionalDiagrams.defaultProps = defaultProps

export default IconFunctionalDiagrams
