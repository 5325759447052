import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconReport = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.width}
      viewBox={viewBox}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="iconReport"
          d="M8 26h4v14a2 2 0 1 1-4 0V26zm2-8a2 2 0 0 1 2 2v5H8v-5a2 2 0 0 1 2-2zm6 14h4v8a2 2 0 1 1-4 0v-8zm2-18a2 2 0 0 1 2 2v15h-4V16a2 2 0 0 1 2-2zm6 12h4v14a2 2 0 1 1-4 0V26zm2-18a2 2 0 0 1 2 2v15h-4V10a2 2 0 0 1 2-2zm6 29h4v3a2 2 0 1 1-4 0v-3zm2-11a2 2 0 0 1 2 2v8h-4v-8a2 2 0 0 1 2-2zm6 4h4v10a2 2 0 1 1-4 0V30zm2-19a2 2 0 0 1 2 2v16h-4V13a2 2 0 0 1 2-2zm6 23h4v6a2 2 0 1 1-4 0v-6zm2-12a2 2 0 0 1 2 2v9h-4v-9a2 2 0 0 1 2-2zM9 3a6 6 0 0 0-6 6v32a6 6 0 0 0 6 6h42a6 6 0 0 0 6-6V9a6 6 0 0 0-6-6H9zm0-3h42a9 9 0 0 1 9 9v32a9 9 0 0 1-9 9H9a9 9 0 0 1-9-9V9a9 9 0 0 1 9-9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g transform="translate(10 15)">
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconReport" />
        </g>
      </g>
    </svg>
  )
}

IconReport.defaultProps = defaultProps

export default IconReport
