import { connect } from 'react-redux'
import Modal from '../components/Modal'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { unsetModal } from '../actions/modal-actions'

const propTypes = {
  containers: PropTypes.array,
}

const defaultProps = {
  containers: null,
}

class ModalContainer extends Component {
  render() {
    return <Modal {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

ModalContainer.propTypes = propTypes
ModalContainer.defaultProps = defaultProps

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
