import * as types from '../actions/action-types'

const initialState = {
  search: '',
  harnesses: null,
  selectedHarness: null,
}

const addFaultHarnessReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_FAULT_HARNESS_RESET_STATE:
      return Object.assign({}, initialState)
    case types.ADD_FAULT_HARNESS_SET_SEARCH:
      return Object.assign({}, state, { search: action.search })
    case types.ADD_FAULT_HARNESS_SET_HARNESSS:
      return Object.assign({}, state, { harnesses: action.harnesses })
    case types.ADD_FAULT_HARNESS_SET_SELECTED_HARNESS:
      return Object.assign({}, state, {
        selectedHarness: action.selectedHarness,
      })
    case types.ADD_FAULT_HARNESS_RESET_HARNESS:
      return Object.assign({}, state, {
        selectedHarness: initialState.selectedHarness,
      })
    default:
      return state
  }
}

export default addFaultHarnessReducer
