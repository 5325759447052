import React from 'react'
import { LeftArrow, RightArrow, UpArrow, DownArrow } from '../components/Arrows'
import classNames from 'classnames'

const PanControls = ({ left, right, up, down, disabled }) => {
  const onLeftClick = () => {
    if (disabled) return
    left()
  }
  const onRightClick = () => {
    if (disabled) return
    right()
  }
  const onUpClick = () => {
    if (disabled) return
    up()
  }
  const onDownClick = () => {
    if (disabled) return
    down()
  }

  const leftClassName = classNames('left', {
    disabled: disabled,
  })
  const rightClassName = classNames('right', {
    disabled: disabled,
  })
  const upClassName = classNames('up', {
    disabled: disabled,
  })
  const downClassName = classNames('down', {
    disabled: disabled,
  })

  return (
    <div className="pan-control">
      <div className={leftClassName} onClick={onLeftClick}>
        <LeftArrow />
      </div>
      <div className={rightClassName} onClick={onRightClick}>
        <RightArrow />
      </div>
      <div className={upClassName} onClick={onUpClick}>
        <UpArrow />
      </div>
      <div className={downClassName} onClick={onDownClick}>
        <DownArrow />
      </div>
    </div>
  )
}

export default PanControls
