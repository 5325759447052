import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ActivityIndicator from './ActivityIndicator'

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  uppercase: PropTypes.bool,
  wrapperClass: PropTypes.string,
  loading: PropTypes.bool,
}

const defaultProps = {
  uppercase: false,
  wrapperClass: '',
}

const Button = ({
  loading = false,
  handleClick,
  wrapperClass,
  children,
  small,
  uppercase,
  disabled = false,
  outline,
}) => {
  const onClick = (e) => {
    if (disabled || loading) {
      return
    }
    handleClick(e)
  }

  const btnClassNames = classNames(wrapperClass, 'btn', {
    'btn-sm': small,
    'text-uppercase': uppercase,
    'btn-outline': outline,
    'btn-disabled': disabled || loading,
  })

  return (
    <a className={btnClassNames} onClick={onClick}>
      {loading ? <ActivityIndicator /> : children}
    </a>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
