import { useQuery } from 'react-query'
import {
  apiGetMachineAlerts,
  apiGetMachine,
  apiGetJohnDeereAuth,
  apiJohnDeereLogin,
  apiJohnDeereAuthorize,
} from './api'

export const MACHINE_ALERTS_KEY = 'john-deere-machine-alerts'
export const MACHINE_KEY = 'john-deere-machine'
export const AUTH = 'john-deere-auth'
export const LOGIN = 'john-deere-login'
export const AUTHORIZE = 'john-deere-authorize'

export const useJohnDeereLogin = ({ redirectURI }) => {
  const {
    status,
    data: url,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [LOGIN],
    async () => {
      const result = await apiJohnDeereLogin({ redirectURI })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    url,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useJohnDeereAuthorize = () => {
  const {
    status,
    data: isAuthorized,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [AUTHORIZE],
    async () => {
      const result = await apiJohnDeereAuthorize()
      return result.data.status === 'success'
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    isAuthorized,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useJohnDeereAuth = () => {
  const {
    status,
    data: isAuthenticated,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [AUTH],
    async () => {
      const result = await apiGetJohnDeereAuth()

      return result.data.status === true
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    isAuthenticated,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useMachineAlerts = ({ vin }) => {
  const {
    status,
    data: machineAlerts,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [MACHINE_ALERTS_KEY, vin],
    async () => {
      const result = await apiGetMachineAlerts({ vin })

      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin,
    },
  )
  return {
    status,
    machineAlerts,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useMachine = ({ vin }) => {
  const {
    status,
    data: machine,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [MACHINE_KEY, vin],
    async () => {
      const result = await apiGetMachine({ vin })

      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin,
    },
  )
  return {
    status,
    machine,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
