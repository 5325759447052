import axios from 'axios'
import moment from 'moment'
import uuidv4 from 'uuid/v4'

import store from '../store'
import {
  setNotification,
  unsetNotification,
} from '../actions/notification-actions'
import {
  setRetryingTime,
  forceRetry,
} from '../actions/retry-connection-actions'
import { NOTIFICATION_NETWORK_ERROR } from '../constants'
import { defaultErrorInterceptor, noCacheInterceptor } from './interceptors'

const NO_RESPONSE_INTERCEPTOR = (req) => req
const DEFAULT_ERROR_HANDLER = Symbol('DEFAULT_ERROR_HANDLER')
const NO_CACHE_HANDLER = Symbol('NO_CACHE_HANDLER')

export const InterceptorOptions = {
  defaultErrorInterceptor: DEFAULT_ERROR_HANDLER,
  noCacheInterceptor: NO_CACHE_HANDLER,
}

export function create(config, ...interceptorOptions) {
  return doCreate(config, interceptorOptions, {
    count: 0,
  })
}

const doCreate = (config, interceptorOptions, retryCounter) => {
  const api = axios.create(config)

  decorateInterceptors(api, interceptorOptions)

  api.interceptors.request.use((config) => {
    if (config.method === 'post' || config.method === 'put') {
      config.headers['Idempotency-Key'] = uuidv4()
    }
    return config
  })

  api.interceptors.response.use(
    NO_RESPONSE_INTERCEPTOR,
    createRetryInterceptor(api, interceptorOptions, retryCounter),
  )
  return api
}
const RETRYABLE_CODES = new Set(['408', '429', '444', '503', '504', '599'])
const hasResponseAndAreNotRetryableCodes = (error) =>
  error.response && !RETRYABLE_CODES.has(`${error.response.status}`)

const createRetryInterceptor =
  (axiosConfig, interceptorOptions, retryCounter) => (error) => {
    if (!error.config) return Promise.reject(error)
    if (hasResponseAndAreNotRetryableCodes(error)) return Promise.reject(error)

    const newRetryCounter = { count: retryCounter.count + 1 }
    const delay = exponentialDelay(newRetryCounter.count)

    return new Promise(function (resolve) {
      let newConfig = doCreate(
        error.config,
        interceptorOptions,
        newRetryCounter,
      )
      newConfig.interceptors.response.use(clearNotification)

      setRetryTime(delay)

      let execute = function () {
        /*  cleaning up base URL so it does not re add the the config url  */
        setRetryNow()
        error.config.baseURL = ''

        return resolve(newConfig(error.config))
      }

      let timerHandle = setInterval(() => {
        let retryTime = store.getState().retryConnectionState.retryTime
        let difference = moment.duration(retryTime.diff(moment())).seconds()

        if (difference < 1) {
          clearInterval(timerHandle)
          execute()
        }
      }, 1000)
      return timerHandle
    })
  }

const exponentialDelay = (retryNumber) => {
  let delay = Math.pow(2, retryNumber) * 100
  let randomSum = delay * 0.2 * Math.random() // 0-20% of the delay
  return (delay + randomSum) / 1000
}

const decorateInterceptors = (api, interceptorOptions) => {
  if (interceptorOptions.includes(DEFAULT_ERROR_HANDLER))
    api.interceptors.response.use(
      NO_RESPONSE_INTERCEPTOR,
      defaultErrorInterceptor,
    )
  if (interceptorOptions.includes(NO_CACHE_HANDLER))
    api.interceptors.request.use(noCacheInterceptor)
}

const setRetryNow = () => store.dispatch(forceRetry())
const setRetryTime = (seconds) => {
  store.dispatch(setRetryingTime(moment().add(seconds, 's')))
  store.dispatch(setNotification(NOTIFICATION_NETWORK_ERROR))
}
const clearNotification = (args) => {
  store.dispatch(unsetNotification())
  return Promise.resolve(args)
}

export default {
  create: create,
}
