import { useQuery } from 'react-query'
import {
  apiGetSalesCodeConstraints,
  apiGetSalesCodeConstraintsByVin,
} from '../api/sales-codes'

export const SALES_CODE_CONSTRAINTS_KEY = 'sales-code-constraints'

export const useSalesCodeConstraints = ({ makeModelID }) => {
  const {
    status,
    data: salesCodeConstraints,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [SALES_CODE_CONSTRAINTS_KEY, makeModelID],
    async () => {
      const result = await apiGetSalesCodeConstraints({ makeModelID })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!makeModelID,
    },
  )
  return {
    status,
    salesCodeConstraints,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const SALES_CODE_CONSTRAINTS_BY_VIN_KEY = 'sales-code-constraints-by-vin'

export const useSalesCodeConstraintsByVin = ({ vin }) => {
  const {
    status,
    data: salesCodeConstraints,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [SALES_CODE_CONSTRAINTS_BY_VIN_KEY, vin],
    async () => {
      const result = await apiGetSalesCodeConstraintsByVin({ vin })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!vin,
    },
  )
  return {
    status,
    salesCodeConstraints,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
