import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SvgViewer from './SvgViewer'
import ResizableContent from '../ResizableContent'
import { HIGHLIGHT_PIN_COLOR } from '../../constants'
import { useCDN } from '../../hooks/CDN'

function ConnectorView({ imageUrl, panzoom, connectorName, pins, onClick }) {
  const { isSuccess, cdn } = useCDN()
  const [svg, setSvg] = React.useState(null)
  const [ready, setReady] = React.useState(false)

  const errorComponent = (
    <p>
      <FormattedMessage
        id="state.imageNotAvailable"
        values={{ component: connectorName }}
      />
    </p>
  )

  const onSvgReady = (svg, panzoom) => {
    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    for (const pin of pins) {
      highlightPinNumberInSvg(svg, pin)
    }
    setSvg(svg)
    setReady(true)
  }
  const handleClick = () => {
    if (onClick && ready) {
      onClick()
    }
  }

  const handleUpdate = (height, width) => {
    if (height && width && svg) {
      svg.setAttribute('max-height', height)
      svg.setAttribute('max-width', width)
    }
  }

  const highlightPinNumberInSvg = (svg, pinNumber) => {
    if (!pinNumber) {
      return false
    }

    const pathElement = svg.getElementById(`pin_${pinNumber}_path`)
    const textElement = svg.getElementById(`pin_${pinNumber}_text`)

    if (!pathElement || pathElement.length === 0) {
      console.warn(`Component pin_${pinNumber} not found in SVG`)
      return
    } else if (!textElement || textElement.length === 0) {
      console.warn(`Component pin_${pinNumber}_text not found in SVG`)
      return
    }
    pathElement.setAttribute('fill', HIGHLIGHT_PIN_COLOR)
    pathElement.setAttribute('stroke', 'white')

    textElement.setAttribute('fill', 'white')
    textElement.setAttribute('font-family', 'Montserrat')
  }

  return (
    isSuccess && (
      <ResizableContent
        onClick={handleClick}
        onUpdate={handleUpdate}
        className="connector-view"
      >
        <SvgViewer
          key={imageUrl}
          src={`${cdn.imagesURL}/${imageUrl}`}
          onReady={onSvgReady}
          errorComponent={errorComponent}
          panzoom={panzoom}
        />
      </ResizableContent>
    )
  )
}

export default ConnectorView
