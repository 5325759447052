import React from 'react'
import Loading from './Loading'
import { logout } from '../api/auth-api'
import { FormattedMessage } from 'react-intl'

class Logout extends React.Component {
  componentDidMount() {
    logout()
  }
  render() {
    return (
      <Loading
        backgroundColor=""
        text={<FormattedMessage id="processes.loggingOut" />}
      />
    )
  }
}

export default Logout
