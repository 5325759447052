import React from 'react'
import classNames from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setActionBarSelection } from '../actions/embedded-mode-actions'
import { ReactComponent as DTCIcon } from '../assets/images/icons/embedded/svg/dtc.svg'
import { ReactComponent as PinoutIcon } from '../assets/images/icons/embedded/svg/pinout.svg'
import { ReactComponent as ComponentsIcon } from '../assets/images/icons/embedded/svg/components.svg'
import { ReactComponent as RepairHistoryIcon } from '../assets/images/icons/embedded/svg/repair-history.svg'
import { ReactComponent as SwitchTestIcon } from '../assets/images/icons/embedded/svg/switch-test.svg'
import { FeatureTogglesProvider, useFeatureToggles } from './FeatureToggles'
import {
  isSelectedElementADevice,
  isSelectedElementAHarness,
} from '../selectors'
import { VEHICLE_VIEW } from '../constants'
import { SELECTION_VALUES } from './const'
import { Up, Down } from './Controls'
import { centerOnSelectedElement } from '../actions/component-actions'

const ComponentListScrollControls = ({ scrollable }) => {
  const scrollState = useSelector(
    (state) => state.embeddedModeState.componentList,
    shallowEqual,
  )
  return scrollState ? (
    <ScrollControls scrollable={scrollable} scrollPosition={scrollState} />
  ) : null
}

const DTCListScrollControls = ({ scrollable }) => {
  const scrollState = useSelector(
    (state) => state.embeddedModeState.dtcList,
    shallowEqual,
  )
  return scrollState ? (
    <ScrollControls scrollable={scrollable} scrollPosition={scrollState} />
  ) : null
}

const ScrollControls = ({ scrollable, scrollPosition }) => {
  const [isTop, setIsTop] = React.useState(true)
  const [isBottom, setIsBottom] = React.useState(false)

  React.useEffect(() => {
    setIsTop(getIsTop())
    setIsBottom(getIsBottom())
  }, [scrollPosition])

  function getIsTop() {
    return scrollPosition.scrollTop == 0
  }

  function getIsBottom() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
    return (
      scrollPosition.scrollHeight - Math.abs(scrollPosition.scrollTop) ===
      scrollPosition.clientHeight
    )
  }

  const onTopClick = () => {
    if (isTop) return
    scrollable.scrollUp()
  }

  const onBottomClick = () => {
    if (isBottom) return
    scrollable.scrollDown()
  }

  return (
    <>
      <Up onClick={onTopClick} disabled={isTop} />
      <Down onClick={onBottomClick} disabled={isBottom} />
    </>
  )
}

const DTC = ({ onClick, selected, disabled }) => {
  const featureToggles = useFeatureToggles()

  const className = SELECTION_VALUES.dtc

  const onClickItem = () => {
    if (disabled) {
      return
    }
    onClick(className)
  }

  const actionClass = classNames({
    selected: selected === className,
    'disabled-control': disabled,
  })

  if (!featureToggles.dtc) return null
  return (
    <div onClick={onClickItem} className={actionClass}>
      <DTCIcon />
    </div>
  )
}

const Components = ({ onClick, selected }) => {
  const featureToggles = useFeatureToggles()
  const className = SELECTION_VALUES.components

  const onClickItem = () => {
    onClick(className)
  }

  const actionClass = classNames({
    selected: selected === className,
  })
  if (!featureToggles.components) return null
  return (
    <div onClick={onClickItem} className={actionClass}>
      <ComponentsIcon />
    </div>
  )
}

const Pinout = ({ onClick, selected, disabled }) => {
  const featureToggles = useFeatureToggles()
  const className = SELECTION_VALUES.pinout

  const onClickItem = () => {
    if (disabled) {
      return
    }
    onClick(className)
  }

  const actionClass = classNames({
    'disabled-control': disabled,
    selected: selected === className,
  })

  if (!featureToggles.pinout) return null

  return (
    <div onClick={onClickItem} className={actionClass}>
      <PinoutIcon />
    </div>
  )
}

const RepairHistory = ({ onClick, selected }) => {
  const featureToggles = useFeatureToggles()

  const className = SELECTION_VALUES.repairHistory

  const onClickItem = () => {
    onClick(className)
  }

  const actionClass = classNames({
    selected: selected === className,
  })

  if (!featureToggles.repairHistory) return null

  return (
    <div onClick={onClickItem} className={actionClass}>
      <RepairHistoryIcon />
    </div>
  )
}

const SwitchTest = ({ onClick, selected }) => {
  const featureToggles = useFeatureToggles()
  const className = SELECTION_VALUES.switchTest

  const onClickItem = () => {
    onClick(className)
  }

  const actionClass = classNames({
    selected: selected === className,
  })

  if (!featureToggles.switchTest) return null

  return (
    <div onClick={onClickItem} className={actionClass}>
      <SwitchTestIcon />
    </div>
  )
}

const ActionBar = ({ children }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      actionBarSelection: state.embeddedModeState.actionBarSelection,
      disabledDTC:
        state.embeddedModeState.harnessVehicleSwitch === VEHICLE_VIEW &&
        state.embeddedModeState.actionBarSelection === SELECTION_VALUES.dtc,
      disabledPinout:
        isSelectedElementAHarness(state) ||
        isSelectedElementADevice(state) ||
        state.dtcState.toggledOnDtc,
    }),
    shallowEqual,
  )

  const setSelected = (selected) => {
    dispatch(setActionBarSelection(selected))
    if (
      [SELECTION_VALUES.dtc, SELECTION_VALUES.components].includes(selected)
    ) {
      dispatch(centerOnSelectedElement())
    }
  }

  return (
    <div className="action-bar">
      <FeatureTogglesProvider>
        <DTC
          onClick={setSelected}
          selected={props.actionBarSelection}
          disabled={props.disabledDTC}
        />
        <Components onClick={setSelected} selected={props.actionBarSelection} />
        <Pinout
          disabled={props.disabledPinout}
          onClick={setSelected}
          selected={props.actionBarSelection}
        />
        <RepairHistory
          onClick={setSelected}
          selected={props.actionBarSelection}
        />
        <SwitchTest onClick={setSelected} selected={props.actionBarSelection} />
        {children}
      </FeatureTogglesProvider>
    </div>
  )
}

export {
  ActionBar,
  DTCListScrollControls,
  ComponentListScrollControls,
  SELECTION_VALUES,
}
