import {
  SET_CURRENT_VEHICLE_TEST_ID,
  SET_VEHICLE_TESTS,
  SET_VEHICLE_TESTS_LOADING,
} from '../actions/action-types'

const initialState = {
  data: [],
  isLoading: false,
}

const vehicleTestsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VEHICLE_TEST_ID:
      return Object.assign({}, state, {
        currentVehicleTest: action.currentVehicleTest,
      })
    case SET_VEHICLE_TESTS_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case SET_VEHICLE_TESTS:
      return Object.assign({}, state, {
        data: action.vehicleTests,
        isLoading: false,
      })
    default:
      return state
  }
}

export default vehicleTestsReducer
