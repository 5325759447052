import * as types from '../actions/action-types'

export const initialState = {
  error: null,
}

const errorReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_ERROR:
      return Object.assign({}, state, { error: action.error })
    case types.CLEAR_ERROR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export default errorReducer
