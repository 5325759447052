import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SvgViewer from './SvgViewer'
import ResizableContent from '../ResizableContent'
import { HIGHLIGHT_PIN_COLOR } from '../../constants'
import { useCDN } from '../../hooks/CDN'

const PowerDistributionBoxView = ({
  imageName,
  panzoom,
  connectorName,
  onClick,
  units,
}) => {
  const { isSuccess, cdn } = useCDN()
  const [svg, setSvg] = React.useState(null)

  const errorComponent = (
    <p>
      <FormattedMessage
        id="state.imageNotAvailable"
        values={{ component: connectorName }}
      />
    </p>
  )

  const handleClick = () => {
    if (onClick && svg) {
      onClick()
    }
  }

  const handleUpdate = (height, width) => {
    if (height && width && svg) {
      svg.setAttribute('max-height', height)
      svg.setAttribute('max-width', width)
    }
  }

  const onSvgReady = (svg) => {
    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')
    units.map((unit) => highlightUnitInSvg(svg, unit))
    setSvg(svg)
  }

  const highlightUnitInSvg = (svg, unit) => {
    if (!unit) {
      return false
    }

    const element = svg.querySelector(`#${unit.name} > *`)

    if (!element || element.length === 0) {
      return
    }
    element.setAttribute('fill', HIGHLIGHT_PIN_COLOR)
  }

  return (
    isSuccess && (
      <ResizableContent onClick={handleClick} onUpdate={handleUpdate}>
        <SvgViewer
          key={imageName}
          src={`${cdn.imagesURL}/${imageName}`}
          onReady={onSvgReady}
          errorComponent={errorComponent}
          panzoom={panzoom}
        />
      </ResizableContent>
    )
  )
}

export default PowerDistributionBoxView
