import { addNavigationTab, removeNavigationTab } from '../actions/tab-actions'
import { selectElement as selectElementAction } from '../actions/pin-actions'
import {
  PINOUT_NAMESPACE,
  FUSES_AND_RELAYS_VIEW,
  VEHICLE_VIEW,
} from '../constants'

export function selectElement(selectedElement) {
  return function (dispatch) {
    dispatch(selectElementAction(selectedElement))
    const isPowerDistribution = selectedElement
      ? selectedElement.category === 'power_distribution'
      : false
    if (isPowerDistribution) {
      dispatch(addNavigationTab(FUSES_AND_RELAYS_VIEW, PINOUT_NAMESPACE))
    } else {
      dispatch(removeNavigationTab(FUSES_AND_RELAYS_VIEW, PINOUT_NAMESPACE))
    }
    dispatch(addNavigationTab(VEHICLE_VIEW, PINOUT_NAMESPACE))
  }
}
