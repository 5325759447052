import * as types from './action-types'

export function setAdditionalComponentFaultMenuVisible(componentId, visible) {
  return {
    type: types.SET_ADDITIONAL_COMP_FAULT_MENU_VIS,
    componentId,
    visible,
  }
}

export function resetAdditionalComponentFaultMenu() {
  return {
    type: types.RESET_ADDITIONAL_COMP_FAULT_MENU,
  }
}
