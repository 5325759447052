import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  postCantFixEvent,
  putCantFixEvent,
  deleteCantFixEvent,
  getCantFixEvent,
} from '../api/cant-fix-api'

export const useCantFix = (vehicleTestId) => {
  const {
    status,
    data: cantFix,
    error,
    isFetching,
    isLoading,
    isIdle,
  } = useQuery(
    ['cant-fix-toggle', vehicleTestId],
    async () => {
      const result = await getCantFixEvent(vehicleTestId)
      return result.data
    },
    {
      // The query will not execute until the vehicleTestId exist
      enabled: !!vehicleTestId,
      refetchOnWindowFocus: false,
    },
  )
  return [status, cantFix, error, isFetching, isLoading, isIdle]
}

export const useAddCantFix = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ vehicleTestId, reasonId }) =>
      postCantFixEvent({ vehicleTestId, reasonId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cant-fix-toggle')
        queryClient.invalidateQueries('cant-fix-reason')
      },
    },
  )
}

export const useDeleteCantFix = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ vehicleTestId }) => deleteCantFixEvent({ vehicleTestId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cant-fix-toggle')
        queryClient.invalidateQueries('cant-fix-reason')
      },
    },
  )
}

export const useUpdateCantFix = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ vehicleTestId, reasonId }) =>
      putCantFixEvent({ vehicleTestId, reasonId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cant-fix-toggle')
      },
    },
  )
}

export const useCantFixReasons = () => {
  const {
    status,
    data: cantFixReasons,
    error,
    isFetching,
    isLoading,
  } = useQuery(
    'cant-fix-reasons',
    async () => {
      const result = await axios.get('/tracer/cant-fix-reasons/')
      return result.data.data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return [status, cantFixReasons, error]
}

export const useCantFixReason = (vehicleTestId) => {
  const {
    status,
    data: cantFixReason,
    error,
    isFetching,
    isLoading,
  } = useQuery(
    ['cant-fix-reason', vehicleTestId],
    async () => {
      const result = await axios.get(
        `/tracer/vehicles/tests/${vehicleTestId}/cant-fix/reason/`,
      )
      return result.data.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vehicleTestId,
    },
  )
  return [status, cantFixReason, error]
}
