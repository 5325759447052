import React from 'react'

export default (props) => {
  const { meta, ignoreTouched } = props
  const error = <span className="text-danger">{meta.error}</span>

  if (meta.error && (ignoreTouched || meta.touched)) {
    return error
  }

  return null
}
