import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getVehicleTestsByVin } from '../api/vehicle-tests-api'
import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import { requireAuthentication } from '../helpers/authentication'
import VehicleTestsSearch from '../components/VehicleTestsSearch'

class VehicleTestsSearchContainer extends Component {
  render() {
    return (
      <MustHave permission="page-search:get" fallback={<Forbidden />}>
        <VehicleTestsSearch {...this.props} />
      </MustHave>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    vehicleTests: state.vehicleTests.data
      ? state.vehicleTests.data.sort((a, b) =>
          a.timestamp > b.timestamp ? -1 : 1,
        )
      : state.vehicleTests.data,
    vehicleTestsLoading: state.vehicleTests.isLoading,
  }
}

const mapDispatchToProps = () => {
  return {
    searchForVin: (vin) => {
      getVehicleTestsByVin(vin)
    },
  }
}

const ProtectedVehicleTestsSearchContainer = requireAuthentication(
  VehicleTestsSearchContainer,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedVehicleTestsSearchContainer)
