import React from 'react'
import { Form } from 'antd'
import { useHistory } from 'react-router'
import { requireAuthentication } from '../helpers/authentication'
import { injectIntl } from 'react-intl'
import { useVirtualVehicleConfiguration } from '../VirtualVehicleConfiguration/VirtualVehicleConfigurationContext'
import {
  MakeModelSelect,
  SalesCodeSelect,
} from '../VirtualVehicleConfiguration'

function SalesCodesPage() {
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const history = useHistory()

  return (
    <div className="functional-diagrams-sales-codes">
      <h2>Create Functional Diagram</h2>
      <div className="functional-diagrams-sales-codes-input">
        <Form layout="vertical" size="medium">
          <div className="functional-diagrams-make-model-select">
            <MakeModelSelect />
          </div>
          {virtualVehicleConfiguration.makeModelID && (
            <SalesCodeSelect
              makeModelID={virtualVehicleConfiguration.makeModelID}
            />
          )}
        </Form>
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(SalesCodesPage))
