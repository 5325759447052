import mapValues from 'lodash/mapValues'
import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import store from '../store'
import {
  deleteRepairHistoryItem as deleteRepairHistoryItemAction,
  addAppliedComponent,
} from '../actions/logging-actions'
import {
  setCircuitCategories,
  updateStateOfHarnesses,
  updateStateOfComponents,
  updateStateOfDevices,
  setRepairHistory,
  setBatchHistoryForComponents,
} from '../actions/logging-actions'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer/',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
  InterceptorOptions.noCacheInterceptor,
)

export function getCircuitCategories() {
  api
    .get(`/collection/circuit/categories?datetime=${Date.now()}`)
    .then((response) => {
      store.dispatch(setCircuitCategories(response.data))
    })
}

function transformComponentStateResponse(componentStates) {
  return mapValues(componentStates, (componentState) => ({
    status: componentState.status,
    annotation: componentState.annotation,
    category: componentState.category,
    problemId: componentState.problem_id,
    id: componentState.id,
  }))
}

export function updateCircuitState(makeModelId, vin) {
  api
    .get('/log/problem', {
      params: {
        datetime: Date.now(),
        makeModelId,
        vin,
      },
    })
    .then((response) => {
      store.dispatch(
        updateStateOfComponents(
          transformComponentStateResponse(response.data.components),
        ),
      )
      store.dispatch(updateStateOfHarnesses(response.data.harnesses))
      store.dispatch(updateStateOfDevices(response.data.devices))
    })
}

export function getRepairHistory(makeModelId, vin) {
  api
    .get(`/collection/repair-history/make_model_id/${makeModelId}/vin/${vin}`)
    .then((response) => {
      store.dispatch(setRepairHistory(response.data))
    })
}

export function deleteRepairHistoryItem(id, makeModelId, vin) {
  api.delete(`collection/repair-history/id/${id}`).then((response) => {
    if (response.status === 200) {
      store.dispatch(deleteRepairHistoryItemAction(id))
      updateCircuitState(makeModelId, vin)
    }
  })
}

export function getHistoryGroupedByComponents(
  makeModelId,
  vin,
  vehicleTestResultId,
) {
  api
    .get(
      `/log/component-histories/vehicle_test_result_id/${vehicleTestResultId}/make_model_id/${makeModelId}/vin/${vin}`,
    )
    .then((response) =>
      store.dispatch(setBatchHistoryForComponents(response.data)),
    )
}

export function applyComponentToCurrentSession(
  vehicleTestResultId,
  makeModelId,
  vin,
  problemLogId,
) {
  api
    .post(`/log/component-history/apply`, {
      vehicleTestResultId,
      makeModelId,
      vin,
      problemLogId,
    })
    .then((response) => {
      if (response.status === 201) {
        store.dispatch(addAppliedComponent(problemLogId))
      } else {
        throw new Error(response.message)
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export function fetchVerifiedComponents(vin) {
  return api.get(`/log/verified/${vin}`)
}

export function markComponentAsVerified(vin, componentId) {
  return api.post(`/log/verified`, { vin, component_id: componentId })
}

export function unmarkComponentAsVerified(vin, componentId) {
  return api.delete(`/log/verified/${vin}/${componentId}`)
}
