// Routing
export const ROUTING = Object.freeze({
  memoAuthoring: '/memo-authoring',
  functionalDiagrams: '/functional-diagrams/',
})

/*
 * These constants should match appropriate constants on BE
 * file: model.py, class: CircuitCategories
 */
export const CIRCUIT_CATEGORY_HARNESS = 'harness'
export const CIRCUIT_CATEGORY_CONNECTOR = 'connector'
export const CIRCUIT_CATEGORY_INLINE = 'inline'
export const CIRCUIT_CATEGORY_OTHER = 'other'

// UP- / DOWN- LOADING STATEs
export const LOADING_STATE = Object.freeze({
  notStarted: 'notStarted',
  started: 'started',
  endOk: 'endOk',
  endWithError: 'endWithError',
})

// Manage DTC Mapping.
export const DTC_MAPPING_ACTIVE_UI = Object.freeze({
  MANAGE_MAPPINGS: 'MANAGE_MAPPINGS',
  ADD_NEW_MAPPING_SELECT_COMPONENT: 'ADD_NEW_MAPPING_SELECT_COMPONENT',
  ADD_NEW_MAPPING_SELECT_START: 'ADD_NEW_MAPPING_SELECT_START',
})

// VehicleTest.organisation_type
export const VEHICLE_TEST_ORGANISATION_TYPE = Object.freeze({
  pfs: 'pfs',
  tester: 'tester',
  dealer: 'dealer',
})

/*
 * Datetimes
 */
export const DEFAULT_BUSINESS_TIMEZONE = 'UTC'

/*
 * These constants should match appropriate constants on BE
 * file: model.py, class: ComponentCategory
 */
export const COMPONENT_CATEGORY_INLINE = 'inline'
export const COMPONENT_CATEGORY_SPLICE = 'splice'
export const COMPONENT_CATEGORY_MODULE = 'module'
export const COMPONENT_CATEGORY_CONNECTOR = 'connector'
export const COMPONENT_CATEGORY_POWER_DISTRIBUTION = 'power_distribution'
export const COMPONENT_CATEGORY_GROUND = 'ground'
export const COMPONENT_CATEGORY_GATEWEY_ODBII = 'gateway_odbii'
export const COMPONENT_CATEGORY_JOINT = 'joint'
export const COMPONENT_CATEGORY_CAN_STAR = 'can_star'
export const COMPONENT_CATEGORY_FUSE = 'fuse'
export const COMPONENT_CATEGORY_HIGH_VOLTAGE = 'high_voltage'
export const COMPONENT_CATEGORY_DEVICE = 'device'
export const EDGE_TYPE_INLINE = 'inline'
export const EDGE_TYPE_DEVICE = 'device'
export const EDGE_TYPE_INTERNAL_DEVICE = 'internal_device'
export const EDGE_TYPE_INTERNAL_FUSE = 'internal_fuse'
/*
 * Modal window IDs
 */
export const MODAL_CHANGE_START_VIEW = 'MODAL_CHANGE_START_VIEW'
export const MODAL_COMPONENT_INFO = 'MODAL_COMPONENT_INFO'
export const MODAL_COMPONENT_STATE = 'MODAL_COMPONENT_STATE'
export const MODAL_CONNECTOR_VIEW = 'MODAL_CONNECTOR_VIEW'
export const MODAL_EMBEDDED_MODE_CONNECTOR_VIEW =
  'MODAL_EMBEDDED_MODE_CONNECTOR_VIEW'
export const MODAL_DEVICE_STATE = 'MODAL_DEVICE_STATE'
export const MODAL_HARNESS_STATE = 'MODAL_HARNESS_STATE'
export const MODAL_INLINE_COMPONENTS_SELECTOR =
  'MODAL_INLINE_COMPONENTS_SELECTOR'
export const MODAL_PIN_DESTINATION = 'MODAL_PIN_DESTINATION'
export const MODAL_EMBEDDED_MODE_PIN_DESTINATION =
  'MODAL_EMBEDDED_MODE_PIN_DESTINATION'
export const MODAL_REPAIR_HISTORY = 'MODAL_REPAIR_HISTORY'
export const MODAL_SAME_ISSUES_HISTORY = 'MODAL_SAME_ISSUES_HISTORY'
export const MODAL_SUBMIT_FEEDBACK = 'MODAL_SUBMIT_FEEDBACK'
export const MODAL_SELECT_PLANT = 'MODAL_SELECT_PLANT'
export const MODAL_SELECT_STATION_ALERT = 'MODAL_SELECT_STATION_ALERT'
export const MODAL_SWITCH_TEST = 'MODAL_SWITCH_TEST'
export const MODAL_CONFIRM_VERIFY_COMPONENT = 'MODAL_CONFIRM_VERIFY_COMPONENT'
export const MODAL_CANT_FIX = 'MODAL_CANT_FIX'
export const MODAL_ADD_FAULT = 'MODAL_ADD_FAULT'
export const MODAL_DTC_CROWDSOURCED_EDIT = 'MODAL_DTC_CROWDSOURCED_EDIT'
export const MODAL_CANT_SAVE_START_COMPONENT = 'MODAL_CANT_SAVE_START_COMPONENT'
export const MODAL_DTC_DEPENDENCY_INFO = 'MODAL_DTC_DEPENDENCY_INFO'

/**
 * Notification window IDs
 */
export const NOTIFICATION_GENERIC = 'NOTIFICATION_GENERIC'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const NOTIFICATION_NETWORK_ERROR = 'NETWORK_ERROR'

/**
 * Icon orientations
 */
export const ORIENTATION_FORWARD = 'forward'
export const ORIENTATION_BACK = 'back'
export const ORIENTATION_UP = 'up'
export const ORIENTATION_DOWN = 'down'

export const CLASS_NAME_ACTIVE = 'active'
export const CLASS_NAME_FAULTY = 'faulty'

export const ICON_VERIFIED = '\uF05D'
export const ICON_FAULTY = '\uF00D'

export const HARNESS_VIEW = 'harnessView'
export const VEHICLE_VIEW = 'vehicleView'
export const PINOUT_VIEW = 'pinoutView'
export const ADD_DTC_MEMO_VIEW = 'addDTCMemoView'
export const MANAGE_MAPPINGS_VIEW = 'manageMappingsView'
export const FUSES_AND_RELAYS_VIEW = 'fusesAndRelaysView'
export const INIT_MAIN_VIEW_QUERY_PARAM = 'mainView'
export const INIT_SELECTED_COMPONENT_QUERY_PARAM = 'componentId'
export const INIT_CONTEXT_QUERY_PARAM = 'context'
export const ADD_FAULT_GENERIC_VIEW = 'addFaultGenericView'
export const ADD_FAULT_COMPONENT_VIEW = 'addFaultComponentView'
export const ADD_FAULT_HARNESS_VIEW = 'addFaultHarnessView'
export const ADD_FAULT_DEVICE_VIEW = 'addFaultDeviceView'

export const USER_ROLE_SUPER_ADMIN = 'superadmin'

/*
 * Data constants
 */
export const ANNOTATION_MAX_LENGTH = 60
export const MAX_NUMBER_OF_SUGGESTED_FAULTS = 3

/**
 * Local storage
 */
export const LOCAL_STORAGE_USER_KEY = 'user'

/**
 * React References used as booleans.
 */
export const SELECTED_COMPONENT_LIST_ITEM = 'selectedComponentListItem'

export const ERROR_NO_GRAPH = 'ERROR_NO_GRAPH'

const { REACT_APP_VERSION } = process.env
export const TRACER_VERSION = REACT_APP_VERSION

export const SAME_ISSUES_HISTORY_SHORT_LIST_LENGTH = 2

export const MAIN_NAMESPACE = 'main'
export const PINOUT_NAMESPACE = 'pinout'
export const COMPONENT_DETAILS_NAMESPACE = 'component-details'
export const DTC_CROWDSOURCED_EDIT_NAMESPACE = 'dtc-crowdsourced-edit'
export const ADD_FAULT_TABS_NAMESPACE = 'add-fault'

// Open "Root cause not listed" modal
export const ROOT_CAUSE_QUERY_PARAM = 'rcnl'
