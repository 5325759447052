import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetSalesCodeConstraints({ makeModelID }) {
  return api
    .get(`/make-models/${makeModelID}/sales-code-constraints`)
    .then((resp) => resp.data)
}

export function apiGetSalesCodeConstraintsByVin({ vin }) {
  return api
    .get(`/make-models/vin/${vin}/sales-code-constraints`)
    .then((resp) => resp.data)
}
