import React from 'react'
import { ConfigProvider } from 'antd'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { Provider as StoreProvider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import store from './store'
import history from './history'
import App from './components/App'
import { locale, translations } from './Locale'

const queryClient = new QueryClient()

/* Define Root with Route Provider */
export default function Root() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: 'rgba(255, 255, 255, 1)',
          },
          Pagination: {
            colorText: 'white',
            colorTextDisabled: 'grey',
          },
          Popover: {
            zIndexPopup: 8000,
          },
        },
        token: {
          fontFamily: 'Montserrat',
          colorBgContainerDisabled: 'rgba(255, 255, 255, 1)',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} messages={translations}>
          <CookiesProvider>
            <StoreProvider store={store}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route component={App} />
                </Switch>
              </ConnectedRouter>
            </StoreProvider>
          </CookiesProvider>
        </IntlProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  )
}
