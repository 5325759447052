import React, { useState } from 'react'
import { Table, Button } from 'antd'
import { RightSquareFilled } from '@ant-design/icons'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Input } from 'antd'
import { requireAuthentication } from '../../../helpers/authentication'
import { useSearchDTCs } from '../../../hooks/DTC'
import { useAddVehicleTest } from '../../../hooks/VehicleTests'
import { generateManualPayload } from '../payload'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import './styles.scss'

const GenerateVehicleTestButton = ({ vin, dtcs }) => {
  const user = useSelector((state) => state.user.data.username, shallowEqual)
  const authToken = useSelector((state) => state.user.data.token, shallowEqual)
  const addVehicleTest = useAddVehicleTest()
  const history = useHistory()

  const onClick = () => {
    const payload = generateManualPayload({ vin, userId: user, dtcs })
    addVehicleTest.mutate(
      { payload, authToken },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/tracer/${data.cache_id}`),
      },
    )
  }

  return (
    <Button
      block
      disabled={dtcs.length === 0}
      onClick={onClick}
      size="large"
      type="primary"
      loading={addVehicleTest.isLoading}
    >
      <FormattedMessage id="johnDeere.manualDTCPage.generateTest" />
    </Button>
  )
}

function JohnDeereManualDTCPage() {
  const makeModelID = 57 // TODO: Ask Sergio to clarify if only one make model will be used.
  const [vin, setVin] = useState('')
  const [searchString, setSearchString] = useState(null)
  const [payloadDtcs, setPayloadDtcs] = useState([])
  const { isLoading, dtcs } = useSearchDTCs({ makeModelID, searchString })

  const addPayloaddDtc = (dtc) =>
    setPayloadDtcs([...new Set([...payloadDtcs, dtc])])
  const removePayloaddDtc = (dtc) => {
    setPayloadDtcs(payloadDtcs.filter((payloadDtc) => payloadDtc.id !== dtc.id))
  }

  const columns = [
    {
      title: <FormattedMessage id="johnDeere.manualDTCPage.columns.alert" />,
      dataIndex: 'dtc',
    },
    {
      title: <FormattedMessage id="johnDeere.manualDTCPage.columns.acronym" />,
      dataIndex: 'acronym',
    },
    {
      title: (
        <FormattedMessage id="johnDeere.manualDTCPage.columns.shortDescription" />
      ),
      dataIndex: 'short_description',
    },
  ]
  const dtcColumns = [
    ...columns,
    {
      title: '',
      key: 'button',
      render: (text, record, index) => {
        const isAdded = payloadDtcs.some((dtc) => dtc.id === record.id)
        return (
          <Button
            type="primary"
            disabled={isAdded}
            onClick={() => addPayloaddDtc(record)}
          >
            <FormattedMessage id="johnDeere.manualDTCPage.columns.add" />
          </Button>
        )
      },
    },
  ]
  const payloadColumns = [
    ...columns,
    {
      title: '',
      key: 'button',
      render: (text, record, index) => (
        <Button type="primary" onClick={() => removePayloaddDtc(record)}>
          <FormattedMessage id="johnDeere.manualDTCPage.columns.remove" />
        </Button>
      ),
    },
  ]

  return (
    <div
      style={{
        margin: 'auto',
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'start',
        }}
      >
        <h2>
          <FormattedMessage id="johnDeere.manualDTCPage.manualAlerts" />
        </h2>
      </div>
      <div
        style={{
          width: '48%',
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
          <div>
            <FormattedMessage id="johnDeere.manualDTCPage.vin" />
          </div>
          <Input
            maxLength={17}
            placeholder="Enter VIN"
            onChange={(event) => setVin(event.target.value)}
          />
        </div>
        <div style={{ width: '40%' }}>
          <div>
            <FormattedMessage id="johnDeere.manualDTCPage.alert" />
          </div>
          <Input.Search
            placeholder="Search Alerts"
            enterButton="Search"
            onSearch={(value, event) => setSearchString(value)}
            size="medium"
            loading={isLoading}
            disabled={!(vin && vin.length === 17)}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <div style={{ width: '48%' }}>
          <Table
            rowKey={(record) =>
              `${record.make_model_id}-${record.acronym}-${record.dtc}`
            }
            size="small"
            columns={dtcColumns}
            dataSource={dtcs}
            loading={isLoading}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            className="jd-manual-alerts"
          />
        </div>
        <div
          style={{
            width: '4%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RightSquareFilled style={{ fontSize: '24px' }} />
        </div>
        <div style={{ width: '48%' }}>
          <Table
            rowKey={(record) =>
              `${record.make_model_id}-${record.acronym}-${record.dtc}`
            }
            size="small"
            columns={payloadColumns}
            dataSource={payloadDtcs}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            className="jd-manual-alerts"
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <div style={{ width: '20%' }}>
          <GenerateVehicleTestButton vin={vin} dtcs={payloadDtcs} />
        </div>
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(JohnDeereManualDTCPage))
