import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, shallowEqual, useSelector } from 'react-redux'
import get from 'lodash/get'

import { isContextActive } from '../services/context'
import { userHasPermission } from '../services/permissions'

/**
 * This component is a part of security model.
 * It renders children component if given("permission" prop) permission is allowed for the user("rules" prop)
 */
function MustHave(props) {
  const rules = useSelector(
    (state) => get(state, 'user.data.rules', []),
    shallowEqual,
  )
  const { permission, children, context, fallback } = props

  let allow = false

  if (permission && context) {
    allow = userHasPermission(rules, permission) && isContextActive(context)
  } else if (permission) {
    allow = userHasPermission(rules, permission)
  } else if (context) {
    allow = isContextActive(context)
  }

  if (!allow) {
    if (fallback && React.isValidElement(fallback)) {
      return fallback
    }
    return null
  }

  return children
}

MustHave.propTypes = {
  context: PropTypes.string,
  permission: PropTypes.string,
  fallback: PropTypes.node,
}

export default MustHave
