/*
  These components are a copy (with some cleanup) of
  "https://github.com/reaviz/reaflow/blob/master/src/Canvas.tsx"
  adjusted to the needs of Block Diagrams.
*/

import React, {
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useRef,
} from 'react'
import { useId, CloneElement } from 'rdk'
import { Node } from 'reaflow'
import { Edge } from 'reaflow'
import { MarkerArrow } from 'reaflow'
import { CanvasPosition } from 'reaflow'
import classNames from 'classnames'
import { CanvasProvider, useCanvas } from 'reaflow'
import { motion } from 'framer-motion'
import './CustomCanvas.scss'

const InternalCanvas = forwardRef(
  (
    {
      className,
      height = '100%',
      width = '100%',
      disabled = false,
      animated = true,
      arrow = <MarkerArrow />,
      node = <Node />,
      edge = <Edge />,
      onMouseEnter = () => undefined,
      onMouseLeave = () => undefined,
      onCanvasClick = () => undefined,
    },
    ref,
  ) => {
    const id = useId()
    const {
      pannable,
      layout,
      containerRef,
      svgRef,
      canvasHeight,
      canvasWidth,
      xy,
      zoom,
      setZoom,
      observe,
      zoomIn,
      zoomOut,
      positionCanvas,
      fitCanvas,
      setScrollXY,
      ...rest
    } = useCanvas()

    useImperativeHandle(ref, () => ({
      ...rest,
      observe,
      zoom,
      xy,
      layout,
      canvasHeight,
      containerRef,
      canvasWidth,
      svgRef,
      positionCanvas,
      setZoom,
      zoomIn,
      zoomOut,
      fitCanvas,
      setScrollXY,
    }))

    const mount = useRef(false)

    useLayoutEffect(() => {
      if (!mount.current && layout !== null && xy[0] > 0 && xy[1] > 0) {
        mount.current = true
      }
    }, [layout, xy])

    return (
      <div
        style={{ height, width }}
        className={classNames('canvas-container', className, {
          ['canvas-pannable']: pannable,
        })}
        ref={(el) => {
          observe(el)
          containerRef.current = el
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id={id}
          ref={svgRef}
          height={canvasHeight}
          width={canvasWidth}
          onClick={onCanvasClick}
        >
          {arrow !== null && (
            <defs>
              <CloneElement element={arrow} {...arrow} />
            </defs>
          )}
          <motion.g
            initial={{
              opacity: 0,
              scale: 0,
              transition: {
                translateX: false,
                translateY: false,
              },
            }}
            animate={{
              opacity: 1,
              translateX: xy[0],
              translateY: xy[1],
              scale: zoom,
              transition: animated
                ? {
                    velocity: 100,
                    translateX: { duration: mount.current ? 0.3 : 0 },
                    translateY: { duration: mount.current ? 0.3 : 0 },
                    opacity: { duration: 0.8 },
                    when: 'beforeChildren',
                  }
                : {
                    type: false,
                    duration: 0,
                    when: 'beforeChildren',
                  },
            }}
          >
            {layout?.edges?.map((e) => {
              const element = typeof edge === 'function' ? edge(e) : edge
              return (
                <CloneElement
                  key={e.id}
                  element={element}
                  disabled={disabled}
                  children={element.props.children}
                  {...e}
                  properties={{
                    ...e.properties,
                    ...(e.data ? { data: e.data } : {}),
                  }}
                  id={`${id}-edge-${e.id}`}
                />
              )
            })}
            {layout?.children?.map(({ children, ...n }) => {
              const element = typeof node === 'function' ? node(n) : node
              return (
                <CloneElement
                  key={n.id}
                  element={element}
                  disabled={disabled}
                  children={element.props.children}
                  animated={animated}
                  nodes={children}
                  childEdge={edge}
                  childNode={node}
                  {...n}
                  id={`${id}-node-${n.id}`}
                />
              )
            })}
          </motion.g>
        </svg>
      </div>
    )
  },
)

export const Canvas = forwardRef(
  (
    {
      selections = [],
      readonly = false,
      fit = false,
      nodes = [],
      edges = [],
      maxHeight = 2000,
      maxWidth = 2000,
      direction = 'DOWN',
      pannable = true,
      zoom = 1,
      defaultPosition = CanvasPosition.CENTER,
      zoomable = true,
      minZoom = -0.5,
      maxZoom = 1,
      onNodeLink = () => undefined,
      onNodeLinkCheck = () => undefined,
      onLayoutChange = () => undefined,
      onZoomChange = () => undefined,
      layoutOptions,
      ...rest
    },
    ref,
  ) => (
    <CanvasProvider
      layoutOptions={layoutOptions}
      nodes={nodes}
      edges={edges}
      zoom={zoom}
      defaultPosition={defaultPosition}
      minZoom={minZoom}
      maxZoom={maxZoom}
      fit={fit}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      direction={direction}
      pannable={pannable}
      zoomable={zoomable}
      readonly={readonly}
      onLayoutChange={onLayoutChange}
      selections={selections}
      onZoomChange={onZoomChange}
      onNodeLink={onNodeLink}
      onNodeLinkCheck={onNodeLinkCheck}
    >
      <InternalCanvas ref={ref} {...rest} />
    </CanvasProvider>
  ),
)
