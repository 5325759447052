import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Multiselect } from 'react-widgets'
import Select from '../Fields/Select'
import { required } from '../../validators'
import { createDtcList } from '../../selectors'
import { getSelectedDtcs, getDtcOptions } from '../../selectors/repairHistory'
import { addOtherProblem, updateOtherProblem } from '../../api/controller-api'
import { FieldEntry, FormButtons } from './index'

/*
 Helper FSCs
*/

class OtherDetails extends Component {
  constructor(props) {
    super(props)
    const isNew = !(this.props.item && this.props.item.id)
    this.state = {
      isNew,
      connectorType: 'other',
      selectedDtcs: this.props.selectedDtcs,
    }
    this.props.initialize({ item: this.props.item })
  }
  saveItem = (values) => {
    const { id, annotation } = values.item

    const reason = values.item.reason.value || values.item.reason
    const dtcs = _.map(this.state.selectedDtcs, 'value')
    if (this.state.isNew) {
      this.props.add({
        annotation,
        dtcs,
        reason,
      })
    } else {
      this.props.update({ id, annotation, dtcs, reason })
    }
  }

  onDtcChange = (selectedDtcs) => {
    this.setState({ selectedDtcs })
  }

  render = () => (
    <form
      id="repair-history-other-details"
      onSubmit={this.props.handleSubmit(this.saveItem)}
    >
      <div className="panel panel-default">
        <div className="panel-body">
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.faultTypeLabel',
            })}
          >
            <Select
              name="item.reason"
              options={this.props.faults}
              clearable={false}
              placeholder={this.props.intl.formatMessage({
                id: 'repairHistory.add.form.faultTypePlaceholder',
              })}
              validate={[required]}
            />
          </FieldEntry>
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.dtcCodesLabel',
            })}
          >
            <Multiselect
              name="item.dtcs"
              defaultValue={this.state.selectedDtcs}
              data={this.props.dtcOptions}
              onChange={this.onDtcChange}
              textField="label"
              valueField="value"
            />
          </FieldEntry>
          <FieldEntry
            label={this.props.intl.formatMessage({
              id: 'repairHistory.add.form.notesLabel',
            })}
          >
            <Field
              className="form-control annotation"
              name="item.annotation"
              type="text"
              component="textarea"
              placeholder={this.props.intl.formatMessage({
                id: 'repairHistory.add.form.notesPlaceholder',
              })}
              ref={(elem) => (this.elemAnnotation = elem)}
            />
          </FieldEntry>
          <FormButtons onCancel={() => this.props.onClose()} />
        </div>
      </div>
    </form>
  )
}
OtherDetails = reduxForm({
  form: 'repair-history-other-details',
  destroyOnUnmount: true,
})(OtherDetails)

const mapStateToProps = (state, ownProps) => {
  return {
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    dtcOptions: getDtcOptions(state),
    selectedDtcs: getSelectedDtcs(_.get(ownProps, 'item.dtcs', []))(state),
    faults: state.loggingState.faults.other.map((item) => ({
      value: item.key,
      label: item.key,
    })),
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _add: ({
    annotation,
    vehicleTestResultId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    addOtherProblem({
      annotation,
      vehicleTestResultId,
      dtcs,
      isProblem: true,
      makeModelId,
      reason,
      vin,
    })
  },
  _update: ({
    id,
    annotation,
    vehicleTestResultId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    updateOtherProblem({
      id,
      annotation,
      vehicleTestResultId,
      makeModelId,
      vin,
      isProblem: true,
      reason,
      dtcs,
    })
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    add: (args) => {
      dispatchProps._add({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
    update: (args) => {
      dispatchProps._update({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
  }
}

export const OtherDetailsContainer = injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(OtherDetails),
)
