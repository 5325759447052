import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'antd'

const subsystemsTableColumns = [
  {
    title: <FormattedMessage id="functionalDiagrams.subsystemsTable.system" />,
    dataIndex: ['system', 'name'],
    key: 'system',
  },
  {
    title: (
      <FormattedMessage id="functionalDiagrams.subsystemsTable.subsystem" />
    ),
    dataIndex: 'name',
    key: 'subsystem',
  },
]

export function SubsystemsViewTable({
  subsystems,
  searchString,
  systemTypesFilter,
  pageSize = 15,
  susbsystemCallback,
}) {
  const [selectedRowKey, setSelectedRowKey] = useState(null)

  const filteredSubsystems = searchList({
    items: subsystems?.map((item) => {
      return {
        ...item,
        key: item.id,
      }
    }),
    searchString,
    fields: [['name'], ['system', 'name']],
    includeFilter: systemTypesFilter,
    includeField: ['system', 'name'],
  })

  const selectSubsystem = ({ record }) => {
    setSelectedRowKey(record.id)
    susbsystemCallback({ record })
  }

  return (
    <Table
      rowSelection={{
        type: 'radio',
        onSelect: (record, selected, selectedRows, nativeEvent) =>
          selectSubsystem({ record }),
        selectedRowKeys: [selectedRowKey],
      }}
      size="small"
      dataSource={filteredSubsystems}
      columns={subsystemsTableColumns}
      pagination={{
        pageSize: pageSize,
        hideOnSinglePage: true,
      }}
      onRow={(record, rowIndex) => ({
        onClick: (event) => selectSubsystem({ record }),
      })}
      data-testid="subsystems-table"
    />
  )
}

export function searchList({
  items = [],
  searchString = '',
  fields = [],
  includeFilter = [],
  includeField = [],
}) {
  if (searchString) {
    searchString = searchString.toLowerCase()

    items = items.filter((object) =>
      fields.some((field) =>
        getValueByProps({ object, props: field })
          .toLowerCase()
          .includes(searchString),
      ),
    )
  }

  if (includeField && includeFilter && includeFilter.length) {
    const types = new Set(includeFilter)
    items = items.filter((object) =>
      types.has(getValueByProps({ object, props: includeField })),
    )
  }

  return items
}

const getValueByProps = ({ object, props }) => {
  return props.reduce((acc, prop) => acc[prop], object)
}

export default SubsystemsViewTable
