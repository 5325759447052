import React from 'react'
import NodeLabel from '../labels/Label'
import { Node, Port } from '../reaflow'

function portRouter({ port, onPortClick }) {
  const { category } = port.properties

  switch (category) {
    case 'inline-prime':
      return (
        <Port
          onClick={() => {}}
          style={{ visibility: 'hidden' }}
          labelStyle={{ visibility: 'hidden' }}
        />
      )
    default:
      return <Port onClick={(event, port) => onPortClick({ event, port })} />
  }
}

export default function BaseNode({
  label = <NodeLabel />,
  port = <Port />,
  onPortClick,
  onNodeClick,
  children,
  ...props
}) {
  return (
    <Node
      {...props}
      port={(port) => portRouter({ port, onPortClick })}
      onClick={(event, node) => onNodeClick({ event, node })}
      label={label}
    >
      {children}
    </Node>
  )
}
