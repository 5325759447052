import React from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import { navigateToDashboard } from '../history'
import { isEmbeddedMode, useFunctionalDiagrams } from '../services/navigation'
import Navigation from './Navigation'
import ModalContainer from '../containers/ModalContainer'
import Notification from '../components/Notification'
import Logo from '../components/Logo'
import Routing from '../router'
import RoutingEmbeddedMode from '../EmbeddedMode/router'
import FunctionalDiagramsRouter from '../FunctionalDiagrams/router'
import UserMenu from '../containers/UserMenu'
import VehicleMakeModelName from '../components/VehicleMakeModelName'

const App = ({ location }) => {
  const dispatch = useDispatch()
  const isFunctionalDiagrams = useFunctionalDiagrams()

  const props = useSelector((state) => {
    return {
      notificationContainer: state.notificationState.container,
      modalContainers: state.modalState.containers,
      user: state.user,
      vin: state.componentState.vin || state.app.vin,
    }
  }, shallowEqual)

  let vin = props.vin
  if (isFunctionalDiagrams) {
    vin = location.pathname.split('/')[3]

    return (
      <div className="functional-diagrams-page">
        <div className="functional-diagrams-page-top-bar">
          <div className="functional-diagrams-page-top-bar-logo">
            <Logo
              className={'functional-diagrams-page-top-bar-logo-image'}
              navigateToDashboard={() => dispatch(navigateToDashboard)}
            />
          </div>
          <div className="navbar-vehicle-make-model">
            {vin && (
              <VehicleMakeModelName
                className="vehicle-make-model vehicle-make-model-menu navbar-user-menu"
                vin={vin}
                showVin={true}
              />
            )}
          </div>
          <div className="navbar-spacer"></div>
          <div className="functional-diagrams-page-top-bar-user-menu">
            <UserMenu className="user-menu navbar-user-menu" />
          </div>
        </div>
        <FunctionalDiagramsRouter />
      </div>
    )
  }

  if (isEmbeddedMode(location.search)) {
    return (
      <>
        <RoutingEmbeddedMode />
        <Notification container={props.notificationContainer} />
        <ModalContainer containers={props.modalContainers} />
      </>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div
          id="nav-row"
          className="row row-no-padding nav-row row-fill-container"
        >
          <div className="col-xs-3 col-lg-2 navbar-brand">
            <Logo navigateToDashboard={() => dispatch(navigateToDashboard)} />
          </div>
          <div className="col-xs-3 col-lg-2 navbar-vehicle-make-model">
            {vin && (
              <VehicleMakeModelName
                className="vehicle-make-model vehicle-make-model-menu navbar-user-menu"
                vin={vin}
                showVin={false}
              />
            )}
          </div>
          <div className="col-xs-4 col-lg-6">
            <Navigation
              showViewNavigation={
                location.pathname.includes('/tracer/') ||
                location.pathname.includes('/vehicle-test/preview')
              }
            />
          </div>
          <div className="col-xs-2 col-lg-2">
            <UserMenu className="user-menu navbar-user-menu" />
          </div>
        </div>
        <Routing />
      </div>
      <Notification container={props.notificationContainer} />
      <ModalContainer containers={props.modalContainers} />
    </>
  )
}

App.propTypes = {
  notificationContainer: PropTypes.string,
  modalContainers: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object,

  // react-router props
  location: PropTypes.shape({
    pathname: PropTypes.string, // e.g. "/tracer/32023e0b68ae435bbe9c48275113a629:42"
    search: PropTypes.string, // e.g. "?embedded"
    hash: PropTypes.string,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string, // e.g. "/tracer/:pathId"
    url: PropTypes.string, // e.g. "/tracer/32023e0b68ae435bbe9c48275113a629:42"
    params: PropTypes.shape({
      pathId: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
}

export default withRouter(App)
