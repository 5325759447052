import * as types from '../actions/action-types'
import moment from 'moment'
export function forceRetry() {
  return {
    type: types.SET_RETRY_NOW,
    retryTime: moment(),
  }
}
export function setRetryingTime(retryTime) {
  return {
    type: types.SET_RETRY_TIME,
    retryTime: retryTime,
  }
}
