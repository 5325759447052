import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'

const GlobalSearchContext = createContext(null)
const GlobalSearchDispatchContext = createContext(null)

export function GlobalSearchProvider({ children }) {
  const [state, dispatch] = useImmerReducer(
    globalSearchReducer,
    initialGlobalSearch,
  )

  return (
    <GlobalSearchContext.Provider value={state}>
      <GlobalSearchDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalSearchDispatchContext.Provider>
    </GlobalSearchContext.Provider>
  )
}

export function useGlobalSearchContext() {
  return useContext(GlobalSearchContext)
}

export function useGlobalSearchDispatch() {
  const dispatch = useContext(GlobalSearchDispatchContext)

  const reset = () => dispatch({ type: 'reset' })

  const handleComponentSelection = ({
    componentId,
    locationImageName,
    imageName,
    color,
    alias,
    category,
  }) => {
    dispatch({
      type: 'component_selected',
      componentId,
      locationImageName,
      imageName,
      color,
      alias,
      category,
    })
  }

  const handleDtcsSelection = ({ dtcs }) => {
    dispatch({
      type: 'dtcs_selected',
      dtcs,
    })
  }

  const handleDtcsSelectionReset = () => {
    dispatch({
      type: 'dtcs_reset',
    })
  }

  const handleSearch = ({ vin, searchString }) => {
    dispatch({
      type: 'search_executed',
      searchString,
      vin,
    })
  }

  const handleRelatedSubsystemComponentId = ({
    relatedSubsystemComponentID,
  }) => {
    dispatch({
      type: 'subsystem_componentId',
      componentId: relatedSubsystemComponentID,
    })
  }

  return {
    handleSearch,
    handleComponentSelection,
    handleDtcsSelection,
    handleDtcsSelectionReset,
    handleRelatedSubsystemComponentId,
    reset,
  }
}

function globalSearchReducer(draft, action) {
  switch (action.type) {
    case 'reset': {
      draft.selection = initialGlobalSearch.selection
      break
    }
    case 'component_selected': {
      draft.selection.componentId = action.componentId
      draft.selection.locationImageName = action.locationImageName
      draft.selection.alias = action.alias
      draft.selection.color = action.color
      draft.selection.imageName = action.imageName
      draft.selection.category = action.category
      break
    }
    case 'dtcs_selected': {
      draft.dtcSelection.dtcs = action.dtcs
      break
    }
    case 'dtcs_reset': {
      draft.dtcSelection = initialGlobalSearch.dtcSelection
      break
    }
    case 'search_executed': {
      draft.selection = initialGlobalSearch.selection
      draft.dtcSelection = initialGlobalSearch.dtcSelection
      draft.vin = action.vin
      draft.searchString = action.searchString
      break
    }
    case 'subsystem_componentId': {
      draft.subsystems.componentId = action.componentId
      break
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialGlobalSearch = {
  vin: null,
  searchString: null,
  dtcSelection: {
    dtcs: [],
  },
  selection: {
    locationImageName: null,
    alias: null,
    color: null,
    imageName: null,
    componentId: null,
    category: null,
  },
  subsystems: {
    componentId: null,
  },
}
