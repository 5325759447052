import * as types from '../actions/action-types'

export function clearPinDestination() {
  return {
    type: types.CLEAR_PIN_DESTINATION,
  }
}

export function clearSelectedPin() {
  return {
    type: types.CLEAR_SELECTED_PIN,
  }
}

export function selectElement(selectedElement) {
  return {
    type: types.SELECT_ELEMENT_IN_PIN_DESTINATION,
    selectedElement,
  }
}

export function selectPin(pinNumber, pin, componentId) {
  return {
    type: types.SELECT_PIN,
    pinNumber,
    pin,
    componentId,
  }
}

export function setPinDestination(pinDestination) {
  return {
    type: types.SET_PIN_DESTINATION,
    pinDestination,
  }
}
