import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconVinSearch = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.width}
      viewBox={viewBox}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="iconVinSearch"
          d="M58.5 32a1.5 1.5 0 0 1-1.5-1.5V9a6.007 6.007 0 0 0-6-6H9a6.007 6.007 0 0 0-6 6v32a6.007 6.007 0 0 0 6 6h31.5a1.5 1.5 0 0 1 0 3H9a9 9 0 0 1-9-9V9a9 9 0 0 1 9-9h42a9 9 0 0 1 9 9v21.5a1.5 1.5 0 0 1-1.5 1.5zM51 29h-3V9h3v20zm-6 0h-3V9h3v20zM27 41h-3V9h3v32zM21 9v32h-6V9h6zm18 0v32h-9V9h9zM12 9v32H9V9h3zm47.959 38.837a1.5 1.5 0 1 1-2.122 2.122l-3.2-3.202a7.5 7.5 0 1 1 2.121-2.121l3.2 3.201zm-5.703-4.857a4.5 4.5 0 1 0-1.277 1.277l.769-.51.508-.767z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g transform="translate(10 15)">
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconVinSearch" />
        </g>
      </g>
    </svg>
  )
}

IconVinSearch.defaultProps = defaultProps

export default IconVinSearch
