import React from 'react'
import IconClose from './Icons/IconClose'
import classNames from 'classnames'

const NotificationGeneric = (props) => {
  const notificationClasses = classNames(
    'notification-base',
    props.wrapperClass,
  )

  return (
    <div className={notificationClasses}>
      <div>
        {props.message}
        {props.children}
        <a onClick={props.close} className="icon-close">
          <IconClose />
        </a>
      </div>
    </div>
  )
}

export default NotificationGeneric
