export function getShortDescription(dtc, intl) {
  let shortDescription = intl.formatMessage({ id: 'state.noDescription' })

  if (!dtc.is_supported && !dtc.is_misconfigured) {
    // DTC can be still unsupported but editor is already provided a short description
    if (dtc.memo && dtc.memo.short_desc) {
      shortDescription = dtc.memo.short_desc
    } else if (dtc.memo && !dtc.memo.memo && !dtc.memo.short_desc) {
      shortDescription =
        dtc.description ||
        intl.formatMessage({ id: 'tracer.dtcListPanel.unsupported' })
    }
  } else if (dtc.description) {
    shortDescription = dtc.description
  }

  return shortDescription.slice(0, 100)
}
