const buildQueryString = (queryParams = {}) => {
  let queryString = ''
  queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  if (queryString) {
    queryString = '?' + queryString
  }
  return queryString
}

const getQueryParam = (paramName) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(paramName)
}

const setQueryParameter = (param, value) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(param, value)
  const newUrl =
    window.location.protocol +
    `//${window.location.host}${window.location.pathname}` +
    `?${searchParams.toString()}`
  window.history.replaceState({ path: newUrl }, '', newUrl)
}

const customerUrlRegex = RegExp(/^([^.]+)\.tracer\.tweddle\.(tech|io)/)

const getCustomerSubdomainPart = (defaultValue = '') => {
  const subdomainMatch = window.location.host.match(customerUrlRegex)
  if (subdomainMatch) {
    return subdomainMatch[1]
  } else {
    return defaultValue
  }
}

const getCustomerEnvironment = (defaultValue = '') => {
  const subdomainMatch = window.location.host.match(customerUrlRegex)
  const envs = {
    tech: 'staging',
    io: 'production',
  }
  if (subdomainMatch && subdomainMatch.length === 3) {
    return envs[subdomainMatch[2]]
  } else {
    return defaultValue
  }
}

export default {
  buildQueryString,
  getQueryParam,
  setQueryParameter,
  getCustomerSubdomainPart,
  getCustomerEnvironment,
}
