import React from 'react'
import { withRouter } from 'react-router'
import history from '../../history'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatTimestamp } from '../../helpers/utils'
import NavigationTabs from '../Navigation/NavigationTabs'
import {
  ADD_DTC_MEMO_VIEW,
  DTC_CROWDSOURCED_EDIT_NAMESPACE,
  DTC_MAPPING_ACTIVE_UI,
  MANAGE_MAPPINGS_VIEW,
} from '../../constants'
import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import { useDTCMemo, useUpdateDTCMemo } from '../../hooks/DTCMemo'
import { useDeleteDTCMapping } from '../../hooks/CrowdsourcedDTCMapping'
import { useActiveDTCMapping } from '../../hooks/ActiveDTCMapping'
import MustHave from '../../containers/MustHave'
import Forbidden from '../Forbidden'
import { requireAuthentication } from '../../helpers/authentication'
import TitleBar from '../TitleBar'
import DTCMemo from '../DTCMemo'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { AddConnectorMapping } from './AddConnectorMapping'
import AddConnectorMappingStartComponent from './AddConnectorMappingStartComponent'
import { TableButton, EditDTCMappingsHeader } from './CrowdsourcedDTCMapping'
import Loading from '../Loading'
import RoundBackButton from '../Buttons/RoundBackButton'

const MemoAndMappingContent = ({ dtcMemoId }) => {
  const dispatch = useDispatch()
  const { dtcMemo, isLoading, isSuccess } = useDTCMemo(dtcMemoId)
  const updateDTCMemo = useUpdateDTCMemo()

  return (
    <>
      {isLoading && <Loading useBackgroundColor={false} />}
      {isSuccess && (
        <>
          <div className="row memo-mapping-header">
            <div className="col-md-4">
              <RoundBackButton onClick={history.goBack} />
            </div>
            <div className="col-md-4">
              <NavigationTabs namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE} />
            </div>
          </div>
          <TabContent>
            <TabPane
              id={ADD_DTC_MEMO_VIEW}
              position={0}
              canBeDeactivated={false}
              namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE}
            >
              <DTCMemo
                memoId={dtcMemoId}
                initialMemo={
                  history.location.state?.initialMemo ?? dtcMemo.memo
                }
                initialShortDescription={
                  history.location.state?.initialShortDescription ??
                  dtcMemo.short_desc
                }
                acronym={dtcMemo.acronym}
                makeModelStr={dtcMemo.make_model_str}
                dtcError={dtcMemo.dtc}
                dtcId={dtcMemo.dtc_id}
                onSubmit={updateDTCMemo}
              />
            </TabPane>
            <TabPane
              id={MANAGE_MAPPINGS_VIEW}
              position={1}
              canBeDeactivated={false}
              namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE}
            >
              <div className="container">
                <div className="row mb-3">
                  <EditDTCMappingsHeader dtcMemoId={dtcMemoId} />
                  <TitleBar wrapperClass="container left-right">
                    <span className="left-col">
                      <FormattedMessage id="crowdsourcedDtcMapping.currentMappingLabel" />
                    </span>
                    <button
                      className="btn table-btn uppercase"
                      onClick={() =>
                        dispatch(
                          setActiveUIComponent(
                            DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT,
                          ),
                        )
                      }
                    >
                      <FormattedMessage id="crowdsourcedDtcMapping.newMappingButton" />
                    </button>
                  </TitleBar>
                  <DTCMapping
                    makeModelId={dtcMemo.make_model_id}
                    dtcCode={dtcMemo.dtc}
                    acronym={dtcMemo.acronym}
                  />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </>
      )}
    </>
  )
}

const DTCMapping = injectIntl(function ({
  makeModelId,
  dtcCode,
  acronym,
  intl,
}) {
  const { activeDTCMappings, isLoading, isSuccess } = useActiveDTCMapping({
    makeModelId,
    dtcCode,
    acronym,
  })
  const deleteDTCMapping = useDeleteDTCMapping()
  const dateTimeFormat = intl.formatMessage({ id: 'format.timestampFull' })

  const renderStartComponents = (start_components) => {
    return start_components.map((start_component, index) => (
      <React.Fragment key={`${start_component.id}-${index}`}>
        <strong>{start_component.id}</strong>
        <br />
        {start_component?.description}
        <br />
      </React.Fragment>
    ))
  }

  return (
    <>
      {isLoading && 'Loading'}
      {isSuccess && (
        <table className="table">
          <thead className="bs-table-header">
            <tr>
              <th>
                <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.destination" />
              </th>
              <th>
                <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.start" />
              </th>
              <th>
                <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.updatedat" />
              </th>
              <th>
                <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.updatedby" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="bs-table-body">
            {activeDTCMappings.data.map((mapping) => (
              <tr
                key={`${mapping.make_model_id}-${mapping.dtc_code}-${mapping.acronym}-${mapping.destination_component.id}-${mapping.start_components}`}
              >
                <td>
                  <strong>{mapping.destination_component.id}</strong>
                  <br />
                  {mapping.destination_component.description}
                </td>
                <td>
                  {mapping.start_components.length > 0
                    ? renderStartComponents(mapping.start_components)
                    : 'Destination Only'}
                </td>
                <td>{formatTimestamp(dateTimeFormat, mapping.updated_at)}</td>
                <td>{mapping.updated_by}</td>
                <td>
                  {mapping.mapping_type === 'crowdsourced' && (
                    <TableButton
                      onClick={() => deleteDTCMapping.mutate(mapping)}
                    >
                      <FormattedMessage id="crowdsourcedDtcMapping.delete" />
                    </TableButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
})

const DTCMemoAndMappingWrapper = ({
  match: {
    params: { dtcMemoId, vin },
  },
}) => {
  // State from dtcMappingReducer
  const props = useSelector((state) => state.dtcMapping, shallowEqual)

  let InnerContent

  switch (props.activeUIComponent) {
    case DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT:
      InnerContent = AddConnectorMapping
      break
    case DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_START:
      InnerContent = AddConnectorMappingStartComponent
      break
    default:
      InnerContent = MemoAndMappingContent
  }

  return (
    <>
      <MustHave permission="dtc-memo:get" fallback={<Forbidden />}>
        <InnerContent dtcMemoId={dtcMemoId} vin={vin} />
      </MustHave>
    </>
  )
}

export default withRouter(requireAuthentication(DTCMemoAndMappingWrapper))
