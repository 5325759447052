import React, { Component } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { List, Card } from 'antd'

// import './style.scss'
/*
import {
    INIT_MAIN_VIEW_QUERY_PARAM,
    MODAL_ADD_FAULT,
    MODAL_HARNESS_STATE,
    VEHICLE_VIEW,
} from '../../constants'
import Topology from '../SvgViews/Topology'
import VehicleTestResultNavigation from '../VehicleTestResultNavigation'
import {setComponent, setModal} from '../../actions/modal-actions'
import Button from '../Buttons/Button'
import store from "../../store";
*/

export const VehicleDetails = ({ salesCodeConstraints }) => {
  return (
    <Card title="Vehicle details">
      <List
        size="small"
        rowKey={'pin'}
        dataSource={salesCodeConstraints}
        grid={{ column: 2 }}
        renderItem={(item) => (
          <List.Item>
            <h5>{item.group.description}:</h5>
            {item.sales_code.description}
          </List.Item>
        )}
      />
    </Card>
  )
}

export default VehicleDetails
