import {
  apiPostVirtualVehicleConfiguration,
  apiGetVINFromSalesCodes,
} from '../api/virtual-vehicle-configuration-api'
import { useMutation, useQuery } from 'react-query'

const VIN_SEARCH_KEY = 'vin-search-key'

export const useCreateVirtualVehicleConfiguration = () => {
  return useMutation(({ makeModelID, salesCodes }) =>
    apiPostVirtualVehicleConfiguration({ makeModelID, salesCodes }),
  )
}

export const useSalesCodesToFetchVIN = ({ salesCodesString, makeModelID }) => {
  const {
    status,
    data: results,
    error,
    isFetching,
    isLoading: isVinLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    [VIN_SEARCH_KEY, salesCodesString, makeModelID],
    async () => {
      const result = await apiGetVINFromSalesCodes(
        salesCodesString,
        makeModelID,
      )
      return result.data.data
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        !!salesCodesString && salesCodesString.length != 0 && !!makeModelID,
    },
  )

  return {
    status,
    results,
    error,
    isVinLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}
