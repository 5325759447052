import React from 'react'

const defaultProps = {
  height: '80',
  width: '80',
}

const IconSearch = (props) => {
  const viewBox = `0 0 100 100`
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        id="iconSearchBorder"
        d="M87.499 57.3648C87.499 58.4073 86.654 59.2523 85.6116 59.2523C84.5691 59.2523 83.7241 58.4073 83.7241 57.3648V30.2508C83.7193 26.1105 80.5517 22.5049 76.384 22.5001H23.8249C19.6571 22.5049 16.2797 25.8601 16.275 30.0004V70.2523C16.2797 74.3926 19.8323 77.4957 24 77.5004H63.0726C64.1082 77.5004 64.9477 78.3399 64.9477 79.3755C64.9477 80.4111 64.1082 81.2506 63.0726 81.2506H24C17.7455 81.2506 12.5 76.4657 12.5 70.2523V30.0004C12.5 23.787 17.5703 18.75 23.8249 18.75H76.384C82.6385 18.75 87.499 24.0374 87.499 30.2508V57.3648ZM87.4472 78.7995C88.1843 79.5317 88.1843 80.719 87.4472 81.4512C86.7101 82.1835 85.515 82.1835 84.7779 81.4512L80.7495 77.4494C79.2576 78.431 77.4685 79.0027 75.545 79.0027C70.3329 79.0027 66.1077 74.8052 66.1077 69.6273C66.1077 64.4494 70.3329 60.2519 75.545 60.2519C80.7571 60.2519 84.9824 64.4494 84.9824 69.6273C84.9824 71.5382 84.407 73.3155 83.4188 74.7976L87.4472 78.7995ZM81.2074 69.6273C81.2074 70.75 80.8796 71.8147 80.2715 72.7268L79.6314 73.6869L78.665 74.3228C77.7469 74.9268 76.6751 75.2525 75.545 75.2525C72.4177 75.2525 69.8826 72.734 69.8826 69.6273C69.8826 66.5206 72.4177 64.0021 75.545 64.0021C78.6723 64.0021 81.2074 66.5206 81.2074 69.6273Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        id="iconSearch"
        d="M43.0873 39C42.5705 39 42.0902 39.2608 41.8163 39.69L38.2162 45.3323C37.8003 45.9842 38.2787 46.8298 39.0635 46.8298H60.9367C61.7215 46.8298 62.1999 45.9842 61.784 45.3323L58.1839 39.69C57.91 39.2608 57.4297 39 56.913 39H43.0873ZM36.7612 48.2979C36.2094 48.2979 35.762 48.7361 35.762 49.2766V61.0213C35.762 61.5618 36.2094 62 36.7612 62H40.0085C40.5603 62 41.0076 61.5618 41.0076 61.0213V57.8404H58.9926V61.0213C58.9926 61.5618 59.4399 62 59.9918 62H63.239C63.7909 62 64.2382 61.5618 64.2382 61.0213V49.2766C64.2382 48.7361 63.7909 48.2979 63.239 48.2979H62.2399H59.9918H40.0085H37.7603H36.7612ZM42.7562 53.1915C42.7562 54.4077 41.7496 55.3936 40.508 55.3936C39.2664 55.3936 38.2599 54.4077 38.2599 53.1915C38.2599 51.9753 39.2664 50.9894 40.508 50.9894C41.7496 50.9894 42.7562 51.9753 42.7562 53.1915ZM59.2424 55.3936C60.484 55.3936 61.4905 54.4077 61.4905 53.1915C61.4905 51.9753 60.484 50.9894 59.2424 50.9894C58.0008 50.9894 56.9943 51.9753 56.9943 53.1915C56.9943 54.4077 58.0008 55.3936 59.2424 55.3936ZM62.5831 44.746L63.0873 46.0318C63.2374 46.4147 63.6068 46.6667 64.0182 46.6667H65.4287C65.981 46.6667 66.4287 46.219 66.4287 45.6667V44.381C66.4287 43.8287 65.981 43.381 65.4287 43.381H63.514C62.8095 43.381 62.3258 44.0901 62.5831 44.746ZM36.913 46.0318L37.4172 44.746C37.6744 44.0901 37.1908 43.381 36.4862 43.381H34.5715C34.0192 43.381 33.5715 43.8287 33.5715 44.381V45.6667C33.5715 46.219 34.0192 46.6667 34.5715 46.6667H35.982C36.3934 46.6667 36.7628 46.4147 36.913 46.0318Z"
      />
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g>
          <use
            fill="#FFFFFF"
            fillRule="nonzero"
            xlinkHref="#iconSearchBorder"
          />
          <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#iconSearch" />
        </g>
      </g>
    </svg>
  )
}

IconSearch.defaultProps = defaultProps

export default IconSearch
