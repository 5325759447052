export default function (sortOrder, sortProperty, a, b) {
  const orderFactor = sortOrder === 'asc' ? 1 : -1
  const key = sortProperty
  // eslint-disable-next-line
  if (a[key] == b[key]) {
    return 0
  }
  const numOrder = a[key] < b[key] ? -1 : 1
  return orderFactor * numOrder
}
