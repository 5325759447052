import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EBONY_CLAY_COLOR } from '../constants'

const defaultProps = {
  backgroundColor: EBONY_CLAY_COLOR,
  text: <FormattedMessage id="processes.loading" />,
  useBackgroundColor: true,
  className: 'loading',
}

class Loading extends Component {
  renderLoadingIcon() {
    const { height } = this.props
    const inlineStyle = {
      color: 'white',
      width: '100%',
      height: height || '100%',
    }
    if (this.props.useBackgroundColor) {
      inlineStyle['backgroundColor'] = this.props.backgroundColor
    }

    return (
      <div className="text-center" style={inlineStyle}>
        <i
          className="fa fa-circle-o-notch fa-spin fa-5x fa-fw"
          style={{ position: 'relative', top: '40%' }}
        />
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  renderLoadingText() {
    return this.props.text ? <p>{this.props.text}</p> : null
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderLoadingText()}
        {this.renderLoadingIcon()}
      </div>
    )
  }
}

Loading.defaultProps = defaultProps

export function Loadable({ isLoading, children }) {
  if (isLoading) {
    return (
      <div>
        <Loading useBackgroundColor={false} />
      </div>
    )
  }
  return children
}

export default Loading
