import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  basename: '/',
})

export function navigateToDashboard() {
  history.push('/')
}

export default history
