import * as types from '../actions/action-types'

const initialState = {
  harnessesForModel: [],
  inlineComponents: [],
}

const harnessReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_INLINE_COMPONENTS:
      return Object.assign({}, state, {
        inlineComponents: [],
      })
    case types.SET_HARNESSES_FOR_MODEL:
      return Object.assign({}, state, {
        harnessesForModel: action.harnesses,
      })
    case types.SET_INLINE_COMPONENTS:
      return Object.assign({}, state, {
        inlineComponents: action.components,
      })
    case types.SET_HARNESS_LEVELS:
      return Object.assign({}, state, {
        harnessLevels: action.harnessLevels,
      })
    default:
      return state
  }
}

export default harnessReducer
