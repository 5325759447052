import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import React, { Component } from 'react'
import Select from 'react-select'

import { capitalizeAllWords } from '../../helpers/utils'
import { GRAY_COLOR } from '../../constants'

class SelectWithRadioButtons extends Component {
  // Assume that options for both Select and RadioButtons are
  // in the form of [{value: 3, label: 'Three'}]
  render() {
    const {
      className,
      name,
      onChange,
      options,
      placeholder,
      selected,
      titleText,
      classNamePrefix,
    } = this.props
    const { allOptions, suggestedOptions } = options

    const CombinedComponent = () => (
      <div className={className}>
        <RadioButtons
          name={name}
          options={suggestedOptions}
          onChange={onChange}
          selected={selected}
          titleText={titleText}
        />
        <Select
          classNamePrefix={classNamePrefix}
          name={name}
          options={allOptions}
          onChange={onChange}
          value={selected}
          placeholder={placeholder}
        />
      </div>
    )

    return <CombinedComponent />
  }
}

const LeadText = ({ titleText }) => {
  if (isEmpty(titleText)) return null

  const style = {
    color: GRAY_COLOR,
    fontStyle: 'italic',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  }
  return <label style={style}>{titleText}</label>
}

const RadioButtons = (props) => {
  const { name, onChange, options, selected, titleText } = props

  const shouldRender = !isEmpty(name) && !isEmpty(options)

  if (shouldRender) {
    return (
      <React.Fragment>
        <LeadText titleText={titleText} />
        {options.map((option, index) => {
          const styles = {
            wrapper: {
              display: 'block',
              marginBottom: '1rem',
            },
            text: {
              marginLeft: '1rem',
            },
          }

          return (
            <label
              key={`${index}`}
              htmlFor={`${name}-${index}`}
              style={styles.wrapper}
              className="clickable"
            >
              <input
                id={`${name}-${index}`}
                name={name}
                type="radio"
                value={option.value}
                checked={isEqual(selected, option) ? 'checked' : null}
                onChange={() => onChange(option)}
                className="clickable"
              />
              <span style={styles.text} className="modal-prominent-text">
                {capitalizeAllWords(option.label)}
              </span>
            </label>
          )
        })}
      </React.Fragment>
    )
  }
  return null
}

export default SelectWithRadioButtons
