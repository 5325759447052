import React from 'react'
import { useMeasure, useDebounce } from 'react-use'
import { Table, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import {
  useGlobalSearchContext,
  useGlobalSearchDispatch,
} from './GlobalSearchContext'
import { useAddEphemeralVehicleTest } from '../hooks/EphemeralVehicleTest'
import { generateTestPayload } from '../services/test-payload'
import { useSelector, shallowEqual } from 'react-redux'

export const GenerateEphemeralVehicleTestButton = () => {
  const context = useGlobalSearchContext()
  const user = useSelector((state) => state.user.data.username, shallowEqual)
  const addEphemeralVehicleTest = useAddEphemeralVehicleTest()
  const history = useHistory()

  const onClick = () => {
    const payload = generateTestPayload({
      vin: context.vin,
      userId: user,
      dtcs: context.dtcSelection.dtcs,
    })
    addEphemeralVehicleTest.mutate(
      { payload },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/vehicle-test/preview`, { result: data.data }),
      },
    )
  }

  return (
    <Button
      disabled={context.dtcSelection.dtcs.length === 0}
      onClick={onClick}
      type="primary"
      loading={addEphemeralVehicleTest.isLoading}
    >
      <FormattedMessage id="globalSearch.manualDtc.generateVehicleTestButton" />
    </Button>
  )
}

const dtcColumns = [
  {
    title: <FormattedMessage id="globalSearch.resultsTable.dtcResults.name" />,
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.dtcResults.acronym" />
    ),
    dataIndex: 'acronym',
    key: 'acronum',
    width: '15%',
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.commonColumns.description" />
    ),
    dataIndex: 'description',
    key: 'description',
    textWrap: 'word-break',
    ellipsis: true,
  },
]

const ResultsTable = ({ columns, results, isLoading, dataTestId, rowKey }) => {
  const [containerRef, { height: containerHeight }] = useMeasure()
  const globalSearchDispatch = useGlobalSearchDispatch()
  const context = useGlobalSearchContext()
  const [tableHeight, setTableHeight] = React.useState(0)

  // containerHeight changes its height multiple times during re-render
  // but only the largest (last) value is needed.
  useDebounce(
    () => {
      setTableHeight(containerHeight - 50) // 50 is the height of header.
    },
    1,
    [containerHeight],
  )

  return (
    <div className="global-search-results-table" ref={containerRef}>
      <Table
        data-testid={dataTestId}
        size="middle"
        columns={columns}
        dataSource={results}
        loading={isLoading}
        pagination={false}
        scroll={{
          y: tableHeight,
        }}
        rowKey={rowKey}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows, info) => {
            globalSearchDispatch.handleDtcsSelection({ dtcs: selectedRows })
          },
          selectedRowKeys: context.dtcSelection.dtcs.map((dtc) => rowKey(dtc)),
        }}
      />
    </div>
  )
}

export const DtcResults = ({ results, isLoading }) => {
  return (
    <ResultsTable
      columns={dtcColumns}
      results={results}
      loading={isLoading}
      dataTestId="dtc-results"
      rowKey={(record) => `${record.name}-${record.acronym}`}
    />
  )
}

export const ManualDtcs = () => {
  const context = useGlobalSearchContext()
  return (
    <ResultsTable
      columns={dtcColumns}
      results={context.dtcSelection.dtcs}
      loading={false}
      dataTestId="manual-dtcs"
      rowKey={(record) => `${record.name}-${record.acronym}`}
    />
  )
}
