import * as types from '../actions/action-types'

export function resetState() {
  return {
    type: types.ADD_FAULT_HARNESS_RESET_STATE,
  }
}

export function setSearchHarness(search) {
  return {
    type: types.ADD_FAULT_HARNESS_SET_SEARCH,
    search,
  }
}

export function setHarnesses(harnesses) {
  return {
    type: types.ADD_FAULT_HARNESS_SET_HARNESSS,
    harnesses,
  }
}

export function setSelectedHarness(selectedHarness) {
  return {
    type: types.ADD_FAULT_HARNESS_SET_SELECTED_HARNESS,
    selectedHarness,
  }
}

export function resetHarness() {
  return {
    type: types.ADD_FAULT_HARNESS_RESET_HARNESS,
  }
}
