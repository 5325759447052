import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { fetchComponentDetails, getIsLoading, getComponentData } from './slice'
import { requireAuthentication } from '../helpers/authentication'

import './styles.scss'
import TabContent from '../components/Navigation/TabContent'
import TabPane from '../components/Navigation/TabPane'
import {
  COMPONENT_DETAILS_NAMESPACE,
  PINOUT_VIEW,
  VEHICLE_VIEW,
} from '../constants'
import Topology from '../components/SvgViews/Topology'
import Loading from '../components/Loading'
import PinoutContainer from '../containers/PinoutContainer'
import NavigationTabs from '../components/Navigation/NavigationTabs'

const Page = ({ fetchComponentDetails, componentDetails, isLoading, vin }) => {
  useEffect(() => {
    fetchComponentDetails()
  }, [])

  return (
    <div className="row row-no-padding row-fill-container main-content">
      <div
        className="col-sm-3 col-lg-2 repair-history-container"
        id="repair-history"
      >
        <div className="vin-panel" style={{ height: '50px' }}>
          <div className="vin-panel-left">
            <div className="vin-item vin">
              <span>
                <FormattedMessage id="generic.vin" />:{' '}
              </span>
              <span>{vin}</span>
            </div>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="title-container">Component</div>
          </div>
          <div className="panel-collapse">
            <ul id="component-list" className="list-group">
              <li className="list-group-item">
                <div className="title-container">
                  <span className="title">{componentDetails?.alias}</span>
                  {isLoading && <span className="title">Loading...</span>}
                </div>
                <div className="subtitle">{componentDetails?.description}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xs-9 col-lg-10 gradient-container">
        <div style={{ width: '50%', marginTop: '-55px', marginLeft: '20%' }}>
          <NavigationTabs namespace={COMPONENT_DETAILS_NAMESPACE} />
        </div>
        <div className="gradient-top" />
        <div id="app-layout" className="row">
          <TabContent>
            <TabPane
              id={VEHICLE_VIEW}
              namespace={COMPONENT_DETAILS_NAMESPACE}
              position={0}
              enabled
            >
              {!isLoading && <Topology component={componentDetails} />}
              {isLoading && (
                <Loading useBackgroundColor={false} height={'100px'} />
              )}
            </TabPane>

            <TabPane
              id={PINOUT_VIEW}
              namespace={COMPONENT_DETAILS_NAMESPACE}
              position={1}
            >
              <PinoutContainer component={componentDetails} />
            </TabPane>
          </TabContent>
        </div>
        <div className="gradient-bottom" />
      </div>
    </div>
  )
}

const mapState = (
  state,
  {
    match: {
      params: { vin },
    },
  },
) => ({
  isLoading: getIsLoading(state),
  componentDetails: getComponentData(state),
  vin,
})

const mapDispatch = (
  dispatch,
  {
    match: {
      params: { vin, componentId },
    },
  },
) => ({
  fetchComponentDetails: () =>
    dispatch(fetchComponentDetails({ vin, componentId })),
})

export default connect(mapState, mapDispatch)(requireAuthentication(Page))
