import { useQuery } from 'react-query'
import { getFuseDetails } from '../api/power-distribution-api'

const FUSE_DETAILS_KEY = 'fuse-details'

export const useFuseDetails = ({ makeModelId, fuseName }) => {
  const {
    status,
    data: results,
    error,
    isFetching,
    isLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    [FUSE_DETAILS_KEY, makeModelId, fuseName],
    async () => {
      const result = await getFuseDetails({ makeModelId, fuseName })
      return result
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!makeModelId && !!fuseName,
    },
  )

  return {
    status,
    results,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}
