import React from 'react'
import { connect } from 'react-redux'
import CustomPayloadForm from '../components/CustomPayloadForm'
import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import { getDemoPayloads } from '../api/controller-api'
import { requireAuthentication } from '../helpers/authentication'

class DemoContainer extends React.Component {
  componentWillMount() {
    getDemoPayloads()
  }

  render() {
    return (
      <div>
        <MustHave permission="page-demo:get" fallback={<Forbidden />}>
          <CustomPayloadForm {...this.props} />
        </MustHave>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ProtectedDemoContainer = requireAuthentication(DemoContainer)

export default connect(mapStateToProps)(ProtectedDemoContainer)
