import React from 'react'
import history from '../history'
import { getNavigatedFromKey } from '../services/navigation'
import { ORIENTATION_BACK } from '../constants'
import IconChevron from './Icons/IconChevron'
import { FormattedMessage } from 'react-intl'

function BackButton({ intl }) {
  const historyLabelKey = `navigationHistory.${getNavigatedFromKey(window.location.search)}`
  const defaultMessage = intl.formatMessage({ id: 'generic.back' })
  return (
    <>
      <div className="back-button" onClick={history.goBack}>
        <span data-testid="toggle-icon" className="toggle-icon">
          <IconChevron orientation={ORIENTATION_BACK} />
        </span>
        <div className="">
          <div>
            <FormattedMessage
              id={historyLabelKey}
              defaultMessage={defaultMessage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BackButton
