import axiosWrapper from './axios-wrapper'

const preLoadFetcher = axiosWrapper.create({
  baseURL: '/tracer/device/',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function search(query, makeModelId) {
  return preLoadFetcher
    .get(`/search?query=${query}&make_model_id=${makeModelId}`)
    .then((res) => res.data)
}
