import { createSelector } from '@reduxjs/toolkit'
import { getPinNumberAsInt } from '../helpers/utils'
import { getPinLetter } from '../helpers/utils'
import naturalSort from 'javascript-natural-sort'
import flatten from 'lodash/flatten'
import cloneDeep from 'lodash/cloneDeep'
import _ from 'lodash'
import { COMPONENT_CATEGORY_DEVICE } from '../constants'

// selector parameter functions
const getCurrentComponent = (state) => state.componentState.selectedElement
const getShowEmptyPins = (state) => state.pinState.showEmptyPins

// label formatters
const letterNumberLabelFormatter = (letter) => (pinNumber) =>
  `${letter}${pinNumber}`
const numberLetterLabelFormatter = (letter) => (pinNumber) =>
  `${pinNumber}${letter}`

const emptyPinsFillers =
  (labelFormatter = _.identity) =>
  (showEmptyPins, pinArray, totalPins, pinsWithJumper) => {
    if (!showEmptyPins) return
    _.range(1, totalPins + 1)
      .filter((i) => pinArray[i] === undefined)
      .filter((i) => !pinsWithJumper.includes(`${i}`))
      .forEach((i) => {
        const label = labelFormatter(i)

        pinArray[i] = [
          {
            key: `empty-${label}`,
            cavity: label,
            color_desc: null,
            circuit_category: null,
            signal_desc: null,
            isEmpty: true,
          },
        ]
      })
  }

const emptyPinsFillersLetter =
  (labelFormatter = _.identity) =>
  (showEmptyPins, pinArray, totalPins) => {
    if (!showEmptyPins) return
    _.range(1, totalPins + 1)
      .filter((i) => pinArray[i] === undefined)
      .forEach((i) => {
        //const label = labelFormatter(i)
        const label = getPinLetter(i)
        //const label = String.fromCharCode(65 + i);
        pinArray[i] = [
          {
            key: `empty-${label}`,
            cavity: label,
            color_desc: null,
            circuit_category: null,
            signal_desc: null,
          },
        ]
      })
  }

// helper functions for groupping
const pinoutAsCollection = (pinout) =>
  _.keys(pinout).map((key) => _.merge({ _key: key }, { array: pinout[key] }))
const pinoutAsMap = (pinoutItems) =>
  _.reduce(
    pinoutItems,
    (memo, item) => {
      memo[item._key] = item.array
      return memo
    },
    {},
  )

// sorters

const alphanumericSorter = (labelFormatter) => (component, showEmptyPins) => {
  const { pinout, inline_siblings, total_pins, jumpers = {} } = component
  const grouppedByLetters = _.groupBy(pinoutAsCollection(pinout), (item) =>
    item._key.replace(/\d/g, ''),
  )
  const pinsWithJumper = collectPinsWithJumper(jumpers)

  const totalPinoutPerGroup = total_pins / _.keys(grouppedByLetters).length
  const result = _.reduce(
    _.keys(grouppedByLetters).sort(),
    (memo, groupKey) => {
      const pinoutItems = grouppedByLetters[groupKey]
      const pinoutOldFormat = pinoutAsMap(pinoutItems)
      const { pins } = simpleNumbersSorter(
        emptyPinsFillers(labelFormatter(groupKey)),
        simplePinNumberParser,
      )(
        {
          pinout: pinoutOldFormat,
          total_pins: totalPinoutPerGroup,
          inline_siblings,
        },
        showEmptyPins,
      )
      memo.push(pins)
      return memo
    },
    [],
  )

  const jumperPins = flatten(
    pinsWithJumper.map((pin) =>
      pinout[pin].map((wire) => ({
        ...wire,
        cavity: pin,
        originalCavityNumber: pin,
      })),
    ),
  )
  const pins = flatten(result).filter((value) => value !== undefined)

  // return combined pins collection with next ordering: pins without jumpers are ordered naturally
  // and pins with jumper are placed in the end of the list
  return {
    pins,
    jumperPins,
  }
}

const alphaSorter =
  (emptyPinsFillersLetter, pinNumberConverter) =>
  (component, showEmptyPins) => {
    const { pinout, jumpers = {} } = component
    const pinsWithJumper = collectPinsWithJumper(jumpers)
    const totalPins = component.total_pins
    const pinoutCopy = cloneDeep(pinout)
    const pinArray = []
    Object.keys(pinoutCopy)
      .filter(isPinWithoutJumperPredicate(pinsWithJumper))
      .sort(naturalSort)
      .forEach((pin) => {
        const pinNumber = pinNumberConverter(pin, component)

        pinArray[pinNumber] = pinoutCopy[pin]
          .filter((wire) => !wire.virtual)
          .filter((wire) => showEmptyPins || wire.in_session)
          .map((wire) => ({ ...wire, cavity: pin, originalCavityNumber: pin }))
      })

    emptyPinsFillersLetter(showEmptyPins, pinArray, totalPins)

    const jumperPins = flatten(
      pinsWithJumper.map((pin) =>
        pinoutCopy[pin].map((wire) => ({
          ...wire,
          cavity: pin,
          originalCavityNumber: pin,
        })),
      ),
    )
    const pins = flatten(pinArray)
      .filter((value) => value !== undefined)
      .filter((wire) => wire.circuit_category !== 'DEV')

    // return combined pins collection with next ordering: pins without jumpers are ordered naturally
    // and pins with jumper are placed in the end of the list
    return {
      pins,
      jumperPins,
    }
  }

const simplePinNumberParser = (pin) => parseInt(pin.replace(/\D/g, ''), 10)

const pinNumberWithInlineSiblingsParser = (component) => (pin) =>
  getPinNumberAsInt(pin, component.total_pins, component.inline_siblings)

const isPinWithoutJumperPredicate = (pinsWithJumper) => (pin) =>
  pinsWithJumper.indexOf(pin) === -1

const collectPinsWithJumper = (jumpers) =>
  Object.entries(jumpers).reduce(
    (result, [key, value]) => [key, value, ...result],
    [],
  )

const devicePinSorter = () => (component, showEmptyPins) => {
  const { pinout, jumpers = {} } = component
  const pinoutCopy = cloneDeep(pinout)
  const pinArray = []

  Object.keys(pinoutCopy)
    .sort(naturalSort)
    .forEach((pin) => {
      pinArray.push(
        pinoutCopy[pin]
          .filter((wire) => !wire.virtual)
          .filter((wire) => showEmptyPins || wire.in_session)
          .map((wire) => ({ ...wire, cavity: pin, originalCavityNumber: pin })),
      )
    })

  const jumperPins = []
  const pins = flatten(pinArray).filter((value) => value !== undefined)

  // return combined pins collection with next ordering: pins without jumpers are ordered naturally
  // and pins with jumper are placed in the end of the list
  return {
    pins,
    jumperPins,
  }
}

const simpleNumbersSorter =
  (emptyPinsFillers, pinNumberConverter) => (component, showEmptyPins) => {
    const { pinout, jumpers = {} } = component
    const pinsWithJumper = collectPinsWithJumper(jumpers)
    const totalPins = component.total_pins
    const pinoutCopy = cloneDeep(pinout)
    const pinArray = []
    Object.keys(pinoutCopy)
      .filter(isPinWithoutJumperPredicate(pinsWithJumper))
      .sort(naturalSort)
      .forEach((pin) => {
        const pinNumber = pinNumberConverter(pin, component)
        pinArray[pinNumber] = pinoutCopy[pin]
          .filter((wire) => !wire.virtual)
          .filter((wire) => showEmptyPins || wire.in_session)
          .map((wire) => ({ ...wire, cavity: pin, originalCavityNumber: pin }))
      })

    emptyPinsFillers(showEmptyPins, pinArray, totalPins, pinsWithJumper)
    const jumperPins = flatten(
      pinsWithJumper.map((pin) => {
        if (pinoutCopy[pin]) {
          return pinoutCopy[pin].map((wire) => ({
            ...wire,
            cavity: pin,
            originalCavityNumber: pin,
          }))
        }
        return [] // Return an empty array if there's no matching pin in pinoutCopy
      }),
    )
      .filter((value) => value !== undefined)
      .sort((a, b) => (a.circuit > b.circuit ? 1 : -1))

    const pins = flatten(pinArray)
      .filter((value) => value !== undefined)
      .filter((wire) => wire.circuit_category !== 'DEV')

    // return combined pins collection with next ordering: pins without jumpers are ordered naturally
    // and pins with jumper are placed in the end of the list
    return {
      pins,
      jumperPins,
    }
  }

const letterNumberSorter = alphanumericSorter(letterNumberLabelFormatter)
const numberLetterSorter = alphanumericSorter(numberLetterLabelFormatter)

// TODO: consider to move sorting logic on the backend side
export const strategyFactory = (component) => {
  const numberLetterPatternTester = /^\d+[A-Z|a-z]+$/
  const letterNumberPatternTester = /^[A-Z|a-z]+\d+$/
  const letterPatternTester = /^[A-Z|a-z]+$/
  const devicePinTester = /^[A-Za-z]+\d+[A-Za-z]+-\d+$/

  const checkPatternCriteria = (tester) =>
    !_.isEmpty(component.pinout) &&
    _.chain(component.pinout)
      .keys()
      .every((pin) => tester.test(pin))
      .value()

  let sorter
  if (checkPatternCriteria(numberLetterPatternTester)) {
    sorter = numberLetterSorter
  } else {
    if (checkPatternCriteria(letterNumberPatternTester)) {
      sorter = letterNumberSorter
    } else {
      if (checkPatternCriteria(letterPatternTester)) {
        sorter = alphaSorter(
          emptyPinsFillersLetter(),
          pinNumberWithInlineSiblingsParser(component),
        )
      } else if (
        checkPatternCriteria(devicePinTester) ||
        component.category === COMPONENT_CATEGORY_DEVICE
      ) {
        sorter = devicePinSorter(pinNumberWithInlineSiblingsParser(component))
      } else {
        sorter = simpleNumbersSorter(
          emptyPinsFillers(),
          pinNumberWithInlineSiblingsParser(component),
        )
      }
    }
  }

  return sorter
}

export const getOrderedPins = createSelector(
  [getCurrentComponent, getShowEmptyPins],
  (component, showEmptyPins) => {
    const strategy = strategyFactory(component)
    return strategy(component, showEmptyPins)
  },
)
