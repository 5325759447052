import React, { useState } from 'react'
import { Spin } from 'antd'
import { useDiagram } from '../DiagramContext'
import { useComponentPinout, useComponentFullPinout } from '../../BlockDiagram'
import PinoutTable from './PinoutTable'

const ConnectorSinglePinInfo = ({ isAllPins, setIsAllPins }) => {
  const diagram = useDiagram()
  const { isLoading, isSuccess, isIdle, pinout } = useComponentPinout({
    vin: diagram.vin,
    componentId: diagram.detailDiagram.data.componentId,
    neighborIds: diagram.detailDiagram.data.neighborIds,
  })

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {isSuccess && (
        <PinoutTable
          pinout={pinout.filter(
            (pinout_data) => pinout_data.pin === diagram.detailDiagram.data.pin,
          )}
          isLoading={isLoading}
          isAllPins={isAllPins}
          setIsAllPins={setIsAllPins}
        />
      )}
    </>
  )
}

const ConnectorFullPinout = ({ isAllPins, setIsAllPins }) => {
  const diagram = useDiagram()
  const { isLoading, pinout } = useComponentFullPinout({
    vin: diagram.vin,
    componentId: diagram.detailDiagram.data.componentId,
  })

  return (
    <PinoutTable
      pinout={pinout}
      isLoading={isLoading}
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
    />
  )
}

const ConnectorPinout = () => {
  const [isAllPins, setIsAllPins] = useState(false)
  return isAllPins ? (
    <ConnectorFullPinout isAllPins={isAllPins} setIsAllPins={setIsAllPins} />
  ) : (
    <ConnectorSinglePinInfo isAllPins={isAllPins} setIsAllPins={setIsAllPins} />
  )
}

export default ConnectorPinout
