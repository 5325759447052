import * as types from '../actions/action-types'

const initialState = {
  vehicleTestResultId: null,
  centerOnSelectedElement: false,
  componentsForModel: [],
  demoPayloads: {},
  elements: [],
  gateway: null,
  graphData: {
    edges: [],
    nodes: [],
  },
  makeModelId: null,
  modToCompIdx: {},
  modules: [],
  selectedElement: null,
  sequenceNumber: null,
  svgEnabled: false,
  vin: null,
  verifiedComponents: [],
  dtcMemosLoaded: false,
  dtcMemos: null,
  popover: false,
}

const componentReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.RESET_COMPONENTS:
      return Object.assign({}, initialState)
    case types.SELECT_ELEMENT:
      return Object.assign({}, state, {
        centerOnSelectedElement: action.centerOnSelectedElement,
        selectedElement: action.selectedElement,
      })
    case types.CENTER_ON_SELECTED_ELEMENT:
      return Object.assign({}, state, {
        centerOnSelectedElement: true,
      })
    case types.SELECT_ELEMENT_BY_ID:
      const { elements = [] } = state
      const element = elements.find(
        (element) => element.id === action.selectedElementId,
      )
      return Object.assign({}, state, {
        centerOnSelectedElement: action.centerOnSelectedElement,
        selectedElement: element,
      })
    case types.RESET_ELEMENT:
      return Object.assign({}, state, {
        selectedElement: initialState.selectedElement,
      })
    case types.SET_ALL_VEHICLE_DATA:
      return Object.assign({}, state, {
        vehicleTestResultId: action.vehicleTestResultId,
        elements: action.elements,
        gateway: action.gateway,
        graphData: action.graphData,
        makeModelId: action.makeModelId,
        modToCompIdx: action.modToCompIdx,
        modules: action.modules,
        sequenceNumber: action.sequenceNumber,
        svgEnabled: action.svgEnabled,
        vin: action.vin,
        dtcDependencies: action.dtcDependencies,
      })
    case types.SET_COMPONENTS_FOR_MODEL:
      return Object.assign({}, state, {
        componentsForModel: action.elements,
      })
    case types.SET_DEMO_PAYLOADS:
      return Object.assign({}, state, {
        demoPayloads: action.demoPayloads,
      })
    case types.UPDATE_DTC_LIST:
      return Object.assign({}, state, {
        modules: action.modules,
      })
    case types.SET_VERIFIED_COMPONENTS:
      return Object.assign({}, state, {
        verifiedComponents: action.componentIds,
      })
    case types.APPEND_VERIFIED_COMPONENT:
      return Object.assign({}, state, {
        verifiedComponents: [...state.verifiedComponents, action.componentId],
      })
    case types.REMOVE_VERIFIED_COMPONENT:
      return Object.assign({}, state, {
        verifiedComponents: state.verifiedComponents.filter(
          (componentId) => componentId !== action.componentId,
        ),
      })
    case types.SHOW_COMPONENT_POPOVER:
      return Object.assign({}, state, {
        popover: true,
      })
    case types.HIDE_COMPONENT_POPOVER:
      return Object.assign({}, state, {
        popover: false,
      })
    case types.SET_DTC_MEMOS_FOR_COMPONENTS: {
      const { makeModelId, modules } = state
      const { acronymMemos = [] } = action
      // "memos" have the following shape:
      //    [
      //      {id, make_model_id, dtc, acronym, short_desc, memo},
      //      ...
      //    ]
      //
      // "modules" have the shape:
      //    [
      //      {
      //        dtcs: [         // for each of DTCs we need
      //          {
      //            dtc_code,   // <-- its code
      //            active,
      //            components,
      //            connectors,
      //            description,
      //            harness_description,
      //            harness_pn,
      //            is_misconfigured,
      //            is_supported,
      //            module,
      //            pins,
      //            start_connector,
      //          },
      //          ...
      //        ],
      //        acronym,
      //
      //        // Other module attributes
      //        connector,
      //        connector_alias,
      //        is_supported,
      //        module_id,
      //      },
      //      ...
      //    ]

      // We take makeModelId which is the only one for the whole app,
      // for each module we take it acronym, for each DTC inside module (dtcs attribute)
      // we take it dtc_code. That three values (makeModelId, dtc_code, acronym)
      // uniquely identify DTC memo.
      // If found such a memo we add it to dtc (module.dtcs[N].memo).
      const diff = {
        modules: modules.map((module) => {
          let diff = {}
          if (acronymMemos[module.acronym]) {
            diff = {
              dtcs: module.dtcs.map((dtc) => ({
                ...dtc,
                memo: acronymMemos[module.acronym].find(
                  (memo) =>
                    memo.make_model_id === makeModelId &&
                    memo.dtc === dtc.dtc_code &&
                    memo.acronym === module.acronym,
                ),
              })),
            }
          }
          return { ...module, ...diff }
        }),
      }

      return Object.assign({}, state, diff)
    }
    default:
      return state
  }
}

export default componentReducer
