import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import 'trix'
import 'trix/dist/trix.css'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import history from '../history'
import { Editor } from './Editor'
import { MappingsHeader } from './DTCMapping/CrowdsourcedDTCMapping'

const DTCMemo = ({
  memoId,
  initialMemo = '',
  initialShortDescription = '',
  acronym,
  makeModelStr,
  dtcError,
  dtcId,
  onSubmit,
  username,
}) => {
  const [description, setDescription] = React.useState(initialMemo)
  const [shortDescription, setShortDescription] = React.useState(
    initialShortDescription,
  )

  const doSaveAndGoBack = () => {
    onSubmit.mutate({
      memoId,
      memo: description,
      shortDescription,
      updatedBy: username,
    })
    history.goBack()
  }
  React.useEffect(() => {
    setDescription(initialMemo)
  }, [initialMemo])

  React.useEffect(() => {
    setShortDescription(initialShortDescription)
  }, [initialShortDescription])

  return (
    <>
      <div className="container">
        <div className="row">
          <MappingsHeader dtcMemoId={memoId}>
            {initialMemo ? (
              <FormattedMessage id="dtcMemo.addMemo" />
            ) : (
              <FormattedMessage id="dtcMemo.editMemo" />
            )}
          </MappingsHeader>
        </div>
      </div>
      <div className="container trix-wrapper">
        <div className="row justify-content-center mb-1">
          <div className="col-md-2 gray">
            <span className="mr-1">
              <b>
                <FormattedMessage id="dtcMemo.model" />:
              </b>
            </span>{' '}
            {makeModelStr}
          </div>
          <div className="col-md-2 gray">
            <span className="mr-1">
              <b>
                <FormattedMessage id="dtcMemo.module" />:
              </b>
            </span>{' '}
            {acronym}
          </div>
          <div className="col-md-2 gray">
            <span className="mr-1">
              <b>
                <FormattedMessage id="dtcMemo.dtc" />:
              </b>
            </span>{' '}
            {dtcError}
          </div>
        </div>
        <div className="row justify-content-center mb-1">
          <div className="col-md-12">
            <label className="control-label" htmlFor="shortDescription">
              <FormattedMessage id="dtcMemo.shortDescription" />
            </label>
            <input
              id="shortDescription"
              type="text"
              value={shortDescription}
              className="form-control blue-black"
              onChange={(event) => setShortDescription(event.target.value)}
              disabled={dtcId}
            />
          </div>
        </div>
        <div className="row justify-content-center mb-1">
          <div className="col-md-12">
            <label className="control-label" htmlFor="trix-description">
              <FormattedMessage id="dtcMemo.notes" />
            </label>
            <Editor
              id="trix-description"
              value={description}
              onChange={setDescription}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2 col-md-offset-4">
            <button
              id="cancel-edit-memo"
              className="btn table-btn w-100"
              onClick={() => {
                history.goBack()
              }}
            >
              <FormattedMessage id="dtcMemo.cancel" />
            </button>
          </div>
          <div className="col-md-2">
            <button
              id="save-memo"
              className="btn btn-default btn-sm w-100"
              onClick={() => doSaveAndGoBack()}
            >
              <FormattedMessage id="dtcMemo.save" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

DTCMemo.propTypes = {
  // Data
  dtcMemo: PropTypes.shape({
    id: PropTypes.number,
    make_model_id: PropTypes.number,
    make_model_str: PropTypes.string,
    acronym: PropTypes.string,
    dtc: PropTypes.string,
    short_desc: PropTypes.string,
    memo: PropTypes.string,
  }),
}

const mapStateToProps = (state) => ({
  username: get(state, 'user.data.username'),
})

export default connect(mapStateToProps)(DTCMemo)
