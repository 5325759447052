import React, { Component } from 'react'
import Modal from 'react-modal'
import { FormattedMessage, injectIntl } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import _ from 'lodash'
import capitalize from 'lodash/capitalize'

import ComponentDescription from '../Common/ComponentDescription'
import { ComponentDetailsContainer } from './ComponentDetails'
import { DeviceDetailsContainer } from './DeviceDetails'
import { HarnessDetailsContainer } from './HarnessDetails'
import { OtherDetailsContainer } from './OtherDetails'
import MustHave from '../../containers/MustHave'
import {
  CIRCUIT_CATEGORY_HARNESS,
  CIRCUIT_CATEGORY_CONNECTOR,
  CIRCUIT_CATEGORY_INLINE,
  CIRCUIT_CATEGORY_OTHER,
  COMPONENT_CATEGORY_INLINE,
  ANNOTATION_MAX_LENGTH,
} from '../../constants'
import { formatLocalizedDate } from '../../helpers/datetime'
import {
  guessComponentOrHarnessName,
  isEdge,
  isNode,
} from '../../helpers/element'
import { dtcItemKey } from '../../helpers/utils'

const DisplayMode = Object.freeze({
  DISPLAY_TABLE: Symbol('DISPLAY_TABLE'),
  DISPLAY_HARNESS_DETAILS: Symbol('DISPLAY_HARNESS_DETAILS'),
  DISPLAY_CONNECTOR_DETAILS: Symbol('DISPLAY_CONNECTOR_DETAILS'),
  DISPLAY_OTHER_DETAILS: Symbol('DISPLAY_OTHER_DETAILS'),
  DISPLAY_DEVICE_DETAILS: Symbol('DISPLAY_DEVICE_DETAILS'),
})

const MAX_NOTES_LEN = 100

export class RepairHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleCommentExpansion: {},
      toggleDtcExpansion: {},
      selectedDtcs: null,
      rowItem: null,
      displayMode: DisplayMode.DISPLAY_TABLE,
      components: [],
    }
  }

  resetState = () => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_TABLE,
      rowItem: null,
      toggleCommentExpansion: {},
      toggleDtcExpansion: {},
      selectedCategory: null,
      selectedElement: null,
      selectedReason: null,
      selectedDtcs: null,
    })
  }

  toggleMoreOrLess(idx, key, booleanMap) {
    const newState = {}
    const mapObj = _.merge({}, booleanMap)
    mapObj[idx] = !booleanMap[idx]
    newState[key] = mapObj
    this.setState(newState)
  }

  getCircuitCategory(item) {
    if (isEmpty(item)) {
      return null
    }

    return this.props.circuitCategories.find((category) => {
      if (!isEdge(item) && !isNode(item)) {
        return category.id === CIRCUIT_CATEGORY_OTHER
      }
      if (isEdge(item)) {
        return category.id === CIRCUIT_CATEGORY_HARNESS
      }
      const componentCategory =
        item.category === COMPONENT_CATEGORY_INLINE
          ? COMPONENT_CATEGORY_INLINE
          : CIRCUIT_CATEGORY_CONNECTOR
      return category.id === componentCategory
    })
  }

  getComponentsForSelectedCircuitCategory() {
    const selectedCategoryId =
      this.state.selectedCategory && this.state.selectedCategory.id
    return this.getComponentsForCircuitCategory(selectedCategoryId)
  }

  getComponentsForCircuitCategory(categoryId) {
    if (!categoryId) {
      return []
    }

    const componentsForModelFn = (component) => ({
      label: `${guessComponentOrHarnessName(component)}`,
      description: component.description,
      value: component.id,
      id: component.id,
    })

    const harnessesForModelFn = (harness) => ({
      label: `${harness.id}`,
      value: harness.id,
      id: harness.id,
    })

    switch (categoryId) {
      case CIRCUIT_CATEGORY_CONNECTOR:
        const componentsForModel = this.props.componentsForModel.filter(
          (component) => component.category !== COMPONENT_CATEGORY_INLINE,
        )
        return componentsForModel.map(componentsForModelFn)
      case CIRCUIT_CATEGORY_INLINE:
        const inlineComponentsForModel = this.props.componentsForModel.filter(
          (component) => component.category === COMPONENT_CATEGORY_INLINE,
        )
        return inlineComponentsForModel.map(componentsForModelFn)
      case CIRCUIT_CATEGORY_HARNESS:
        return this.props.harnessesForModel.map(harnessesForModelFn)
      case CIRCUIT_CATEGORY_OTHER:
        return []
      default:
        throw new Error('unsupported circuit category')
    }
  }

  getFaultsFromCircuitCategory(categoryId) {
    const { component, inline, harness, other } = this.props.faults
    switch (categoryId) {
      case CIRCUIT_CATEGORY_CONNECTOR:
        return component
      case CIRCUIT_CATEGORY_INLINE:
        return inline
      case CIRCUIT_CATEGORY_HARNESS:
        return harness
      case CIRCUIT_CATEGORY_OTHER:
        return other
      default:
        throw new Error('unsupported circuit category')
    }
  }

  getDtcValues(element) {
    let elementDtcs
    if (this.state.selectedDtcs) {
      return this.state.selectedDtcs
    }
    if (this.state.rowItem && this.state.rowItem.id) {
      elementDtcs = this.getDtcsForElement(element)
    } else {
      elementDtcs = this.props.dtcs
    }
    return elementDtcs.map((dtc) => dtc.dtc_code)
  }

  getDtcOptions(element) {
    return this.props.dtcs.map((dtc) => ({
      value: dtc.dtc_code,
      label: dtcItemKey(dtc.module, dtc),
    }))
  }

  getCircuitCategories = (item) => {
    if (isEmpty(item)) {
      return this.props.circuitCategories
    }

    const isComponent = isNode(item) || item.type === 'component'
    const isHarness = isEdge(item) || item.type === 'harness'

    return this.props.circuitCategories.filter(
      (category) =>
        (isHarness && category.id === CIRCUIT_CATEGORY_HARNESS) ||
        (isComponent &&
          (category.id === CIRCUIT_CATEGORY_CONNECTOR ||
            category.id === CIRCUIT_CATEGORY_INLINE)) ||
        (!isHarness && !isComponent && category.id === CIRCUIT_CATEGORY_OTHER),
    )
  }

  getDtcsForElement(element) {
    const elementDtcCodes = new Set(_.map(element.dtcs, 'dtc'))
    return this.props.dtcs.filter((dtc) => elementDtcCodes.has(dtc.dtc_code))
  }

  onCircuitCategoryChange(selection) {
    this.setState({
      selectedCategory: selection,
      faults: this.getFaultsFromCircuitCategory(selection.id),
      selectedElement: null,
      selectedReason: null,
    })
    this.props.resetFields(['component', 'reason'])
  }

  onDtcChange(selectedDtcs) {
    this.setState({
      selectedDtcs,
    })
  }

  onReasonChange = (selectedReason) => {
    this.setState({
      selectedReason,
    })
  }

  closeModal = () => {
    this.resetState()
    this.props.onClose()
  }

  addNewFault = (item) => {
    this.hideAddOptions()
    const hasNewItems = this.props.repairHistory.filter(
      (item) => !item.id,
    ).length
    if (hasNewItems) {
      return false
    }
    this.resetState()
    // reset form to initial values
    this.props.reset()
    this.props.addRepairHistoryItem(item)
  }

  addDeviceFault = () => {
    this.hideAddOptions()
    this.setState({
      displayMode: DisplayMode.DISPLAY_DEVICE_DETAILS,
      rowItem: {},
    })
  }

  deleteItem = (item, idx) => {
    this.resetState()
    this.props.onDeleteHistoryItem(item.id, item.make_model_id, item.vin)
  }

  editHarness = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_HARNESS_DETAILS,
      rowItem: item,
    })
  }
  editComponent = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_CONNECTOR_DETAILS,
      rowItem: item,
    })
  }
  editOther = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_OTHER_DETAILS,
      rowItem: item,
    })
  }

  editDevice = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_DEVICE_DETAILS,
      rowItem: item,
    })
  }

  dtcFormatter = (row, idx) => {
    const newContent = this.getDtcsForElement(row)
      .map((dtc) => dtcItemKey(dtc.module, dtc))
      .join(', ')
    return this.displayTruncated(
      newContent,
      !!this.state.toggleDtcExpansion[idx],
      () =>
        this.toggleMoreOrLess(
          idx,
          'toggleDtcExpansion',
          this.state.toggleDtcExpansion,
        ),
    )
  }

  notesFormatter = (note) => {
    if (!note) {
      return ''
    }
    if (note.length < MAX_NOTES_LEN) {
      return note
    }
    return `${note.substring(0, MAX_NOTES_LEN)}...`
  }

  displayTruncated = (content, isExpanded, toggleCallback) => {
    if (content && content.length > ANNOTATION_MAX_LENGTH) {
      if (isExpanded) {
        return (
          <span>
            {content}{' '}
            <a onClick={toggleCallback}>
              <FormattedMessage id="forms.showLess" />
            </a>
          </span>
        )
      } else {
        const truncatedContent = content.substr(0, ANNOTATION_MAX_LENGTH)
        return (
          <span>
            {truncatedContent}{' '}
            <a onClick={toggleCallback}>
              <FormattedMessage id="forms.showMore" />
            </a>
          </span>
        )
      }
    }
    return content
  }

  delete = (obj, idx) => {
    this.deleteItem(obj, idx)
  }

  edit = (obj, idx) => {
    const editHandle = {
      harness: this.editHarness,
      component: this.editComponent,
      device: this.editDevice,
      other: this.editOther,
    }
    editHandle[obj.type](obj, idx)
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeModal}
        className="modal-repair-history-view"
        overlayClassName="modal-overlay"
        contentLabel="Modal"
      >
        {this.renderViews()}
      </Modal>
    )
  }

  renderViews() {
    switch (this.state.displayMode) {
      case DisplayMode.DISPLAY_TABLE:
        return this.renderTable()
      case DisplayMode.DISPLAY_CONNECTOR_DETAILS:
        return this.renderComponentDetails()
      case DisplayMode.DISPLAY_HARNESS_DETAILS:
        return this.renderHarnessDetails()
      case DisplayMode.DISPLAY_OTHER_DETAILS:
        return this.renderOthersDetails()
      case DisplayMode.DISPLAY_DEVICE_DETAILS:
        return this.renderDeviceDetails()
      default:
        return null
    }
  }

  renderHarnessDetails() {
    return (
      <div>
        <div className="repair-history-modal-header">
          <div
            className="repair-history-modal-back-button"
            onClick={() => this.resetState()}
          />
          <div className="flex space-between align-items-center w-100">
            <h2 className="repair-history-modal-title">
              <FormattedMessage id="repairHistory.titles.harnessHistory" />
            </h2>
          </div>
        </div>
        <div className="repair-history-form-wrapper">
          <HarnessDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        </div>
      </div>
    )
  }

  renderComponentDetails() {
    return (
      <div>
        <div className="repair-history-modal-header">
          <div
            className="repair-history-modal-back-button"
            onClick={() => this.resetState()}
          />
          <div className="flex space-between align-items-center w-100">
            <h2 className="repair-history-modal-title">
              <FormattedMessage id="repairHistory.titles.componentHistory" />
            </h2>
          </div>
        </div>
        <div className="repair-history-form-wrapper">
          <ComponentDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        </div>
      </div>
    )
  }

  renderOthersDetails() {
    return (
      <div>
        <div className="repair-history-modal-header">
          <div
            className="repair-history-modal-back-button"
            onClick={() => this.resetState()}
          />
          <div className="flex space-between align-items-center w-100">
            <h2 className="repair-history-modal-title">
              <FormattedMessage id="repairHistory.titles.otherHistory" />
            </h2>
          </div>
        </div>
        <div className="repair-history-form-wrapper">
          <OtherDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        </div>
      </div>
    )
  }

  renderDeviceDetails() {
    return (
      <div>
        <div className="repair-history-modal-header">
          <div
            className="repair-history-modal-back-button"
            onClick={() => this.resetState()}
          />
          <div className="flex space-between align-items-center w-100">
            <h2 className="repair-history-modal-title">
              <FormattedMessage id="repairHistory.titles.deviceHistory" />
            </h2>
          </div>
        </div>
        <div className="repair-history-form-wrapper">
          <DeviceDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        </div>
      </div>
    )
  }

  renderTable() {
    return (
      <>
        <div
          className="repair-history-modal-header"
          data-testid="repair-history-modal-header"
        >
          <div
            className="repair-history-modal-back-button"
            onClick={this.closeModal}
          />
          <div className="flex space-between align-items-center w-100">
            <h2 className="repair-history-modal-title">
              <FormattedMessage id="repairHistory.titles.general" />
            </h2>
            <div className="">
              <MustHave permission="component-fault:create">
                <button
                  className="btn btn-default"
                  onClick={this.props.openAddFaultModal}
                  type="button"
                  data-testid="open-add-fault-modal"
                >
                  <FormattedMessage id="repairHistory.titles.addFault" />
                </button>
              </MustHave>
            </div>
          </div>
        </div>
        <div
          id="repair-history-view"
          className="row table-container"
          data-testid="repair-history-modal-content"
        >
          <div
            className="react-bs-container-body bs-table-body"
            style={{ height: '100%' }}
          >
            <table className="table table-hover table-condensed">
              <colgroup>
                <col span="1" />
                <col span="1" />
                <col span="1" />
                <col span="1" style={{ width: '300px' }} />
                <col span="1" style={{ width: '300px' }} />
                <col span="1" />
                <col span="1" />
                <col span="1" style={{ width: '70px' }} />
                <col span="1" style={{ width: '110px' }} />
              </colgroup>
              <thead className="bs-table-header">
                <tr>
                  <th>
                    <FormattedMessage id="repairHistory.columns.date" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.category" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.name" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.dtcs" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.notes" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.faultType" />
                  </th>
                  <th>
                    <FormattedMessage id="repairHistory.columns.userId" />
                  </th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody className="bs-table-body">
                {this.props.repairHistory &&
                  this.props.repairHistory.map((obj, idx) => (
                    <tr key={obj.id}>
                      <td>
                        {formatLocalizedDate(
                          obj.created_at,
                          this.props.intl.formatMessage({
                            id: 'format.timestamp',
                          }),
                          this.props.tz,
                        )}
                      </td>
                      <td>{capitalize(obj.type)}</td>
                      <td>
                        {obj.type === 'other' ? (
                          'N/A'
                        ) : (
                          <ComponentDescription
                            name={guessComponentOrHarnessName(obj)}
                            level={obj.harness_level}
                            description={obj.description}
                          />
                        )}
                      </td>
                      <td>{this.dtcFormatter(obj, idx)}</td>
                      <td>{this.notesFormatter(obj.annotation)}</td>
                      <td>{obj.reason}</td>
                      <td>{obj.created_by_id}</td>
                      <td>
                        <button
                          className="btn table-btn"
                          type="button"
                          onClick={() => this.edit(obj, idx)}
                        >
                          <FormattedMessage id="forms.edit" />
                        </button>
                      </td>
                      {this.props.userRules.includes(
                        'component-fault:delete',
                      ) && (
                        <td>
                          <button
                            className="btn btn-warn"
                            type="button"
                            onClick={() => this.delete(obj, idx)}
                          >
                            <FormattedMessage id="forms.delete" />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(RepairHistory)
