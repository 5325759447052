import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Modal from 'react-modal'
import history from '../history'
import { useUserPlant, useUserInfo } from '../hooks/User'
import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR, GRAY_COLOR } from '../constants'

import CloseButton from './CloseButton'

const SelectStationAlert = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [storedStationAlertFilter, setStoredStationAlertFilter] =
    useLocalStorage('station-alert', 'send,skip')
  const [, , remove] = useLocalStorage('reports-query')
  const [stationAlertFilter, setStationAlertFilter] = useState(
    storedStationAlertFilter,
  )

  const options = [
    {
      value: 'send,skip',
      label: intl.formatMessage({ id: 'selectStationAlert.allFaultsLabel' }),
    },
    {
      value: 'send',
      label: intl.formatMessage({ id: 'selectStationAlert.sendOnlyLabel' }),
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Select Report Filter"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          remove()
          setStoredStationAlertFilter(stationAlertFilter)
          closeModal()
          if (location.pathname == '/reports') history.go(0)
        }}
        id="select-station-alert-form"
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage id="selectStationAlert.title" />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row" style={{ color: GRAY_COLOR }}>
              <FormattedMessage id="selectStationAlert.leadingText" />
            </div>
            <div className="row">
              <div className="btn-group-vertical" role="group">
                {options.map((availableStationAlertFilter) => {
                  return (
                    <div
                      className="radio"
                      key={availableStationAlertFilter.value}
                      id={availableStationAlertFilter.value}
                    >
                      <label style={{ color: BLACK_COLOR }}>
                        <input
                          type="radio"
                          name="selectStationAlertFilter"
                          id={availableStationAlertFilter.value}
                          value={availableStationAlertFilter.value}
                          checked={
                            availableStationAlertFilter.value ===
                            stationAlertFilter
                          }
                          onChange={() =>
                            setStationAlertFilter(
                              availableStationAlertFilter.value,
                            )
                          }
                        />
                        {availableStationAlertFilter.label}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={setStationAlertFilter === null}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectStationAlert),
)
