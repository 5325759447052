import { useQuery } from 'react-query'
import { apiGetMakeModels, apiGetMakeModelByVin } from '../api/make-models'

export const MAKE_MODELS_KEY = 'make-models'

export const useMakeModels = ({ plantName = null } = {}) => {
  const key = [MAKE_MODELS_KEY]
  if (plantName) key.push(plantName)

  const {
    status,
    data: makeModels,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    key,
    async () => {
      const result = await apiGetMakeModels({ plantName })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: plantName === null ? true : !!plantName,
    },
  )
  return {
    status,
    makeModels,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const MAKE_MODEL_BY_VIN_KEY = 'make-model-by-vin'

export const useMakeModelByVin = ({ vin }) => {
  const {
    status,
    data: makeModel,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [MAKE_MODEL_BY_VIN_KEY, vin],
    async () => {
      const result = await apiGetMakeModelByVin({ vin })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
    },
  )
  return {
    status,
    makeModel,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
