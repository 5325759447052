import React, { useEffect, useRef, useState } from 'react'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'
import { getTestsForVin, navigateToVehicleTest } from '../api/vehicle-tests-api'
import { injectIntl } from 'react-intl'

const QrCodeScanContainer = ({ intl }) => {
  const videoRef = useRef(null)
  const videoContainerRef = useRef(null)
  const codeReader = new BrowserMultiFormatReader(null, 3000)
  const title = intl.formatMessage({ id: 'qrCodeScan.routeLabel' })
  const noDataText = intl.formatMessage({ id: 'qrCodeScan.noData' })
  const hintDefaultText = intl.formatMessage({ id: 'qrCodeScan.hintDefault' })
  const hintBadVinText = intl.formatMessage({ id: 'qrCodeScan.hintBadVin' })
  const statusText = intl.formatMessage({ id: 'qrCodeScan.status' })
  const validVinText = intl.formatMessage({ id: 'qrCodeScan.validVin' })
  const invalidVinText = intl.formatMessage({ id: 'qrCodeScan.invalidVin' })
  const scannedCodeText = intl.formatMessage({ id: 'qrCodeScan.scannedCode' })

  const [codeData, setCodeData] = useState(noDataText)
  const [status, setStatus] = useState(false)
  const [hint, setHint] = useState(hintDefaultText)
  const [isComponentMounted, setIsComponentMounted] = useState(true)
  const [borderColor, setBorderColor] = useState('border-not-detected')

  const validateVIN = (str) => {
    return /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-npr-z\d]{2}\d{6}$/.test(
      str,
    )
  }

  useEffect(() => {
    codeReader.decodeFromVideoDevice(
      undefined,
      videoRef.current,
      (result, err) => {
        if (result) {
          if (isComponentMounted) {
            setBorderColor('border-detected')
            let code = result.getText()
            if (code.length > 17) {
              code = code.slice(-17)
            }
            setCodeData(code)
            const isValid = validateVIN(code)
            setStatus(isValid)
            if (isValid) {
              getTestsForVin(code).then((data) => {
                if (data.length) {
                  const latest = data[0]
                  navigateToVehicleTest({ vehicleTestId: latest.id })
                } else {
                  setHint(hintBadVinText)
                }
              })
            }
          }
        } else if (err && err instanceof NotFoundException) {
          setCodeData(noDataText)
          setStatus(false)
          setHint(hintDefaultText)
          setBorderColor('border-not-detected')
        } else {
          console.error(err)
        }
      },
    )

    return () => {
      codeReader.reset()
      setIsComponentMounted(false)
    }
  }, [])

  return (
    <div className="container">
      <div className="row">
        <h2>{title}</h2>
      </div>
      <div className={'row'}>
        <div
          className={`qr-video-container ${borderColor}`}
          ref={videoContainerRef}
        >
          <video ref={videoRef} className={'qr-video'} />
        </div>
      </div>
      <div className="row">
        <p>
          {scannedCodeText}: {codeData}
        </p>
      </div>
      <div className="row">
        <p className={status ? 'status-valid' : 'status-invalid'}>
          {`${statusText}: ${status ? validVinText : invalidVinText}`}
        </p>
      </div>
      <div className="row">
        <p>{hint}</p>
      </div>
    </div>
  )
}

export default injectIntl(QrCodeScanContainer)
