import { getComponentDetails } from '../api/component-api'
import { useQuery } from 'react-query'
import { strategyFactory } from '../selectors/pinout'
import { locale } from '../Locale'
import { apiGetTerminology } from '../api/terminology'

const QUERY_KEY = 'component-pins'

export const useComponentDetails = ({ componentId, vin }) => {
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, componentId, vin],
    async () => {
      const result = await getComponentDetails(vin, componentId)
      if (!!result) {
        const codes = [result.id]
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        if (!!terms[result.id] && !!terms[result.id]['description']) {
          result.description = terms[result.id]['description']
        }
      }
      const { pins, jumperPins } = strategyFactory(result)(result, true)
      return { pins, component: { ...result, isNode: true }, jumperPins }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!componentId,
    },
  )

  return { componentDetails: data, isLoading, isSuccess }
}
