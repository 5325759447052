import React from 'react'
import classNames from 'classnames'
import './DetailLabel.scss'

// Reaflow Label supports only a text string passed via `text` property.
// This is a workaround: custom Label component which
// adds support of two strings separated by a new line.
export const Label = ({
  /* Start of reaflow Label props. Do not rename or remove. */
  text, // Reaflow property used for two letter color code.
  x,
  y,
  style,
  className,
  originalText,
  /* End of reaflow Label props. */

  circuit, // Extra property added on our side.
}) => (
  <>
    <title>{originalText}</title>
    <g transform={`translate(${x}, ${y})`}>
      <text className={classNames('detail-label', className)} style={style}>
        <tspan x="0">{circuit}</tspan>
        <tspan x="0" dy="1.1em">
          {text}
        </tspan>
      </text>
    </g>
  </>
)
