import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'

const getUserData = (state) => state.user.data

export const getUserPlants = createSelector(
  [getUserData],
  (user) => get(user, 'plants', []) || [],
)

export const getUserManagableRoles = createSelector(
  [getUserData],
  (user) => get(user, 'managable_roles', []) || [],
)

export const getIsUserLoggedIn = createSelector(
  [getUserData],
  (userData) => userData !== null,
)
