import { connect } from 'react-redux'
import { unsetModal } from '../actions/modal-actions'
import SwitchTest from '../components/SwitchTest'
import { getVehicleTestsByVin } from '../api/vehicle-tests-api'
import get from 'lodash/get'

const mapStateToProps = (state, ownProps) => {
  return {
    currentVehicleTest: state.vehicleTests.currentVehicleTest,
    isOpen: state.modalState.isOpen,
    vehicleTests: state.vehicleTests.data
      ? state.vehicleTests.data.sort((a, b) =>
          a.timestamp > b.timestamp ? -1 : 1,
        )
      : state.vehicleTests.data,
    vin: state.componentState.vin,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(unsetModal())
    },
    searchForVin: (vin) => {
      getVehicleTestsByVin(vin)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchTest)
