import * as types from '../actions/action-types'

export function selectNavigationTab(tab, namespace) {
  return {
    type: types.SELECT_NAVIGATION_TAB,
    tab,
    namespace,
  }
}

export function addNavigationTab(tab, namespace) {
  return {
    type: types.ADD_NAVIGATION_TAB,
    tab,
    namespace,
  }
}

export function removeNavigationTab(tab, namespace) {
  return {
    type: types.REMOVE_NAVIGATION_TAB,
    tab,
    namespace,
  }
}
