import React from 'react'
import classnames from 'classnames'

const TitleBar = (props) => {
  const classNames = classnames('title-bar', {
    [props.wrapperClass]: props.wrapperClass,
  })

  return <div className={classNames}>{props.children}</div>
}

export default TitleBar
