import * as types from '../actions/action-types'

export function setActiveUIComponent(activeUIComponent) {
  return {
    type: types.DTC_MAPPING_SET_ACTIVE_UI,
    activeUIComponent,
  }
}

export function resetState() {
  return {
    type: types.DTC_MAPPING_RESET_STATE,
  }
}

export function setSearch(search) {
  return {
    type: types.DTC_MAPPING_SET_SEARCH,
    search,
  }
}

export function setComponents(components) {
  return {
    type: types.DTC_MAPPING_SET_COMPONENTS,
    components,
  }
}

export function setSelectedComponentId(selectedComponentId) {
  return {
    type: types.DTC_MAPPING_SET_SELECTED_COMPONENT_ID,
    selectedComponentId,
  }
}

export function setSelectedComponent(selectedComponent) {
  return {
    type: types.DTC_MAPPING_SET_SELECTED_COMPONENT,
    selectedComponent,
  }
}

export function resetComponent() {
  return {
    type: types.DTC_MAPPING_RESET_COMPONENT,
  }
}
