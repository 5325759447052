import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer/jd',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetMachineAlerts({ vin }) {
  return api.get(`/machines/${vin}/alerts`).then((response) => response.data)
}

export function apiGetMachine({ vin }) {
  return api.get(`/machines/${vin}`).then((response) => response.data)
}

export function apiGetJohnDeereAuth() {
  return api.get(`/authorize/status`).then((response) => response.data)
}

export function apiJohnDeereLogin({ redirectURI }) {
  const params = new URLSearchParams({ redirect_uri: redirectURI })
  return api
    .get(`/login?${params.toString()}`)
    .then((response) => response.data)
}

export function apiJohnDeereAuthorize() {
  return api
    .get(`/authorize${window.location.search}`)
    .then((response) => response.data)
}
