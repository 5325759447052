import React from 'react'
import Subtitle from '../components/Subtitle'
import classNames from 'classnames'

const Header = (props) => {
  const headerClassNames = classNames('text-center', 'header', {
    'header-relative': props.relative,
  })
  return (
    <div className={headerClassNames}>
      <h5>
        {props.title} {props.extendedtitle}
      </h5>
      <Subtitle>{props.subtitle}</Subtitle>
    </div>
  )
}

export default Header
