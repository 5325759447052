import React from 'react'

import { MAIN_NAMESPACE } from '../../constants'
import NavigationTabs from './NavigationTabs'

const Navigation = ({ showViewNavigation }) => {
  return (
    <nav className="navbar navbar-default">
      {/* Collect the nav links, forms, and other content for toggling */}
      {showViewNavigation && (
        <div className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li>
              <NavigationTabs namespace={MAIN_NAMESPACE} />
            </li>
          </ul>
        </div>
      )}
    </nav>
  )
}

Navigation.defaultProps = {
  showViewNavigation: false,
}

export default Navigation
