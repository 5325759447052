import CookiesManager from 'universal-cookie'

import * as types from '../actions/action-types'
import {
  MAIN_NAMESPACE,
  PINOUT_NAMESPACE,
  COMPONENT_DETAILS_NAMESPACE,
  HARNESS_VIEW,
  VEHICLE_VIEW,
  PINOUT_VIEW,
  FUSES_AND_RELAYS_VIEW,
  INIT_MAIN_VIEW_QUERY_PARAM,
  ADD_FAULT_TABS_NAMESPACE,
  ADD_FAULT_GENERIC_VIEW,
  ADD_FAULT_COMPONENT_VIEW,
  ADD_FAULT_HARNESS_VIEW,
  ADD_FAULT_DEVICE_VIEW,
  DTC_CROWDSOURCED_EDIT_NAMESPACE,
  ADD_DTC_MEMO_VIEW,
  MANAGE_MAPPINGS_VIEW,
} from '../constants'
import UrlService from '../services/url'

const availableMainViews = [HARNESS_VIEW, VEHICLE_VIEW, PINOUT_VIEW]
const cookies = new CookiesManager()

export const mainViewCookieKey = 'favoriteMainView'
export const availableMainViewsToFavorite = [HARNESS_VIEW, VEHICLE_VIEW]

/*
 * Tab Selection
 *
 * Business Rules:
 * - allow user to set initial main view preference with cookies
 * - support initial main view from query params
 * - regardless of URL query param, cookie takes precedence
 */
function determineInitialView() {
  let initialView = HARNESS_VIEW

  const favoriteMainView = cookies.get(mainViewCookieKey)
  const mainViewFromUrl = UrlService.getQueryParam(INIT_MAIN_VIEW_QUERY_PARAM)

  if (mainViewFromUrl && availableMainViews.includes(mainViewFromUrl)) {
    initialView = mainViewFromUrl
  } else if (
    favoriteMainView &&
    availableMainViews.includes(favoriteMainView)
  ) {
    initialView = favoriteMainView
  }

  return initialView
}

export const initialState = {
  tabs: {
    [MAIN_NAMESPACE]: availableMainViews,
    [PINOUT_NAMESPACE]: [HARNESS_VIEW, VEHICLE_VIEW, FUSES_AND_RELAYS_VIEW],
    [COMPONENT_DETAILS_NAMESPACE]: [VEHICLE_VIEW, PINOUT_VIEW],
    [ADD_FAULT_TABS_NAMESPACE]: [
      ADD_FAULT_GENERIC_VIEW,
      ADD_FAULT_COMPONENT_VIEW,
      ADD_FAULT_HARNESS_VIEW,
      ADD_FAULT_DEVICE_VIEW,
    ],
    [DTC_CROWDSOURCED_EDIT_NAMESPACE]: [
      ADD_DTC_MEMO_VIEW,
      MANAGE_MAPPINGS_VIEW,
    ],
  },
  currentTab: {
    [MAIN_NAMESPACE]: determineInitialView(),
    [PINOUT_NAMESPACE]: HARNESS_VIEW,
    [ADD_FAULT_TABS_NAMESPACE]: ADD_FAULT_GENERIC_VIEW,
    [COMPONENT_DETAILS_NAMESPACE]: VEHICLE_VIEW,
    [DTC_CROWDSOURCED_EDIT_NAMESPACE]: ADD_DTC_MEMO_VIEW,
  },
}

const tabReducer = function (state = initialState, action) {
  const assign = (obj) => Object.assign({}, state, obj)
  switch (action.type) {
    case types.SELECT_NAVIGATION_TAB:
      // Set query param (only for main views: "Harness", "Vehicle", "Pinout").
      if (availableMainViews.includes(action.tab)) {
        UrlService.setQueryParameter(INIT_MAIN_VIEW_QUERY_PARAM, action.tab)
      }
      return assign({
        currentTab: {
          ...state.currentTab,
          [action.namespace]: action.tab,
        },
      })
    case types.ADD_NAVIGATION_TAB:
      const namespaceTabs = state.tabs[action.namespace]
      if (namespaceTabs.indexOf(action.tab) !== -1) {
        return state
      }

      return assign({
        tabs: {
          ...state.tabs,
          [action.namespace]: [...state.tabs[action.namespace], action.tab],
        },
      })
    case types.REMOVE_NAVIGATION_TAB:
      return assign({
        tabs: {
          ...state.tabs,
          [action.namespace]: state.tabs[action.namespace].filter(
            (tab) => tab !== action.tab,
          ),
        },
      })
    default:
      return state
  }
}

export default tabReducer
