import React from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export const EmailField = ({ onChange, onBlur, value, error }) => (
  <React.Fragment>
    <div className="row">
      <label>
        <FormattedMessage id="users.email" />:
      </label>
      <input
        className="form-control"
        type="email"
        name="email"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder="email"
      />
    </div>
    {error && <div className="row form-text-error">{error}</div>}
  </React.Fragment>
)

export const RoleField = ({ onChange, value, error, options }) => (
  <React.Fragment>
    <div className="row">
      <label>
        <FormattedMessage id="users.role" />:
      </label>
      <Select
        className="select"
        name="role"
        options={options}
        onChange={onChange}
        value={value}
        placeholder="role"
      />
    </div>
    {error && <div className="row form-text-error">{error}</div>}
  </React.Fragment>
)

export const PlantField = ({ role, options, onChange, value, error }) => {
  return (
    <React.Fragment>
      <div className="row">
        <label>
          <FormattedMessage id="users.plant" />:
        </label>
        <Select
          className="select"
          name="plant"
          options={options}
          onChange={onChange}
          isMulti="true"
          value={value}
          placeholder="plant"
        />
      </div>
      {error && <div className="row form-text-error">{error}</div>}
    </React.Fragment>
  )
}

export const DisabledField = ({ options, onChange, value }) => (
  <div className="row">
    <label>
      <FormattedMessage id="users.disabled" />:
    </label>
    <Select
      className="select"
      isClearable={false}
      name="disabled"
      options={options}
      onChange={onChange}
      value={value}
      placeholder="disabled"
    />
  </div>
)

export const Buttons = ({ isSaving }) => (
  <div className="row">
    <div className="text-center">
      <div className="btn-wrapper">
        <button type="submit" className="btn btn-default" disabled={isSaving}>
          {isSaving ? (
            <FormattedMessage id="processes.saving" />
          ) : (
            <FormattedMessage id="forms.save" />
          )}
        </button>
        <Link className="btn" to="/users" disabled={isSaving}>
          <FormattedMessage id="forms.cancel" />
        </Link>
      </div>
    </div>
  </div>
)
