import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import componentReducer from './component-reducer'
import dtcReducer from './dtc-reducer'
import errorReducer from './error-reducer'
import harnessReducer from './harness-reducer'
import loggingReducer from './logging-reducer'
import modalReducer from './modal-reducer'
import notificationReducer from './notification-reducer'
import pinReducer from './pin-reducer'
import retryConnectionReducer from './retry-connection-reducer'
import sidebarReducer from './sidebar-reducer'
import tabReducer from './tab-reducer'
import titleReducer from './title-reducer'
import userReducer from './user-reducer'
import vehicleTestsReducer from './vehicle-tests-reducer'
import ephemeralVehicleTestReducer from './ephemeral-vehicle-test-reducer'
import usersReducer from './users-reducer'
import dtcMemoSearch from './dtc-memo-search'
import dtcMemoReducer from './dtc-memo'
import addFaultComponentReducer from './add-fault-component'
import addFaultHarnessReducer from './add-fault-harness'
import addFaultDeviceReducer from './add-fault-device'
import dtcMappingReducer from './dtc-mapping'
import embeddedModeReducer from './embedded-mode-reducer'
import componentDetailsReducer from '../ComponentDetails/slice'
import appReducer from './app-reducer'
import additionalComponentFaultMenuReducer from './additional-component-fault-menu'

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    componentState: componentReducer,
    componentDetails: componentDetailsReducer,
    dtcState: dtcReducer,
    error: errorReducer,
    form: formReducer,
    harnessState: harnessReducer,
    loggingState: loggingReducer,
    modalState: modalReducer,
    notificationState: notificationReducer,
    pinState: pinReducer,
    retryConnectionState: retryConnectionReducer,
    sidebar: sidebarReducer,
    tabState: tabReducer,
    title: titleReducer,
    user: userReducer,
    vehicleTests: vehicleTestsReducer,
    users: usersReducer,
    dtcMemoSearch: dtcMemoSearch,
    dtcMemo: dtcMemoReducer,
    addFaultComponent: addFaultComponentReducer,
    addFaultHarness: addFaultHarnessReducer,
    addFaultDevice: addFaultDeviceReducer,
    dtcMapping: dtcMappingReducer,
    additionalComponentFaultMenu: additionalComponentFaultMenuReducer,
    embeddedModeState: embeddedModeReducer,
    ephemeralVehicleTest: ephemeralVehicleTestReducer,
  })

export default reducers
