import { Edge } from '../../reaflow'
import { Label } from './Label'

export const DetailDiagramEdge = ({ onEdgeClick, width = '1pt', ...props }) => {
  return (
    <Edge
      {...props}
      interpolation="linear"
      onClick={(event, edge) => {
        onEdgeClick({ event, edge })
      }}
      label={<Label circuit={props.data.circuit} />}
    >
      {
        // Set edge color(s) programmatically.
        // Based on the "use" element.
        // Read more: https://www.w3.org/TR/SVG/struct.html#UseElement
        (event) => {
          const strokes = []
          if (
            !props?.data?.colors ||
            props.data.colors.hex_triplets.length === 0
          ) {
            // Set default edge color.
            return (
              <use
                href={`#${props.properties.id}`}
                strokeWidth={width}
                stroke="#485a74"
              />
            )
          }

          // Set color(s).
          for (let [index, color] of props.data.colors.hex_triplets.entries()) {
            strokes.push(
              <use
                key={`${props.properties.id}-${color}`}
                href={`#${props.properties.id}`}
                strokeDasharray={`10,${index * 10}`}
                strokeDashoffset={`${index * 10}`}
                stroke={`${color}`}
                strokeWidth={width}
              />,
            )
          }
          return strokes
        }
      }
    </Edge>
  )
}
