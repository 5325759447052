import React from 'react'
import { connect } from 'react-redux'
import Pinout from '../components/Pinout'

const PinoutContainer = ({ ...props }) => {
  return <Pinout {...props} />
}

const mapStateToProps = (state, { component }) => {
  return {
    component: component || state.componentState.selectedElement,
  }
}

export default connect(mapStateToProps)(PinoutContainer)
