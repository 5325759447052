import React from 'react'
import classnames from 'classnames'

import './style.scss'

const Button = (props) => {
  const {
    onClick,
    className,
    title,
    dataTestId = '',
    small = false,
    disabled = false,
    children,
    ...otherProps
  } = props

  const classes = classnames(className, 'tracer-button', { small, disabled })

  return (
    <button
      type="button"
      className={classes}
      onClick={onClick}
      data-testid={dataTestId}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default Button
