import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const apiConfig = {
  baseURL: '/tracer/vehicles/tests',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
}
const silentApi = () => axiosWrapper.create(apiConfig)
const apiWithNotificationHandler = () =>
  axiosWrapper.create(apiConfig, InterceptorOptions.defaultErrorInterceptor)

export function getCantFixEvent(vehicleTestId) {
  return silentApi()
    .get(`/${vehicleTestId}/cant-fix/`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
    })
}

export function postCantFixEvent({ vehicleTestId, reasonId }) {
  return apiWithNotificationHandler()
    .post(`/${vehicleTestId}/cant-fix/`, { reason_id: reasonId })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
    })
}

export function deleteCantFixEvent({ vehicleTestId }) {
  return apiWithNotificationHandler()
    .delete(`/${vehicleTestId}/cant-fix/`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
    })
}

export function putCantFixEvent({ vehicleTestId, reasonId }) {
  return apiWithNotificationHandler()
    .put(`/${vehicleTestId}/cant-fix/`, { reason_id: reasonId })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
    })
}
