import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import { FormattedMessage } from 'react-intl'

import CloseButton from './CloseButton'
import { unsetModal } from '../actions/modal-actions'

const DependentDTCItem = ({ dtc, module, description }) => (
  <div className="dependent-dtc-item">
    <div>
      <b>
        {dtc} ({module})
      </b>
    </div>
    <div>{description}</div>
  </div>
)

const DTCDependencyInfo = () => {
  const props = useSelector(
    (state) => ({
      isOpen: state.modalState.isOpen,
      dtcDependencies: state.componentState.dtcDependencies,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()
  const closeModal = () => dispatch(unsetModal())

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal}
      className="modal-confirmation"
      overlayClassName="modal-overlay"
    >
      <div className="panel panel-default" data-cy="dtc-dependencies-info">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-8">
              <div className="panel-title">
                <FormattedMessage
                  id="dtcDependencies.title"
                  values={{ total: props.dtcDependencies.length }}
                />
              </div>
            </div>
            <div className="col-xs-4">
              <CloseButton
                wrapperClassName="close-button-container"
                onClose={closeModal}
              />
            </div>
          </div>
        </div>
        <div className="dependent-dtc-items-list">
          {props.dtcDependencies.map((item, idx) => (
            <DependentDTCItem
              key={idx}
              dtc={item.dependent_dtc}
              module={item.dependent_dtc_module_acronym}
              description={item.dependent_dtc_desc}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default DTCDependencyInfo
