import { connect } from 'react-redux'
import NotificationError from '../components/NotificationError'
import { unsetNotification } from '../actions/notification-actions'

const mapStateToProps = (state, ownProps) => {
  return {
    message: state.notificationState.message,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close: () => {
      dispatch(unsetNotification())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationError)
