import { connect } from 'react-redux'
import { unsetNotification } from '../actions/notification-actions'
import { forceRetry } from '../actions/retry-connection-actions'
import NotificationNetworkError from '../components/NotificationNetworkError'

const mapStateToProps = (state, ownProps) => {
  return {
    retryingTime: state.retryConnectionState.retryTime,
    totalRequests: state.retryConnectionState.totalRequests,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close: () => {
      dispatch(unsetNotification())
    },
    forceRetry: () => {
      dispatch(forceRetry())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationNetworkError)
