import React from 'react'
import { connect } from 'react-redux'
import { requireAuthentication } from '../helpers/authentication'
import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import DTCMemo from '../components/DTCMemo'
import history from '../history'
import { useDTCMemo, useUpdateDTCMemo } from '../hooks/DTCMemo'

function DTCMemoContainer({ dtcMemoId }) {
  const { dtcMemo, isLoading, isSuccess } = useDTCMemo(dtcMemoId)
  const updateDTCMemo = useUpdateDTCMemo()

  return (
    <>
      {isLoading && 'Loading'}
      {isSuccess && (
        <MustHave permission="dtc-memo:get" fallback={<Forbidden />}>
          {
            <DTCMemo
              memoId={dtcMemoId}
              initialMemo={history.location.state?.initialMemo ?? dtcMemo.memo}
              initialShortDescription={
                history.location.state?.initialShortDescription ??
                dtcMemo.short_desc
              }
              acronym={dtcMemo.acronym}
              makeModelStr={dtcMemo.make_model_str}
              dtcError={dtcMemo.dtc}
              dtcId={dtcMemo.dtc_id}
              onSubmit={updateDTCMemo}
            />
          }
        </MustHave>
      )}
    </>
  )
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { dtcMemoId, add },
    },
  },
) => ({
  dtcMemoId,
})

export default connect(
  mapStateToProps,
  null,
)(requireAuthentication(DTCMemoContainer))
