import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const RepairHistoryEmbeddedMode = () => {
  return (
    <div>
      <FormattedMessage id="repairHistory.titles.general" />
    </div>
  )
}

export default injectIntl(RepairHistoryEmbeddedMode)
