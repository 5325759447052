export const AMBER_COLOR = '#ffc000'
export const AZURE_RADIANCE_COLOR = '#0089E9'
export const BLACK_COLOR = '#000000'
export const BRAND_BLUE = '#0189E9'
export const COMET_COLOR = '#5a6382'
export const DODGER_BLUE_COLOR = '#47b3ff'
export const EBONY_CLAY_COLOR = '#272F42'
export const GRAY_COLOR = '#8f8f8f'
export const LIGHT_GRAY_COLOR = '#e7e8ed'
export const GREEN_FILL_COLOR = '#1cae5b'
export const HIGHLIGHT_PIN_COLOR = '#E04F4D'
export const PICKLED_BLUEWOOD_COLOR = '#2E374E'
export const BRAND_TRANSPARENT_DARK_BLUE_COLOR = '#0069B2'
export const PIPER_COLOR = '#c36a26'
export const RED_COLOR = 'red'
export const RED_FILL_COLOR = '#cc422e'
export const SUPERNOVA_COLOR = '#FFC700'
export const TURBO_COLOR = '#ffe800'
export const VALENCIA_COLOR = '#d74053'
export const WHITE_COLOR = '#FFFFFF'
export const WILD_WATERMELON_COLOR = '#ff6c7e'
export const ISO_7010_COLOR = '#f9a800'
export const ANSI_HIGH_VOLTAGE_COLOR = '#FEE633'
