import React from 'react'

const IconArrow = (props) => {
  const flip = props && props.up ? 'rotate(180 10 10)' : ''
  return (
    <svg
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="icon-arrow"
    >
      <path
        fill="#7F7F7F"
        fillRule="evenodd"
        d="M15.953 8.123l-5.83 5.83-5.83-5.83z"
        transform={flip}
      />
    </svg>
  )
}

IconArrow.defaultProps = {
  onClick: () => null,
}

export default IconArrow
