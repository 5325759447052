import * as types from '../actions/action-types'

const initialState = {
  retryTime: null,
  timerOn: false,
  totalRequests: 0,
}

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_RETRY_TIME:
      // only update to the latest time, otherwise keeps the state
      let retryTime = !state.timerOn ? action.retryTime : state.retryTime

      return {
        ...state,
        retryTime: retryTime,
        timerOn: true,
        totalRequests: state.totalRequests + 1,
      }
    case types.SET_RETRY_NOW:
      return {
        ...state,
        retryTime: action.retryTime,
        timerOn: false,
        totalRequests: 0,
      }
    default:
      return state
  }
}

export default reducer
