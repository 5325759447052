import * as types from '../actions/action-types'

export const setActionBarSelection = (actionBarSelection) => ({
  type: types.EMBEDDED_SET_ACTION_BAR_SELECTION,
  actionBarSelection,
})

export const setHarnessVehicleSwitchSelection = (harnessVehicleSwitch) => ({
  type: types.EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION,
  harnessVehicleSwitch,
})

export const setHarnessVehicleSwitchSelectionOnPinout = (
  harnessVehicleSwitch,
) => ({
  type: types.EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION_ON_PINOUT,
  harnessVehicleSwitch,
})

export const setDTCListScroll = ({
  scrollTop,
  scrollHeight,
  clientHeight,
}) => ({
  type: types.EMBEDDED_SET_DTC_LIST_SCROLL,
  payload: { scrollTop, scrollHeight, clientHeight },
})

export const setComponentListScroll = ({
  scrollTop,
  scrollHeight,
  clientHeight,
}) => ({
  type: types.EMBEDDED_SET_COMPONENT_LIST_SCROLL,
  payload: { scrollTop, scrollHeight, clientHeight },
})

export const setPinoutTableScroll = ({
  scrollTop,
  scrollHeight,
  clientHeight,
}) => ({
  type: types.EMBEDDED_SET_PINOUT_TABLE_SCROLL,
  payload: { scrollTop, scrollHeight, clientHeight },
})

export const setSwitchTestTableScroll = ({
  scrollTop,
  scrollHeight,
  clientHeight,
}) => ({
  type: types.EMBEDDED_SET_SWITCH_TEST_TABLE_SCROLL,
  payload: { scrollTop, scrollHeight, clientHeight },
})
