import React, { Component } from 'react'
import { Provider, connect } from 'react-redux'

import { defaultTitle } from './reducers/title-reducer'
import store from './store'

const Title = ({ title }) => title || defaultTitle

const mapStateToProps = (store, _) => ({ title: store.title })

const WrappedTitle = connect(mapStateToProps)(Title)

class TitleProvider extends Component {
  render() {
    return (
      <Provider store={store}>
        <WrappedTitle />
      </Provider>
    )
  }
}

export default TitleProvider
