import React from 'react'
import { FormattedMessage } from 'react-intl'

import IconNoImage from './Icons/IconNoImage'
import VehicleTestResultNavigation from './VehicleTestResultNavigation'

class NoGraph extends React.Component {
  render() {
    return (
      <div className="no-graph-wrapper">
        <div className="no-graph-error-message">
          <IconNoImage />
          <p>
            <FormattedMessage id="tracer.harnessView.noGraph.message" />
          </p>
          <p>
            <FormattedMessage id="tracer.harnessView.noGraph.youCan" />
            <span className="feedback" onClick={this.props.openFeedbackModal}>
              <FormattedMessage id="tracer.harnessView.noGraph.leaveFeedback" />
            </span>
          </p>
        </div>
        <VehicleTestResultNavigation
          vehicleTestResultId={this.props.vehicleTestResultId}
        />
      </div>
    )
  }
}

export default NoGraph
