import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import {
  setUserLoading,
  setUserLoggedIn,
  setUserLoggedOut,
} from '../actions/user-actions'
import store from '../store'

const api = () =>
  axiosWrapper.create(
    { baseURL: '/' },
    InterceptorOptions.defaultErrorInterceptor,
  )

export function getUser() {
  store.dispatch(setUserLoading())
  return api()
    .get(`/tracer/userinfo`)
    .then((response) => {
      const userInfo = response.data
      store.dispatch(setUserLoggedIn(userInfo))
    })
    .catch((error) => {
      store.dispatch(setUserLoggedOut())
      console.log(error)
    })
}

export function getUserInfo() {
  return api()
    .get(`/tracer/userinfo`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export function login(redirectUrl) {
  if (!redirectUrl) {
    const queryParameters = new URLSearchParams(window.location.search)
    if (queryParameters) {
      redirectUrl = queryParameters.has('redirect_url')
        ? queryParameters.get('redirect_url')
        : '/'
    }
  }

  return api()
    .get(`/tracer/login_url?redirect_url=${encodeURIComponent(redirectUrl)}`)
    .then((response) => {
      if (response.data && response.data.location) {
        window.location = response.data.location
      } else {
        console.log(
          'Error: Failed to retrieve a valid login URL from the server.',
        )
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export function logout(redirectUrl) {
  if (!redirectUrl) {
    const queryParameters = new URLSearchParams(window.location.search)
    redirectUrl = queryParameters.has('redirect_url')
      ? queryParameters.get('redirect_url')
      : '/'
  }

  return api()
    .get(`/tracer/logout_url?redirect_url=${encodeURIComponent(redirectUrl)}`)
    .then((response) => {
      if (response.data && response.data.location) {
        // TODO: Verify this works
        window.location = response.data.location
      } else {
        console.log(
          'Error: Failed to retrieve a valid logout URL from the server.',
        )
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export function auth0callback() {
  return api()
    .get(`/tracer/auth0callback${window.location.search}`, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data && response.data.location) {
        window.location = response.data.location
      } else {
        console.log(
          'Error: Failed to retrieve a valid Auth0 callback redirect URL from the server.',
        )
        window.location = '/'
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
