import React, { useState } from 'react'

const MIN_SYMBOLS_TO_START_SEARCHING = 2

const Search = ({ makeSearchRequest, opts }) => {
  const [query, setQuery, isLoading] = useState('')
  const handleInput = (e) => {
    const searchQuery = e.target ? e.target.value.trim() : ''
    setQuery(searchQuery)
    if (
      searchQuery.length >= MIN_SYMBOLS_TO_START_SEARCHING ||
      searchQuery === ''
    ) {
      makeSearchRequest({ ...opts, query: searchQuery })
    }
  }

  return (
    <div className="search-box">
      <input
        disabled={isLoading}
        type="text"
        placeholder="Type email, first or last name"
        value={query}
        onChange={handleInput}
      />
    </div>
  )
}

export default Search
