import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getComponentDetails } from '../api/component-api'
import * as pinThunks from '../thunks/pin'

const sliceName = 'componentDetails'

const initialState = {
  isLoading: false,
  data: null,
}

const reducers = {
  setIsLoading(state, action) {
    state.isLoading = action.payload
  },
  setData(state, action) {
    state.data = action.payload
    if (state.data) {
      state.data.isNode = true
    }
  },
}

const componentDetailsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers,
})

export const { setIsLoading, setData } = componentDetailsSlice.actions
export default componentDetailsSlice.reducer

// selectors
const getState = (state) => state[sliceName]

export const getIsLoading = createSelector(
  [getState],
  (state) => state.isLoading,
)

export const getComponentData = createSelector(
  [getState],
  (state) => state.data,
)

// thunks
export function fetchComponentDetails({ vin, componentId }) {
  return async function (dispatch) {
    dispatch(setData(initialState.data))
    dispatch(setIsLoading(true))
    try {
      const componentDetails = await getComponentDetails(vin, componentId)
      dispatch(setData(componentDetails))
      dispatch(pinThunks.selectElement(componentDetails))
    } catch (error) {
      dispatch(setData(null))
    }
    dispatch(setIsLoading(false))
  }
}
