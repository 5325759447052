import { useQuery } from 'react-query'
import { search } from '../api/component-api'
import { locale } from '../Locale'
import { apiGetTerminology } from '../api/terminology'

const COMPONENT_SEARCH_KEY = 'search-component'

export const useComponentSearch = ({ searchString, vin }) => {
  const {
    status,
    data: components,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [COMPONENT_SEARCH_KEY, searchString, vin],
    async () => {
      const result = await search({ componentId: searchString, vin })
      if (!!result) {
        const codes = result.map((r) => r.component.id)
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        result.forEach((r, idx) => {
          const key = r.component.id
          if (!!key && !!terms[key] && !!terms[key]['description']) {
            result[idx].component['description'] = terms[key]['description']
          }
        })
      }
      return result
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!searchString,
    },
  )

  return {
    status,
    components,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  }
}
