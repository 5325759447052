export function generateTestPayload({
  vin,
  makeModelId,
  userId,
  dtcs,
  salesCodes,
  vehiclePhase,
}) {
  const payload = {
    meta: {
      user_id: userId,
    },
    vin,
  }

  const modules = dtcs.reduce((modules, dtc) => {
    if (modules.get(dtc.acronym)) {
      modules.get(dtc.acronym).push({ error: dtc.name })
    } else {
      modules.set(dtc.acronym, [{ error: dtc.name }])
    }

    return modules
  }, new Map())

  return { ...payload, modules: Object.fromEntries(modules) }
}
