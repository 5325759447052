import { useQuery } from 'react-query'
import { search } from '../api/device-api'
import { locale } from '../Locale'
import { apiGetTerminology } from '../api/terminology'

const DEVICE_SEARCH_KEY = 'search-device'

export const useDeviceSearch = ({ searchString, makeModelId }) => {
  const { status, data, error, isFetching, isLoading, isError, isSuccess } =
    useQuery(
      [DEVICE_SEARCH_KEY, searchString, makeModelId],
      async () => {
        const results = await search(searchString, makeModelId)
        if (!!results && !!results.data) {
          const codes = results.data.map((d) => d.id)
          const terms = await apiGetTerminology({
            makeModelId,
            codes,
            language: locale,
          })
          results.data.forEach((d, idx) => {
            const key = d.id
            if (!!key && !!terms[key] && !!terms[key]['description']) {
              results.data[idx]['description'] = terms[key]['description']
            }
          })
        }
        return results
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!searchString,
      },
    )

  return {
    status,
    devices: data && data.data,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  }
}
