import { useQuery } from 'react-query'
import { apiGetStations } from '../api/stations'

export const STATIONS_KEY = 'stations'

export const useStations = ({ plant_name }) => {
  const {
    status,
    data: stationIds,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [STATIONS_KEY, plant_name],
    async () => {
      const result = await apiGetStations({ plant_name })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!plant_name,
    },
  )
  return {
    status,
    stationIds,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
