import React from 'react'
import Topology from '../SvgViews/Topology'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import {
  setSearch,
  setSelectedComponentId,
  setSelectedComponent,
  resetComponent,
} from '../../actions/dtc-mapping'
import { DestinationComponentHeader, Button } from './CrowdsourcedDTCMapping'
import { PinsTable } from './AddConnectorMappingStartComponent'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { DTC_MAPPING_ACTIVE_UI } from '../../constants'
import { useComponentSearch } from '../../hooks/ComponentSearch'

const ComponentsSearchTable = ({ componentSearchString, vin }) => {
  const dispatch = useDispatch()
  const { components, isLoading, isSuccess } = useComponentSearch({
    searchString: componentSearchString,
    vin,
  })
  const selectedComponentId = useSelector(
    (state) => state.dtcMapping.selectedComponentId,
    shallowEqual,
  )
  const {
    componentDetails,
    isLoading: componentDetailsIsLoading,
    isSuccess: componentDetailsIsSuccess,
  } = useComponentDetails({ vin, componentId: selectedComponentId })

  const doSelectComponent = (componentId) => () => {
    dispatch(resetComponent())
    dispatch(setSelectedComponentId(componentId))
  }

  React.useEffect(() => {
    if (!componentDetailsIsSuccess) return
    dispatch(setSelectedComponent(componentDetails.component))
  }, [componentDetails, selectedComponentId])

  return (
    <div className="mapping-table-wrapper">
      <div className="mapping-table-header">
        Components {isLoading && ' - Loading...'}
        {isSuccess && '-'}
        {isSuccess &&
          ` ${components.length} results for "${componentSearchString}"`}
      </div>
      <table className="table">
        <thead className="bs-table-header">
          <tr>
            <th />
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.alias" />
            </th>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.id" />
            </th>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.description" />
            </th>
          </tr>
        </thead>
        <tbody className="bs-table-body">
          {components &&
            components.map((obj) => (
              <tr
                key={obj.component.id}
                onClick={doSelectComponent(obj.component.id)}
              >
                <td>
                  <input
                    type="radio"
                    value={obj.component.id}
                    checked={obj.component.id === selectedComponentId}
                    onChange={doSelectComponent(obj.component.id)}
                  />
                </td>
                <td>{obj.component.alias}</td>
                <td>{obj.component.id}</td>
                <td>{obj.component.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export const AddConnectorMapping = ({ vin, dtcMemoId }) => {
  const dispatch = useDispatch()
  // State from dtcMappingReducer
  const props = useSelector((state) => state.dtcMapping, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearch(searchString))
    dispatch(resetComponent())
  }

  const doSearchByPressEnter = (e) =>
    e.key === 'Enter' && searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <div className="container-fluid">
      <div className="start-component-header">
        <DestinationComponentHeader dtcMemoId={dtcMemoId} vin={vin} />
        <Button
          secondary={true}
          onClick={() =>
            dispatch(
              setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
            )
          }
        >
          <FormattedMessage id="crowdsourcedDtcMapping.back" />
        </Button>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="vehicle-view">
            <FormattedMessage id="crowdsourcedDtcMapping.vehicleView" />
          </label>
          <div id="vehicle-view" className="mapping-vehicle-view-wrapper">
            <Topology component={props.selectedComponent} />
          </div>
        </div>
        <div className="col-sm-6">
          <label htmlFor="search-input">
            <FormattedMessage id="crowdsourcedDtcMapping.searchLabel" />
          </label>
          <div className="row mb-2">
            <div className="col-sm-8">
              <input
                className="form-control"
                id="search-input"
                type="text"
                value={searchString}
                onKeyPress={doSearchByPressEnter}
                onChange={doChangeSearchQuery}
              />
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-default uppercase w-100"
                onClick={doSearch}
                disabled={!searchString}
              >
                <FormattedMessage id="crowdsourcedDtcMapping.search" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ComponentsSearchTable
                vin={vin}
                componentSearchString={props.search}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <PinsTable
                componentId={props.selectedComponentId}
                vin={vin}
                dtcMemoId={dtcMemoId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
