import React from 'react'

const IconFail = (props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g
        id="Components.-Harness-View"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
      >
        <g
          id="Harness-View-Harness-Selected-Faulty-Dialog-4"
          transform="translate(-900.000000, -170.000000)"
          fill="#FFFFFF"
        >
          <g id="Headers" transform="translate(500.000000, 150.000000)">
            <g
              id="Icon-/-Icon-Close"
              transform="translate(400.000000, 20.000000)"
            >
              <polygon
                id="Icon-Close"
                points="17 15.026 15.026 17 10 11.974 4.974 17 3 15.026 8.026 10 3 4.974 4.974 3 10 8.026 15.026 3 17 4.974 11.974 10"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconFail
