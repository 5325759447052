import React, { Component } from 'react'
import NotificationGeneric from './NotificationGeneric'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

const DEFAULT_MESSAGE = (
  <FormattedMessage id="tracer.nortificationNetworkError.defaultMessage" />
)

class NetworkError extends Component {
  constructor(props) {
    super(props)
    this.state = { timerStopped: true, message: DEFAULT_MESSAGE }
  }

  componentDidMount = () => {
    this.timer = setInterval(this.tick, 1000)
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  tick = () => {
    let duration = moment.duration(this.props.retryingTime.diff(moment()))
    let timerStopped = duration.asSeconds() < 1
    let message = timerStopped
      ? DEFAULT_MESSAGE
      : `${this.props.intl.formatMessage({ id: 'tracer.nortificationNetworkError.alternateMessage' })} ${Math.floor(duration.asSeconds())}s ...`
    this.setState({ message, timerStopped })
  }

  render = () => {
    let { message, timerStopped } = this.state

    return (
      <NotificationGeneric
        message={message}
        wrapperClass="notification-network-error"
        {...this.props}
      >
        {!timerStopped && (
          <button
            className="btn btn-default flex-item text-uppercase"
            onClick={this.props.forceRetry}
          >
            <FormattedMessage id="tracer.nortificationNetworkError.retry" />
          </button>
        )}
      </NotificationGeneric>
    )
  }
}

export default injectIntl(function (props) {
  return <NetworkError {...props} />
})
