import React from 'react'
import Select from 'react-select'
import { Field } from 'redux-form'
import isArray from 'lodash/isArray'
import ErrorMessage from './ErrorMessage'

function component(props, field) {
  const { input, meta } = field

  const value = input.value.value || input.value
  const selectValue = { label: value, value: value }

  return (
    <div>
      <Select {...props} value={selectValue} onChange={input.onChange} />
      <ErrorMessage meta={meta} />
    </div>
  )
}

export default function (props) {
  let validators = null
  const componentWithProps = component.bind(null, props)

  if (!props.disabled && props.validate) {
    validators = isArray(props.validate) ? props.validate : [props.validate]
  }

  return (
    <Field
      name={props.name}
      validate={validators}
      component={componentWithProps}
      onChange={props.onChange}
    />
  )
}
