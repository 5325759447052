import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  ORIENTATION_FORWARD,
  ORIENTATION_BACK,
  ORIENTATION_UP,
  ORIENTATION_DOWN,
  WHITE_COLOR,
} from '../../constants'

const propTypes = {
  orientation: PropTypes.string,
  fill: PropTypes.string,
  skipFill: PropTypes.bool,
}

const defaultProps = {
  orientation: ORIENTATION_DOWN,
  fill: WHITE_COLOR,
  opacity: 1,
  skipFill: false,
}

const IconChevron = (props) => {
  const svgClassName = classNames({
    'rotate-90': props.orientation === ORIENTATION_FORWARD,
    'rotate-180': props.orientation === ORIENTATION_UP,
    'rotate-270': props.orientation === ORIENTATION_BACK,
  })

  const svgProps = {
    className: svgClassName,
  }

  const pathProps = {}
  if (!props.skipFill) {
    pathProps.stroke = props.fill
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        {...pathProps}
        fill="none"
        fillRule="evenodd"
        strokeWidth="2"
        d="M15.953 13.123l-5.83-5.83-5.83 5.83"
        opacity={props.opacity}
      />
    </svg>
  )
}

IconChevron.propTypes = propTypes
IconChevron.defaultProps = defaultProps

export default IconChevron
