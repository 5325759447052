import {
  USER_LOADING,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
} from '../actions/action-types'

export function setUserLoading() {
  return {
    type: USER_LOADING,
  }
}

export function setUserLoggedIn(user) {
  return {
    type: USER_LOGGED_IN,
    user,
  }
}

export function setUserLoggedOut() {
  return {
    type: USER_LOGGED_OUT,
  }
}
