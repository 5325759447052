import React from 'react'
import { auth0callback } from '../api/auth-api'
import Loading from './Loading'
import { FormattedMessage } from 'react-intl'

class Auth0Callback extends React.Component {
  componentDidMount() {
    auth0callback()
  }
  render() {
    return (
      <Loading
        backgroundColor=""
        text={<FormattedMessage id="processes.loggingIn" />}
      />
    )
  }
}

export default Auth0Callback
