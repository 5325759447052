/* Setup i18n */
import i18n from './i18n'

const preferredLocale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US'

export const locale = i18n.setLocale(preferredLocale)
export const translations = i18n.translationsForLocale(locale)
