import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  apiGetDTCMappings,
  apiCreateDTCMapping,
  apiDeleteDTCMapping,
  apiActivateDTCMapping,
} from '../api/crowdsourced-dtc-mappings'
import { ACTIVE_DTC_MAPPING_QUERY_KEY } from './ActiveDTCMapping'

const DTC_MAPPING_QUERY_KEY = 'dtc-mapping'

export const useDTCMapping = ({ makeModelId, dtcCode, acronym }) => {
  const {
    status,
    data: crowdsourcedDTCMappings,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [DTC_MAPPING_QUERY_KEY, makeModelId, dtcCode, acronym],
    async () => {
      const result = await apiGetDTCMappings({ makeModelId, dtcCode, acronym })
      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    crowdsourcedDTCMappings,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useAddDTCMapping = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      destinationComponentId,
      makeModelId,
      dtcCode,
      acronym,
      updatedBy,
      startComponentId = null,
    }) =>
      apiCreateDTCMapping({
        destinationComponentId,
        makeModelId,
        dtcCode,
        acronym,
        updatedBy,
        startComponentId,
      }),
  )
}

export const useDeleteDTCMapping = () => {
  const queryClient = useQueryClient()

  return useMutation((mapping) => apiDeleteDTCMapping(mapping.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(DTC_MAPPING_QUERY_KEY)
      queryClient.invalidateQueries(ACTIVE_DTC_MAPPING_QUERY_KEY)
    },
  })
}

export const useActivateDTCMapping = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, updatedBy }) => apiActivateDTCMapping({ id, updatedBy }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DTC_MAPPING_QUERY_KEY)
        queryClient.invalidateQueries(ACTIVE_DTC_MAPPING_QUERY_KEY)
      },
    },
  )
}
