import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requireAuthentication } from '../helpers/authentication'
import { getUserPlants, getUserManagableRoles } from '../selectors/user'
import {
  getUserIsSaving,
  getEditingUser,
  getUserIsLoading,
} from '../selectors/users'
import { requestUserUpdating, fetchEditingUser } from '../thunks/users'
import Forbidden from '../components/Forbidden'
import MustHave from '../containers/MustHave'
import { EditUser } from '../components/Users'

class UserEditContainer extends Component {
  componentDidMount() {
    const { fetchEditingUser } = this.props
    fetchEditingUser()
  }
  render() {
    return (
      <MustHave
        permission="page-user-administration:get"
        fallback={<Forbidden />}
      >
        <EditUser {...this.props} />
      </MustHave>
    )
  }
}

const mapState = (
  state,
  {
    match: {
      params: { userId },
    },
  },
) => ({
  userId,
  editingUser: getEditingUser(state),
  plants: getUserPlants(state),
  roles: getUserManagableRoles(state),
  isSaving: getUserIsSaving(state),
  isLoading: getUserIsLoading(state),
})

const mapDispatch = (
  dispatch,
  {
    match: {
      params: { userId },
    },
  },
) => ({
  updateUser: (values) => dispatch(requestUserUpdating(userId, values)),
  fetchEditingUser: () => dispatch(fetchEditingUser(userId)),
})

export default connect(
  mapState,
  mapDispatch,
)(requireAuthentication(UserEditContainer))
