import * as types from '../actions/action-types'

const initialState = {
  currentPin: {},
  componentId: null,
  pinNumber: null,
  pinDestination: {},
  showEmptyPins: false,
}

const pinReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_PIN_DESTINATION:
      return Object.assign({}, state, {
        pinDestination: initialState.pinDestination,
      })
    case types.CLEAR_SELECTED_PIN:
      return Object.assign({}, state, {
        currentPin: initialState.currentPin,
        pinNumber: initialState.pinNumber,
        componentId: initialState.componentId,
      })
    case types.SELECT_ELEMENT_IN_PIN_DESTINATION:
      return Object.assign({}, state, {
        selectedElement: action.selectedElement,
      })
    case types.SELECT_PIN:
      return Object.assign({}, state, {
        currentPin: action.pin,
        pinNumber: action.pinNumber,
        componentId: action.componentId,
      })
    case types.SET_PIN_DESTINATION:
      return Object.assign({}, state, {
        pinDestination: action.pinDestination,
      })
    case types.TOGGLE_SHOW_EMPTY_PINS:
      return Object.assign({}, state, {
        showEmptyPins: !state.showEmptyPins,
      })
    case types.SET_SHOW_EMPTY_PINS:
      return Object.assign({}, state, {
        showEmptyPins: action.visibility,
      })
    default:
      return state
  }
}

export default pinReducer
