import * as types from '../actions/action-types'

export function setCircuitCategories(categories) {
  return {
    type: types.SET_CIRCUIT_CATEGORIES,
    categories,
  }
}

export function updateStateOfComponents(states) {
  return {
    type: types.UPDATE_COMPONENT_STATE,
    states,
  }
}

export function updateStateOfHarnesses(states) {
  return {
    type: types.UPDATE_HARNESS_STATE,
    states,
  }
}

export function updateStateOfDevices(states) {
  return {
    type: types.UPDATE_DEVICE_STATE,
    states,
  }
}

export function setRepairHistory(repairHistory) {
  return {
    type: types.SET_REPAIR_HISTORY,
    repairHistory,
  }
}

export function deleteRepairHistoryItem(id) {
  return {
    type: types.DELETE_REPAIR_HISTORY_ITEM,
    id,
  }
}

export function deleteEmptyRepairHistoryItems() {
  return {
    type: types.DELETE_EMPTY_REPAIR_HISTORY_ITEMS,
  }
}

export function addRepairHistoryItem(newRepairHistoryItem) {
  return {
    type: types.ADD_REPAIR_HISTORY_ITEM,
    newRepairHistoryItem,
  }
}

export function setFaultCategories(faults) {
  return {
    type: types.SET_FAULT_CATEGORIES,
    faults,
  }
}

export function setHistoryForComponent(componentId, history) {
  return {
    type: types.SET_HISTORY_FOR_COMPONENT,
    componentId,
    history,
  }
}

export function setBatchHistoryForComponents(componentsWithHistory) {
  return {
    type: types.SET_BATCH_HISTORY_FOR_COMPONENTS,
    componentsWithHistory,
  }
}

export function resetHistoryForComponent() {
  return {
    type: types.RESET_HISTORY_FOR_COMPONENT,
  }
}

export function addAppliedComponent(componentId) {
  return {
    type: types.ADD_APPLIED_COMPONENT,
    componentId,
  }
}

export function resetAppliedComponents() {
  return {
    type: types.RESET_APPLIED_COMPONENTS,
  }
}
