import { connect } from 'react-redux'
import get from 'lodash/get'

import { setModal } from '../actions/modal-actions'
import {
  TRACER_VERSION,
  MODAL_CHANGE_START_VIEW,
  MODAL_SUBMIT_FEEDBACK,
  MODAL_SELECT_PLANT,
  MODAL_SELECT_STATION_ALERT,
} from '../constants'
import UserMenu from '../components/UserMenu'
import { logout } from '../api/auth-api'
import { isAuthenticated } from '../helpers/authentication'

const mapStateToProps = (state) => ({
  username: get(state, 'user.data.username'),
  clientId: get(state, 'user.data.client_id'),
  userIsSignedIn: isAuthenticated(get(state, 'user')),
  tracerVersion: TRACER_VERSION,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeStartView: () => dispatch(setModal(MODAL_CHANGE_START_VIEW)),
    openFeedbackModal: () => {
      dispatch(setModal(MODAL_SUBMIT_FEEDBACK))
    },
    openSelectPlantModal: () => {
      dispatch(setModal(MODAL_SELECT_PLANT))
    },
    openSelectStationAlertModal: () => {
      dispatch(setModal(MODAL_SELECT_STATION_ALERT))
    },
    onLogout: () => logout(),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
