import React from 'react'

const IconPlus = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 0H14.5V14.5L0 14.5V21.5H14.5V36H21.5V21.5H36V14.5L21.5 14.5V0Z"
        fill="white"
      />
    </svg>
  )
}

export default IconPlus
