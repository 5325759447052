import React from 'react'
import classnames from 'classnames'

const IconInfo = (props) => {
  const classNames = classnames('icon-info', {
    disabled: props.disabled,
    clickable: !props.notClickable,
  })
  const onClickHandler = (e) => {
    if (props.disabled) {
      return false
    }
    if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames}
      onClick={onClickHandler}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M2 10c0 4.415 3.585 8 8 8s8-3.585 8-8-3.585-8-8-8-8 3.585-8 8zm-2 0C0 4.48 4.48 0 10 0s10 4.48 10 10-4.48 10-10 10S0 15.52 0 10z"
        />
        <path d="M9 15V9h2v6zM9 7V5h2v2z" />
      </g>
    </svg>
  )
}

export default IconInfo
