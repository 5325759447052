import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { LeftArrow, RightArrow } from '../Arrows'
import { setMainDashboardPageNum } from '../../actions/app-actions'

const DashboardCarousel = ({ elements, perPage = 6 }) => {
  const dispatch = useDispatch()
  const pageNum = useSelector(
    (state) => get(state, 'app.mainDashboardPageNum', []),
    shallowEqual,
  )
  const nonNullElements = elements.filter((e) => e)
  const totalPages = Math.ceil(nonNullElements.length / perPage)
  const pages = [...Array(totalPages).keys()]
  const widthUnit = '%'

  const wrapperStyle = {
    display: 'flex',
    transition: 'all 200ms ease-out 0s',
    transform: `translate3d(-${(pageNum * 100) / totalPages}${widthUnit}, 0px, 0px)`,
    width: `${totalPages * 100}${widthUnit}`,
  }

  const pageStyle = {
    width: `${100 / totalPages}%`,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  }

  return (
    <div className="main-dashboard-container" data-testid="main-dashboard">
      {totalPages > 1 && (
        <button
          className={classNames('main-dashboard-pagination-ctrl prev-page', {
            disabled: pageNum === 0,
          })}
          type="button"
          onClick={() => {
            if (pageNum > 0) {
              dispatch(setMainDashboardPageNum(pageNum - 1))
            }
          }}
        >
          <LeftArrow />
        </button>
      )}
      <div className="main-dashboard-carousel-wrapper">
        <div style={wrapperStyle}>
          {pages.map((page) => (
            <div key={page} style={pageStyle}>
              {nonNullElements.slice(page * perPage, (page + 1) * perPage)}
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="main-dashboard-pagination-wrapper">
            <div>
              <FormattedMessage
                id="generic.pagination"
                values={{ pageNum: pageNum + 1, totalPages }}
              />
            </div>
            <div data-cy="main-dashboard-pages">
              {pages.map((page) => (
                <button
                  onClick={() => dispatch(setMainDashboardPageNum(page))}
                  className={classNames(
                    'main-dashboard-pagination-button next-page',
                    { active: page === pageNum },
                  )}
                  key={page}
                  type="button"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <button
          className={classNames('main-dashboard-pagination-ctrl next-page', {
            disabled: pageNum + 1 === totalPages,
          })}
          type="button"
          onClick={() => {
            if (pageNum + 1 < totalPages) {
              dispatch(setMainDashboardPageNum(pageNum + 1))
            }
          }}
        >
          <RightArrow />
        </button>
      )}
    </div>
  )
}

export default DashboardCarousel
