import React from 'react'

const IconComment = (props) => {
  return (
    <svg
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="icon-comment"
    >
      <path
        id="a"
        d="M7 14l1.333-1H15a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h4v1zM3 0h12a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H9l-4 3v-3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm3 5h6a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2zm0 3h3a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2z"
      />
    </svg>
  )
}

IconComment.defaultProps = {
  onClick: () => null,
}

export default IconComment
