import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import ComponentListItem from './ComponentListItem'

const COMPONENT_LIST_ID = 'component-list'

const ComponentList = (props) => {
  React.useEffect(() => {
    if (!isEmpty(props.elements)) {
      // When a new component list is set, select the first element.
      props.selectFirstComponent(
        props.elements[0],
        props.dtcs,
        props.toggledOnDtc,
      )
    }
  }, [props.elements])

  function renderComponent(component) {
    if (component.isNode) {
      const relatedComponents =
        props.toggledOnDtc && props.connectorSet.has(component.id)
      const selectedComponent =
        props.selectedComponent && props.selectedComponent.id === component.id
      // Get list of problem connectors
      const status = get(props.componentStates[component.id], 'status')
      return (
        <ComponentListItem
          component={component}
          key={component.id}
          onClick={createOnClickHandler(component)}
          selected={selectedComponent}
          indicate={relatedComponents}
          status={status}
        />
      )
    } else {
      return null
    }
  }

  const createOnClickHandler = (component) => {
    return function (component) {
      props.onComponentClicked(component)
    }
  }

  return (
    <ul id={COMPONENT_LIST_ID} className="list-group">
      {!isEmpty(props.elements) &&
        props.elements.map((component) => {
          return renderComponent(component)
        })}
    </ul>
  )
}

export default ComponentList
