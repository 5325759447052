import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { injectIntl } from 'react-intl'
import { getComponentsForInlineEdge } from '../helpers/utils'
import { getElementName } from '../helpers/element'
import StateButtons from '../components/StateButtons'

const HarnessViewGraphTooltip = ({
  graph,
  element,
  intl,
  componentStates,
  selectedElement,
  historiesForComponents,
}) => {
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )
  const createTooltipTextContent = (
    intl,
    nameHeader,
    name,
    idHeader,
    id,
    descriptionHeader,
    description,
    assemblyStation,
  ) => {
    const tooltipNameHeader = nameHeader
    const tooltipName = name
    const tooltipIdHeader = idHeader
    const tooltipId = id

    const tooltipDescriptionHeader = descriptionHeader
      ? descriptionHeader
      : null
    const tooltipDescription = description ? description : null

    const tooltipAssemblyStationHeader =
      (assemblyStation &&
        intl.formatMessage({ id: 'generic.assemblyStation' })) ||
      null
    const tooltipAssemblyStation = assemblyStation || null
    return {
      tooltipNameHeader,
      tooltipName,
      tooltipIdHeader,
      tooltipId,
      tooltipDescriptionHeader,
      tooltipDescription,
      tooltipAssemblyStationHeader,
      tooltipAssemblyStation,
    }
  }

  const getComponentTooltipContent = (intl, element) => {
    return createTooltipTextContent(
      intl,
      intl.formatMessage({ id: 'generic.component' }),
      getElementName(element.data()),
      intl.formatMessage({ id: 'generic.id' }),
      element.data('id'),
      intl.formatMessage({ id: 'generic.description' }),
      element.data('description'),
      element.data('assembly_station'),
    )
  }

  const getHarnessTooltipContent = (intl, element) => {
    return createTooltipTextContent(
      intl,
      intl.formatMessage({ id: 'tracer.toolTip.harnessPartNumber' }),
      element.data('harness') + ' ' + element.data('level'),
      intl.formatMessage({ id: 'generic.description' }),
      element.data('description'),
      element.data('assembly_station'),
    )
  }

  const getDeviceTooltipContent = (intl, element) => {
    return createTooltipTextContent(
      intl,
      intl.formatMessage({ id: 'generic.component' }),
      getElementName(element.data()),
      intl.formatMessage({ id: 'generic.id' }),
      element.data('id'),
      intl.formatMessage({ id: 'generic.description' }),
      element.data('description'),
      element.data('assembly_station'),
    )
  }

  const getInlineTooltipContent = (intl, componentStates, element, graph) => {
    const inlineComponents = getComponentsForInlineEdge(
      graph,
      element,
      componentStates,
    )
    const inlineComponentsArr = inlineComponents.map(
      (component) => component.element,
    )

    return createTooltipTextContent(
      intl,
      intl.formatMessage({ id: 'generic.component' }),
      `${getElementName(inlineComponentsArr[0])} - ${getElementName(inlineComponentsArr[1])}`,
      intl.formatMessage({ id: 'generic.id' }),
      `${inlineComponentsArr[0].id} - ${inlineComponentsArr[1].id}`,
      intl.formatMessage({ id: 'generic.description' }),
      element.data('description'),
      element.data('assembly_station'),
    )
  }

  const createTooltipContent = () => {
    if (element.isEdge()) {
      if (element.data('edge_type')) {
        return getInlineTooltipContent(intl, componentStates, element, graph)
      } else {
        return getHarnessTooltipContent(intl, element)
      }
    } else if (element.isNode()) {
      if (element.data().category === 'device') {
        return getDeviceTooltipContent(intl, element)
      } else {
        return getComponentTooltipContent(intl, element)
      }
    }
  }

  // Get Cytoscape scratchpad data.
  // More info: https://js.cytoscape.org/#ele.scratch
  const destroyTooltip = () => element.scratch('_tip').destroy()

  const toolTipContent = createTooltipContent()
  return (
    <div>
      <h6>{toolTipContent.tooltipNameHeader}</h6>
      <p>{toolTipContent.tooltipName}</p>
      <h6>{toolTipContent.tooltipIdHeader}</h6>
      <p>{toolTipContent.tooltipId}</p>
      <h6>{toolTipContent.tooltipDescriptionHeader}</h6>
      <p>{toolTipContent.tooltipDescription}</p>
      <h6>{toolTipContent.tooltipAssemblyStationHeader}</h6>
      <p>{toolTipContent.tooltipAssemblyStation}</p>
      {!isEphemeralVehicleTest && (
        <StateButtons onBeforeClick={destroyTooltip} outline tooltip />
      )}
    </div>
  )
}

export default injectIntl(HarnessViewGraphTooltip)
