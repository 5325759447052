import { formatTimestamp } from '../helpers/utils'
import PropTypes from 'prop-types'
import React from 'react'
import ReactTable from 'react-table-6'
import { shallowEqual, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  navigateToVehicleTest,
  vehicleTestPayloadUrl,
} from '../api/vehicle-tests-api'
import MustHave from '../containers/MustHave'
import { CantFixMessage } from './CantFixReason'
import { postEmptyVehicleTest } from '../api/harness-api'

const propTypes = {
  currentVehicleTest: PropTypes.object,
  data: PropTypes.array,
  onViewButtonClick: PropTypes.func,
  showVin: PropTypes.bool,
  style: PropTypes.object,
}

export function displayCantFix(rowData) {
  return (
    <span>
      <CantFixMessage vehicleTestId={rowData.id} />
    </span>
  )
}

const createColumns = (
  intl,
  showVin,
  currentVehicleTest,
  onViewButtonClick,
  createEmptyVehicleTest,
) => {
  const timeStampFormat = intl.formatMessage({ id: 'format.dayAndTime' })
  return [
    {
      accessor: ({ vin }) => vin,
      Header: intl.formatMessage({ id: 'generic.vin' }),
      minWidth: 154,
      id: 'vin',
      show: showVin,
    },
    {
      accessor: ({ timestamp }) =>
        timestamp ? formatTimestamp(timeStampFormat, timestamp) : '--',
      Header: intl.formatMessage({ id: 'vinSearch.columns.date' }),
      id: 'date',
      minWidth: 128,
    },
    {
      accessor: (data) => (data.meta ? data.meta.test_type : null),
      Header: intl.formatMessage({ id: 'vinSearch.columns.testType' }),
      id: 'testType',
      minWidth: 85,
    },
    {
      accessor: (data) =>
        data.test_zone_description
          ? data.test_zone_description
          : data.meta
            ? data.meta.hardware_location_id
            : null,
      Header: intl.formatMessage({ id: 'vinSearch.columns.location' }),
      id: 'location',
      minWidth: 85,
    },
    {
      accessor: displayCantFix,
      Header: intl.formatMessage({ id: 'vinSearch.columns.cantFix' }),
      id: 'cantFix',
      minWidth: 128,
    },
    {
      accessor: (data) => (data.meta ? data.meta.user_id : null),
      Header: intl.formatMessage({ id: 'vinSearch.columns.user' }),
      id: 'user',
      minWidth: 60,
    },
    {
      accessor: (data) =>
        data.id ? (
          createActions(currentVehicleTest, intl, onViewButtonClick)(data)
        ) : (
          <button
            className="btn table-btn uppercase view-tracer-button action-button"
            onClick={createEmptyVehicleTest(data.vin)}
          >
            {intl.formatMessage({ id: 'repairHistory.titles.addFault' })}
          </button>
        ),
      id: 'actions',
      maxWidth: 230,
      minWidth: 155,
    },
  ]
}

const createActions = (currentVehicleTest, intl, onViewButtonClick) => {
  const viewButton = (rowData) =>
    currentVehicleTest && currentVehicleTest.id === rowData.id ? (
      <p className="current-vehicle-test uppercase">
        {intl.formatMessage({ id: 'generic.current' })}
      </p>
    ) : (
      <button
        className="btn table-btn uppercase view-tracer-button action-button"
        onClick={(e) => {
          navigateToVehicleTest({ vehicleTestId: rowData.id })
          if (onViewButtonClick) {
            onViewButtonClick(rowData.id)
          }
        }}
      >
        {intl.formatMessage({ id: 'vinSearch.buttons.tracer' })}
      </button>
    )

  const downloadButton = (rowData) => (
    <MustHave permission="vehicle-test-download:get">
      <a
        className="btn table-btn uppercase download-tracer-payload-button action-button"
        href={vehicleTestPayloadUrl(rowData.id)}
      >
        {intl.formatMessage({ id: 'vinSearch.buttons.download' })}
      </a>
    </MustHave>
  )

  return (rowData) => (
    <span>
      {viewButton(rowData)}
      {downloadButton(rowData)}
    </span>
  )
}

const defaultProps = {
  onViewButtonClick: undefined,
  currentVehicleTest: undefined,
  data: [],
  showVin: true,
  style: {
    maxHeight: '100%',
    position: 'absolute',
    width: '100%',
  },
}

function VehicleTestSearchTable({
  onViewButtonClick,
  currentVehicleTest,
  data,
  style,
  intl,
  showVin,
}) {
  const authToken = useSelector((state) => state.user.data.token, shallowEqual)
  const createEmptyVehicleTest = (vin) => () =>
    postEmptyVehicleTest(vin, authToken)

  const rowCount = data.length

  return (
    <ReactTable
      showPagination={false}
      data={data}
      columns={createColumns(
        intl,
        showVin,
        currentVehicleTest,
        onViewButtonClick,
        createEmptyVehicleTest,
      )}
      style={style}
      tableProps={{
        defaultPageSize: rowCount,
        pageSize: rowCount,
        showPagination: false,
      }}
    />
  )
}

VehicleTestSearchTable.propTypes = propTypes
VehicleTestSearchTable.defaultProps = defaultProps

export default injectIntl(VehicleTestSearchTable)
