import React, { useState } from 'react'
import { useDiagram } from '../DiagramContext'
import { useComponentPinout, useComponentFullPinout } from '../../BlockDiagram'
import PinoutTable from './PinoutTable'

const ConnectorFullPinout = ({ isAllPins, setIsAllPins }) => {
  const diagram = useDiagram()
  const { isLoading, pinout } = useComponentFullPinout({
    vin: diagram.vin,
    componentId: diagram.blockDiagram.graph.data.componentId,
  })

  return (
    <PinoutTable
      pinout={pinout}
      isLoading={isLoading}
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
    />
  )
}

const ConnectorSessionPinout = ({ isAllPins, setIsAllPins }) => {
  const diagram = useDiagram()
  const { isLoading, pinout } = useComponentPinout({
    vin: diagram.vin,
    componentId: diagram.blockDiagram.graph.data.componentId,
    neighborIds: diagram.blockDiagram.graph.data.neighborIds,
  })
  return (
    <PinoutTable
      pinout={pinout}
      isLoading={isLoading}
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
    />
  )
}

const ConnectorPinout = () => {
  const [isAllPins, setIsAllPins] = useState(false)
  return isAllPins ? (
    <ConnectorFullPinout isAllPins={isAllPins} setIsAllPins={setIsAllPins} />
  ) : (
    <ConnectorSessionPinout isAllPins={isAllPins} setIsAllPins={setIsAllPins} />
  )
}

export default ConnectorPinout
