import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import max from 'lodash/max'

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

import IconArrow from './Icons/IconArrow'
import IconComment from './Icons/IconComment'
import { formatLocalizedDate } from '../helpers/datetime'
import { capitalizeAllWords } from '../helpers/utils'

const getLastTimeApplied = (faultsDates) => {
  const lastTimeApplied = max(faultsDates)
  return lastTimeApplied
}

const ComponentFaultHistoryItem = injectIntl(({ fault, intl, tz }) => {
  const { vin, created_at: createdAt, annotation } = fault
  const noCommentsText = intl.formatMessage({
    id: 'tracer.componentHistory.noComments',
  })
  const formatString = intl.formatMessage({ id: 'format.timestamp' })
  const formattedDate = formatLocalizedDate(createdAt, formatString, tz)

  return (
    <div className="history-item">
      <div className="vin cell">{vin}</div>
      <div className="last-time-applied cell">{formattedDate}</div>
      <div className="annotation cell">{annotation || noCommentsText}</div>
    </div>
  )
})

const ComponentFaultHistoryGroup = injectIntl((props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { faultType, faultsItems, intl, tz } = props
  const annotationsCount = filter(
    faultsItems,
    (faultItem) => !isEmpty(faultItem.annotation),
  ).length
  const formatString = intl.formatMessage({ id: 'format.timestamp' })
  const faultsDates = map(faultsItems, 'created_at')
  const lastTimeApplied = getLastTimeApplied(faultsDates)
  const formattedLastTimeApplied = formatLocalizedDate(
    lastTimeApplied,
    formatString,
    tz,
  )

  return (
    <div className="component-fault-history-group">
      <div
        className="header-container"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="arrow">
          <IconArrow up={isExpanded} />
        </div>
        <div className="fault-type-container">
          <span className="fault-type-name">
            {capitalizeAllWords(faultType)}
          </span>{' '}
          ({faultsItems.length})
        </div>
        <div className="cell">{formattedLastTimeApplied}</div>
        <div className="annotation-count-container">
          {annotationsCount === 0 && (
            <span className="no-comments">
              <FormattedMessage id="tracer.componentHistory.noComments" />
            </span>
          )}
          {annotationsCount > 0 && (
            <React.Fragment>
              <IconComment />
              <span className="annotation-count">{annotationsCount}</span>
            </React.Fragment>
          )}
        </div>
      </div>
      {isExpanded &&
        map(faultsItems, (faultItem) => (
          <ComponentFaultHistoryItem
            fault={faultItem}
            key={faultItem.id}
            tz={tz}
          />
        ))}
    </div>
  )
})

const mapStateToProps = (state, ownProps) => {
  return {
    tz: state.user.businessTimezone,
  }
}

const ComponentFaultsHistoryList = (props) => {
  const { componentFaultsHistory, tz } = props
  return map(componentFaultsHistory, (faultsItems, faultType) => (
    <ComponentFaultHistoryGroup
      faultType={faultType}
      faultsItems={faultsItems}
      key={faultType}
      tz={tz}
    />
  ))
}

export default connect(mapStateToProps)(ComponentFaultsHistoryList)
