import React from 'react'

const IconNoGraph = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <path
          id="b"
          d="M22.605-1.442l18.83 32.954A3 3 0 0 1 38.83 36H1.17a3 3 0 0 1-2.605-4.488l18.83-32.954a3 3 0 0 1 5.21 0z"
        />
        <filter
          id="a"
          width="146.6%"
          height="147.5%"
          x="-23.3%"
          y="-22.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#5A6382"
          d="M50 0c27.614 0 50 22.38 50 50 0 27.614-22.38 50-50 50-27.614 0-50-22.38-50-50C0 22.386 22.38 0 50 0z"
        />
        <g fillRule="nonzero">
          <g transform="translate(30 30)">
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use fill="#FFC700" xlinkHref="#b" />
          </g>
          <path fill="#000" d="M48 42h4v12h-4V42zm0 14h4v4h-4v-4z" />
        </g>
      </g>
    </svg>
  )
}

export default IconNoGraph
