import { useQuery, useMutation, useQueryClient } from 'react-query'
import { apiGetActiveDTCMappings } from '../api/active-dtc-mappings'

export const ACTIVE_DTC_MAPPING_QUERY_KEY = 'active-dtc-mapping'

export const useActiveDTCMapping = ({ makeModelId, dtcCode, acronym }) => {
  const {
    status,
    data: activeDTCMappings,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [ACTIVE_DTC_MAPPING_QUERY_KEY, makeModelId, dtcCode, acronym],
    async () => {
      const result = await apiGetActiveDTCMappings({
        makeModelId,
        dtcCode,
        acronym,
      })
      return result
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    activeDTCMappings,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
