import React, { useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { strategyFactory } from '../selectors/pinout'
import { getPinDestination } from '../api/controller-api'
import { selectPin } from '../actions/pin-actions'
import { selectElement } from '../thunks/pin'
import { setConnectorImage, setModal } from '../actions/modal-actions'
import {
  MODAL_CONNECTOR_VIEW,
  MODAL_EMBEDDED_MODE_PIN_DESTINATION,
} from '../constants'
import GroundIcon from '../assets/images/GND.png'
import PowerIcon from '../assets/images/PWR.png'
import CanIcon from '../assets/images/CAN.png'
import Pin from '../components/Pin'
import PinEmpty from '../components/PinEmpty'
import { IconFilter } from '../components/Icons/IconFilter'
import { ReactComponent as IconBack } from '../assets/images/icons/embedded/svg/back.svg'
import {
  setActionBarSelection,
  setPinoutTableScroll,
} from '../actions/embedded-mode-actions'
import { SELECTION_VALUES } from './const'
import { centerOnSelectedElement } from '../actions/component-actions'
import { Down, Up } from './Controls'

// NOTE: the same value defined in embedded-mode/_pinout-view.scss,
// if you change this value you should make corresponding change in CSS code.
const PINOUT_TABLE_ROW_HEIGHT = 70 // px
const N = 4 // scroll multiple
const SCROLL_UP_PARAMS = { top: N * PINOUT_TABLE_ROW_HEIGHT, left: 0 }
const SCROLL_DOWN_PARAMS = { top: -N * PINOUT_TABLE_ROW_HEIGHT, left: 0 }

const hairlineWidth = 1
const centerLineMargin = 3
const longLineHeight = 32
const shortLineHeight = 10

const JumperHalfConnection = ({ reverseDirection }) => {
  const VerticalLine = ({ height }) => {
    const styles = {
      width: hairlineWidth,
      height,
      border: 'solid 1px #ffffff',
      marginLeft: centerLineMargin,
    }
    return <div style={styles} />
  }

  const Circle = () => {
    const centerCirclePadding = 2
    const styles = {
      paddingRight: centerCirclePadding,
      borderRadius: 4,
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
    }
    return <div style={styles} />
  }

  const oneThirdOfRow = 17
  const paddingTop = !reverseDirection ? 0 : oneThirdOfRow
  const paddingBottom = reverseDirection ? 0 : oneThirdOfRow
  const centerContentPadding = 24
  const resetPadding = 0
  const lineHeight = reverseDirection ? longLineHeight : shortLineHeight

  const tdStyles = {
    paddingTop,
    paddingRight: resetPadding,
    paddingBottom,
    paddingLeft: centerContentPadding,
    width: hairlineWidth,
  }

  return (
    <td style={tdStyles}>
      <div style={{ display: 'inline' }}>
        {!reverseDirection && <VerticalLine height={lineHeight} />}
        <Circle />
        {reverseDirection && <VerticalLine height={lineHeight} />}
      </div>
    </td>
  )
}

const Pins = ({ pins, pathId, selectedElement, onPinClick, showJumper }) => {
  const pinFormatter = (content) =>
    content ? (
      <Pin colors={content} pinClassName="pin-color" />
    ) : (
      <PinEmpty pinEmptyClassName="pin-color" />
    )

  const categoryFormatter = (content, row) => {
    const iconConfig = {
      GND: GroundIcon,
      PWR: PowerIcon,
      CAN: CanIcon,
    }
    const src = iconConfig[content]

    if (src) {
      return (
        <div key={`${row.circuit}-${src}`} style={{ height: '25px' }}>
          <img className="category-icon" src={src} alt="circuit category img" />
        </div>
      )
    }
    return null
  }

  return (
    <tbody>
      {pins.map((pin, index) => (
        <tr
          key={pin.key}
          onClick={() =>
            onPinClick(pin, pathId, selectedElement, pin.originalCavityNumber)
          }
          className="embedded-mode-clickable-animation"
        >
          {showJumper && (
            <JumperHalfConnection reverseDirection={index % 2 === 0} />
          )}
          <td>
            <div>{pin.cavity}</div>
          </td>
          <td>
            {pinFormatter(pin.colors)} {pin.color_desc}
          </td>
          <td>{categoryFormatter(pin.circuit_category, pin)}</td>
          <td>{pin.signal_desc}</td>
        </tr>
      ))}
    </tbody>
  )
}

const PinTable = (props) => {
  const { pins, jumperPins, pathId, selectedElement, onPinClick } = props
  const pinsIsntEmpty = pins.length !== 0
  const jumperPinsIsntEmpty = jumperPins.length !== 0

  let PinTableBody = (
    <tbody>
      <tr>
        <td colSpan="4">
          <FormattedMessage id="state.noData" />
        </td>
      </tr>
    </tbody>
  )

  if (pinsIsntEmpty) {
    PinTableBody = (
      <Pins
        pins={pins}
        pathId={pathId}
        selectedElement={selectedElement}
        onPinClick={onPinClick}
      />
    )
  } else if (jumperPinsIsntEmpty) {
    // TODO: consider to remove this
    PinTableBody = (
      <Pins
        pins={jumperPins}
        pathId={pathId}
        selectedElement={selectedElement}
        onPinClick={onPinClick}
        showJumper
      />
    )
  }

  const pinout = useRef(null)

  const dispatch = useDispatch()

  const goBack = () => {
    dispatch(setActionBarSelection(SELECTION_VALUES.components))
    dispatch(centerOnSelectedElement())
  }

  const handleScroll = () => {
    dispatch(
      setPinoutTableScroll({
        scrollTop: pinout.current.scrollTop,
        scrollHeight: pinout.current.scrollHeight,
        clientHeight: pinout.current.clientHeight,
      }),
    )
  }

  React.useEffect(handleScroll, [props.isAllPinsVisible])

  const { scrollTop, scrollHeight, clientHeight } =
    props.pinoutTableScrollParams
  const isUpScrollDisabled = scrollTop === 0
  const isScrollDownDisabled = scrollTop === scrollHeight - clientHeight

  return (
    <div className="embedded-mode-table-wrapper">
      <div className="embedded-mode-table-scroll-header embedded-mode-pinout-header">
        <div className="controls" onClick={goBack}>
          <IconBack />
        </div>
        <Up
          onClick={() => pinout.current.scrollBy(SCROLL_DOWN_PARAMS)}
          className="controls"
          disabledClassName="disabled"
          disabled={isUpScrollDisabled}
        />
      </div>
      <div
        ref={pinout}
        className="embedded-mode-table-scroll-content fix-select"
        onScroll={handleScroll}
      >
        <table className="embedded-mode-pinout-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="pinout.columns.pin" />
              </th>
              <th>
                <FormattedMessage id="pinout.columns.color" />
              </th>
              <th>''</th>
              <th>
                <FormattedMessage id="pinout.columns.signalDescription" />
              </th>
            </tr>
          </thead>
          {PinTableBody}
        </table>
      </div>
      <div className="embedded-mode-table-scroll-footer embedded-mode-pinout-footer">
        <Down
          onClick={() => pinout.current.scrollBy(SCROLL_UP_PARAMS)}
          className="controls"
          disabledClassName="disabled"
          disabled={isScrollDownDisabled}
        />
      </div>
    </div>
  )
}

// TODO: add propTypes
const PinoutViewEmbeddedMode = ({ component }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      pinState: state.pinState,
      pathId: state.componentState.pathId || component.cache_id,
      isAllPinsVisible: state.pinState.showEmptyPins,
      pinoutTableScrollParams: state.embeddedModeState.pinoutTableScrollParams,
    }),
    shallowEqual,
  )

  const { pins, jumperPins } = strategyFactory(component)(
    component,
    props.isAllPinsVisible,
  )

  return (
    <PinTable
      pins={pins}
      jumperPins={jumperPins}
      selectedElement={component}
      pathId={props.pathId}
      isAllPinsVisible={props.isAllPinsVisible}
      pinoutTableScrollParams={props.pinoutTableScrollParams}
      onPinClick={(pinObject, pathId, component, pinNumber) => {
        getPinDestination(pathId, component.id, pinNumber)
        dispatch(selectPin(pinNumber, pinObject, component.id))
        dispatch(selectElement(component))
        dispatch(setModal(MODAL_EMBEDDED_MODE_PIN_DESTINATION))
      }}
      showConnectorModal={(pins, imageUrl) => {
        dispatch(setConnectorImage(pins, imageUrl))
        dispatch(setModal(MODAL_CONNECTOR_VIEW))
      }}
    />
  )
}

export default PinoutViewEmbeddedMode
