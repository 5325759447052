import React from 'react'
import PropTypes from 'prop-types'
import IconPlus from './Icons/IconPlus'
import IconMinus from './Icons/IconMinus'
import IconRecenter from './Icons/IconRecenter'

export const ZoomInControl = ({ zoomIn }) => (
  <div className="zoom-in-ctrl">
    <a className="btn" onClick={zoomIn}>
      <IconPlus />
    </a>
  </div>
)

ZoomInControl.propTypes = {
  zoomIn: PropTypes.func,
}

export const ZoomOutControl = ({ zoomOut }) => (
  <div className="zoom-out-ctrl">
    <a className="btn" onClick={zoomOut}>
      <IconMinus />
    </a>
  </div>
)

ZoomOutControl.propTypes = {
  zoomOut: PropTypes.func,
}

export const ZoomResetControl = ({ zoomReset }) => (
  <div className="zoom-reset-ctrl">
    <a className="btn" onClick={zoomReset}>
      <IconRecenter cssClass="icon-recenter" />
    </a>
  </div>
)

ZoomResetControl.propTypes = {
  zoomReset: PropTypes.func,
}

export const ZoomControl = ({ zoomIn, zoomOut, zoomReset }) => (
  <div className="zoom-controls">
    <ZoomInControl zoomIn={zoomIn} />
    <ZoomResetControl zoomReset={zoomReset} />
    <ZoomOutControl zoomOut={zoomOut} />
  </div>
)
