import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { logout } from '../api/auth-api'
import Button from './Button'

const defaultProps = {
  text: 'Forbidden. You do not have permission to access this resource.',
}

class Forbidden extends Component {
  performLogout = () => logout()

  render() {
    return (
      <div id="error-screen">
        <p>{this.props.text}</p>
        <Button
          handleClick={this.performLogout}
          wrapperClass="content-row btn-logout"
        >
          <span data-testid="logout-button">
            <FormattedMessage id="auth.logout" defaultMessage="Logout" />
          </span>
        </Button>
      </div>
    )
  }
}

Forbidden.defaultProps = defaultProps

export default Forbidden
