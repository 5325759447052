import React from 'react'
import IconPlus from '../components/Icons/IconPlus'
import IconMinus from '../components/Icons/IconMinus'
import IconRecenter from '../components/Icons/IconRecenter'

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
  <div className="base-diagram-controls">
    <div className="base-diagram-controls-button-in">
      <IconPlus onClick={() => zoomIn()} />
    </div>
    <div
      className="base-diagram-controls-button-reset"
      data-testid="base-diagram-controls-button-reset"
    >
      <IconRecenter onClick={() => resetTransform()} />
    </div>
    <div className="base-diagram-controls-button-out">
      <IconMinus onClick={() => zoomOut()} />
    </div>
  </div>
)

export default Controls
