import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import store from '../store'
// import {setSubmittingFeedback, unsetSubmittingFeedback} from '../actions/feedback-actions.js'

const api = axiosWrapper.create(
  { baseURL: '/feedback' },
  InterceptorOptions.defaultErrorInterceptor,
)

export function submitFeedback(payload) {
  //store.dispatch(setSubmittingFeedback())
  return api
    .post(`/`, payload)
    .then((response) => {
      console.log(response)
      // TODO: propagate success to banner message
      //store.dispatch(unsetSubmittingFeedback())
    })
    .catch((error) => {
      //store.dispatch(unsetSubmittingFeedback())
      console.error(error)
    })
}
