import { useLocalStorage } from 'react-use'

export function useRecentSubsystems() {
  const [recentSubsystemIds, setRecentSubsystemIds] = useLocalStorage(
    'functional-diagrams-recent-susbsystems',
    [],
  )
  const setRecentSubsystemId = ({ subsystemId }) => {
    const subsystems = new Set(recentSubsystemIds)
    subsystems.add(subsystemId)
    setRecentSubsystemIds([...subsystems])
  }
  return { recentSubsystemIds, setRecentSubsystemId }
}
