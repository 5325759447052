import { NOTIFICATION_ERROR, NOTIFICATION_GENERIC } from '../constants'
import { push } from 'connected-react-router'
import { setNotification } from '../actions/notification-actions'
import { setUserLoggedOut } from '../actions/user-actions'
import statuses from 'statuses'
import store from '../store'

export function defaultErrorInterceptor(error) {
  if (error.response) {
    const { status, data } = error.response
    switch (status) {
      case statuses('forbidden'):
        handleForbidden()
        break
      case statuses('Internal Server Error'):
        handleError()
        break
      case statuses('Unauthorized'):
        handleUnauthorized()
        break
      case statuses('Unprocessable Entity'):
        // All NOOP (i.e. 422 no-graph) errors are handled by the API calling method.
        return Promise.resolve(error.response)
      case statuses(409):
        // 409 - Conflict is used to identify already existing resource.
        break
      default:
        handleError(data)
    }
  }

  return Promise.reject(error)
}

function handleForbidden() {
  // TODO: Redirect to a forbidden page.
  store.dispatch(
    setNotification(
      NOTIFICATION_ERROR,
      'Forbidden. You do not have permission to access one or more items.',
    ),
  )
}

function handleError(data) {
  if (data && data.message) {
    store.dispatch(setNotification(NOTIFICATION_ERROR, data.message))
  } else if (data instanceof Array && data.length > 0) {
    store.dispatch(setNotification(NOTIFICATION_ERROR, data[0]))
  } else {
    store.dispatch(
      setNotification(
        NOTIFICATION_ERROR,
        'Server was unable to process the request',
      ),
    )
  }
}

function handleUnauthorized() {
  store.dispatch(
    setUserLoggedOut(),
    store.dispatch(
      push(
        `/login?redirect_url=${encodeURIComponent(window.location.pathname)}`,
      ),
    ),
  )
}

export function noCacheInterceptor(config) {
  config.headers.Pragma = 'no-cache'
  return config
}
