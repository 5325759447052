import React from 'react'

const IconMinus = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="36"
      height="7"
      viewBox="0 0 36 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36 0L36 7L-3.0598e-07 7L0 -1.57361e-06L36 0Z" fill="white" />
    </svg>
  )
}

export default IconMinus
