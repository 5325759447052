import * as types from '../actions/action-types'

export function clearInlineComponents() {
  return {
    type: types.CLEAR_INLINE_COMPONENTS,
  }
}

export function setHarnessesForModel(harnesses) {
  return {
    type: types.SET_HARNESSES_FOR_MODEL,
    harnesses,
  }
}

export function setInlineComponents(components) {
  return {
    type: types.SET_INLINE_COMPONENTS,
    components,
  }
}
export function setHarnessLevels(harnessLevels) {
  return {
    type: types.SET_HARNESS_LEVELS,
    harnessLevels,
  }
}
export function updateStateOfHarnesses(state) {
  return {
    type: types.UPDATE_HARNESS_STATE,
    state,
  }
}
