import React from 'react'

import MustHave from './MustHave'
import { OFFLINE } from '../services/context'

const MustBeOffline = (props) => {
  return <MustHave context={OFFLINE} {...props} />
}

export default MustBeOffline
