import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconClose from './Icons/IconClose'

const propTypes = {
  onClose: PropTypes.func,
  wrapperClassName: PropTypes.string,
}

class CloseButton extends Component {
  render() {
    return (
      <div className={this.props.wrapperClassName}>
        <a onClick={this.props.onClose}>
          <IconClose />
        </a>
      </div>
    )
  }
}

CloseButton.propTypes = propTypes

export default CloseButton
