import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { ERROR_NO_GRAPH } from '../../constants'
import NoGraphContainer from '../../containers/NoGraphContainer'

const propTypes = {
  canBeDeactivated: PropTypes.bool,
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  error: PropTypes.string,
  ignoreError: PropTypes.bool,
}

const defaultProps = {
  enabled: true,
  canBeDeactivated: true,
  ignoreError: false,
}

class TabPane extends Component {
  render() {
    const activeTab =
      this.props.selectedTab && this.props.selectedTab === this.props.id

    const tabClassNames = classNames('tab-pane', {
      active: activeTab,
    })

    if (!this.props.ignoreError && this.props.error === ERROR_NO_GRAPH) {
      return (
        <div className={tabClassNames}>
          <NoGraphContainer />
        </div>
      )
    }

    if (!this.props.enabled) {
      return null
    } else if (!activeTab && this.props.canBeDeactivated) {
      return null
    }

    return <div className={tabClassNames}>{this.props.children}</div>
  }
}

TabPane.propTypes = propTypes
TabPane.defaultProps = defaultProps

const mapStateToProps = (state, { namespace, name, position }) => {
  const selectedTab = get(state.tabState.currentTab, namespace)

  return {
    name,
    position,
    selectedTab,
    error: state.error.error,
  }
}

export default connect(mapStateToProps)(TabPane)
