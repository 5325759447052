import React from 'react'
import { FormattedMessage } from 'react-intl'

export const FieldEntry = ({ label, show = true, children }) =>
  show && (
    <div className="row">
      <label id={`field_entry_${label.replace(/\W+/g, '_').toLowerCase()}`}>
        {label}
      </label>
      {children}
    </div>
  )
export const FormButtons = (props) => (
  <div className="row">
    <div className="modal-btn-group text-center">
      <div className="btn-wrapper">
        <button
          type="button"
          className="btn btn-cancel"
          onClick={props.onCancel}
        >
          <FormattedMessage id="forms.cancel" />
        </button>
        <button type="submit" className="btn">
          <FormattedMessage id="forms.save" />
        </button>
      </div>
    </div>
  </div>
)
