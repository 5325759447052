import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import MoreMenu from '../MoreMenu'
import Button from '../Button'
import './style.scss'
import { setAdditionalComponentFaultMenuVisible } from '../../actions/action-menu'
import { PINOUT_VIEW } from '../../constants'

const AdditionalComponentFaultMenu = ({
  component,
  openModal,
  deviceList = [],
}) => {
  const dispatch = useDispatch()

  const nodes = useSelector(
    (state) => state.componentState.graphData.nodes,
    shallowEqual,
  )
  const edges = useSelector(
    (state) => state.componentState.graphData.edges,
    shallowEqual,
  )
  const isMenuVisible = useSelector(
    (state) => state.additionalComponentFaultMenu[component.id],
  )
  const isPinoutTabSelected =
    PINOUT_VIEW === useSelector((state) => state.tabState.currentTab?.main)

  const menuOptions = []
  const harnessSet = new Set()

  const hideMenuThenOpenModal = (obj) => {
    dispatch(setAdditionalComponentFaultMenuVisible(component.id, false))
    openModal(obj)
  }

  edges
    .filter(
      (edge) =>
        edge.data.edge_type === null &&
        (edge.data.source === component.id ||
          edge.data.target === component.id),
    )
    .forEach((obj) => {
      const harness = obj.data.harness
      if (!harnessSet.has(harness)) {
        menuOptions.push(
          <span>
            <div>
              <FormattedMessage id="tracer.componentListPanel.harness" />
            </div>
            <div className="additional-component-fault-menu-mb">
              <b>{`${harness}`}</b>
            </div>
            <Button
              handleClick={() => hideMenuThenOpenModal(obj.data)}
              uppercase={true}
              small={true}
              wrapperClass="btn-faulty"
            >
              <FormattedMessage id="tracer.componentListPanel.logFailureButton" />
            </Button>
          </span>,
        )
        harnessSet.add(harness)
      }
    })

  const adjacentDeviceIds = []

  edges.forEach((edge) => {
    if (edge.data.edge_type === 'device') {
      if (edge.data.source === component.id) {
        adjacentDeviceIds.push(edge.data.target)
      } else if (edge.data.target === component.id) {
        adjacentDeviceIds.push(edge.data.source)
      }
    }
  })

  deviceList.forEach((device) =>
    menuOptions.push(
      <span>
        <div>
          <FormattedMessage id="tracer.componentListPanel.device" />
        </div>
        <div className="additional-component-fault-menu-mb">
          <b>{`${device.alias}`}</b>
        </div>
        <Button
          handleClick={() => hideMenuThenOpenModal(device)}
          uppercase={true}
          small={true}
          wrapperClass="btn-faulty"
        >
          <FormattedMessage id="tracer.componentListPanel.logFailureButton" />
        </Button>
      </span>,
    ),
  )

  return (
    <div
      className="additional-component-fault-menu-ml"
      data-testid="additional-fault-menu"
    >
      <MoreMenu
        isDisabled={isPinoutTabSelected}
        menuOptions={menuOptions}
        isOpen={isMenuVisible}
        setIsOpen={(visible) =>
          dispatch(
            setAdditionalComponentFaultMenuVisible(component.id, visible),
          )
        }
      />
    </div>
  )
}

export default AdditionalComponentFaultMenu
