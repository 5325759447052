import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'

import createRootReducer from './reducers'
import history from './history'

const middlewares = [routerMiddleware(history), thunk]

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares)),
)

export default store
