import calculateSize from 'calculate-size'

import {
  ICON_WIDTH,
  ICON_WIDTH_PADDING,
  ICON_WIDTH_OFFSET,
} from '../icons/constants'
import { getIconByCategory } from '../icons/DeviceIcon'

const NODE_PADDING = 30
const PORT_PADDING = 5

export const calculateTextSize = (text) => {
  return calculateSize(text, {
    font: 'Montserrat", sans-serif',
    fontSize: '14px',
  })
}

export const getNodeWidthByContent = ({ parent, ports }) => {
  const totalPortWidth = ports.reduce((totalWidth, port) => {
    return totalWidth + port.width
  }, 0)

  const nodeLabelWidth = calculateTextSize(parent.data('label')).width

  const iconWidth = getIconByCategory(parent.data('category')) ? ICON_WIDTH : 0
  const innerContentWidth = nodeLabelWidth + iconWidth
  let width =
    totalPortWidth > innerContentWidth
      ? totalPortWidth + ports.length * PORT_PADDING
      : innerContentWidth

  // Label is centered. Add extra space for icon if needed.
  if ((width - nodeLabelWidth) / 2 < iconWidth) {
    width += iconWidth * 2 + ICON_WIDTH_PADDING + ICON_WIDTH_OFFSET
  }

  return width + NODE_PADDING
}
