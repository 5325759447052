import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Label, useCanvas } from 'reaflow'
import { CloneElement } from 'rdk'
import classNames from 'classnames'

import { curveBundle, line } from 'd3-shape'
import { getBezierPath, getPathCenter } from './utils'
import './CustomEdge.scss'

/*
  Edge is based on reaflow Edge component.
 */
export const Edge = ({
  sections,
  interpolation = 'curved',
  properties,
  labels,
  className,
  containerClassName,
  disabled,
  removable = true,
  selectable = true,
  upsertable = true,
  style,
  children,
  label = <Label />,
  onClick = () => undefined,
  onKeyDown = () => undefined,
  onEnter = () => undefined,
  onLeave = () => undefined,
  onRemove = () => undefined,
  onAdd = () => undefined,
}) => {
  const pathRef = useRef(null)
  const [center, setCenter] = useState(null)
  const { selections, readonly } = useCanvas()
  const isActive = selections?.length
    ? selections.includes(properties?.id)
    : false
  const isDisabled = disabled || properties?.disabled
  const canSelect = selectable && !properties?.selectionDisabled
  const dataTestId = properties?.dataTestId

  // The "d" attribute defines a path to be drawn. See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
  const d = useMemo(() => {
    if (!sections?.length) {
      return null
    }

    // Handle bend points that elk gives
    // us separately from drag points
    if (sections[0].bendPoints) {
      const points = sections
        ? [
            sections[0].startPoint,
            ...(sections[0].bendPoints || []),
            sections[0].endPoint,
          ]
        : []

      let pathFn = line()
        .x((d) => d.x)
        .y((d) => d.y)
      if (interpolation !== 'linear') {
        pathFn =
          interpolation === 'curved'
            ? pathFn.curve(curveBundle.beta(1))
            : interpolation
      }
      return pathFn(points)
    } else {
      return getBezierPath({
        sourceX: sections[0].startPoint.x,
        sourceY: sections[0].startPoint.y,
        targetX: sections[0].endPoint.x,
        targetY: sections[0].endPoint.y,
      })
    }
  }, [sections])

  useEffect(() => {
    if (sections?.length > 0) {
      setCenter(
        getPathCenter(
          pathRef.current,
          sections[0].startPoint,
          sections[0].endPoint,
        ),
      )
    }
  }, [sections])

  const edgeChildProps = {
    edge: properties,
    center,
    pathRef,
  }

  return (
    <g
      className={classNames('edge', containerClassName, {
        ['disabled']: isDisabled,
        ['selectionDisabled']: !canSelect,
      })}
    >
      <path
        id={properties?.id}
        ref={pathRef}
        style={style}
        className={classNames('path', properties?.className, className, {
          ['active']: isActive,
        })}
        d={d}
        markerEnd="url(#end-arrow)"
        data-testid={dataTestId}
      />
      <path
        className={'clicker'}
        d={d}
        tabIndex={-1}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if (!isDisabled && canSelect) {
            onClick(event, properties)
          }
        }}
        onKeyDown={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if (!isDisabled) {
            onKeyDown(event, properties)
          }
        }}
        onMouseEnter={(event) => {
          event.stopPropagation()
          if (!isDisabled) {
            onEnter(event, properties)
          }
        }}
        onMouseLeave={(event) => {
          event.stopPropagation()
          if (!isDisabled) {
            onLeave(event, properties)
          }
        }}
      />
      {children && (
        <>
          {typeof children === 'function' ? children(edgeChildProps) : children}
        </>
      )}
      {labels?.length > 0 &&
        labels.map((l, index) => (
          <CloneElement
            element={label}
            key={index}
            edgeChildProps={edgeChildProps}
            {...l}
          />
        ))}
    </g>
  )
}
