import React from 'react'

const Spinner = () => (
  <div
    className="text-center"
    style={{
      color: 'white',
      width: '100%',
      height: '100%',
    }}
  >
    <i
      className="fa fa-circle-o-notch fa-spin fa-5x fa-fw"
      style={{ position: 'relative', top: '40%' }}
    />
  </div>
)

export default Spinner
