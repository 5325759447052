import React, { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import './style.scss'

export const Loader = ({ showLoader }) => {
  return (
    <div className="loader">
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        className="loader-spinner"
      />
    </div>
  )
}
