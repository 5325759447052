import React from 'react'

import MustHave from './MustHave'
import { ONLINE } from '../services/context'

const MustBeOnline = (props) => {
  return <MustHave context={ONLINE} {...props} />
}

export default MustBeOnline
