import React from 'react'

import { WHITE_COLOR } from '../../constants'

const defaultProps = {
  color: WHITE_COLOR,
  height: '24',
  width: '24',
  skipFill: false,
}

const IconSameIssuesHistory = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  const svgProps = {}
  if (!props.skipFill) {
    svgProps.fill = props.color
  }

  return (
    <svg
      height={props.height}
      viewBox={viewBox}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fillRule="evenodd">
        <g {...svgProps}>
          <path d="M14.125 22h-.113A1.15 1.15 0 0 1 13 21.11L10.412 7.53 8.8 12.354c-.15.445-.563.742-1.05.742H2V10.87h4.925l2.737-8.125c.15-.482.638-.779 1.125-.742.488.037.938.408 1.013.89l2.625 13.913 1.5-5.565c.225-.223.525-.371.825-.371H22v2.226h-4.762l-2.063 8.2c-.15.407-.6.704-1.05.704z" />
        </g>
      </g>
    </svg>
  )
}

IconSameIssuesHistory.defaultProps = defaultProps

export default IconSameIssuesHistory
