import { useQuery } from 'react-query'
import { apiGetShifts } from '../api/shifts'

export const KEY = 'shifts'

export const useShifts = ({ plant_name }) => {
  const {
    status,
    data: shifts,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [KEY, plant_name],
    async () => {
      const result = await apiGetShifts({ plant_name })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!plant_name,
    },
  )
  return {
    status,
    shifts,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
