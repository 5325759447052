import React from 'react'
import { Popover } from 'antd'
import { FormattedMessage } from 'react-intl'
import { func, string } from 'prop-types'

import { login } from '../../api/auth-api'
import { BLACK_COLOR, ORIENTATION_UP } from '../../constants'
import IconChevron from '../Icons/IconChevron'

import iconUsername from '../../assets/images/ic-username.svg'

function Collapsible(props) {
  const { username, onClick } = props

  return (
    <div data-testid="collapsible" onClick={onClick} className="collapsible">
      <div className="username">
        <span>{username}</span>
        <img src={iconUsername} alt="user" />
      </div>
      <span data-testid="toggle-icon" className="toggle-icon">
        <IconChevron orientation={ORIENTATION_UP} />
      </span>
    </div>
  )
}

function ClickableItem({ onClick, messageKey, defaultLabel, testId }) {
  return (
    <div style={{ padding: '10px' }}>
      <a
        onClick={onClick}
        className="content-row btn btn-link"
        data-testid={testId || null}
        style={{ padding: '0 10px' }}
      >
        <FormattedMessage
          id={messageKey}
          defaultMessage={defaultLabel || null}
        />
      </a>
    </div>
  )
}

function Content(props) {
  const {
    onChangeStartView,
    openFeedbackModal,
    onLogout,
    tracerVersion,
    clientId,
    userIsSignedIn,
    openSelectPlantModal,
    openSelectStationAlertModal,
  } = props
  return (
    <div className="content" id="user-menu-popover">
      {userIsSignedIn ? (
        clientId && (
          <>
            <div className="content-row username">{clientId}</div>
            <div data-testid="separator" className="separator" />
          </>
        )
      ) : (
        <a
          style={{ color: BLACK_COLOR, padding: 0 }}
          onClick={() => login()}
          className="btn btn-link"
        >
          <FormattedMessage id="auth.signin" defaultMessage="Sign In" />
        </a>
      )}
      <div style={{ margin: 0, padding: '10px 20px' }}>
        <ClickableItem
          onClick={onChangeStartView}
          messageKey="userMenu.changeStartView"
          defaultLabel="Change your start view"
          testId="change-start-view"
        />
        <ClickableItem
          onClick={openFeedbackModal}
          messageKey="userMenu.feedback"
          defaultLabel="Feedback"
          testId="feedback"
        />
        <ClickableItem
          onClick={openSelectPlantModal}
          messageKey="userMenu.selectPlant"
          defaultLabel="Select Plant"
          testId="select-plant"
        />
        <ClickableItem
          onClick={openSelectStationAlertModal}
          messageKey="userMenu.selectStationAlert"
          defaultLabel="Select Report Filter"
          testId="select-station-alert"
        />
        {userIsSignedIn && (
          <ClickableItem
            onClick={onLogout}
            messageKey="auth.logout"
            defaultLabel="Logout"
            testId="logout-button"
          />
        )}
      </div>
      <div data-testid="separator" className="separator" />
      <div data-testid="tracer-version" className="content-row tracer-version">
        <FormattedMessage
          id="userMenu.tracerVersionLabel"
          defaultMessage="Tracer Version"
        />
        {':'} {tracerVersion}
      </div>
    </div>
  )
}

function UserMenu(props) {
  const { className, username, clientId, ...remainingProps } = props

  return (
    <div className={className} data-testid="user-menu">
      <Popover
        trigger="click"
        placement="bottom"
        content={() => <Content clientId={clientId} {...remainingProps} />}
      >
        <Collapsible username={username} />
      </Popover>
    </div>
  )
}

UserMenu.propTypes = {
  username: string,
  clientId: string,
  tracerVersion: string.isRequired,
  onLogout: func.isRequired,
}
UserMenu.defaultProps = {
  username: null,
}

UserMenu.defaultProps = {
  username: '',
}

export default UserMenu
