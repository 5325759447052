import React, { Component } from 'react'
import NotificationGenericContainer from '../containers/NotificationGenericContainer'
import NotificationErrorContainer from '../containers/NotificationErrorContainer'
import NotificationNetworkErrorContainer from '../containers/NotificationNetworkErrorContainer'

import {
  NOTIFICATION_GENERIC,
  NOTIFICATION_ERROR,
  NOTIFICATION_NETWORK_ERROR,
} from '../constants'

class Notification extends Component {
  getContainer() {
    if (this.props.containers[this.props.container]) {
      return this.props.containers[this.props.container]
    }

    return false
  }

  render() {
    const container = this.getContainer()
    if (!container) {
      return null
    }

    const containerElement = React.createElement(container, null, null)

    return <div className="notification slideIn">{containerElement}</div>
  }
}

const containers = {
  [NOTIFICATION_GENERIC]: NotificationGenericContainer,
  [NOTIFICATION_ERROR]: NotificationErrorContainer,
  [NOTIFICATION_NETWORK_ERROR]: NotificationNetworkErrorContainer,
}

Notification.defaultProps = {
  containers,
}

export default Notification
