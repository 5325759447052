import React, { useEffect, useRef, useState } from 'react'
import { Player, BigPlayButton } from 'video-react'
import 'video-react/dist/video-react.css'
import guidesData from '../constants/guides.json'
import { Table, Tag, Modal, Radio, Space } from 'antd'
import { getUserInfo } from '../api/auth-api'
import { selectAppropriateLocale } from '../helpers/utils'

import {
  getConfigGuidesInfo,
  getGuidesRecord,
  saveGuidesRecord,
} from '../api/getting-started-api'

const GettingStartedContainer = () => {
  const player = useRef(null)
  const [playerSource, setPlayerSource] = useState('')
  const [showPlayer, setShowPlayer] = useState(true)
  const [pdfSource, setPdfSource] = useState('')
  const [showPdf, setShowPdf] = useState(false)
  const [content, setContent] = useState([])
  const [currentContent, setCurrentContent] = useState(-1)
  const [selected, setSelected] = useState([])
  const [preTestPassed, setPreTestPassed] = useState({})
  const [showFinal, setShowFinal] = useState(false)
  const [finalStatus, setFinalStatus] = useState('')
  const [customer, setCustomer] = useState('')
  const [user, setUser] = useState(null)
  const [dbSave, setDbSave] = useState(false)
  const [questions, setQuestions] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [currentTest, setCurrentTest] = useState(null)
  const [showScore, setShowScore] = useState(false)
  const [score, setScore] = useState(0)
  const [showTest, setShowTest] = useState(false)
  const [currentAnswer, setCurrentAnswer] = useState(null)
  const [userAnswer, setUserAnswer] = useState(null)
  const [title, setTitle] = useState('')
  const [showTestBox, setShowTestBox] = useState(false)

  let guides = []
  let locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    'en-US'
  locale = locale.replace('-', '_')
  locale = selectAppropriateLocale(locale, guidesData.language_support, '_')

  // hooks

  useEffect(() => {
    try {
      handler()
    } catch (e) {
      console.error('Following error has occurred: ' + e)
    }
  }, [])

  useEffect(() => {
    if (showTest) {
      if (guidesData.test_options.randomize_answer_order) {
        for (let question of questions) {
          question.answerOptions = shuffle(question.answerOptions)
        }
      }

      setQuestions(questions)
    }
  }, [questions])

  useEffect(() => {
    try {
      if (dbSave === true) {
        saveStatus(user.username, null, prepareContentStatusSaving())
      }
    } catch (e) {
      console.error('Following error has occurred: ' + e)
    }
  }, [dbSave])

  // main
  const handler = async () => {
    let obj = await getConfigGuidesInfo()

    let userInfo = await getUserInfo()
    setUser(userInfo)

    let dbRec = null
    let userGuideRecord = await getGuidesRecord(userInfo.username)
    if (userGuideRecord && userGuideRecord.data) {
      dbRec = userGuideRecord.data
      setFinalStatus('' || dbRec.final)
    }

    if (!obj['guides_url']) {
      throw new Error('Response does not contain guides_url')
    }
    if (!obj['customer']) {
      throw new Error('Response does not contain customer')
    }
    let url = obj['guides_url']
    let env = obj['customer']
    let listOfGuidesResponse = getListOfGuides(
      env,
      guidesData,
      locale,
      url,
      dbRec,
    )
    if (listOfGuidesResponse.error) {
      throw new Error(listOfGuidesResponse.error)
    } else {
      guides = listOfGuidesResponse.list
      setContent(guides)
      guides = content
    }
  }

  // test button handlers
  const handleCancel = () => {
    setContentStatus(currentContent, 'failed')
    toggleTest(false)
    setDbSave(true)
  }
  const handleOk = () => {
    if (showScore === false) {
      if (userAnswer) {
        if (currentQuestion < questions.length) {
          for (let answerOption of questions[currentQuestion].answerOptions) {
            if (answerOption.isCorrect) {
              if (userAnswer === answerOption.answerText) {
                setScore(score + 1)
              }
            }
          }
        }
        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1)
          setUserAnswer(null)
        } else {
          setShowScore(true)
        }
      }
    } else {
      submitTestResult()
    }
  }

  // Test Business Logic

  const toggleFinalTest = () => {
    let testName = guidesData.final_test_associations[customer]
    setQuestions(guidesData.test[testName][locale])
    setTitle('Final Test')
    setCurrentTest('final')
    toggleTest(true)
  }
  const toggleTest = (show = true) => {
    if (show === true) {
      setShowTest(true)
      setShowScore(false)
      setCurrentQuestion(0)
      setScore(0)
      setCurrentAnswer(null)
      modalUIQuestions()
    } else {
      setShowTest(false)
      setCurrentQuestion(0)
      setScore(0)
      setCurrentAnswer(null)
    }
  }

  const submitTestResult = () => {
    if (showScore) {
      if (score === questions.length) {
        if (currentTest === 'final') {
          setFinalStatus('passed')
          setDbSave(true)
        } else {
          if (
            preTestPassed[currentTest] === true ||
            preTestPassed[currentTest] === false
          ) {
            preTestPassed[currentTest] = true
            setPreTestPassed(preTestPassed)
            let displayFinal = true
            for (const testName in preTestPassed) {
              if (preTestPassed[testName] === false) {
                displayFinal = false
                break
              }
            }
            setShowFinal(displayFinal)
          }

          switchToNextContent()
        }
      } else {
        if (currentTest === 'final') {
          setFinalStatus('failed')
          setDbSave(true)
        } else {
          if (
            preTestPassed[currentTest] === true ||
            preTestPassed[currentTest] === false
          ) {
            preTestPassed[currentTest] = false
            setPreTestPassed(preTestPassed)
            setShowFinal(false)
          }
          setContentStatus(currentContent, 'failed')
          setDbSave(true)
        }
      }
      //submitting the result and closing the score window
      toggleTest(false)
    }
  }

  // Modal Window UI styling
  const modalUIQuestions = () => {
    setAntdHeaderAndTextColor('#0189E9', 'white')
  }

  const modalUIFailure = () => {
    setAntdHeaderAndTextColor('#ff4d4f', 'white')
  }

  const modalUISuccess = () => {
    setAntdHeaderAndTextColor('#52c41a', 'white')
  }

  const setAntdHeaderAndTextColor = (backgroundColor, textColor) => {
    setTimeout(function () {
      let header = document.querySelectorAll('.ant-modal-header')
      if (header.length > 0) {
        header[0].style.backgroundColor = backgroundColor
      }
      let headerText = document.querySelectorAll('.ant-modal-title')
      if (headerText.length > 0) {
        headerText[0].style.color = textColor
      }
    })
  }

  const switchToNextContent = (oldContentStatus = 'completed') => {
    let currentId = currentContent
    let newContent
    if (content.length > 1 && currentId < content.length - 1) {
      newContent = content[currentId + 1]
      handleRowSwitch(
        newContent.id,
        newContent.type,
        newContent.url,
        oldContentStatus,
      )
    } else {
      newContent = content[currentId]
      handleRowSwitch(
        newContent.id,
        newContent.type,
        newContent.url,
        oldContentStatus,
      )
    }
  }

  // Content list playback and management
  const getListOfGuides = (
    env,
    guidesData,
    language,
    contentPrefix,
    record,
  ) => {
    setShowFinal(true)
    let err = null
    let listOfGuides = null

    if (
      !guidesData.hasOwnProperty('associations') ||
      !guidesData.hasOwnProperty('guides')
    ) {
      err = 'Guides JSON is malformed'
      return { list: listOfGuides, error: err }
    }
    let associations = guidesData.associations
    let collection = guidesData.guides
    let assessmentTestAssociations = guidesData.assessment_test_associations
    let assessmentTestName = assessmentTestAssociations[env]

    if (!associations.hasOwnProperty(env)) {
      err = 'No supported environment is found:' + env
      return { list: listOfGuides, error: err }
    }
    setCustomer(env)
    let guideNames = associations[env]
    let guides = []
    if (guideNames.length) {
      for (const [index, guideName] of guideNames.entries()) {
        if (!collection.hasOwnProperty(guideName)) {
          err = 'Missing listed guide:' + guideName
          return { list: listOfGuides, error: err }
        }
        let guide = collection[guideName]
        if (!guide.hasOwnProperty('language')) {
          err = 'Guide ' + guideName + ' is missing language block'
          return { list: listOfGuides, error: err }
        }
        let guideLanguageBlock = guide.language
        if (!guideLanguageBlock.hasOwnProperty(language)) {
          language = 'en_US'
          if (!guideLanguageBlock.hasOwnProperty(language)) {
            err =
              'Guide ' + guideName + ' does not have a suitable language entry'
            return { list: listOfGuides, error: err }
          }
        }
        let guideLanguage = guideLanguageBlock[language]
        if (!guideLanguage.hasOwnProperty('title')) {
          err = 'Guide ' + guideName + ' does not have a suitable title'
        }
        let title = guideLanguage['title']
        let urlParts = guide['file_url'].split('.')
        let url = ''
        if (urlParts.length > 1) {
          let extension = urlParts.pop()
          urlParts.push(language, extension)
          url = contentPrefix + '/' + urlParts.join('.')
        } else {
          if (guide['type'] === 'test') {
            url = guide['file_url']
            if (assessmentTestName !== url) {
              preTestPassed[url] = false
              setPreTestPassed(preTestPassed)
              setShowFinal(false)
            }
          }
        }

        let status = ''
        let isCompleted = false
        if (record && record.content) {
          let row = record.content[guideName]
          if (row) {
            if (row.status === 'viewing') {
              if (row.type === 'video') {
                row.status = 'viewed'
              }
              if (row.type === 'pdf') {
                row.status = 'completed'
              }
              if (row.type === 'test') {
                row.status = 'failed'
              }
            }
            status = row.status
            isCompleted = row.is_completed
          }
        }

        let item = {
          key: index,
          id: index,
          url: url,
          type: guide['type'],
          title: title,
          language: language,
          status: status,
          is_completed: isCompleted,
          name: guideName,
        }
        guides.push(item)
      }
    }
    listOfGuides = guides
    return { list: listOfGuides, error: null }
  }

  const handleRowSwitch = (id, type, url, oldContentStatus, force = false) => {
    let updateObj = {}

    if (currentContent > -1) {
      if (content[currentContent].type === 'video') {
        player.current.pause()
        handleValueChange('')
      }
      if (['selected', 'viewing'].includes(oldContentStatus)) {
        oldContentStatus = 'viewed'
      }
      updateObj[currentContent] = oldContentStatus || 'viewed'
    }
    if (currentContent !== id || force) {
      if (type === 'video') {
        setShowPlayer(true)
        setShowPdf(false)
        setShowTestBox(false)
        updateObj[id] = 'selected'
        handleValueChange(url)
      }
      if (type === 'pdf') {
        setShowPlayer(false)
        setShowPdf(true)
        setShowTestBox(false)
        setPdfSource(url)
        updateObj[id] = 'viewing'
      }

      if (type === 'test') {
        setShowPlayer(false)
        setShowPdf(false)
        setShowTestBox(true)
        setTitle(content[id].title)
        setQuestions(guidesData.test[url][locale])
        setCurrentTest(url)
        updateObj[id] = 'viewing'
        toggleTest(true)
      }
    }

    setCurrentContent(id)
    setSelected([id])
    handleContentChange(updateObj)
    setDbSave(true)
  }

  const setContentStatus = (id, status) => {
    let updateObj = {}
    if (id > -1) {
      updateObj[id] = status
      handleContentChange(updateObj)
      setDbSave(true)
    }
  }

  const handleValueChange = (url) => {
    setPlayerSource(url)
    player.current.load()
  }

  const handleContentChange = (items) => {
    const newContent = content.map((row) => {
      if (Object.keys(items).includes(row.id.toString())) {
        // Need to create a new object here, or else you would be
        // modifyting the existing ref to the row
        return { ...row, status: items[row.id] }
      }
      return row
    })
    setContent(newContent)
  }

  const onRowClick = (row) => {
    let status = ''
    if (currentContent > -1) {
      status = content[currentContent].status
    }
    handleRowSwitch(row.id, row.type, row.url, status, true)
  }

  // Saving Records
  const saveStatus = (username, fullname, data) => {
    saveGuidesRecord(username, null, data)
    setDbSave(false)
  }

  const prepareContentStatusSaving = () => {
    let savedContent = {}
    let data = {}
    for (let item of content) {
      if (item.status === 'selected') {
        continue
      }
      let name = item.name
      savedContent[name] = {
        type: item.type,
        status: item.status,
        is_completed: item.is_completed,
      }
    }
    data['content'] = savedContent
    data['final'] = finalStatus
    return data
  }

  // Misc
  function shuffle(array) {
    return [...array].sort(() => Math.random() - 0.5)
  }

  const finalTestColumns = [
    {
      dataIndex: 'title',
      key: 'title',
      width: '66%',
      render: (showFinal) => {
        if (!showFinal) {
          return guidesData.common.names.test_disabled_title[locale]
        } else {
          return guidesData.common.names.test_enabled_title[locale]
        }
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (showFinal) => {
        let color = ''
        if (!showFinal) {
          color = '#818584'
        } else {
          if (finalStatus === '') {
            color = '#0189E9'
          }
          if (finalStatus === 'passed') {
            color = 'green'
          }
          if (finalStatus === 'failed') {
            color = 'red'
          }
        }
        let status = guidesData.common.names.test_button_text[locale]
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        )
      },
    },
  ]

  const finalButton = [
    {
      key: '0',
      title: showFinal,
      status: showFinal,
    },
  ]

  const columns = [
    {
      title: guidesData.common.names.title[locale] || 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: guidesData.common.names.type[locale] || 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return type.toUpperCase()
      },
    },
    {
      title: guidesData.common.names.status[locale] || 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        status = status.toLowerCase()
        let color = ''
        if (status === 'viewing') {
          color = 'geekblue'
        }
        if (status === 'viewed') {
          color = '#a1d7cf'
        }
        if (status === 'completed') {
          color = 'green'
        }
        if (status === 'selected') {
          color = '#0189E9'
        }
        if (status === 'failed') {
          color = 'red'
        }
        if (status && status !== '') {
          status = guidesData.common.names[status][locale]
        }

        if (color === '') {
          return status
        } else {
          return (
            <Tag color={color} key={status}>
              {status.toUpperCase()}
            </Tag>
          )
        }
      },
    },
  ]

  return (
    <div className="container">
      <div style={{ display: showPlayer ? 'block' : 'none' }}>
        <Player
          ref={player}
          videoId="video-1"
          onEnded={() => switchToNextContent()}
          onPlay={() => setContentStatus(currentContent, 'viewing')}
        >
          <BigPlayButton position="center" />
          <source src={playerSource} />
        </Player>
      </div>
      <div style={{ display: showPdf ? 'block' : 'none' }}>
        <iframe
          src={pdfSource}
          style={{ minWidth: '100%', minHeight: '65vh' }}
        />
      </div>
      <div style={{ display: showTestBox ? 'block' : 'none' }}>
        <div
          style={{
            minWidth: '100%',
            minHeight: '65vh',
            backgroundColor: 'black',
          }}
        />
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          dataSource={content}
          onRow={(record) => ({
            onClick: () => {
              onRowClick(record)
            },
          })}
          pagination={false}
          scroll={{ y: 180 }}
        />
      </div>
      {guidesData.final_test_associations[customer] ? (
        <div className="final-test">
          <Table
            columns={finalTestColumns}
            dataSource={finalButton}
            pagination={false}
            onRow={() => ({
              onClick: () => {
                if (showFinal) {
                  toggleFinalTest()
                }
              },
            })}
          />
        </div>
      ) : (
        <></>
      )}

      {showTest ? (
        <Modal
          title={title}
          open={showTest}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          width={1000}
        >
          <div>
            {showScore ? (
              <div>
                <div className="score-section">
                  You scored {score} out of {questions.length}
                </div>
                {score === questions.length
                  ? modalUISuccess()
                  : modalUIFailure()}
                {score === questions.length ? (
                  <div>Well done! You got all questions right.</div>
                ) : (
                  <div>
                    Try re-watching study materials and once ready, take test
                    again.
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="question-section">
                  <div className={'question-count'}>
                    <span>Question {currentQuestion + 1}</span>/
                    {questions.length}
                  </div>
                  <div className="question-text">
                    {questions[currentQuestion].questionText}
                  </div>
                </div>
                <div className="answer-section">
                  <Radio.Group
                    style={{ width: '100%' }}
                    value={userAnswer}
                    buttonStyle="solid"
                    size="large"
                    onChange={(e) => setUserAnswer(e.target.value)}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {questions[currentQuestion].answerOptions.map(
                        (answerOption) => (
                          <Radio.Button
                            style={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                            }}
                            key={answerOption.answerText}
                            value={answerOption.answerText}
                          >
                            {answerOption.answerText}
                          </Radio.Button>
                        ),
                      )}
                    </Space>
                  </Radio.Group>
                </div>
              </>
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  )
}

export default GettingStartedContainer
