import * as types from '../actions/action-types'
import { HARNESS_VIEW } from '../constants'
import { SELECTION_VALUES } from '../EmbeddedMode/const'

const initialState = {
  actionBarSelection: SELECTION_VALUES.dtc,
  harnessVehicleSwitch: HARNESS_VIEW,
  harnessVehicleSwitchOnPinout: HARNESS_VIEW,
  pinoutTableScrollParams: {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  },
  switchTestTableScrollParams: {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  },
}

const embeddedModeReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.EMBEDDED_SET_ACTION_BAR_SELECTION:
      return Object.assign({}, state, {
        actionBarSelection: action.actionBarSelection,
      })
    case types.EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION:
      return Object.assign({}, state, {
        harnessVehicleSwitch: action.harnessVehicleSwitch,
      })
    case types.EMBEDDED_SET_HARNESS_VEHICLE_SWITCH_SELECTION_ON_PINOUT:
      return Object.assign({}, state, {
        harnessVehicleSwitchOnPinout: action.harnessVehicleSwitch,
      })
    case types.EMBEDDED_SET_DTC_LIST_SCROLL:
      return Object.assign({}, state, {
        dtcList: action.payload,
      })
    case types.EMBEDDED_SET_COMPONENT_LIST_SCROLL:
      return Object.assign({}, state, {
        componentList: action.payload,
      })
    case types.EMBEDDED_SET_PINOUT_TABLE_SCROLL:
      return Object.assign({}, state, {
        pinoutTableScrollParams: action.payload,
      })
    case types.EMBEDDED_SET_SWITCH_TEST_TABLE_SCROLL:
      return Object.assign({}, state, {
        switchTestTableScrollParams: action.payload,
      })
    default:
      return state
  }
}

export default embeddedModeReducer
