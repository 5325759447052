import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import { CancelToken } from 'axios'

export let cancelFetchUsers

const api = axiosWrapper.create(
  {
    baseURL: '/tracer/users',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function fetchUsers(params) {
  return api
    .get('', {
      params: params,
      cancelToken: new CancelToken((c) => {
        cancelFetchUsers = c
      }),
    })
    .then((res) => res.data)
}

export function createUser(userData) {
  return api.post('', userData).then((res) => res.data)
}

export function updateUser(userId, userData) {
  return api.put(`/${userId}`, userData).then((res) => res.data)
}

export function fetchUser(userId) {
  return api.get(`/${userId}`).then((res) => res.data)
}
