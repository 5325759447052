import React, { Component } from 'react'
import Measure from 'react-measure'

class ResizableContent extends Component {
  state = {
    dimensions: null,
  }

  render() {
    return (
      <Measure bounds onResize={this.handleOnResize}>
        {({ measureRef }) => (
          <div
            id={this.props.id}
            ref={measureRef}
            onClick={this.handleClick}
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {this.props.children}
          </div>
        )}
      </Measure>
    )
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleOnResize = (contentRect) => {
    this.setState({ dimensions: contentRect.bounds })
  }

  componentDidUpdate() {
    if (this.state.dimensions) {
      const { height, width } = this.state.dimensions
      this.props.onUpdate(height, width)
    }
  }
}

export default ResizableContent
