import * as types from '../actions/action-types'

const initialState = {
  container: null,
  message: '',
  isOpen: false,
}

const modalReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_NOTIFICATION_CONTAINER:
      return Object.assign({}, state, {
        container: action.container,
        isOpen: true,
        message: action.message,
      })
    case types.UNSET_NOTIFICATION_CONTAINER:
      return initialState
    default:
      return state
  }
}

export default modalReducer
