import React from 'react'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import Modal from 'react-modal'
import Topology from '../SvgViews/Topology'
import {
  MODAL_CANT_SAVE_START_COMPONENT,
  DTC_MAPPING_ACTIVE_UI,
  MODAL_CONNECTOR_VIEW,
  BLACK_COLOR,
} from '../../constants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SvgCircle from '../SvgCircle'
import { getPinDestination } from '../../api/controller-api'
import {
  selectPin,
  clearPinDestination,
  clearSelectedPin,
} from '../../actions/pin-actions'
import { unsetModal } from '../../actions/modal-actions'
import { SET_MODAL_CONTAINER } from '../../actions/action-types'
import CloseButton from '../CloseButton'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { selectElement } from '../../thunks/pin'
import PinDestinationGraphContainer from '../../containers/PinDestinationGraphContainer'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import {
  useAddDTCMapping,
  useActivateDTCMapping,
} from '../../hooks/CrowdsourcedDTCMapping'
import { useDTCMemo } from '../../hooks/DTCMemo'
import PinDetails from '../PinDetails'
import { setModal, setConnectorImage } from '../../actions/modal-actions'
import {
  StartComponentHeader,
  Button,
  TableButton,
} from './CrowdsourcedDTCMapping'
import { Loadable } from '../../components/Loading'

export const PinsTable = ({ vin, componentId, dtcMemoId, intl }) => {
  const addDTCMapping = useAddDTCMapping()
  const activateDTCMapping = useActivateDTCMapping()

  const props = useSelector(
    (state) => ({
      ...state.dtcMapping, // components, selectedComponent, pins, jumperPins, etc.
      ...state.pinState, // pinNumber, currentPin, componentId, pinDestination, etc.
    }),
    shallowEqual,
  )

  const { dtcMemo } = useDTCMemo(dtcMemoId)
  const dispatch = useDispatch()
  const { componentDetails, isLoading, isSuccess } = useComponentDetails({
    vin,
    componentId,
  })
  const currentUser = useSelector(
    (state) => state.user.data.username,
    shallowEqual,
  )

  const selectPinOfComponent = (pin) => {
    if (pin.originalCavityNumber) {
      dispatch(clearPinDestination())
      dispatch(clearSelectedPin())
      getPinDestination(vin, componentDetails.component.id, pin.cavity)
      dispatch(selectPin(pin.cavity, pin, componentDetails.component.id))
      dispatch(selectElement(componentDetails.component))
      dispatch(
        setActiveUIComponent(
          DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_START,
        ),
      )
    }
  }
  const saveDestinationOnly = () => {
    addDTCMapping.mutate(
      {
        destinationComponentId: componentDetails.component.id,
        makeModelId: dtcMemo.make_model_id,
        dtcCode: dtcMemo.dtc,
        acronym: dtcMemo.acronym,
        updatedBy: currentUser,
      },
      {
        onSuccess: () => {
          dispatch(setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS))
        },
        onError: (error, variables, context) => {
          if (error.response.status === 409) {
            activateDTCMapping.mutate(
              { id: error.response.data.existing_id, updatedBy: currentUser },
              {
                onSuccess: () => {
                  dispatch(
                    setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
                  )
                },
              },
            )
          }
        },
      },
    )
  }

  return (
    <div className="mapping-table-wrapper">
      <div className="mapping-table-header">
        <div>
          <FormattedMessage id="crowdsourcedDtcMapping.pins" />{' '}
          {isLoading && (
            <FormattedMessage id="crowdsourcedDtcMapping.loading" />
          )}
        </div>
        <Button
          onClick={saveDestinationOnly}
          disabled={isLoading || !componentDetails}
        >
          <FormattedMessage id="crowdsourcedDtcMapping.saveDestinationOnly" />
        </Button>
      </div>
      <table className="table">
        <thead className="bs-table-header">
          <tr>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.pin" />
            </th>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.color" />
            </th>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.category" />
            </th>
            <th>
              <FormattedMessage id="crowdsourcedDtcMapping.columns.description" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bs-table-body">
          {isSuccess &&
            componentDetails.pins.map((pin) => (
              <tr key={pin.key}>
                <td>{pin.cavity}</td>
                <td>
                  <SvgCircle colors={pin.colors} /> {pin.color_desc}
                </td>
                <td>{pin.circuit_category}</td>
                <td>{pin.signal_desc}</td>
                <td>
                  {!pin.isEmpty && (
                    <TableButton
                      testid="select-start-button"
                      onClick={() => {
                        selectPinOfComponent(pin)
                      }}
                    >
                      <FormattedMessage id="crowdsourcedDtcMapping.selectStart" />
                    </TableButton>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const HARNESS_VIEW = 'harness_view'
const VEHICLE_VIEW = 'vehicle_view'

const ViewBtnGroup = ({ view, setView }) => {
  const vehicleClassNames = classnames('btn', {
    active: view === VEHICLE_VIEW,
  })

  const harnessClassNames = classnames('btn', {
    active: view === HARNESS_VIEW,
  })

  return (
    <div className="btn-group btn-group-justified view-btn-group" role="group">
      <a className={harnessClassNames} onClick={() => setView(HARNESS_VIEW)}>
        <FormattedMessage id="crowdsourcedDtcMapping.harnessView" />
      </a>
      <a className={vehicleClassNames} onClick={() => setView(VEHICLE_VIEW)}>
        <FormattedMessage id="crowdsourcedDtcMapping.vehicleView" />
      </a>
    </div>
  )
}

export const CantSavePopup = injectIntl(function ({ intl }) {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(unsetModal())
  const isOpen = useSelector((state) => state.modalState.isOpen, shallowEqual)
  const selectedElement = useSelector(
    (state) => state.pinState.selectedElement,
    shallowEqual,
  )

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel={intl.formatMessage({
        id: 'crowdsourcedDtcMapping.cantSave.body',
      })}
    >
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-8">
              <div className="panel-title">
                <h4>
                  <FormattedMessage id="crowdsourcedDtcMapping.cantSave.header" />
                </h4>
              </div>
            </div>
            <div className="col-xs-4">
              <CloseButton
                wrapperClassName="close-button-container"
                onClose={closeModal}
              />
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="row" style={{ color: BLACK_COLOR }}>
            <FormattedMessage id="crowdsourcedDtcMapping.cantSave.header" />
          </div>
          <div className="row">
            <FormattedMessage
              id="crowdsourcedDtcMapping.cantSave.body"
              values={{
                alias: selectedElement.alias,
                description: selectedElement.description,
              }}
            />
          </div>
          <div className="row">
            <div className="modal-btn-group text-center">
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={closeModal}
                >
                  <FormattedMessage id="forms.cancel" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
})

const AddConnectorMappingStartComponent = ({ vin, dtcMemoId }) => {
  const addDTCMapping = useAddDTCMapping()
  const activateDTCMapping = useActivateDTCMapping()
  const props = useSelector(
    (state) => ({
      ...state.dtcMapping, // components, selectedComponent, pins, jumperPins, etc.
      ...state.pinState, // pinNumber, currentPin, componentId, pinDestination, etc.
    }),
    shallowEqual,
  )
  const pinDestination = useSelector((state) => state.pinState.pinDestination)

  const currentUser = useSelector(
    (state) => state.user.data.username,
    shallowEqual,
  )
  const dispatch = useDispatch()

  const { dtcMemo, isSuccess } = useDTCMemo(dtcMemoId)
  const pinState = useSelector((state) => state.pinState, shallowEqual)

  const [view, setView] = React.useState(HARNESS_VIEW)

  const openCantSaveModal = () => {
    dispatch({
      type: SET_MODAL_CONTAINER,
      container: MODAL_CANT_SAVE_START_COMPONENT,
    })
  }

  const saveMapping = () => {
    if (props.selectedComponentId === pinState.selectedElement.id) {
      openCantSaveModal()
      return
    }
    addDTCMapping.mutate(
      {
        destinationComponentId: props.selectedComponentId,
        makeModelId: isSuccess && dtcMemo.make_model_id,
        dtcCode: isSuccess && dtcMemo.dtc,
        acronym: isSuccess && dtcMemo.acronym,
        startComponentId: pinState.selectedElement.id,
        updatedBy: currentUser,
      },
      {
        onSuccess: () =>
          dispatch(setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS)),
        onError: (error, variables, context) => {
          if (error.response.status === 409) {
            activateDTCMapping.mutate(
              { id: error.response.data.existing_id, updatedBy: currentUser },
              {
                onSuccess: () => {
                  dispatch(
                    setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
                  )
                },
              },
            )
          }
        },
      },
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="start-component-header">
          <StartComponentHeader dtcMemoId={dtcMemoId} vin={vin} />
          <Button
            secondary={true}
            onClick={() =>
              dispatch(
                setActiveUIComponent(
                  DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT,
                ),
              )
            }
          >
            <FormattedMessage id="crowdsourcedDtcMapping.back" />
          </Button>
          <Button disabled={!pinState.selectedElement.id} onClick={saveMapping}>
            <FormattedMessage id="crowdsourcedDtcMapping.save" />
          </Button>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <ViewBtnGroup setView={setView} view={view} />
            <div id="vehicle-view" className="mapping-vehicle-view-wrapper">
              {view === HARNESS_VIEW ? (
                <Loadable isLoading={isEmpty(pinDestination)}>
                  <PinDestinationGraphContainer
                    zoomInControl={ZoomInControl}
                    zoomOutControl={ZoomOutControl}
                    zoomResetControl={ZoomResetControl}
                  />
                </Loadable>
              ) : (
                <Loadable isLoading={isEmpty(pinDestination)}>
                  <Topology component={pinState.selectedElement} />
                </Loadable>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <PinDetails
              selectedElement={pinState.selectedElement}
              showConnectorModal={(pins, imageUrl) => {
                dispatch(setConnectorImage(pins, imageUrl))
                dispatch(setModal(MODAL_CONNECTOR_VIEW))
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AddConnectorMappingStartComponent
